import { Button, Grid } from '@mui/material'
import React from 'react'
import './nudges.scss';
// import MarkTitle from '../assets/img/MarkTitle.png'
import { Box } from '@mui/system';

const MarkTitle = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/img/MarkTitle.png";


export default function MarkTitleSeen(props) {
    

    return (
        <Grid container className='mt-20 app-section app-container' justifyContent={'center'} alignItems={'center'}>
            <div className='bg-secondary w-100 p-20' style={{borderRadius:'15px'}}>
                <Grid container >
                    <Grid item style={{ flex: 1 }}>
                        <h2 style={{ paddingTop: '10px' }} className='fs-28 fw-700 lh-28 m-0'>{props?.titles} titles watched so far</h2>
                        <h2 className='fs-24 fw-500 lh-24 mt-8 text-muted'>mark titles as seen to increase your cinescore</h2>
                        <Button style={{ marginBottom: '10px' }} onClick={props?.onClick} className='text-selected bg-light rounded-btn mw-100'>update</Button>
                    </Grid>
                    <Box
                        component={'img'}
                        src={MarkTitle}
                        alt='mark title'
                    />
                </Grid>
            </div>
        </Grid>
    )
}
