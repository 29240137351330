import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';


function ConfirmationDialogRaw(props) {
    const { onClose, open, title, mesg } = props;

    const handleCancel = () => {
        onClose(false);
    };

    const handleOk = () => {
        onClose(true);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                {mesg}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    No
                </Button>
                <Button onClick={handleOk}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
}

export default function ConfirmDialog(props) {

    return (
        <>
            <ConfirmationDialogRaw
                id="ringtone-menu"
                keepMounted
                open={props.open}
                onClose={props.handleClose}
                title={props.title}
                mesg={props.mesg}
            />
        </>
    );
}