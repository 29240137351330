import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getOnboardingTitles,
  updateOnboardingTitles,
  clearMessages,
  getOnboardingMediaCount,
} from "../../slices/auth";
import {
  frenziSearch,
  updateWatchList,
  createWatchList,
  clearMessages as clearMessagesCommon,
} from "../../slices/common";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
// import rightImg from "../../assets/icons/right.svg";
import { toast } from "react-toastify";
import BookmarkAction from "../watchlist/BookmarkAction";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

const rightImg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/right.svg";

const style = {
  width: "45%",
  maxWidth: "100vw",
  maxHeight: "100%",
  position: "fixed",
  top: "50%",
  left: "25%",
  transform: "translate(0, -50%)",
  overflowY: "auto",
  p: 4,
  borderRadius: "18px",
};

function Handpick(props) {
  const { mediaCount } = props;
  const [open, setOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const bookmarkMedia = {};
  const [searchText, setSearchText] = useState("");
  const [watchlistName, setWatchlistName] = useState("");
  const [addActiveWatchlist, setAddActiveWatchlist] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [selecetedTitles, setSelecetedTitles] = useState([]);
  const [onboardingTitleList, setOnboardingTitleList] = useState([]);
  const [addedId, setAddeddId] = useState('');

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const common = useSelector((state) => state.common);
  const { onboardingMediaCountData } = auth;


  useEffect(() => {
    if (auth.errMesg && auth.errMesg !== "") {
      helpers.toastError(auth.errMesg);
      dispatch(clearMessages());
    }
    if (auth.successMesg && auth.successMesg !== "") {
      helpers.toastSuccess(auth.successMesg);
      dispatch(clearMessages());
      if (auth.successMesg === "Like status changed") {
        window.location.href = "/";
      }
    }
  }, [auth]);

  useEffect(() => {
    if (common.errMesg && common.errMesg !== "") {
      helpers.toastError(common.errMesg);
      dispatch(clearMessagesCommon());
    }
    if (common.successMesg && common.successMesg !== "") {
      helpers.toastSuccess(common.successMesg);
      dispatch(clearMessagesCommon());
    }
  }, [common]);

  useEffect(() => {
    dispatch(getOnboardingTitles());
    dispatch(getOnboardingMediaCount());
  }, []);

  useEffect(() => {
    if (searchText && searchText !== "") {
      dispatch(frenziSearch({ val: searchText }));
    }
  }, [searchText]);

  useEffect(() => {
    if (
      auth.onboardingTitles &&
      auth.onboardingTitles.results &&
      auth.onboardingTitles.results.length > 0
    ) {
      setOnboardingTitleList(
        helpers.removeDuplicate([
          ...onboardingTitleList,
          ...auth.onboardingTitles.results,
        ])
      );
    }
  }, [auth.onboardingTitles]);

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const handleSelectedHandpick = () => {
    if (selecetedTitles && selecetedTitles.length > 2) {
      var body = {
        media_list: selecetedTitles,
      };
      dispatch(updateOnboardingTitles(body));
    } else {
      helpers.toastError("Please select atleast 3 title");
    }
  };

  const handleSelectedTitles = (id) => {
    const tempArr = selecetedTitles;
    if (tempArr.includes(id)) {
      const index = tempArr.indexOf(id);
      if (index > -1) {
        tempArr.splice(index, 1);
      }
      setSelecetedTitles([...tempArr]);
    } else {
      if (selecetedTitles.length < 3) {
        tempArr.push(id);
        setSelecetedTitles([...tempArr]);
      }
    }
  };

  const handleOnsearchMediaClick = (x) => {
    handleSelectedTitles(x.media);
    setOnboardingTitleList(
      helpers.removeDuplicate([x, ...onboardingTitleList])
    );
    setSearchText("");
  };

  // const handleBookmarkClick = (data) => {
  //   setAddActiveWatchlist("");
  //   setBookmarkMedia(data);
  //   dispatch(getUserWatchListDetail(data.media));
  //   handleOpen();
  // };

  const handleCreateWatchlist = () => {
    if (watchlistName && watchlistName !== "") {
      const body = {
        is_public: isPublic,
        category_name: watchlistName,
        medias: [bookmarkMedia.media],
      };
      dispatch(createWatchList(body));
    } else {
      toast.warning("Watchlist name is required.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleAddToWatchlist = (data) => {
    setAddActiveWatchlist(addActiveWatchlist === data.uuid ? "" : data.uuid);
  };

  const handleAddToWatchlistContinue = () => {
    helpers.trackMoengageEvent('FR3_Pre_Title_Pref', { title_continue_click: MOENGAGE_ATTRIBUTE.YES });

    if (addActiveWatchlist && addActiveWatchlist !== "") {
      const body = {
        medias: [bookmarkMedia.media],
      };
      dispatch(updateWatchList({ id: addActiveWatchlist, body: body }));
    } else {
      toast.warning("Please select a watchlist", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <Paper elevation={10} className="bg-secondary p-10 mt-20 ">
        <Grid align="center" className="relative">
          <ArrowBackRoundedIcon onClick={() => props.setActiveSteps(props.activeSteps - 1)} sx={{ position: 'absolute', top: { xs: '26px', sm: '7px' }, left: { xs: '22px', sm: '50px' } }} className='pointer' />
          <Typography className="fs-32 fw-700 ls-1 lh-32 mt-20">
            {" "}
            <b>
              {/* <span className="fs-32 fw-700 text-selected ls-1 lh-32">
                {onboardingMediaCountData ? onboardingMediaCountData : mediaCount}
              </span>{" "} */}
              tell us <span className="text-selected">3</span>  titles you like
            </b>
          </Typography>
          <Grid item className="pt-20">
            <Typography variant="subtitle" className="fs-14 fw-600 ">
              to handpick <span className="text-selected">10</span> titles for you from <span className="text-selected">{onboardingMediaCountData ? onboardingMediaCountData : mediaCount}</span> titles
            </Typography>
          </Grid>
          <Paper
            component="form"
            style={{ height: 40 }}
            className="mw-320 w-100 d-flex ai-center br-25 relative mt-10"
          >
            <IconButton
              className={`p-5 h-100 br-25 ${isInputFocused ? "left-search-btn-focused" : ""
                }`}
              aria-label="menu"
            >
              <SearchIcon
                className={`${!isInputFocused ? "text-light-grey" : ""
                  } ml-5 pt-2`}
                color="error"
              />
            </IconButton>
            <InputBase
              className="f-1 fs-14 pl-10 lh-18 ls-1 fw-500"
              placeholder="search for your favorite titles"
              inputProps={{ "aria-label": "search for your favorite titles" }}
              onChange={(e) => setSearchText(e.target.value)}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              value={searchText}
            />
            {searchText &&
              searchText !== "" &&
              common.mediaSearchResults &&
              common.mediaSearchResults.results &&
              common.mediaSearchResults.results.length > 0 && (
                <Box className="handpick-movie-search-panel">
                  <Grid>
                    {common.mediaSearchResults.results.map((x, i) => {
                      return (
                        <Grid
                          container
                          className="pointer"
                          onClick={() => handleOnsearchMediaClick(x)}
                        >
                          <Grid>
                            <Box
                              component="img"
                              sx={{
                                height: 53,
                                width: 65,
                                maxHeight: { xs: 53, sm: 53 },
                                maxWidth: { xs: 65, sm: 65 },
                                borderRadius: "8px",
                                padding: "5px",
                              }}
                              alt="poster"
                              src={x.poster ? helpers.getFullUrl(x.poster) : ""}
                            />
                          </Grid>
                          <Grid>
                            <h2 className="text-dark fs-14 lh-14 fw-700">
                              {x.title ? x.title : "N/A"}
                            </h2>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              )}
          </Paper>

          <Grid
            container
            justifyContent={"flex-start"}
            className="language-container mt-20 h-50 mhr-60 pl-80 pr-80"
            spacing={1}
          >
            {onboardingTitleList.length > 0 &&
              onboardingTitleList.map((x, i) => {
                return (
                  <Grid item xs={6} sm={4} md={3} className="mt-5 " key={i}>
                    <Grid
                      item
                      className="w-102 h-155 movie-container d-inline-block"
                    >
                      <img
                        alt=""
                        src={x.poster ? helpers.getFullUrl(x.poster) : helpers.getDefaultImage('suggestion')}
                        className="handpick-poster-icon pointer"
                        onClick={() => handleSelectedTitles(x.media)}
                      />
                      <BookmarkAction size={28} data={x} addedId={addedId} setAddedId={setAddeddId} />

                      {selecetedTitles.includes(x.media) && (
                        <div
                          className="selected-overlay-rect"
                          onClick={() => handleSelectedTitles(x.media)}
                        >
                          <img alt="" src={rightImg} />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
          <Button
            onClick={() => handleSelectedHandpick()}
            className="rounded-btn bg-selected text-light mt-20 mb-20 fs-14 mw-320 fw-500"
          >
            Continue
          </Button>
        </Grid>
      </Paper>
      <Modal
        open={open && !common.isLoading}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="bg-primary" sx={style}>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography className="fs-28 fw-600 lh-32 mw-270">
                {" "}
                hooray, you’ve discovered{" "}
                <span className="fs-28 fw-600 text-selected  lh-32">
                  watchlists
                </span>{" "}
              </Typography>
            </Grid>
            <Grid item>
              <img
                alt=""
                src={
                  bookmarkMedia.poster
                    ? helpers.getFullUrl(bookmarkMedia.poster)
                    : ""
                }
                className="handpick-poster-icon"
              />
            </Grid>
          </Grid>
          {common.userWatchlistDetail &&
            common.userWatchlistDetail.results &&
            common.userWatchlistDetail.results.length === 0 && (
              <>
                <Grid item>
                  <Typography className="">
                    create <b>new</b> watchlist
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent={"space-between"}
                  className="mt-24"
                >
                  <Grid item>
                    <TextField
                      id="outlined-basic"
                      onChange={(e) => setWatchlistName(e.target.value)}
                      label="Watchlist Name"
                      value={watchlistName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        label="Public"
                        control={
                          <GreenSwitch
                            checked={isPublic}
                            onChange={(e) => setIsPublic(e.target.checked)}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Button
                  className="rounded-btn bg-selected text-light mt-20 mb-20 fs-14 fw-500"
                  onClick={() => handleCreateWatchlist()}
                >
                  Create
                </Button>
              </>
            )}
          {common.userWatchlistDetail &&
            common.userWatchlistDetail.results &&
            common.userWatchlistDetail.results.length > 0 && (
              <>
                <Grid item>
                  <Typography className="">
                    create <b>new</b> watchlist
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent={"space-between"}
                  className="mt-24"
                >
                  <Grid item>
                    <TextField
                      id="outlined-basic"
                      onChange={(e) => setWatchlistName(e.target.value)}
                      label="Watchlist Name"
                      value={watchlistName}
                      variant="outlined"
                    />
                  </Grid>
                  <Button
                    className="rounded-btn bg-selected text-light w-100 mw-240 mt-15 fs-14 fw-500"
                    onClick={() => handleCreateWatchlist()}
                  >
                    Create
                  </Button>
                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        label="Public"
                        control={
                          <GreenSwitch
                            checked={isPublic}
                            onChange={(e) => setIsPublic(e.target.checked)}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>

                <Grid item className="mt-32">
                  <Typography className="">
                    add to <b>existing</b> watchlist
                  </Typography>
                </Grid>
                <Grid container className="language-container" spacing={0.5}>
                  {common.userWatchlistDetail.results.map((x, i) => {
                    return (
                      <Grid item align="center" key={i} className="mt-14">
                        <Grid
                          item
                          align="center"
                          className="pointer relative"
                          onClick={() => handleAddToWatchlist(x)}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: 58,
                              width: 72,
                              maxHeight: { xs: 58, sm: 58 },
                              maxWidth: { xs: 72, sm: 72 },
                              borderRadius: "8px",
                            }}
                            alt="poster"
                            src={x.poster ? helpers.getFullUrl(x.poster) : ""}
                          />
                          {(addActiveWatchlist === x.uuid ||
                            x.already_added) && (
                              <div className="overlay-selected-watchlist">
                                <img alt="" src={rightImg} />
                              </div>
                            )}
                          <Typography className="fs-12 fw-600 lh-17">
                            {x.category_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Button
                  className="rounded-btn bg-selected text-light mt-20 mb-20 fs-14 fw-500"
                  onClick={() => handleAddToWatchlistContinue()}
                >
                  Continue
                </Button>
              </>
            )}
        </Box>
      </Modal>
    </>
  );
}

export default Handpick;
