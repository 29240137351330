import React from 'react'
import './_heading.scss'
import { Grid } from '@mui/material'


export default function SectionHeading(props) {
    return (
        <Grid item xs={12} className=''>
            <h2 className='fs-24 fw-600 lh-30 m-0'>{props.title}</h2>
            {/* <h2 className='fs-24 fw-400 text-muted lh-28 m-0'>{props.subtitle}</h2> */}
        </Grid>
    )
}
