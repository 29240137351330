import React, { useState } from 'react'
import parse from 'html-react-parser';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { HELPER_ACCORDION_TEXT } from '../../lang/HelperText';
import { useSearchParams } from 'react-router-dom';

export default function ContentAccordion({ data, isFull, title }) {

    const [open, setOpen] = useState(false);
    const [searchParams] = useSearchParams();

    let is_free_check = searchParams.get('is_free');
    let is_free = (is_free_check === true || is_free_check === 'true') ? true : false;


    let heading = HELPER_ACCORDION_TEXT[is_free ? 'FREE' : 'PRIVATE'][title] || null;
    let desc = HELPER_ACCORDION_TEXT[is_free ? 'FREE' : 'PRIVATE'][data] || null;

    return (
        <div className='component-accordian'>
            <div onClick={() => setOpen(!open)} className={`${isFull ? '' : 'full-container'} pointer acrdn relative pt-20 pb-20 pr-50 ${open ? 'open' : ''}`}>
                <h1 className='fs-20' style={{ margin: 0 }}>{heading}</h1>
                <p className='fs-14'>{parse(desc || '')}</p>
                <KeyboardArrowDownRoundedIcon
                    className='text-selected absolute pointer mt-20 fs-40'
                    onClick={() => setOpen(!open)}
                    style={{
                        right: 0,
                        top: 0,
                        transform: open ? 'rotate(180deg)' : ''
                    }}
                />
                {!open ?
                    <div class="content-header--gradient"></div>
                    : ''
                }
            </div>
        </div>
    )
}
