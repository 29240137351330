import React, { useEffect, useRef, useState } from "react";
import { Paper, IconButton, InputBase, Grid, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import SideFilter from "./SideFilter";
import { Box } from "@mui/system";
import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPreferences,
} from "../../slices/auth";
import { clearMessages as clearMessagesMedia } from "../../slices/media";
import {
  getAdvancedSearchResults,
  getMediaSearch,
  getPersonSearch,
  getRecentSearches,
  getSearchAutoComplete,
  getTopSearches,
  resetSearchAutoComplete,
} from "../../slices/search";
import {
  clearMessages,
  getGenreMedia,
  getLanguageMedia,
  getPlatformMedia,
} from "../../slices/trending";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import CommonMediumTiles from "../common/CommonMediumTiles";
import "./_search.scss";
import TrendingPlatform from "../trending/TrendingPlatform";
import PreferMood from "../trending/PreferMood";
import PreferLanguage from "../trending/PreferLanguage";
import Loader from "../common/Loader";
import { Link } from "react-router-dom";
import AdvancedSearch from "./AdvancedSearch";
import { setSearchString, toggleAdvanceSearch } from "../../slices/global";
import CastCrew from "../media/CastCrew";
import MyHelmet from "../common/MyHelmet";
import MyAds from "../adsense/MyAds";

const page = 1;

export default function Search() {
  const [filterPage, setFilterPage] = useState(1);
  const [tempFilter, setTempFilter] = useState({});

  const [tabvalue, setTabvalue] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [searchvalue, setSearchvalue] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedPlatformOttName, setSelectedPlatformOttName] = useState("")
  const [selectedGenreName, setSelectedGenreName] = useState("")
  const [selectedLanguageName, setSelectedLanguageName] = useState("")

  const inputRef = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const _search = useSelector((state) => state.search);
  const trending = useSelector((state) => state.trending);
  const media = useSelector((state) => state.media);
  const search = useSelector((state) => state.search);
  const _global = useSelector((state) => state.global);
  const { isAdvanceSearch, searchString } = _global;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus();
    var body = {
      page: page,
      pagelimit: 10,
      is_free: false,
    };
    dispatch(getTopSearches(body));
    dispatch(getRecentSearches(body));
    dispatch(getMediaSearch(body));
    dispatch(getPersonSearch(body));
    dispatch(getAllPreferences({ is_public: _global.is_public }));
  }, []);

  useEffect(() => {
    if (auth.successMesg && auth.successMesg !== "") {
      dispatch(getAllPreferences({ is_public: _global.is_public }));
    }
  }, [auth]);

  useEffect(() => {
    if (trending.errMesg && trending.errMesg !== "") {
      helpers.toastError(trending.errMesg);
      dispatch(clearMessages());
    }
    if (trending.successMesg && trending.successMesg !== "") {
      helpers.toastSuccess(trending.successMesg);
      dispatch(clearMessages());
    }
  }, [trending]);

  useEffect(() => {
    if (media.successMesg && media.successMesg !== "") {
      helpers.toastSuccess(media.successMesg);
      dispatch(
        getAdvancedSearchResults({
          body: tempFilter,
          page: filterPage,
          pagelimit: 21,
          is_public: _global.is_public,
        })
      );
      dispatch(clearMessagesMedia());
      dispatch(getMediaSearch);
    }
  }, [media]);

  useEffect(() => {
    if (selectedPlatform && selectedPlatform != "") {
      var body = {
        page: page,
        pagelimit: 10,
        id: selectedPlatform,
        is_public: _global.is_public,
      };
      dispatch(getPlatformMedia(body));
    }
  }, [selectedPlatform]);

  useEffect(() => {
    if (selectedGenre && selectedGenre != "") {
      var body = {
        page: page,
        pagelimit: 10,
        id: selectedGenre,
        is_public: _global.is_public,
      };
      dispatch(getGenreMedia(body));
    }
  }, [selectedGenre]);

  useEffect(() => {
    if (selectedLanguage && selectedLanguage != "") {
      var body = {
        page: page,
        pagelimit: 10,
        id: selectedLanguage,
        is_public: _global.is_public,
      };
      dispatch(getLanguageMedia(body));
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (searchString && searchString != "") {
      if (searchString.length > 2) {
        dispatch(
          getSearchAutoComplete({ query: searchString, page: 1, pagelimit: 20 })
        );
        dispatch(
          getMediaSearch({
            query: searchString,
            page: 1,
            pagelimit: 20,
            is_public: _global.is_public,
          })
        );
        dispatch(
          getPersonSearch({
            query: searchString,
            page: 1,
            pagelimit: 20,
            is_public: _global.is_public,
          })
        );
      }
    } else {
      dispatch(resetSearchAutoComplete());
      setTabvalue(false);
    }
  }, [searchString]);

  const handleSearchTextChange = (e) => {
    dispatch(setSearchString(e.target.value));
  };

  const handleToggledownUp = (e) => {
    if (e.code === "Enter") {
      setOpen(true);
      setSearchvalue(searchString);
    } else if (e.code !== "Enter") {
      setOpen(false);
    }
  };

  const handleEnteredSearch = (e) => {
    if (!tabvalue && e.code === "Enter") {
      setTabvalue(true);
    } else {
      if (e.target.value == "") {
        setTabvalue(false);
      }
    }
  };

  const handleApplyFilter = (filter) => {
    setTempFilter({ ...filter });
    helpers.trackMoengageEvent("FR3_Find", {
      apply_click: MOENGAGE_ATTRIBUTE.YES,
      ref_page: document.referrer,
    });
    if (filter) {
      dispatch(
        getAdvancedSearchResults({
          body: filter,
          page: 1,
          pagelimit: 21,
          is_public: _global.is_public,
        })
      );
      document.getElementsByClassName("fixed-full-view") &&
        document.getElementsByClassName("fixed-full-view")[0] &&
        document.getElementsByClassName("fixed-full-view")[0].scrollTo(0, 0);
    }
    dispatch(toggleAdvanceSearch({ value: true }));
  };

  useEffect(() => {
    if (tempFilter && isAdvanceSearch) {
      dispatch(
        getAdvancedSearchResults({
          body: tempFilter,
          page: filterPage,
          pagelimit: 21,
          is_public: _global.is_public,
        })
      );
    }
  }, [filterPage]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        search.advancedSearchPageData &&
        search.advancedSearchPageData &&
        search.advancedSearchPageData.pagination &&
        search.advancedSearchPageData.pagination.is_next_page &&
        !search.isLoading
      ) {
        if (
          window.innerHeight + window.scrollY + 500 >=
          document.body.offsetHeight
        ) {
          setFilterPage(filterPage + 1);
        }
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [filterPage, search]);

  return (
    <>
      <MyHelmet
        title={"Search for your favourite titles"}
        description={"Find Your Favorites Titles, Search for Your Preferred Movies and Series and Discover Where to Stream Them Online!"}
      />
      <Grid>
        {isAdvanceSearch ? (
          <Grid className="app-container">
            {_search.isLoading && <Loader />}
            <AdvancedSearch
              onClose={() => dispatch(toggleAdvanceSearch({ value: false }))}
              isLoading={search.isLoading}
              handleApplyFilter={handleApplyFilter}
              tempFilter={tempFilter}
            />
          </Grid>
        ) : (
          <Grid className="new-container pb-10 ml-30">
            <div className="search-box-container">
              <Grid container>
                <Grid item>
                  <Grid container>
                    <Typography className="fw-700 fs-28 lh-28 text-selected">
                      search
                    </Typography>
                  </Grid>
                  <Grid container>
                    {/* <Typography className="fw-500 fs-24 lh-28 text-muted">
                      titles, cast, crew
                    </Typography> */}
                    <Typography className="fw-500 fs-18 lh-28 text-muted">
                      titles, cast, crew
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <div className="relative pb-10">
                <Grid container justifyContent={"center"} sx={{ marginTop: { xs: '10px' }, marginBottom: '10px' }}>
                  <Paper
                    style={{ opacity: 1 }}
                    className="d-flex ai-center br-25 relative mb-5 mt-20 searchbar"
                    sx={{ maxHeight: { sm: '30px', xs: '25px' }, minWidth: { sm: '700px', xs: '300px' } }}
                  >
                    <IconButton
                      aria-label="search"
                      className="bg-selected"
                      sx={{
                        borderBottomRightRadius: "0px",
                        borderTopRightRadius: "0px",
                        maxHeight: { xs: '25px', sm: '30px' },
                        maxWidth: { xs: '25px', sm: '30px' }
                      }}
                    >
                      <SearchIcon className="ml-3 pt-2" color="light" />
                    </IconButton>
                    <InputBase
                      className="f-1 fs-16 pl-10 lh-18 ls-1 fw-500"
                      value={searchString}
                      type="text"
                      inputRef={inputRef}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleEnteredSearch}
                      onKeyUp={handleToggledownUp}
                    />
                    <SideFilter
                      onSearchApply={(e) => handleApplyFilter(e)}
                      tempFilter={tempFilter}
                      iconClassName="pointer text-selected bg-light"
                    />
                    <IconButton
                      aria-label="search"
                      onClick={() => dispatch(setSearchString(""))}
                      className="h-100 bg-muted ml-5"
                      sx={{
                        borderBottomLeftRadius: "0px",
                        borderTopLeftRadius: "0px",
                        maxHeight: { xs: '25px', sm: '30px' },
                        maxWidth: { xs: '25px', sm: '30px' }
                      }}
                    >
                      <CloseIcon className="ml-0 pt-2" color="light" />
                    </IconButton>
                  </Paper>
                </Grid>

                {!open &&
                  _search.searchAutoCompleteResult?.results &&
                  _search.searchAutoCompleteResult.results.length > 0 && (
                    <Grid container className="search-result-show">
                      <Grid
                        item
                        className="mh-300 bg-light w-100 br-10"
                        sx={{ overflowY: "auto" }}
                        lg={12}
                      >
                        {_search.searchAutoCompleteResult.results.map(
                          (item, i) => {
                            return (
                              <>
                                <Link key={i} to={helpers.getSearchDynamicLink(item)}>
                                  <Grid
                                    container
                                    className="pl-20 pt-10 pointer ai-center"
                                    alignItems={"center"}
                                  >
                                    <Grid xs={4}
                                      item
                                      className="h-50px mw-65 w-100 pr-20"
                                    >
                                      <Box
                                        component="img"
                                        alt="poster"
                                        src={
                                          item.poster && item.poster !== ""
                                            ? helpers.getFullUrl(item.poster)
                                            : helpers.getDefaultImage(
                                              item.type ? item.type : "search"
                                            )
                                        }
                                        sx={{
                                          height: "65px",
                                          width: "50px",
                                          borderRadius: "7px",
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Typography className="text-dark fw-500 lh-26">
                                        {helpers.getSelectedText(
                                          item?.title ? item?.title : "",
                                          searchString
                                        )}
                                      </Typography>
                                      <Grid container>
                                        <p className="fw-300 fs-14 m-0 p-0 text-muted">
                                          {item.media_type
                                            ? item.media_type
                                            : item.department
                                              ? item.department
                                              : ""}
                                        </p>
                                        {item?.release_date && (
                                          <>
                                            <span className="w-300 fs-14 m-0 p-0 text-muted">
                                              &nbsp;|&nbsp;
                                            </span>
                                            <p className="fw-300 fs-14 m-0 p-0 text-muted">
                                              {new Date(
                                                item.release_date
                                              ).getFullYear()}
                                            </p>
                                          </>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="pl-20 pr-20">
                                    <Box className="w-100">
                                      <Divider />
                                    </Box>
                                  </Grid>
                                </Link>
                              </>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  )}
              </div>
            </div>

            <Grid className='app-section' container justifyContent={'center'}>
              <MyAds type="banner_2" adv_type='web_search_top' hideInMobile={true} />
            </Grid>

            {tabvalue ? (
              <>
                {(_search.mediaSearchData.length > 0 || _search.personSearchData.length > 0) ?
                  <>
                    <>
                      {_search.mediaSearchData.length > 0 && (
                        <>
                          <CommonMediumTiles
                            refPage={"search"}
                            heading={
                              <>
                                titles based on{" "}
                                <span className="text-selected">{searchvalue}</span>
                              </>
                            }
                            subheading={`your search for ${searchvalue}`}
                            data={_search.mediaSearchData}
                            allowAll={true}
                            isNextLink={true}
                            nextLink={`/search-results/media/${searchString}`}
                          />
                        </>
                      )}
                    </>
                    <Grid className='app-section' container justifyContent={'center'}>
                      <MyAds type="banner_2" adv_type='web_search_fold_ad' hideInMobile={true} />
                    </Grid>

                    {_search.personSearchData.length > 0 && (
                      <CastCrew
                        heading={
                          <>
                            people similar to
                            <span className="text-selected">
                              {" "}
                              {`${searchvalue}`}
                            </span>
                          </>
                        }
                        subheading={
                          <span className="text-muted">
                            other film folks you may want to look up
                          </span>
                        }
                        data={_search.personSearchData}
                        nextLink={`/search-results/person/${searchString}`}
                      />
                    )}
                  </>
                  :
                  <>{open && <>
                    <p className="fs-14 text-center">We couldn't find what you are searching for. Meanwhile look at the results below.</p>
                    {_search.recentSearchData &&
                      _search.recentSearchData.length > 0 && (
                        <CommonMediumTiles
                          refPage={"Search"}
                          heading={"recent searches"}
                          subheading={"what you've been curious about"}
                          data={_search.recentSearchData.filter(
                            (item) =>
                              item.media_type &&
                              (item.media_type == "movie" ||
                                item.media_type == "tv")
                          )}
                          allowAll={true}
                        />
                      )}

                    {!_global.is_public &&
                      _search.topSearchData &&
                      _search.topSearchData.length > 0 && (
                        <CommonMediumTiles
                          refPage={"Search"}
                          heading={"most searched on frenzi"}
                          subheading={"what caught your attention on frenzi"}
                          data={_search.topSearchData}
                        />
                      )}
                  </>
                  }
                  </>
                }
              </>
            ) : (
              <>
                {_search.recentSearchData &&
                  _search.recentSearchData.length > 0 && (
                    <CommonMediumTiles
                      refPage={"Search"}
                      heading={"recent searches"}
                      subheading={"what you've been curious about"}
                      data={_search.recentSearchData.filter(
                        (item) =>
                          item.media_type &&
                          (item.media_type == "movie" ||
                            item.media_type == "tv")
                      )}
                      allowAll={true}
                    />
                  )}

                {!_global.is_public &&
                  _search.topSearchData &&
                  _search.topSearchData.length > 0 && (
                    <CommonMediumTiles
                      refPage={"Search"}
                      heading={"most searched on frenzi"}
                      subheading={"what caught your attention on frenzi"}
                      data={_search.topSearchData}
                    />
                  )}
                {(_search.recentSearchData &&
                  _search.recentSearchData.length > 0) || (_search.topSearchData &&
                    _search.topSearchData.length > 0) &&
                  <Grid className='app-section' container justifyContent={'center'}>
                    <MyAds type="banner_2" adv_type='web_search_fold_ad' hideInMobile={true} />
                  </Grid>
                }
                <TrendingPlatform
                  heading={"platform-specific searches"}
                  subheading={"a quick look at titles across platform"}
                  isShowHeading={true}
                  setSelectedPlatformOttName={setSelectedPlatformOttName}
                  selectedPlatformOttName={selectedPlatformOttName}
                  platforms={
                    auth.allPreferences && auth.allPreferences.platforms
                      ? auth.allPreferences.platforms
                      : []
                  }
                  setSelectedPlatform={setSelectedPlatform}
                  selectedPlatform={selectedPlatform}
                  data={
                    trending.platformMediaData ? trending.platformMediaData : []
                  }
                />

                {!_global.is_public && (
                  <>
                    <PreferMood
                      refPage={"Search"}
                      heading={"trending searches across genres"}
                      subheading={"different strokes for different folks"}
                      genres={
                        auth.allPreferences && auth.allPreferences.genres
                          ? auth.allPreferences.genres
                          : []
                      }
                      setSelectedGenre={setSelectedGenre}
                      selectedGenre={selectedGenre}
                      selectedGenreName={selectedGenreName}
                      setSelectedGenreName={setSelectedGenreName}
                      data={
                        trending.genreMediaData ? trending.genreMediaData : []
                      }
                    />
                    <Grid className='app-section' container justifyContent={'center'}>
                      <MyAds type="banner_2" adv_type='web_search_fold_ad' hideInMobile={true} />
                    </Grid>
                    <PreferLanguage
                      heading={"trending searches across languages"}
                      subheading={"titles gaining interest"}
                      languages={
                        auth.allPreferences && auth.allPreferences.languages
                          ? auth.allPreferences.languages
                          : []
                      }
                      refPage={"Search"}
                      setSelectedLanguage={setSelectedLanguage}
                      selectedLanguage={selectedLanguage}
                      selectedLanguageName={selectedLanguageName}
                      setSelectedLanguageName={setSelectedLanguageName}
                      data={
                        trending.langMediaData ? trending.langMediaData : []
                      }
                    />
                  </>
                )}
              </>
            )}

            <Grid className='app-section mt-20' container justifyContent={'center'}>
              <MyAds type="banner_2" adv_type='web_search_bottom' />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
