import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

// import GoogleLogo from '../../assets/icons/GoogleLogo.svg'

const GoogleLogo = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/GoogleLogo.svg";

function MyGoogleLogin(props) {

    const googleLogin = useGoogleLogin({
        scope: "email profile https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/userinfo.profile",
        onSuccess: async (tokenResponse) => {
            console.log(tokenResponse);
            const userInfo = await axios.get(
                'https://www.googleapis.com/oauth2/v3/userinfo',
                { headers: { Authorization: `Bearer ${tokenResponse?.access_token}` } },
            );
            props.onSuccess(userInfo?.data, tokenResponse?.access_token);
        },
        onError: errorResponse => console.log(errorResponse),
    });

    return (
        <div>
            <button className='rounded-btn google-btn pointer text-selected bg-light fs-16' onClick={() => googleLogin()}>
                <img src={GoogleLogo} alt='' />
                Continue with Google
            </button>
        </div>
    );
}
export default MyGoogleLogin;