import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Button, Box } from "@mui/material";
import "./onboarding.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessages,
  getGenreList,
  updateGenrePreference,
} from "../../slices/auth";
import helpers from "../../services/helpers";
import { toggleLoginModal } from "../../slices/global";
import { HELPER_TEXT } from "../../lang/HelperText";
// import rightImg from "../../assets/icons/right.svg";

const rightImg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/right.svg";

function Genre(props) {
  const { showTitle } = props;
  const [selectedGenre, setSelectedGenre] = useState([]);
  const dispatch = useDispatch();
  const _global = useSelector((state) => state.global);
  const { is_public } = _global;


  useEffect(() => {
    dispatch(getGenreList());
  }, []);

  const auth = useSelector((state) => state.auth);
  const { onboardingMediaCountData } = auth;

  useEffect(() => {
    if (auth.errMesg && auth.errMesg !== "") {
      helpers.toastError(auth.errMesg);
      dispatch(clearMessages());
    }
    if (auth.successMesg && auth.successMesg !== "") {
      helpers.toastSuccess(auth.successMesg);
      if (auth.successMesg === "genres preferences updated") {
        dispatch(getGenreList());
      }
      dispatch(clearMessages());
    }
  }, [auth]);

  useEffect(() => {
    if (
      auth.genreList && auth.genreList.genres &&
      auth.genreList.genres.length > 0
    ) {
      var temp = [];
      auth.genreList.genres.map((x, i) => {
        if (x.selected) {
          temp.push(x.order);
        }
      });
      setSelectedGenre([...temp]);
    }
  }, [auth.genreList]);

  const handleSelectedGenre = () => {
    if (selectedGenre.length > 0) {
      var body = {
        genre_preference: selectedGenre,
      };
      dispatch(updateGenrePreference(body));
      if (is_public) {
        dispatch(toggleLoginModal({ isOpen: true, content: HELPER_TEXT.NUDGE_TEXT.ADD_GENRE }))
      }
      props.setActiveSteps(props.activeSteps + 1);
    } else {
      if (is_public) {
        dispatch(toggleLoginModal({ isOpen: true, content: HELPER_TEXT.NUDGE_TEXT.ADD_GENRE }))
      }
      helpers.toastError("Please select atleast one genre");
    }
  };

  const handleGenreSelect = (data) => {
    const tempArr = selectedGenre;
    if (is_public) {
      if (tempArr.includes(data.genre)) {
        const index = tempArr.indexOf(data.genre);
        if (index > -1) {
          tempArr.splice(index, 1);
        }
        setSelectedGenre([...tempArr]);
      } else {
        tempArr.push(data.genre);
        setSelectedGenre([...tempArr]);
      }
    } else {
      if (tempArr.includes(data.order)) {
        const index = tempArr.indexOf(data.order);
        if (index > -1) {
          tempArr.splice(index, 1);
        }
        setSelectedGenre([...tempArr]);
        if (showTitle) {
          var body = {
            genre_preference: tempArr,
          };
          dispatch(updateGenrePreference(body));
        }
      } else {
        tempArr.push(data.order);
        setSelectedGenre([...tempArr]);
        if (showTitle) {
          var body = {
            genre_preference: tempArr,
          };
          dispatch(updateGenrePreference(body));
        }
      }
    }
  };

  return (
    <Paper elevation={10} className="bg-secondary p-10 mt-20 ">
      <Grid align="center">
        {showTitle ?
          <>
            <Typography className="fs-32 fw-700 ls-1 lh-32 mt-20">
              {" "}
              <b>
                <span className="fs-32 fw-700 text-selected ls-1 lh-32">
                  {onboardingMediaCountData ? onboardingMediaCountData : auth.genreList?.medias_count}
                </span>{" "}
                titles of your interest
              </b>
            </Typography>
            <Grid item className="pt-10">
              <Typography
                variant="subtitle"
                className="fs-14 fw-600 ls-1"
              >
                choose genre you watch content in
              </Typography>
            </Grid>
          </>
          :
          <Typography className="fs-32 fw-700 ls-1 lh-32 mt-20">
            genres
          </Typography>
        }
        <Grid
          container
          justifyContent={"flex-start"}
          className="language-container mt-30 h-100 mhr-60"
          // lg={10}
          spacing={2}
        >
          {auth.genreList && auth.genreList.genres &&
            auth.genreList.genres.length > 0 &&
            auth.genreList.genres.map((x, i) => {
              return (
                <Grid item xs={4} sm={3} key={i}>
                  <Grid item className="relative d-inline-block">
                    {(x.image && x.image !== "") || (x.genre_logo) ? (
                      <Box
                        component={'img'}
                        alt=""
                        src={x.image ? x.image : x.genre_logo || ''}
                        className="rounded-container"
                        onClick={() => handleGenreSelect(x)}
                        sx={{
                          maxWidth: { xs: '60px', sm: '75px', md: '100px' },
                          maxHeight: { xs: '60px', sm: '75px', md: '100px' }
                        }}
                      />
                    ) : (
                      <Grid
                        className="rounded-container-box rounded-container"
                        onClick={() => handleGenreSelect(x)}
                        sx={{
                          maxWidth: { xs: '60px', sm: '75px', md: '100px' },
                          maxHeight: { xs: '60px', sm: '75px', md: '100px' }
                        }}
                      >
                        {x.genre}
                      </Grid>
                    )}
                    {selectedGenre.includes(is_public ? x.genre : x.order) && (
                      <Grid
                        className="overlay-selected"
                        onClick={() => handleGenreSelect(x)}
                        sx={{
                          maxWidth: { xs: '60px', sm: '75px', md: '100px' },
                          maxHeight: { xs: '60px', sm: '75px', md: '100px' }
                        }}
                      >
                        <Box
                          component={'img'}
                          alt="" src={rightImg}
                          sx={{
                            maxWidth: { xs: '60px', sm: '75px', md: '100px' },
                            maxHeight: { xs: '60px', sm: '75px', md: '100px' }
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Typography className="fs-16 fw-600 lh-23 pt-7">
                    {x.genre}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
        <Button
          onClick={() => handleSelectedGenre()}
          className="rounded-btn bg-selected text-light mt-20 mb-20 fs-14 mw-320 fw-500"
        >
          Continue
        </Button>
      </Grid>
    </Paper>
  );
}

export default Genre;
