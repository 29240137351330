import axiosInstance from "../http-common";

const getActivityHomeData = () => {
    return axiosInstance.get(`native_main/activitybirthday`);
};

const getActivityNews = (page = 1, pagelimit = 10, is_blog = false) => {
    return axiosInstance.get(`native_main/trendingnewsdata?page=${page}&pagelimit=${pagelimit}&is_blog=${is_blog}`);
};

const getPollList = (page = 1, is_public = false, pagelimit = 10) => {
    return axiosInstance.get(`native_main/${is_public ? 'public_userpolllist' : 'userpolllist'}?page=${page}&pagelimit=${pagelimit}`);
};

const getQuizList = (page = 1, is_public = false, pagelimit = 10) => {
    return axiosInstance.get(`native_main/${is_public ? 'public_userquizlist' : 'userquizlist'}?page=${page}&pagelimit=${pagelimit}`);
};

const addPollResponse = (id, body) => {
    return axiosInstance.post(`native_main/userpolllist`, body);
};

const startQuiz = (id) => {
    return axiosInstance.get(`native_main/startquiz?quiz=${id}`);
};

const addQuizResponse = (id, body) => {
    return axiosInstance.post(`native_main/userquizlist`, body);
};

const haveYouWatched = (page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/haveyouwatched3?page=${page}&pagelimit=${pagelimit}&is_web=yes`);
};

const addActivityBirthday = (body) => {
    return axiosInstance.post(`native_main/activitybirthday`, body);
};


const ActivityDataService = {
    getActivityHomeData,
    getActivityNews,
    getPollList,
    getQuizList,
    addPollResponse,
    startQuiz,
    addQuizResponse,
    haveYouWatched,
    addActivityBirthday
};

export default ActivityDataService;