// src/SearchBar.js
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, ClickAwayListener, Grid, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import "./_search-bar.scss";
import helpers from "../../../services/helpers";
import { Link, useNavigate } from "react-router-dom";

const placeHolderList = [
  "Suggest some feel-good movies",
  "TV Shows similar to Prison Break",
  "Show me some classic romantic comedies",
  "Recommend movies directed by Quentin Tarantino",
  "Srk and Deepika Movies"
];

const SearchBar = (props) => {
  const { suggestions, isLoading, input } = props
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [listIndex, stListIndex] = useState(0);
  const [placeholder, setPlaceholder] = useState(
    placeHolderList[listIndex].slice(0, 0)
  );
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const navigate = useNavigate();

  // const suggestions1 = helpers.getSearchHistoryData()

  const suggestions1 = useMemo(() => {
    return helpers.getSearchHistoryData(input)
  }, [input])

  useEffect(() => {
    const intr = setInterval(() => {
      setPlaceholder(placeHolderList[listIndex].slice(0, placeholderIndex));
      if (placeholderIndex + 1 > placeHolderList[listIndex].length) {
        setPlaceholderIndex(0);
        if (listIndex < placeHolderList.length - 1) {
          stListIndex(listIndex + 1);
        } else {
          stListIndex(0);
        }
      } else {
        setPlaceholderIndex(placeholderIndex + 1);
      }
    }, 120);
    return () => {
      clearInterval(intr);
    };
  });

  const handleInputChange = (event) => {
    props.setInput(event.target.value);
    // setOpen(event.target.value?.length > 0);
    props.onChange(event.target.value)
  };

  const handleSuggestionClick = (suggestion) => {
    props.setInput(suggestion);
    helpers.setSearchHistoryData(suggestion)
    navigate(`/partner/results?q=${suggestion}`);
    setOpen(false);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const isOpen = open && suggestions1?.length > 0;

  const inputHeight = inputRef.current?.offsetHeight
    ? inputRef.current.offsetHeight / 2
    : null;
  const radius = `${inputHeight}px` ? inputHeight : "25px";

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className="search-bar-container" sx={{ position: "relative" }}>
        <SearchIcon id="searchIcon" className="search-icon" />
        <input
          placeholder={placeholder}
          value={input}
          ref={inputRef}
          onChange={handleInputChange}
          onKeyUp={(e) => {
            props.onKeyUp(e)
            if (e.key === 'Enter') {
              setOpen(false)
            }
          }}
          onClick={() => setOpen(true)}
          className="seacrh-box-input"
          style={{
            borderTopLeftRadius: radius,
            borderTopRightRadius: radius,
            borderBottomLeftRadius: isOpen ? "0px" : radius,
            borderBottomRightRadius: isOpen ? "0px" : radius,
          }}
        />
        {isOpen ? (
          <div
            className="suggestion-container"
            style={{
              borderBottomLeftRadius: radius,
              borderBottomRightRadius: radius,
            }}
          >

            {suggestions1.map((x, i) => {
              return (
                <div onClick={() => handleSuggestionClick(x?.query)} className="suggestion-list-item" key={i}>
                  <p className="p-0 m-0" style={{ color: '#000' }}>{x?.query}</p>
                </div>
              )
            })}
            {/* {suggestions.map((x, i) => {
              return (
                <div className="suggestion-list-item" key={i}>
                  <Link onClick={() => {
                    if (x.media_type === 'movie' || x.media_type === 'tv') {
                      helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', props?.refPage);
                    } else if (x.watchlist_id && x.watchlist_id !== "") {
                      helpers.watchlistClick(x?.category_name || x?.watchlist, x?.watchlist_id, props?.refPage);
                    }
                  }} to={helpers.getDynamicLink(x)}>
                    <Grid container style={{ alignItems: 'center' }}>
                      <Grid item>
                        <Box
                          component="img"
                          className='d-block'
                          sx={{
                            // height: 40,
                            width: 30,
                            // maxHeight: { xs: 30, sm: 40 },
                            maxWidth: { xs: 20, sm: 30 },
                            borderRadius: "7px",
                            objectFit: 'cover',
                            objectPosition: 'top',
                          }}
                          alt={x?.title || 'poster'}
                          src={x?.poster_path ? helpers.getFullUrl(x?.poster_path, 300) : helpers.getDefaultImage('trendingmedia')}
                        />
                      </Grid>
                      <Grid className="pl-10">
                        <p className="p-0 m-0" style={{ color: '#000' }}>{x?.title}</p>
                      </Grid>
                    </Grid>
                  </Link>
                </div>
              );
            })} */}
          </div>
        ) : (
          ""
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SearchBar;
