import { Button, Grid } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
// import MessageIcon from '../../assets/icons/MessageIcon.png'
// import LocationImg from '../../assets/icons/LocationImg.png'
// import TeleImg from '../../assets/icons/TeleImg.png'
import FormControl from '@mui/material/FormControl';

import { OutlinedInput } from '@mui/material';
import MyHelmet from '../common/MyHelmet'
import './careers.scss'

const MessageIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/MessageIcon.png";
const LocationImg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/LocationImg.png";
const TeleImg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/TeleImg.png";

export default function Career() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
        <MyHelmet
            title = {"career with frenzi"}
            description = {"Join Frenzi for an exciting journey, where exceptional colleagues and challenging problems create a remarkable workplace. Explore our open positions now."}
        />
            <Grid container className='app-container' sx={{ marginBottom: '50px' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent={'center'} alignItems='center'>
                        <Grid item xs={12} sm={6} className=''>
                            <h1 className='fs-42 fw-800 lh-45'>Join the frenzi squad</h1>
                            <h2 className='fs-16 lh-25 fw-300 text-muted'>Think you’ll be a great fit? Share your details with us
                                <br />
                                <span className='text-muted'>and we will reach out.</span>
                            </h2>

                            <Grid item xs={8}>
                                <Grid container className='jc-sb mt-50'>
                                    <Grid item xs={2}>
                                        <Link>
                                            <Box component={'img'}
                                                alt='message'
                                                src={MessageIcon}
                                            />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <a href="mailto:sales@myfrenzi.com">support@myfrenzi.com</a>
                                    </Grid>
                                </Grid>
                                <Grid container className='jc-sb mt-50'>
                                    <Grid item xs={2}>
                                        <Link>
                                            <Box component={'img'}
                                               alt='location'
                                                src={LocationImg}
                                            />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className='fs-16'>Kandivali</p>
                                    </Grid>
                                </Grid>
                                <Grid container className='jc-sb mt-50'>
                                    <Grid item xs={2}>
                                        <Link>
                                            <Box component={'img'}
                                                alt='phone'
                                                src={TeleImg}
                                            />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <a href="tel:+91 9876543211">+91 9876543211</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className='bg-light br-10 p-50' xs={12} sm={6} sx={{ marginTop: '50px' }}>
                            <Box component="form">
                                <p>
                                    <h1 className='fw-700 fs-35 lh-50 pb-20 mt-20 text-dark'>Little bit about you...</h1>
                                </p>
                                <Grid item xs={12}>
                                    <FormControl className='mb-10 w-100'>
                                        <Grid item>
                                            <p className='text-dark m-0'>Name</p>
                                            <OutlinedInput className='border mh-50 mb-10 pr-50 w-100' />
                                        </Grid>
                                        <p className='text-dark m-0'>Phone</p>
                                        <OutlinedInput className='border w-100 mh-50 mb-10' />
                                        <p className='text-dark m-0'>Email</p>
                                        <OutlinedInput className='border w-100 mh-50 mb-10' />

                                        <p className='text-dark m-0'>Why do you want to join Frenzi?</p>
                                        <OutlinedInput variant='outlined' className='border br-8' sx={{ minHeight: '220px' }} />
                                    </FormControl>
                                </Grid>


                            </Box>
                            <p>
                                <span className='text-dark'>
                                    <input type="file" className='text-dark'></input>
                                </span>
                            </p>
                            <p>
                                <Button className='rounded-btn mw-240 bg-selected text-light fs-14 fw-500 lh-16 ml-20'>submit</Button>
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
