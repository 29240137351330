import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { countries } from "country-list-json";
import { useDispatch, useSelector } from "react-redux";
import { handleFilters } from "../../slices/frenziPartner";

const IND = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/IND.svg";

const defaultCountry = {
  name: "India",
  dial_code: "+91",
  code: "IN",
  flag: "🇮🇳",
};

export default function CountrySelection(props) {
  const { isPartner } = props;
  const [open, setOpen] = useState(false);
  const [countryCode, setCountryCode] = useState(defaultCountry);
  const { advFilters } = useSelector((state) => state.frenziPartner);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (value) => {
    setCountryCode(value);
    props.onSelect && props?.onSelect(value);
    dispatch(handleFilters({ ...advFilters, available_countries: [value?.code] }));
    handleClose();
  };

  console.log("countries--", countries);

  const getImageLink = (code) => {
    return `https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${code}.svg`;
  };

  return (
    <div>
      <Box sx={{ minWidth: "50px" }} className="text-center">
        {/* <p className='fs-40 p-0 m-0 text-center'>{defaultCountry?.flag}</p> */}

        {isPartner ? (
          <FormControl size="small" className="country-selection">
            <Select
              labelId="demo-simple-select-label"
              id="country-selection"
              value={countryCode.code}
              label="country code"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              size="small"
              renderValue={(selected) => (
                <Box
                  component="img"
                  alt="logo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    maxWidth: {xs: 30, sm: 36, md: 44, lg: 50 },
                  }}
                  src={getImageLink(selected)}
                />
              )}
            >
              {countries &&
                countries.length > 0 &&
                countries.map((x, i) => {
                  return (
                    <MenuItem onClick={() => handleChange(x)} key={i}>
                      <Grid container justifyContent={"space-between"}>
                        <Grid>{x.name}</Grid>
                        <Grid>
                          <Box
                            component="img"
                            alt="logo"
                            sx={{
                              width: "100%",
                              height: "auto",
                              maxWidth: { xs: 30, sm: 36, md: 44, lg: 50 },
                              marginBottom: "10px",
                            }}
                            src={getImageLink(x?.code)}
                          />
                        </Grid>
                      </Grid>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        ) : (
          <img alt={defaultCountry?.name} src={IND} />
        )}
      </Box>
    </div>
  );
}
