import { Button, Grid } from '@mui/material'
import React from 'react'
import { Box } from '@mui/system';
// import ClearIcon from '../../../assets/icons/ClearIcon.svg'
import { clearSearchHistory } from '../../../slices/settings';
import { useDispatch } from 'react-redux';
import helpers from '../../../services/helpers';

const ClearIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ClearIcon.svg";

export default function SecuritySettings() {

    const dispatch = useDispatch()

    const handleClearSearch = ()=>{
        dispatch(clearSearchHistory()).then((resp) => {
            if(resp.payload && resp.payload.message){
                helpers.toastSuccess(resp.payload.message)
            }
        })
     }
    return (
        <>
                <Grid container justifyContent={'space-between'}>
                    <Grid item xs={12} sm={10}>
                        <Grid container justifyContent={{xs:'left', sm:'left'}} className={'mb-30'}>
                                <Box component={'img'}
                                    sx={{ marginRight: "10px" }}
                                    src={ClearIcon}
                                />                          
                            <Grid item>
                                <p className='fw-500 fs-18 m-0'>clear search history</p>
                                <p className='fw-500 fs-14 text-muted m-0'>delete what you have searched</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2} className='text-left'>
                        <Button onClick={()=>handleClearSearch()} className='mw-150 rounded-btn bg-light text-selected'>delete</Button>
                    </Grid>
                </Grid>
           
        </>
    )
}
