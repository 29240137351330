import {
  ClickAwayListener,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./common.scss";

export default function SeasonView(props) {
  const { data } = props;
  const [isShow, setIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    if (data.season && data.season[0] && data.season[0]) {
      setSelectedData({ ...data.season[0] });
    }
  }, [data]);

  const getView = (item) => {
    return (
      <Grid
        container
        onClick={() => {
          setSelectedData({ ...item });
          setIsShow(!isShow);
        }}
        alignItems={"center"}
        className="pointer h-100"
        justifyContent="space-between"
        sx={{ minWidth: '220px' }}
      >
        <Grid xs={9}>
          <Grid container alignItems={'center'} spacing='2' className="p-0 m-0">
            <Grid item className="pt-0 fs-16 fw-500">
              {item.name ? item.name : "N/A"}
            </Grid >
            <Grid item sx={{ marginTop: '-3px' }} className="pt-0 fs-16 fw-300 text-pink"> | </Grid >
            <Grid item className="pt-0 fs-12 fw-300 text-muted">
              {item.episode_count && item.episode_count < 10
                ? item.episode_count + " Episode"
                : item.episode_count + " Episodes"}
            </Grid >
          </Grid>
        </Grid>
        <Grid xs={3} className="text-right">
          <Grid container alignItems={"center"} flexWrap="nowrap" justifyContent="flex-end">
            <span className="fs-12 fw-300 year">
              {item.air_date ? new Date(item.air_date).getFullYear() : ""}
            </span>
            <ArrowDropDownIcon className="text-selected" />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsShow(false)}>
        <Grid
          item
          xs={12}
          className={`${props.className} season-view-box relative`}
        >
          <div
            className="default-view pr-20 pl-20"
            onClick={() => setIsShow(!isShow)}
          >
            {getView(selectedData)}
          </div>
          {isShow && data.season && data.season.length > 0 && (
            <div className="hidden-view-container" style={props?.style}>
              {data.season.map((x, i) => {
                return (
                  <div className="hidden-view pr-20 pl-20" key={i}>
                    {getView(x)}
                  </div>
                );
              })}
            </div>
          )}
        </Grid>
      </ClickAwayListener>
    </>
  );
}
