import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';

import MediumTile from '../common/MediumTile';
import axios from 'axios';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import { MEDIUM_TILE_BREAKPOINTS } from '../../services/helpers';



export default function FanMedia(props) {
    
    const [mediaList, setMediaList] = useState([]);

    
    

    useEffect(() => {
        if (props.fanId && props.fanId !== "") {
            // var body = {
            //     id: props.fanId,
            //     page: page,
            //     pagelimit: 10
            // }
            // dispatch(getPersonMedia(body));

            const baseUrl = process.env.REACT_APP_BASE_URL;
            var localData = localStorage.getItem("frenzi_user_access");
            var token = localData && JSON.parse(localData).access;
            if (token && token !== '') {
                var config = {
                    method: 'post',
                    url: baseUrl + 'native_main/personmedia?person=' + props.fanId + '&pagelimit=20',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                };

                axios(config)
                    .then(function (response) {
                        if (response.data && response.data.data) {
                            if (response.data.data.results) {
                                setMediaList([...response.data.data.results]);
                            }
                        }
                        // props.setIsLoading(false);
                    })
                    .catch(function (error) {
                        // console.log(error);
                        // props.setIsLoading(false);
                    });
            }

        }
    }, [])

    return (
        <>
            {mediaList && mediaList.length > 0 &&
                <Grid container className='app-container app-section section-border-bottom' sx={{ paddingTop: { xs: '20px', sm: '30px' }, paddingBottom: { xs: '20px', sm: '30px' } }} >
                    <Grid item xs={12}>
                        <Grid item >
                            <h2 className='fw-700 fs-28 lh-28 m-0 ellipsis'>
                                {props?.type === 'actor' ? 'more of ' : 'stories by '} <span className='text-selected'>{props.name ? props.name : ''}</span>
                            </h2>
                            {/* <h2 className='fw-500 fs-24 lh-24 mt-0 pt-5 mb-10'>
                                your most watched {props?.type}
                            </h2> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Swiper
                            slidesPerView={2}
                            spaceBetween={30}
                            // slidesPerGroup={5}
                            freeMode={true}
                            speed={1000}
                            navigation={true}
                            breakpoints={MEDIUM_TILE_BREAKPOINTS}
                            modules={[FreeMode, Navigation]}
                            className="mySwiper"
                        >
                            {mediaList.map((x, i) => {
                                return <SwiperSlide key={i}>
                                    <div style={{ padding: '20px 0px 20px 4px' }} key={i}>
                                        <MediumTile x={x} refPage={props?.refPage} />
                                    </div>
                                </SwiperSlide>
                            })}
                        </Swiper>
                    </Grid>
                </Grid>
            }
        </>

    )
}
