import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SingleMediaTile from '../../common/SingleMediaTile';
import { getCollectionMediaData } from '../../../slices/trending';
import Loader from '../../common/Loader';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import SideFilter from '../../search/SideFilter';
import parse from 'html-react-parser';
import YoutubeModalPlayer from '../../common/YoutubeModalPlayer';
import MyHelmet from '../../common/MyHelmet';

const defaultFilter = {
    service: [],
    genre: [],
    language: [],
    content_type: [],
    exclude_already_watched: '',
    sort: '',
    apply_filter: false
}
const sort = 'd';

export default function CollectionDetail() {
    const [tempFilter, setTempFilter] = useState(defaultFilter);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const is_free = searchParams.get('is_free') && searchParams.get('is_free') == 'true' ? true : false;
    const categoryName = searchParams.get('name');
    const collectionType = searchParams.get('collection_type');

    const trending = useSelector(state => state.trending);
    const { collectionMediaData, collectionMediaPageData, isLoading } = trending;

    useEffect(() => {
        getData(sort, tempFilter);
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        getData(sort, tempFilter);
    }, [page, sort])

    const getData = (sort, filter) => {
        var body = {
            page: page,
            pagelimit: 20,
            id: params.id,
            sort: sort,
            body: { ...filter, ['sort']: sort },
            is_free: is_free
        }

        if (params && params.id && params.id != '') {
            dispatch(getCollectionMediaData({ ...body, media_type: 'movie', collectionType: collectionType }));
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            if (collectionMediaPageData && collectionMediaPageData && collectionMediaPageData.pagination && collectionMediaPageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, trending]);

    const handleApplyFilter = (filter) => {
        if (filter) {
            if (params && params.id && params.id != '') {
                setTempFilter(filter);
                if (page == 1) {
                    getData(sort, filter);
                } else {
                    setPage(1);
                }
            }
            window.scrollTo(0, 0);
        }
    }

    return (
        <>
            <MyHelmet />
            {isLoading && <Loader />}
            <Grid className='new-container'>
                {collectionType == 'youtube' ?
                    <>
                        <Grid container alignItems={'center'} className="pt-20" justifyContent='space-between'>
                            <Grid item>
                                <Grid container alignItems={'center'}>
                                    <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                                    <p className='fs-28 fw-700 lh-28 m-0'>{parse(categoryName)}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ paddingTop: '20px' }} spacing={3}>
                            {collectionMediaData.map((x, i) => {
                                return <Grid key={i} item xs={12} sm={6} md={4}>
                                    <YoutubeModalPlayer ytkey={x?.youtube_link} />
                                </Grid>
                            })}
                        </Grid>
                    </>
                    :
                    <>
                        <Grid container alignItems={'center'} className="pt-30 pb-30" justifyContent='space-between'>
                            <Grid item>
                                <Grid container alignItems={'center'}>
                                    <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                                    <p className='fs-28 fw-700 lh-28 m-0'>{parse(categoryName)}</p>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <SideFilter
                                    onSearchApply={(e) => handleApplyFilter(e)}
                                    hideClearBtn={true}
                                />
                            </Grid>
                        </Grid>

                        {collectionMediaData && collectionMediaData.length > 0 &&
                            <Grid container spacing={2} className='mb-30'>
                                {collectionMediaData.map((x, i) => {
                                    return <Grid item key={i} xs={12} sm={6} md={4} >
                                        <SingleMediaTile refPage={parse(categoryName)} x={x} />
                                    </Grid>
                                })}
                            </Grid>
                        }
                    </>
                }

            </Grid>
        </>
    )
}
