import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../../services/helpers";
import { blockUser, reportUser, getCommonUserData } from "../../../slices/settings";
import FollowUnfollowPerson from "../../common/FollowUnfollowPerson";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import ShareMovie from "../../common/ShareMovie";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import ShareIcon from "@mui/icons-material/Share";

export default function UserProfile(props) {
  const { profileUserDetails, hideProfile } = props;
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const { userCommonData } = settings;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (profileUserDetails?.uuid && profileUserDetails?.uuid !== "") {
      dispatch(getCommonUserData({ uuid: profileUserDetails?.uuid }));
    }
  }, [profileUserDetails]);

  const handleMenuCLick = (type) => {
    if (type === "block") {
      const body = {
        target_user: profileUserDetails?.uuid,
        is_blocked: !profileUserDetails.is_blocked,
      };
      helpers.trackMoengageEvent('FR3_Frd_Profile', {
        block_click: MOENGAGE_ATTRIBUTE.YES,
        user_name: profileUserDetails?.username,
        unique_name: profileUserDetails?.unique_name
      });
      dispatch(blockUser({ body: body }));
    }
    if (type === "report") {
      const body = {
        target_user: profileUserDetails?.uuid,
        is_reported: true,
      };
      helpers.trackMoengageEvent('FR3_Frd_Profile', {
        report_click: MOENGAGE_ATTRIBUTE.YES,
        user_name: profileUserDetails?.username,
        unique_name: profileUserDetails?.unique_name
      });
      dispatch(reportUser({ body: body }));
    }
    if (type === "share") {
      helpers.trackMoengageEvent('FR3_Frd_Profile', {
        share_click: MOENGAGE_ATTRIBUTE.YES,
        user_name: profileUserDetails?.username,
        unique_name: profileUserDetails?.unique_name
      });
    }
    handleClose();
  };

  const trackEvent = (name) => {
    helpers.trackMoengageEvent('FR3_Frd_Profile', {
      [name]: MOENGAGE_ATTRIBUTE.YES,
      user_name: profileUserDetails?.username,
      unique_name: profileUserDetails?.unique_name
    });
  }

  return (
    <Grid
      xs={12}
      sx={{
        paddingBottom: "20px",
        marginTop: { xs: "60px", sm: "80px", md: "100px" },
      }}
      className="section-border-bottom"
    >
      <Grid container spacing={2.5}>
        <Grid item xs={12} sm={4.5} className="br-8 ">
          <Grid
            container
            className="m-0 h-100 jc-center relative br-8  bg-gunpowder"
          >
            <Box
              className="user-profile-img-container"
              sx={{ top: { xs: "-50px", sm: "-70px", md: "-80px" } }}
            >
              <Box
                className="relative w-100"
                sx={{ maxWidth: { xs: 80, sm: 120, md: 140 } }}
              >
                <Box
                  component="img"
                  className="user-profile-img"
                  sx={{
                    height: "100vh",
                    width: "100vw",
                    maxWidth: { xs: 80, sm: 120, md: 140 },
                    maxHeight: { xs: 80, sm: 120, md: 140 },
                  }}
                  alt="profilepic"
                  src={
                    profileUserDetails.profile_image
                      ? helpers.getFullUrl(profileUserDetails.profile_image)
                      : helpers.getDefaultImage("user")
                  }
                />
              </Box>
            </Box>
            {!hideProfile && <>
              <MoreVertRoundedIcon
                onClick={handleClickListItem}
                className="pointer profile-more-icon"
              />
              <Menu
                id={"menbu"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                keepMounted
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box className="pl-20 pr-20">
                  {/* <Grid onClick={() => handleMenuCLick('chat')} container className='pt-10 pb-0 pointer' sm={12} alignItems={'center'} justifyContent={'space-between'}>
                  <p className={`fw-500 fs-16 m-0 pr-20`} >chat</p>
                  <ChatBubbleOutlineRoundedIcon fontSize='16' className='mt-3' />
                </Grid> */}
                  <Grid
                    onClick={() => handleMenuCLick("block")}
                    container
                    className="pt-10 pb-0 pointer"
                    sm={12}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <p className={`fw-500 fs-16 m-0 pr-20`}>{profileUserDetails.is_blocked ? 'unblock' : 'block'}</p>
                    <BlockRoundedIcon fontSize="16" className="mt-3" />
                  </Grid>
                  {!profileUserDetails.is_blocked &&
                    <Grid
                      onClick={() => handleMenuCLick("report")}
                      container
                      className="pt-10 pb-0 pointer"
                      sm={12}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <h2 className={`fw-500 fs-16 m-0 pr-20`}>report</h2>
                      <ReportGmailerrorredRoundedIcon
                        fontSize="16"
                        className="mt-3"
                      />
                    </Grid>
                  }
                  {/* <Grid
                  onClick={() => handleMenuCLick("share")}
                  container
                  className="pt-10 pb-10 pointer"
                  sm={12}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                > */}

                  <ShareMovie
                    width={16}
                    size={16}
                    height={16}
                    hideShare={true}
                    myComponent={true}
                    className={'pt-10 pb-10'}
                    justifyContent="space-between"
                    onClick={() => handleMenuCLick('share')}
                    customComponent={
                      <>
                        <h2 className={`fw-500 fs-16 m-0 pr-20`}>share</h2>
                        <ShareIcon fontSize="16" className="mt-3" />
                      </>
                    }
                  />
                  {/* </Grid> */}
                </Box>
              </Menu>
            </>}

            <Grid
              item
              xs={12}
              sx={{
                paddingTop: { xs: "50px", sm: "60px", md: "72px", lg: "70px" },
              }}
            >
              <Grid container className="jc-center">
                <Grid item>
                  <Typography className="fs-24 fw-700 text-center lh-24">
                    {profileUserDetails?.username}
                  </Typography>
                  <Typography className="fs-16 fw-400 text-center  text-muted pt-5">
                    {profileUserDetails?.unique_name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className="w-100">
                <Grid item xs={12}>
                  <Typography className="fs-20 fw-400 text-center lh-22 p-25 text-muted">
                    {profileUserDetails?.bio}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7.5}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} sm={12}>
              <Grid container className="jc-sb" alignItems={"center"}>
                <Grid
                  item
                  xs={2.6}
                  sm={3}
                  className="bg-gunpowder br-8 p-10"
                  sx={{ border: "2px solid grey" }}
                >
                  <Typography className="fs-32 fw-700 text-center  lh-30 p-0">
                    {userCommonData?.common_watchlists
                      ? userCommonData?.common_watchlists
                      : 0}
                  </Typography>
                  <Typography className="fs-16 fw-300 text-center lh-16">
                    common watchlists
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1.5}
                  sx={{ borderTop: "2px solid white" }}
                ></Grid>
                <Grid item xs={4.8} sm={3} className="bg-muted br-8 p-10">
                  <Typography className="fs-20 fw-700 text-dark text-center lh-30">
                    CINEMATCH
                  </Typography>
                  <Typography className="fs-32 fw-700 text-selected text-center lh-32">
                    {profileUserDetails?.cinematch}%
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1.5}
                  sx={{
                    borderTop: "2px solid white",
                    position: "relative",
                    top: "50%",
                  }}
                ></Grid>
                <Grid
                  item
                  xs={2.6}
                  sm={3}
                  className="bg-gunpowder br-8 p-10"
                  sx={{ border: "2px solid grey" }}
                >
                  <Typography className="fs-32 fw-700 text-center lh-30">
                    {userCommonData?.common_titles_count
                      ? userCommonData?.common_titles_count
                      : 0}
                  </Typography>
                  <Typography className="fs-16 fw-300 text-center lh-16">
                    common titles
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Grid container className="jc-sb" sx={{ alignItems: "end" }}>
                <Grid
                  item
                  xs={hideProfile ? 5.7 : 2.7}
                  className={`bg-gunpowder ${hideProfile ? 'pointer-disabled' : ''} br-8  p-20`}
                >
                  {hideProfile ?
                    <>
                      <Typography className="fs-32 fw-700 text-center lh-32">
                        {helpers.getSocialNumbers(profileUserDetails?.followers)}
                      </Typography>
                      <Typography className="fs-16 fw-300 text-center lh-20">
                        followers
                      </Typography>
                    </>
                    :
                    <Link onClick={() => trackEvent('followers_click')} to={`/followers?user_id=${profileUserDetails.uuid}&name=${profileUserDetails?.username}`}>
                      <Typography className="fs-32 fw-700 text-center lh-32">
                        {helpers.getSocialNumbers(profileUserDetails?.followers)}
                      </Typography>
                      <Typography className="fs-16 fw-300 text-center lh-20">
                        followers
                      </Typography>
                    </Link>
                  }
                </Grid>

                <Grid item xs={hideProfile ? 5.7 : 2.7} className={`bg-gunpowder ${hideProfile ? 'pointer-disabled' : ''} br-8 p-20`}>
                  {hideProfile ?
                    <>
                      <Typography className="fs-32 fw-700 text-center lh-32">
                        {helpers.getSocialNumbers(profileUserDetails?.following)}
                      </Typography>
                      <Typography className="fs-16 fw-300 text-center lh-20">
                        Following
                      </Typography>
                    </>
                    :
                    <Link onClick={() => trackEvent('followers_click')} to={`/following?user_id=${profileUserDetails.uuid}&name=${profileUserDetails?.username}`}>
                      <Typography className="fs-32 fw-700 text-center lh-32">
                        {helpers.getSocialNumbers(profileUserDetails?.following)}
                      </Typography>
                      <Typography className="fs-16 fw-300 text-center lh-20">
                        Following
                      </Typography>
                    </Link>
                  }
                </Grid>
                {!hideProfile &&
                  <Grid item xs={6} sm={6}>
                    <FollowUnfollowPerson
                      data={profileUserDetails}
                      isLarge={true}
                      onClick={() => trackEvent('follow_btn_click')}
                    />
                  </Grid>
                }
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Grid
                container
                className="bg-gunpowder br-8"
                justifyContent="center"
              >
                <Grid
                  item
                  xs={5}
                  sm={5}
                  className="p-10"
                  sx={{ borderRight: "1px solid white" }}
                >
                  <Typography className="fs-32 fw-700 text-center lh-32">
                    {profileUserDetails?.movies_watched}
                  </Typography>
                  <Typography className="fs-16 fw-300 text-center lh-20">
                    Movies Watched
                  </Typography>
                </Grid>

                <Grid item xs={5} sm={5} className="p-10">
                  <Typography className="fs-32 fw-700 text-center lh-32">
                    {profileUserDetails?.web_series_watched}
                  </Typography>
                  <Typography className="fs-16 fw-300 text-center lh-20">
                    Web Series Watched
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
