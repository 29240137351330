import { Box, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import helpers from "../../services/helpers";
import FollowUnfollowPerson from "../common/FollowUnfollowPerson";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";

const breakpoints = {
  319: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  425: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  640: {
    slidesPerView: 4,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 5,
    spaceBetween: 20,
    slidesPerGroup: 4
  },
  1024: {
    slidesPerView: 7,
    spaceBetween: 20,
    slidesPerGroup: 6
  },
  1440: {
    slidesPerView: 9,
    spaceBetween: 20,
    slidesPerGroup: 8
  },
  1880: {
    slidesPerView: 11,
    spaceBetween: 30,
    slidesPerGroup: 10
  },
  2560: {
    slidesPerView: 13,
    spaceBetween: 30,
    slidesPerGroup: 12
  },
}

export default function TopReviewers(props) {
  const { data } = props;

  return (
    <Grid container className='bg-secondary app-section' sx={{ paddingTop: { xs: '20px', sm: '30px' }, paddingBottom: { xs: '20px', sm: '30px' } }}>
      <Grid item xs={12} className='app-container'  >
        <p className='fs-28 lh-28 fw-700 m-0'>meet the frenzi cinephiles</p>
        <p style={{ paddingTop: '7px' }} className='fs-18 lh-14 text-muted fw-300 m-0'>top movie reviewers</p>
        <Grid item xs={12} sx={{ marginTop: '30px' }}>
          <Swiper
            slidesPerView={3}
            spaceBetween={20}
            // slidesPerGroup={5}
            freeMode={true}
            navigation={true}
            speed={1000}
            breakpoints={breakpoints}
            modules={[FreeMode, Navigation]}
            className="mySwiper"
          >
            {data.length > 0 && data.map((x, i) => {
              return <SwiperSlide key={i}>
                <Grid item key={i} className='text-center'>
                  <Link to={`/profile/${x.uuid}`}>
                    <Box
                      component="img"
                      className='d-inline-block'
                      sx={{
                        height: { xs: 60, sm: 80 },
                        width: { xs: 60, sm: 80 },
                        maxHeight: { xs: 60, sm: 80 },
                        maxWidth: { xs: 60, sm: 80 },
                        borderRadius: '50%',
                        objectFit: 'cover'
                      }}
                      alt="profilepic"
                      src={x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage('person')}
                    />
                  </Link>
                  <p className='fs-10 fw-600 text-center pt-5 pb-12 m-0 w-100'>{x.username ? x.username : ''}</p>
                  {x.username != 'Frenzi' ?
                    <FollowUnfollowPerson data={x} />
                    : ''
                  }
                </Grid>
              </SwiperSlide>
            })}
          </Swiper>
        </Grid>
      </Grid>
    </Grid>
  );
}
