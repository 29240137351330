import { LinearProgress } from '@mui/material'
import React from 'react'

export default function LinearLoader() {
    return (
        <div className="line-loader">
            <LinearProgress />
        </div>
    )
}
