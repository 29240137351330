import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Avatar, Button, Divider, Grid, TextareaAutosize } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addBlogComment, deleteCommentReply, getCommentLists, likeCommentReply, resetCommentData } from '../../slices/blog';
import { toggleLoginModal } from '../../slices/global';
import { Box } from '@mui/system';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import helpers from '../../services/helpers';
import BlogReplies from './BlogReplies';
import { useNavigate } from 'react-router-dom';
import MediaOverView from '../common/MediaOverView';



export default function CommentReply(props) {

  const [comment, setComment] = useState('');
  const [replyId, setReplyId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState('n');
  // const [isCurrentLoading, setIsCurrentLoading] = useState(false);

  const { blog } = props;
  const dispatch = useDispatch();
  const blogData = useSelector((state) => state.blog);
  const _global = useSelector((state) => state.global);
  const settings = useSelector((state) => state.settings);
  const { userDetails } = settings;

  const { commentListPageData, commentListData, isLoading } = blogData;
  const navigate = useNavigate();

  useEffect(() => {
    if (blog) {
      dispatch(resetCommentData());
      getData(blog, currentPage, sort);
    }
  }, [blog, currentPage, dispatch])


  const getData = (id, page = 1, sort = 'n') => {
    dispatch(getCommentLists({ id: id, page: page, sort: sort }));
  }

  const handleAddComment = () => {
    if (_global.is_public) {
      dispatch(toggleLoginModal({ isOpen: true }));
    } else {
      setComment("");
      // setIsCurrentLoading(true);
      if (comment !== '') {
        const body = {
          blog: blog,
          comment: comment
        }
        dispatch(addBlogComment({ body: body })).then((resp) => {
          getData(blog, 1, sort);
          // setIsCurrentLoading(false);
        });
      }
    }
  }

  const handleDeleteComment = (id) => {
    let path = `comment=${id}`;
    dispatch(deleteCommentReply({ url: path })).then(() => {
      let j = document.getElementById(`comment-${id}`);
      j.style.display = "none";
    })
  }

  const handleLikeComment = (id) => {
    if (_global.is_public) {
      dispatch(toggleLoginModal({ isOpen: true }));
    } else {
      let path = `comment=${id}`;
      dispatch(likeCommentReply({ url: path, type: 'comment', id: id }));
    }
  }

  const navigateToProfile = (id) => {
    if (_global.is_public) {
      dispatch(toggleLoginModal({ isOpen: true }));
    } else {
      navigate(`/profile/${id}`);
    }
  }

  const handleSort = (type) => {
    setSort(type);
    dispatch(resetCommentData());
    getData(blog, 1, type);
  }

  return (
    <div id="blog-comment">
      <Grid item>
        <Grid container className='relative ai-center'>
          <Grid item xs={12} sx={{ padding: { xs: '20px 40px 20px 40px', sm: '30px 50px 30px 50px' }, marginBottom: '40px' }} className='bg-secondary br-5 w-100'>
            {commentListPageData?.pagination?.total_entries > 0 &&
              <h2 className='mt-0 fw-700 text-light fs-18 mb-10'>{commentListPageData?.pagination?.total_entries > 1 ? commentListPageData?.pagination?.total_entries + ' comments' : commentListPageData?.pagination?.total_entries + ' comment'}</h2>
            }
            <Divider className='bg-light' sx={{ marginBottom: { xs: '20px', sm: '30px' } }} />
            <Grid container>
              <Grid item sx={{ width: { xs: '32px', sm: '52px' }, height: { xs: '32px', sm: '52px' } }} >
                {userDetails && userDetails.profile_image
                  ?
                  <Box
                    component={'img'}
                    alt='profilepic'
                    src={helpers.getFullUrl(userDetails.profile_image)}
                    className='d-block'
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '7px',
                      objectFit: 'cover'
                    }}
                  />
                  :
                  userDetails.username ?
                    <div className='name-initials'>{userDetails?.username[0]}</div>
                    :
                    <div className='name-initials'>F</div>
                }
              </Grid>
              <Grid item style={{ flex: '1', paddingLeft: '10px' }} className='relative'>
                <TextareaAutosize
                  style={{ width: '100%' }}
                  placeholder={'join the discussion'}
                  className="comment-box fs-24"
                  onChange={(e) => setComment(e.target.value)}
                  onClick={() => {
                    if (_global.is_public) {
                      dispatch(toggleLoginModal({ isOpen: true }));
                    }
                  }}
                  value={comment}
                />
                <div className='post-action'>
                  <Grid container justifyContent={'flex-end'}>
                    <Grid item>
                      <Button size='sm'
                        className='rounded-btn bg-selected w-100 text-light mt-0 pr-30 pl-30'
                        sx={{ marginTop: { xs: '20px', sm: '30px' }, textTransform: 'none' }}
                        onClick={() => handleAddComment()}
                        disabled={comment === ''}
                      >
                        comment
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <div className='comments-listing mt-20'>
              {commentListData.length > 0 &&
                <Grid container justifyContent={'flex-end'} className="mb-20">
                  <Button
                    onClick={() => handleSort('n')}
                    className={`rounded-border-btn pr-30 pl-30 mr-30 ${sort === "n" ? "active" : ""
                      }`}
                  >
                    newest
                  </Button>
                  <Button
                    onClick={() => handleSort('o')}
                    className={`rounded-border-btn pr-30 pl-30 mr-30 ${sort === "o" ? "active" : ""
                      }`}
                  >
                    oldest
                  </Button>
                </Grid>
              }
              {commentListData.length > 0 && commentListData.map((x, i) => {
                return <div key={i} style={{ marginBottom: '20px' }} id={`comment-${x?.comment_id}`}>
                  <Grid container>
                    <Grid item sx={{ width: { xs: '32px', sm: '52px' }, height: { xs: '32px', sm: '52px' } }} >
                      <Avatar
                        src={
                          x.profile_image ? (
                            helpers.getFullUrl(x.profile_image)
                          ) : (
                            helpers.getFullUrl('joeschmoe')
                          )
                        }
                        onClick={() => navigateToProfile(x?.user)}
                        alt={x?.username}
                        sx={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '7px',
                          objectFit: 'cover'
                        }}
                      />
                    </Grid>
                    <Grid item xs={11} style={{ flex: '1', paddingLeft: '10px' }} className='relative'>
                      <Grid container className='mb-10' justifyContent={'space-between'}>
                        <Grid item>
                          <h2 className='m-0 fw-700 fs-15 text-light'>{x?.username}</h2>
                          <h2 className='m-0 text-muted fs-12'>{helpers.getIndianTimeAgo(x?.created_at)}</h2>
                        </Grid>
                        <Grid item>
                          {userDetails?.uuid === x?.user && !_global.is_public &&
                            <DeleteRoundedIcon onClick={() => handleDeleteComment(x?.comment_id)} className='pointer' />
                          }
                        </Grid>
                      </Grid>
                      <MediaOverView className='text-light' text={x?.comment} isLess={true} fullHeight={true} max={200} />
                      <Grid container className='mt-10' alignItems={'center'}>
                        <ThumbUpRoundedIcon onClick={() => handleLikeComment(x?.comment_id)} style={{ marginRight: '5px' }} className={`pointer scale-1 ${x?.is_liked ? 'text-selected' : ''}`} />
                        <p className='mt-0 mb-0 scale-1 pointer text-light' style={{ marginRight: '20px' }}>{x?.total_likes || 0}</p>
                        {/* <ThumbDownAltRoundedIcon style={{ marginRight: '10px' }} className='pointer scale-1' /> */}
                        <h2 onClick={() => setReplyId((replyId !== '' && replyId === x?.comment_id) ? '' : x?.comment_id)} className={`mt-0 mb-0 scale-1 pointer text-light ${replyId === x?.comment_id ? 'fw-700' : ''}`} style={{ marginRight: '10px' }}>reply</h2>
                        {/* <p className='mt-0 mb-0 scale-1 pointer'>{x?.total_replies}</p> */}
                      </Grid>
                      <BlogReplies replyId={replyId} commentData={x} commentId={x?.comment_id} setReplyId={setReplyId} />
                    </Grid>
                  </Grid>
                </div>
              })}
            </div>

            {isLoading &&
              <Grid container justifyContent={'center'}>
                <CircularProgress />
              </Grid>
            }

            {commentListPageData?.pagination?.is_next_page && <Button size='sm'
              variant='outlined'
              className='w-100' color='info'
              sx={{ marginTop: { xs: '20px', sm: '30px' }, textTransform: 'none' }}
              onClick={() => {
                getData(blog, (currentPage + 1));
                setCurrentPage(currentPage + 1)
              }}
            >
              load more comments
            </Button>}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
