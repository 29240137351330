import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import { useDispatch, useSelector } from "react-redux";
import { getBlogCategoryList, getBlogs } from "../../slices/blog";
import { isMobileDevice } from "../../services/Utils";
import { getFrenziWatchLists, getGenreMedia, getLanguageMedia, getPlatformMedia } from "../../slices/trending";
import { getAllPreferences } from "../../slices/auth";

const IosPlaystore = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/img/IosPlaystore.svg";
const PlayStore = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/img/PlayStore.svg";

const NewFbIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/NewFbIcon.svg";
const NewInstaIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/NewInstaIcon.svg";
const FooterTwit = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/FooterTwit.svg";
const FooterYt = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/FooterYt.svg";
const FooterFbLight = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/FooterFbLight.svg";
const FooterTelgLight = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/FooterTelgLight.svg";
const FooterTwitLight = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/FooterTwitLight.svg";
const FooterYtLight = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/FooterYtLight.svg";

export default function Footer() {
  const handleClick = (name) => {
    helpers.trackMoengageEvent("FR3_Other", {
      [name]: MOENGAGE_ATTRIBUTE.YES,
    });
  };
  const [email, setEmail] = useState();
  const [popularBlogs, setPopularBlogs] = useState([]);
  const [category, setCategory] = useState('');
  const [sharelists, setSharelists] = useState('');

  const trending = useSelector((state) => state.trending);
  const auth = useSelector((state) => state.auth);
  const _global = useSelector((state) => state.global);

  const currentPage = {
    FEATURED_BLOG: 1,
    LATEST_BLOG: 1,
    POPULAR_BLOG: 1,
  };

  const getLink = (data, section, currentPage, currentIndex) => {
    if (data?.unique_title && data?.unique_title != "") {
      let link1 = `/blog/${data?.unique_title}`;
      return link1;
    } else {
      let index = currentPage + currentIndex;
      let titleUrl = data?.url || data?.title;
      let link = `/blog/${titleUrl.replace(/ /g, '-')}/${data?.uuid}?section=${section}&category=${category}&i=${index}`;
      return link;
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPreferences({ is_public: _global.is_public }));

    dispatch(getBlogCategoryList()).then((resp) => {
      if (resp.payload && resp.payload.data && resp.payload.data[0]) {
        setCategory(resp.payload.data[0].uuid);
      }
    });
  }, [])

  useEffect(() => {
    if (category && category !== '') {
      dispatch(getBlogs({ section: 'popular', category: category, page: currentPage.FEATURED_BLOG, pagelimit: 5 })).then((resp) => {
        if (resp.payload && resp.payload.data && resp.payload.data.results) {
          setPopularBlogs(resp.payload.data.results);
        }
      });
    }
  }, [category, dispatch])

  let languages = auth.allPreferences && auth.allPreferences.languages
    ? auth.allPreferences.languages
    : []
  let genres = auth.allPreferences && auth.allPreferences.genres
    ? auth.allPreferences.genres
    : []
  let platforms = auth.allPreferences && auth.allPreferences.platforms
    ? auth.allPreferences.platforms
    : []


  useEffect(() => {
    var body4 = {
      page: 1,
      pagelimit: 5,
      is_public: _global.is_public,
    };
    dispatch(getFrenziWatchLists(body4)).then((resp) => {
      if (resp.payload && resp.payload.data && resp.payload.data.results) {
        setSharelists(resp.payload.data.results);
      }
    });

  }, []);


  return (
    <>
      {isMobileDevice() ?
        <Grid container className="footerbg mb-180 p-80">
          <Grid item xs={12} className="">
            {/* <h2 className="fs-46 fw-700 lh-61 m-0">simplifying what to watch on <span className="text-selected">OTT</span></h2> */}
            <h2 className="fs-36 fw-700 lh-61 m-0">Streaming<span className="text-selected"> simplified</span></h2>
          </Grid>
          <Grid item xs={12} className="mt-50">
            <p className="fs-24 fw-400 lh-26 m-0">
              Join frenzi and discover much more
            </p>
          </Grid>
          <Grid container className="mt-50">
            <Grid item xs={12} className="mb-50">
              <h2 className="fs-18 fw-700 mt-0 mb-15">Trending by platform</h2>
              {platforms && platforms.length > 0 && platforms.map((x, i) => {
                if (i <= 4) {
                  return <Grid item>
                    <Link
                      to={`/platforms/${x?.ott_name}`}>
                      <p className='fs-14 fw-300 ellipsis w-100 mb-5'>Trending on {x?.ott_name}</p>
                    </Link>
                  </Grid>
                }
              })}
            </Grid>
            <Grid item xs={12} className="mt-50">
              <h2 className="fs-18 fw-700 mt-0 mb-15">Trending by Language</h2>
              {languages && languages.length > 0 && languages.map((x, i) => {
                if (i <= 4) {
                  return <Grid item>
                    <Link
                      to={`/languages/${x?.language}`}>
                      <p className='fs-14 fw-300 ellipsis w-100 mb-5'>Trending in {x?.language} - </p>
                    </Link>
                  </Grid>
                }
              })}
            </Grid>
            <Grid item xs={12} className="mt-50">
              <h2 className="fs-18 fw-700 mt-0">Popular Blogs</h2>
              {popularBlogs.length > 0 && popularBlogs.map((x, i) => {
                return <>
                  <Grid item>
                    <Link to={getLink(x, 'popular', currentPage.POPULAR_BLOG, i)}>
                      <p key={i} className={`fs-14 fw-300 ellipsis w-100 mb-5 pointer ${category === x?.uuid ? 'text-light' : ''} `}>{x?.title}</p>
                    </Link>
                  </Grid>
                </>
              })
              }
            </Grid>
            <Grid item xs={12} className="mt-50">
              <h2 className="fs-18 fw-700 mt-0 mb-15">Trending by Genre</h2>
              {genres && genres.length > 0 && genres.map((x, i) => {
                if (i <= 4) {
                  return <Grid item>
                    <Link
                      to={`/genres/${x?.genre}`}>
                      <p className='fs-14 fw-300 ellipsis w-100 mb-5'>Trending in {x?.genre}</p>
                    </Link>
                  </Grid>
                }
              })}
            </Grid>
            <Grid item xs={5} className="mt-30" sx={{}}>
              <a
                href="https://play.google.com/store/apps/details?id=com.frenzee.app"
                target="_blank"
                rel="noreferrer"
              >
                <Grid item>
                  <Box
                    component={"img"}
                    alt="playstore"
                    className="d-block"
                    src={PlayStore}
                    sx={{
                      height: "30px",
                    }}
                  />
                </Grid>
              </a>
            </Grid>
            <Grid item xs={6} className="text-center mt-30">
              <a
                href="https://play.google.com/store/apps/details?id=com.frenzee.app"
                target="_blank"
                rel="noreferrer"
              >
                <Grid item>
                  <Box
                    component={"img"}
                    alt="playstore"
                    className="d-block"
                    src={IosPlaystore}
                    sx={{
                      height: "30px",
                      // width: '168px',
                      objectFit: 'cover'
                    }}
                  />
                </Grid>
              </a>
            </Grid>
            <Grid item xs={12} className="text-center mt-40">
              <TextField
                id="standard-name"
                value={email}
                // label="Enter your email address"
                placeholder="Enter your email address"
                className="bg-muted ml-0"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                color="grey"
                InputProps={{
                  sx: {
                    height: { xs: "30px", sm: "52px" },
                    maxWidth: { xs: "250px", sm: "670px" },
                    width: "670px",
                    fontSize: '12px'
                  },
                  endAdornment: (
                    <Button
                      className="bg-light fs-14 text-dark"
                      sx={{
                        width: "50px",
                        height: "30px",
                        display: { sm: "block" },
                      }}
                    >
                      subscribe
                    </Button>
                  ),
                }}
              />
            </Grid>
            <Grid container className="mt-50" justifyContent={'center'}>
              <Grid item>
                <a
                  onClick={() => handleClick("facebook")}
                  href="https://www.facebook.com/frenziofficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Grid
                    item
                    className="hover-img-icon text-center"
                    sx={{
                      width: 63,
                      height: 63,
                      maxWidth: { xs: 50, sm: 56, md: 63 },
                      maxHeight: { xs: 50, sm: 56, md: 63 },
                      borderRadius: "50%",
                      border: "1px solid grey",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        padding: { xs: "12px", sm: "15px", md: "18px" },
                        width: { xs: 50, sm: 56, md: 63 },
                        height: { xs: 50, sm: 56, md: 63 },
                      }}
                      src={NewFbIcon}
                      alt="Facebook"
                    />
                    <Box
                      component="img"
                      sx={{
                        padding: { xs: "12px", sm: "15px", md: "18px" },
                        width: { xs: 50, sm: 56, md: 63 },
                        height: { xs: 50, sm: 56, md: 63 },
                      }}
                      src={FooterFbLight}
                      alt="Facebook"
                    />
                  </Grid>
                </a>
              </Grid>
              <Grid item>
                <a
                  onClick={() => handleClick("instagram")}
                  href="https://www.instagram.com/frenzi_in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Grid
                    item
                    className="hover-img-icon text-center"
                    sx={{
                      width: 63,
                      height: 63,
                      maxWidth: { xs: 50, sm: 56, md: 63 },
                      maxHeight: { xs: 50, sm: 56, md: 63 },
                      borderRadius: "50%",
                      border: "1px solid grey",
                      marginLeft: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        padding: { xs: "13px", sm: "15px", md: "18px" },
                        width: { xs: "50px", sm: 56, md: 63 },
                        height: { xs: "50px", sm: 56, md: 63 },
                      }}
                      src={NewInstaIcon}
                      alt="Insta"
                    />
                    <Box
                      component="img"
                      sx={{
                        padding: { xs: "13px", sm: "15px", md: "18px" },
                        width: { xs: "50px", sm: 56, md: 63 },
                        height: { xs: "50px", sm: 56, md: 63 },
                      }}
                      src={FooterTelgLight}
                      alt="Insta"
                    />
                  </Grid>
                </a>
              </Grid>
              <Grid item>
                <a
                  onClick={() => handleClick("twitter")}
                  href="https://twitter.com/Frenzi_Official"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Grid
                    item
                    className="hover-img-icon text-center"
                    sx={{
                      width: 63,
                      height: 63,
                      maxWidth: { xs: 50, sm: 56, md: 63 },
                      maxHeight: { xs: 50, sm: 56, md: 63 },
                      borderRadius: "50%",
                      border: "1px solid grey",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        padding: { xs: "13px", sm: "15px", md: "18px" },
                        width: { xs: "50px", sm: 56, md: 63 },
                        height: { xs: "50px", sm: 56, md: 63 },
                      }}
                      src={FooterTwit}
                      alt="Twitter"
                    />
                    <Box
                      component="img"
                      sx={{
                        padding: { xs: "13px", sm: "15px", md: "18px" },
                        width: { xs: "50px", sm: 56, md: 63 },
                        height: { xs: "50px", sm: 56, md: 63 },
                      }}
                      src={FooterTwitLight}
                      alt="Twitter"
                    />
                  </Grid>
                </a>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        :
        <>
          <Grid container className="new-container footerbg" sx={{ borderBottom: "1px solid grey" }}>
            <Grid item sm={6} md={6} lg={6}>
              <Grid container className="p-20">
                <Grid item sm={10} md={12} lg={12}>
                  {/* <h2 className="fs-36 fw-700 lh-61 m-0">simplifying what to watch on <span className="text-selected">OTT</span></h2> */}
                  <h2 className="fs-36 fw-700 lh-61 m-0">Streaming<span className="text-selected"> simplified</span></h2>
                </Grid>
                <Grid item className="mt-50 mb-10">
                  <p className="fs-18 fw-400 lh-26 m-0">
                    Join frenzi and discover much more
                  </p>
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    id="standard-name"
                    value={email}
                    // label="Enter your email address"
                    placeholder="Enter your email address"
                    className=" ml-0"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    color="grey"
                    InputProps={{
                      sx: {
                        height: { xs: "40px", sm: "52px" },
                        maxWidth: { sm: "370px", md: '570px', lg: '570px' },
                        minWidth: { sm: '270px', md: '370px', lg: '470px' },
                        // width: "420px",
                      },
                      endAdornment: (
                        <Button
                          className="bg-selected text-light"
                          sx={{
                            width: "176px",
                            height: "40px",
                            display: { xs: "none", sm: "block" },
                          }}
                        >
                          subscribe
                        </Button>
                      ),
                    }}
                  />
                </Grid>
                <Grid item className="pt-30">
                  <Grid container alignItems={'center'}>
                    <Grid item>
                      <a
                        onClick={() => handleClick("facebook")}
                        href="https://www.facebook.com/frenziofficial"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Grid
                          item
                          className="hover-img-icon text-center"
                          sx={{
                            width: 63,
                            height: 63,
                            maxWidth: { xs: 50, sm: 56, md: 63 },
                            maxHeight: { xs: 50, sm: 56, md: 63 },
                            borderRadius: "50%",
                            border: "1px solid grey",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              padding: { xs: "12px", sm: "15px", md: "18px" },
                              width: { xs: 50, sm: 56, md: 63 },
                              height: { xs: 50, sm: 56, md: 63 },
                            }}
                            src={NewFbIcon}
                            alt="Facebook"
                          />
                          <Box
                            component="img"
                            sx={{
                              padding: { xs: "12px", sm: "15px", md: "18px" },
                              width: { xs: 50, sm: 56, md: 63 },
                              height: { xs: 50, sm: 56, md: 63 },
                            }}
                            src={FooterFbLight}
                            alt="Facebook"
                          />
                        </Grid>
                      </a>
                    </Grid>
                    <Grid item>
                      <a
                        onClick={() => handleClick("instagram")}
                        href="https://www.instagram.com/frenzi_in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Grid
                          item
                          className="hover-img-icon text-center"
                          sx={{
                            width: 63,
                            height: 63,
                            maxWidth: { xs: 50, sm: 56, md: 63 },
                            maxHeight: { xs: 50, sm: 56, md: 63 },
                            borderRadius: "50%",
                            border: "1px solid grey",
                            marginLeft: "20px",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              padding: { xs: "13px", sm: "15px", md: "18px" },
                              width: { xs: "50px", sm: 56, md: 63 },
                              height: { xs: "50px", sm: 56, md: 63 },
                            }}
                            src={NewInstaIcon}
                            alt="Insta"
                          />
                          <Box
                            component="img"
                            sx={{
                              padding: { xs: "13px", sm: "15px", md: "18px" },
                              width: { xs: "50px", sm: 56, md: 63 },
                              height: { xs: "50px", sm: 56, md: 63 },
                            }}
                            src={FooterTelgLight}
                            alt="Insta"
                          />
                        </Grid>
                      </a>
                    </Grid>
                    <Grid item>
                      <a
                        onClick={() => handleClick("twitter")}
                        href="https://twitter.com/Frenzi_Official"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Grid
                          item
                          className="hover-img-icon text-center"
                          sx={{
                            width: 63,
                            height: 63,
                            maxWidth: { xs: 50, sm: 56, md: 63 },
                            maxHeight: { xs: 50, sm: 56, md: 63 },
                            borderRadius: "50%",
                            border: "1px solid grey",
                            marginLeft: "20px",
                            marginRight: "20px",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              padding: { xs: "13px", sm: "15px", md: "18px" },
                              width: { xs: "50px", sm: 56, md: 63 },
                              height: { xs: "50px", sm: 56, md: 63 },
                            }}
                            src={FooterTwit}
                            alt="Twitter"
                          />
                          <Box
                            component="img"
                            sx={{
                              padding: { xs: "13px", sm: "15px", md: "18px" },
                              width: { xs: "50px", sm: 56, md: 63 },
                              height: { xs: "50px", sm: 56, md: 63 },
                            }}
                            src={FooterTwitLight}
                            alt="Twitter"
                          />
                        </Grid>
                      </a>
                    </Grid>
                    <Grid item className="text-center ml-10">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.frenzee.app"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Grid item>
                          <Box
                            component={"img"}
                            alt="playstore"
                            className="d-block"
                            src={PlayStore}
                            sx={{
                              height: "48px",
                            }}
                          />
                        </Grid>
                      </a>
                    </Grid>
                  </Grid>
                  <Grid container>
                    {/* <Grid item className="mt-8">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.frenzee.app"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Grid item>
                          <Box
                            component={"img"}
                            alt="playstore"
                            className="d-block"
                            src={IosPlaystore}
                            sx={{
                              height: "48px",
                              width: '168px',
                              objectFit: 'cover'
                            }}
                          />
                        </Grid>
                      </a>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} lg={6} className="mt-20 ml-40">
              <Grid container>
                <Grid item sm={4}>
                  <h5 className="mb-10">Trending by <span className="text-selected">platform</span> </h5>
                  {platforms && platforms.length > 0 && platforms.map((x, i) => {
                    if (i <= 4) {
                      return <Grid item>
                        <Link
                          to={`/platforms/${x?.ott_name}`}>
                          <p className='fs-14 fw-300 ellipsis w-100 mb-5'>Trending on {x?.ott_name}</p>
                        </Link>
                      </Grid>
                    }
                  })}
                </Grid>
                <Grid item sm={4}>
                  <h5 className="mb-10">Trending by <span className="text-selected">Language</span> </h5>
                  {languages && languages.length > 0 && languages.map((x, i) => {
                    if (i <= 4) {
                      return <Grid item>
                        <Link
                          to={`/languages/${x?.language}`}>
                          <p className='fs-14 fw-300 ellipsis w-100 mb-5'>Trending in {x?.language}</p>
                        </Link>
                      </Grid>
                    }
                  })}
                </Grid>
                <Grid item sm={4}>
                  <h5 className="mb-10">Popular <span className="text-selected">Blogs</span></h5>
                  {popularBlogs.length > 0 && popularBlogs.map((x, i) => {
                    return <>
                      <Grid item>
                        <Link to={getLink(x, 'popular', currentPage.POPULAR_BLOG, i)}>
                          <p key={i} className={`fs-14 fw-300 ellipsis w-100 mb-5 pointer ${category === x?.uuid ? 'text-light' : ''} `}>{x?.title}</p>
                        </Link>
                      </Grid>
                    </>
                  })
                  }
                </Grid>
                <Grid item sm={4}>
                  <h5 className="mb-10">Trending by <span className="text-selected">Genre</span> </h5>
                  {genres && genres.length > 0 && genres.map((x, i) => {
                    if (i <= 4) {
                      return <Grid item>
                        <Link
                          to={`/genres/${x?.genre}`}>
                          <p className='fs-14 fw-300 ellipsis w-100 mb-5'>Trending in {x?.genre}</p>
                        </Link>
                      </Grid>
                    }
                  })}
                </Grid>
                <Grid item>
                  <h5 className="mb-10">Popular   <span className="text-selected">Sharelists</span> </h5>
                  {sharelists && sharelists.length > 0 && sharelists.map((x, i) => {
                    if (i <= 4) {
                      return <Grid item>
                        <Link to={`/watchlist-detail/${x?.uuid}`}>
                          <p className='fs-14 fw-300 ellipsis w-100 mb-5'>{x?.category_name}</p>
                        </Link>
                      </Grid>
                    }
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container className="footerbg new-container"
            justifyContent={"space-between"}
            display={{ xs: "none", sm: "flex" }}>

            <Grid item className="mt-22">
              <Grid container alignItems={"center"}>
                <Grid item sx={{ marginLeft: "20px" }}>
                  <Link
                    onClick={() => handleClick("legal_click")}
                    to="/privacy"
                    className="fw-400 fs-14 lh-16"
                  >
                    Privacy Policy
                  </Link>
                </Grid>

                <Grid item sx={{ marginLeft: "20px" }}>
                  <Link to="/contactus" className="fw-400 fs-14 lh-16">
                    Contact
                  </Link>
                </Grid>

                <Grid item sx={{ marginLeft: "20px" }}>
                  <Link
                    onClick={() => handleClick("t_and_c")}
                    to="/tnc"
                    className="fw-400 fs-14 lh-16"
                  >
                    Terms and Conditions
                  </Link>
                </Grid>

                <Grid item sx={{ marginLeft: "20px" }}>
                  <Link to="/aboutus" className="fw-400 fs-14 lh-16">
                    About Us
                  </Link>
                </Grid>
                <Grid item sx={{ marginLeft: "20px" }}>
                  <Link to="/blog" className="fw-400 fs-14 lh-16">
                    Blog
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="mt-15">
              <Link to="/blog">
                <Button
                  className="rounded-btn text-light"
                  sx={{ width: "150px", height: "30px", border: '1px solid white' }}
                >
                  {" "}
                  Read our Blogs
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid
            container
            className="jc-center text-center footerbg"
            sx={{
              paddingBottom: { xs: "74px", sm: "24px" },
              paddingTop: "24px",
            }}
          >
            <Typography className="fs-16 text-muted">
              © Frenz Intelligence Pvt. Ltd. {new Date().getFullYear()}. All Rights
              Reserved.
            </Typography>
          </Grid>
        </>
      }
    </>
  );
}
