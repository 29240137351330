import { CircularProgress, Grid } from '@mui/material'
import React, { useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMediaSearch } from '../../slices/search';
import { clearMessages as clearMessagesMedia } from "../../slices/media";
import helpers from "../../services/helpers";
import SingleMediaTile from '../common/SingleMediaTile';
import MyHelmet from '../common/MyHelmet';

export default function SearchInner() {
    const [page, setPage] = useState(1);
    const _search = useSelector((state) => state.search);
    const _global = useSelector((state) => state.global);
    const media = useSelector((state) => state.media);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { mediaSearchPageData } = _search;

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    
    useEffect(() => {
        if (media.successMesg && media.successMesg != "") {
            helpers.toastSuccess(media.successMesg);
            dispatch(getMediaSearch({ query: params?.query, page: page, pagelimit: 20, is_public: _global.is_public }));
            dispatch(clearMessagesMedia());
        }
    }, [media]);

    useEffect(() => {
        dispatch(getMediaSearch({ query: params?.query, page: page, pagelimit: 20, is_public: _global.is_public }));
    }, [page])


    useEffect(() => {
        const handleScroll = () => {
            if (mediaSearchPageData && mediaSearchPageData.is_next_page && !_search.isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, dispatch]);


    return (
        <div className='new-container mb-40'>
        <MyHelmet 
            titles = {"search results"}
            description = {"Unlock a treasure trove of entertainment with our comprehensive search results. Our powerful search engine scours through our vast content library, bringing you a curated selection of results tailored to your query."}
        />
            <Grid container alignItems={'center'} className="pt-20 mb-30" justifyContent='space-between'>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                        <p className='fs-28 fw-700 lh-28 m-0'>titles based on <span className='text-selected'>{params?.query}</span></p>
                    </Grid>
                </Grid>
            </Grid>

            {_search.mediaSearchData.length && _search.mediaSearchData.length > 0 ?
                <Grid container justifyContent={'space-between'} spacing={3}>
                    {_search.mediaSearchData.map((x, i) => {
                        return <Grid item xs={12} sm={6} md={4}>
                            <SingleMediaTile
                                x={x}
                                refPage="Search Results"
                                showNotifyButton={false}
                            />
                        </Grid>
                    })}
                </Grid>
                :
                ''
            }
            {_search.isLoading ?
                <Grid container justifyContent={'center'}>
                    <CircularProgress />
                </Grid>
                :
                ''
            }
        </div>
    )
}
