import { Box, Button, Grid, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomSearch from '../../search/CustomSearch';
import { useDispatch, useSelector } from 'react-redux';
import { addSuggestTitles, getSuggestedTitles } from '../../../slices/watchlist';
import { Link } from 'react-router-dom';
import helpers from '../../../services/helpers';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:{xs:'320px', sm:'500px'},
    bgcolor: '#16213E',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    zIndex: 999,
    minHeight: '470px'
};

export default function SuggestTitles(props) {
    const [isModal, setIsModal] = useState(false);
    const [currentId, setCurrentId] = useState('');
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const watchlist = useSelector(state => state.watchlist);
    const { suggestedTitleData, suggestedTitlePageData, isLoading } = watchlist;

    useEffect(() => {
        if (watchlist.successMesg && watchlist.successMesg != "") {
            let pid = props?.data?.watchlist_id ? props?.data?.watchlist_id : props?.data?.uuid;
            if (currentId && pid == currentId) {
                setPage(1);
                dispatch(getSuggestedTitles({ id: currentId, page: 1, pagelimit: 20 }));
                setTimeout(() => {
                    setIsModal(true);
                }, 200)
            }
        }
    }, [watchlist])

    const handleAddSuggestion = (e, item, data) => {
        let id = data.watchlist_id ? data.watchlist_id : data?.uuid;
        let pid = props?.data?.watchlist_id ? props?.data?.watchlist_id : props?.data?.uuid;
        if (data && id && pid == id) {
            setCurrentId(id);
            const payload = {
                "watchlist_id": id,
                "media_id": item?.media ? item?.media : item?.uuid,
                "action": 'add'
            }
            dispatch(addSuggestTitles({ body: payload }));
        }
    }

    const handleGetSuggestTitle = (data, open = false) => {
        let id = data.watchlist_id ? data.watchlist_id : data?.uuid;
        let pid = props?.data?.watchlist_id ? props?.data?.watchlist_id : props?.data?.uuid;
        if (data && id && pid == id) {
            dispatch(getSuggestedTitles({ id: id, page: page, pagelimit: 20 }));
            if (open) {
                setTimeout(() => {
                    setIsModal(true);
                }, 200)
            }
        }
    }

    useEffect(() => {
        handleGetSuggestTitle(props?.data);
    }, [page])


    const handleDeleteSuggestion = (item, data) => {
        let id = data.watchlist_id ? data.watchlist_id : data?.uuid;
        let pid = props?.data?.watchlist_id ? props?.data?.watchlist_id : props?.data?.uuid;
        if (data && id && pid == id) {
            setCurrentId(id);
            const payload = {
                "watchlist_id": id,
                "media_id": item?.media ? item?.media : item?.uuid,
                "action": 'remove'
            }
            dispatch(addSuggestTitles({ body: payload }));
        }
    }

    const isInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const handleScroll = () => {
        if (suggestedTitlePageData && suggestedTitlePageData.pagination && suggestedTitlePageData.pagination.is_next_page && !isLoading) {
            let elements = document.getElementsByClassName('title-list-item');
            if (elements && elements.length > 0) {
                let element = elements[elements.length - 1];
                if (isInViewport(element)) {
                    setPage(page + 1);
                }
            }
        }
    }

    return (
        <>
            <Button onClick={() => handleGetSuggestTitle(props?.data, true)} className={`rounded-btn ${props.className ? props?.className : ''} bg-light text-selected fs-14 mw-320 fw-500 w-100`} style={{marginTop:'10px'}}>
                suggest titles to sharelist
            </Button>
            <Modal
                open={isModal}
                onClose={() => setIsModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item xs={12}>
                        <p className='fw-700 fs-28 m-0'>suggested titles</p>
                    </Grid>
                    <Grid item xs={12}  className="mt-20 text-center">
                        <CustomSearch
                            placeholder="Search to add titles"
                            clickedItem={(e, item) => handleAddSuggestion(e, item, props?.data)}
                            searchType={'media'}
                            isClearOnClick={true}
                        />
                    </Grid>
                    {suggestedTitleData && suggestedTitleData.length > 0 &&
                        <Grid item xs={12} className="mt-0">
                            <p className='p-0 m-0 fw-600 fs-14'>Titles ({suggestedTitlePageData?.pagination?.total_entries})</p>
                            <Grid item xs={12} className="mt-10" onScroll={() => handleScroll()} style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                                {suggestedTitleData.map((x, i) => {
                                    return <div key={i} className="mt-20 title-list-item">
                                        <Grid container alignItems={'center'} justifyContent='space-between'>
                                            <Grid item>
                                                <Grid container alignItems={'center'}>
                                                    <Link onClick={() => helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', 'Watchlist')} to={helpers.getMediaLink(x)}>
                                                        <div style={{ paddingRight: '10px' }}>
                                                            <Box
                                                                component="img"
                                                                className='d-block'
                                                                sx={{
                                                                    height: '65px',
                                                                    width: '50px',
                                                                    borderRadius: '7px'
                                                                }}
                                                                alt="poster"
                                                                src={x.poster ? helpers.getFullUrl(x.poster) : helpers.getDefaultImage('personmedia')}
                                                            />
                                                        </div>
                                                    </Link>
                                                    <p className='fw-700 fs-16 p-0 m-0'>{x?.title}</p>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={() => handleDeleteSuggestion(x, props?.data)}>
                                                    <DeleteForeverRoundedIcon className='text-selected' />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                })}
                            </Grid>
                        </Grid>
                    }
                </Box>
            </Modal>
        </>
    )
}
