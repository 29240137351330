import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
// import GooglePlay from '../../assets/icons/GooglePlay.png'
// import AboutUsImg from '../../assets/icons/AboutUsImg.png'
import MyHelmet from '../common/MyHelmet'

const GooglePlay = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/GooglePlay.png";
const AboutUsImg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/AboutUsImg.png";


export default function AboutUs() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div>
        <MyHelmet
            title = {'About Frenzi'}
            description = {"Frenzi is a community led OTT aggregator and content discovery platform that helps users simplify their choice of what to watch across streaming platforms."}
        />
            <Grid container >
                <Grid item xs={11} md={6} lg={7} className='pr-15 '>
                    <Grid container className='h-100 pl-48 ml-48' flexDirection={'column'} justifyContent={'center'}>
                        <Grid item md={10}>
                            <p className='mb-8'><h1 className='text-selected'>About Us</h1></p>
                            <p className='mt-20 pb-16'>
                                Know more about Frenzi
                                <br />
                                <br />
                                Frenzi is a community led OTT aggregator and content discovery platform that helps users simplify their choice of what to watch across streaming platforms. Our platform streamlines the search process and recommends new movies and TV shows based on your preferences, making it easy to stay up-to-date on the latest releases and never miss a must-watch show again.
                                <br />
                                <br />

                                Users can also get authentic recommendations and views from family and friends before making a choice of what to watch. Our AI quickly learns your preferences and provides personalized recommendations based on your viewing habits.
                                <br />
                                <br />

                                With Frenzi, you can also become a movie influencer by sharing your opinions, reviews, clips and suggestions with your friends and followers. By doing so, you can not only stand out but also earn rewards and exciting earning opportunities and build a name for yourself.
                                <br />
                                <br />

                                Additionally, Frenzi offers engaging features such as games, quizzes, polls, and discussions where users can earn points and redeem them. Our team of experts is constantly researching the latest and greatest content on OTT platforms to provide users with personalized recommendations. And notify users whenever a new title drops on OTT.
                                <br />
                                <br />

                                We are dedicated to providing our users with the best possible experience and are constantly working to improve and evolve our platform, committed to helping people find the content they love.
                            </p>
                            <Grid item className='mt-24 mb-50'>
                                <Link>
                                    <Box component={'img'}
                                        className="d-block"
                                        alt='playstoreicon'
                                        src={GooglePlay}
                                    />
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={5} className='bg-selected' >
                    <Box component={'img'}
                        src={AboutUsImg}
                        alt=''
                        sx={{
                            height: { xs: '400px', sm: 'auto', md: '100vh' },
                            maxWidth: { xs: '540px', sm: 'auto' },
                            width: '100%'
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
