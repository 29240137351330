import React, { useEffect, useState } from 'react'
import './mycinescore.scss'
import { Box, Button, Grid, Modal } from '@mui/material';
import '../component/component.scss';
import TopList from '../component/topList/TopList';
import { useDispatch, useSelector } from 'react-redux';
import { haveYouWatched } from '../../slices/activity';
import CinescoreLook from '../activity/CinescoreLook';
import { getProfileData } from '../../slices/auth';
// import Loader from '../common/Loader';
import { getCinescoreDecade, getCinescoreGenre, getCinescoreLang, getCinescorePersonDataDirector, getCinescorePersonDataFeMale, getCinescorePersonDataMale, getCinescorePlatform, getCinescoreDetailsByMediaType } from '../../slices/cinescore';
import TopCastList from '../component/topList/TopCastList';
import MyHelmet from '../common/MyHelmet';
// import cinescore_new_bg_image from '../../assets/cinescore_new_bg_image.svg'
// import GroupProfile from '../../assets/icons/GroupProfile.png'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/navigation";
import LinearLoader from '../common/LinearLoader';
import MovieLovers from './MovieLovers';
import CinescoreCard from './CinescoreCard';
import { Link } from 'react-router-dom';
import { isMobileDevice } from '../../services/Utils';
import helpers from '../../services/helpers';
import { clearMessages } from '../../slices/media';
import { toggleLoginModal } from '../../slices/global';
import InviteUsers from '../common/InviteUsers';

const cinescore_new_bg_image = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/cinescore_new_bg_image.svg";
const GroupProfile = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/GroupProfile.png";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: { xs: "90%", sm: "760px" },
    bgcolor: "#16213E",
    boxShadow: 24,
    // p: { xs: 2, sm: 4 },
    borderRadius: "7px",
    maxHeight: "95vh",
    overflowY: "auto",
};

export default function MyCinescore() {

    const dispatch = useDispatch();

    const activity = useSelector(state => state.activity);
    const cinescore = useSelector(state => state.cinescore);
    const auth = useSelector(state => state.auth);
    const media = useSelector((state) => state.media);
    const [open, setOpen] = useState(false);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (media.errMesg && media.errMesg !== "") {
            helpers.toastError(media.errMesg);
            dispatch(clearMessages());
        }
        if (media.successMesg && media.successMesg !== "") {
            helpers.toastSuccess(media.successMesg);
            dispatch(clearMessages());
        }
    }, [media]);

    useEffect(() => {
        dispatch(haveYouWatched({ page: 1, pagelimit: 10 }));
        dispatch(getCinescorePersonDataMale());
        dispatch(getCinescorePersonDataFeMale());
        dispatch(getCinescorePersonDataDirector());
        dispatch(getProfileData());
        dispatch(getCinescoreGenre());
        dispatch(getCinescorePlatform());
        dispatch(getCinescoreDecade());
        dispatch(getCinescoreLang());
        const body = {
            page: 1,
            pagelimit: 7,
            exclude_already_watched: "yes",
            media_type: 'movie',
            prefered_platforms: "yes",
        };
        dispatch(getCinescoreDetailsByMediaType(body));
    }, [])

    return (
        <>
            <MyHelmet
                title={'Get your Cinescore go higher'}
                description={'Watch, like, comment, and review movies. Your active participation can shape the movie-watching community and enhance the overall film experience.'}
            />
            {(auth.isLoading || activity.isLoading) && <LinearLoader />}

            <Grid className='new-container' sx={{ paddingRight: { md: '0px !important' } }}>
                <CinescoreLook isTiles={false} isTime={true} />
            </Grid>

            <Grid className='new-container' sx={{ backgroundImage: `url(${cinescore_new_bg_image})` }}>
                {/* <Grid item xs={12} className='app-section pt-20'>
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" mobtype='mobile_1' slotId={'2918011331'} adv_type={'web_my_cinescore_page_top_ad'} />
                    </Grid>
                </Grid> */}
                {cinescore.cinescoreMaleData && cinescore.cinescoreMaleData.length > 0 &&
                    <Grid container className='app-container pt-10 app-section section-border-bottom'>
                        <Grid item>
                            <h2 className='fs-28 fw-700 lh-28 m-0 '>your most watched male<span className='text-selected'> actors</span></h2>
                        </Grid>
                        <TopCastList
                            data={cinescore.cinescoreMaleData}
                        />
                        {/* <Grid container justifyContent={'flex-end'}>
                        <Button className='rounded-btn mw-240 bg-light text-selected fs-14 fw-500 mt-10' endIcon={<ArrowForwardIcon className='text-selected pl-10' />}>
                            want to add more actors?
                        </Button>
                    </Grid> */}
                    </Grid>
                }
                {cinescore.cinescoreGenre && cinescore.cinescoreGenre.length > 0 &&
                    <Grid container className='app-container pt-10 app-section section-border-bottom'>
                        <Grid item>
                            <h2 className='fs-28 fw-700 lh-28 m-0 '><span className='text-selected'>your most watched </span>genres</h2>
                        </Grid>
                        <TopList
                            link={'/genres/:genre/searched'}
                            customLink={':genre'}
                            data={cinescore.cinescoreGenre}
                        />
                        {/* <Grid container justifyContent={'flex-end'}>
                        <Button className='rounded-btn mw-240 bg-light text-selected fs-14 fw-500 mt-10' endIcon={<ArrowForwardIcon className='text-selected pl-10' />}>
                            want to add more genres?
                        </Button>
                    </Grid> */}
                    </Grid>
                }
                {cinescore.cinescoreFeMaleData && cinescore.cinescoreFeMaleData.length > 0 &&
                    <Grid container className='app-container pt-10 app-section section-border-bottom'>
                        <Grid item>
                            <h2 className='fs-28 fw-700 lh-28 m-0 '>your most watched female<span className='text-selected'> actors</span></h2>
                        </Grid>
                        <TopCastList
                            data={cinescore.cinescoreFeMaleData}
                        />
                        {/* <Grid container justifyContent={'flex-end'}>
                        <Button className='rounded-btn mw-240 bg-light text-selected fs-14 fw-500 mt-10' endIcon={<ArrowForwardIcon className='text-selected pl-10' />}>
                            want to add more actors?
                        </Button>
                    </Grid> */}
                    </Grid>
                }

                <Grid container justifyContent={'center'}>
                    <Grid className='text-center pt-50 pl-20 pr-20 pb-50 mb-50' sx={{
                        background: 'linear-gradient(150deg, #F69 0%, #5C3DA4 100%)', maxWidth: { xs: '300px', sm: '500px' }
                    }}>
                        <p className='fs-25 fw-500 p-0 mt-0'>Frenzi is way more fun with friends 😎</p>
                        <Box component={'img'} src={GroupProfile} sx={{ maxWidth: { xs: '250px' } }} alt="Invite Friends w-100" />
                        <br />
                        <p style={{ maxWidth: '470px', display: 'inline-block' }} className='fs-20 text-center p-0'>Invite friends to Frenzi and get 250 points when they create their account. They get 50 points!</p>
                        <br />

                        <Button
                            style={{
                                maxWidth: '200px',
                                border: '1px solid #fff'
                            }}
                            onClick={() => {
                                if (global.is_public) {
                                    dispatch(toggleLoginModal({ isOpen: true }));
                                } else {
                                    handleOpen();
                                }
                            }} className="rounded-btn text-light w-100 lh-20">Invite your Friends</Button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <InviteUsers onClose={handleClose} />
                            </Box>
                        </Modal>
                    </Grid>
                </Grid>

                {cinescore.cinescoreDirectorData && cinescore.cinescoreDirectorData.length > 0 &&
                    <Grid container className='app-container pt-10 app-section section-border-bottom'>
                        <Grid item>
                            <h2 className='fs-28 fw-700 lh-28 m-0 '>your most watched <span className='text-selected'> directors</span></h2>
                        </Grid>
                        <TopCastList
                            data={cinescore.cinescoreDirectorData}
                        />
                        {/* <Grid container justifyContent={'flex-end'}>
                        <Button className='rounded-btn mw-240 bg-light text-selected fs-14 fw-500 mt-10' endIcon={<ArrowForwardIcon className='text-selected pl-10' />}>
                            want to add more actors?
                        </Button>
                    </Grid> */}
                    </Grid>
                }
                {cinescore.cinescorePlatform && cinescore.cinescorePlatform.length > 0 &&
                    <Grid container className='app-container pt-10 app-section section-border-bottom'>
                        <Grid item>
                            <h2 className='fs-28 fw-700 lh-28 m-0 '>your <span className='text-selected'>watched </span> titles are on</h2>
                        </Grid>
                        <TopList
                            link={'/platforms/:ott_name/most-searched'}
                            customLink={':ott_name'}
                            data={cinescore.cinescorePlatform}
                        />
                        {/* <Grid container justifyContent={'flex-end'}>
                        <Button className='rounded-btn mw-260 bg-light text-selected fs-14 fw-500 mt-10' endIcon={<ArrowForwardIcon className='text-selected pl-10' />}>
                            want to add more platforms?
                        </Button>
                    </Grid> */}
                    </Grid>
                }
                {cinescore.cinescoreDecade && cinescore.cinescoreDecade.length > 0 &&
                    <Grid container className='app-container pt-10 app-section section-border-bottom'>
                        <Grid item>
                            <h2 className='fs-28 fw-700 lh-28 m-0 '><span className='text-selected'>decades </span>you loved most</h2>
                        </Grid>
                        <TopList
                            link={'/decades/:decade/all'}
                            customLink={':decade'}
                            data={cinescore.cinescoreDecade}
                        />
                        {/* <Grid container justifyContent={'flex-end'}>
                        <Button className='rounded-btn mw-260 bg-light text-selected fs-14 fw-500 mt-10' endIcon={<ArrowForwardIcon className='text-selected pl-10' />}>
                            want to add more platforms?
                        </Button>
                    </Grid> */}
                    </Grid>
                }
                {cinescore.cinescoreLang && cinescore.cinescoreLang.length > 0 &&
                    <Grid container className='app-container pt-10 app-section section-border-bottom'>
                        <Grid item>
                            <h2 className='fs-28 fw-700 lh-28 m-0 '><span className='text-selected'>languages </span>you prefer</h2>
                        </Grid>
                        <TopList
                            link={'/languages/:language/most-searched'}
                            customLink={':language'}
                            data={cinescore.cinescoreLang}
                        />
                        {/* <Grid container justifyContent={'flex-end'}>
                        <Button className='rounded-btn mw-260 bg-light text-selected fs-14 fw-500 mt-10' endIcon={<ArrowForwardIcon className='text-selected pl-10' />}>
                            want to add more platforms?
                        </Button>
                    </Grid> */}
                    </Grid>
                }
                {cinescore?.cinescoreDetailsByMediaTypeData && cinescore.cinescoreDetailsByMediaTypeData.length > 0 &&
                    <div className='mt-20 section-border-bottom mb-20 pb-20'>
                        <div className='row mb-20' style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                            <p className='p-0 m-0 fs-20'>cinescore</p>
                            <Link to={'/cinescore/movies'} >
                                <div className='row fs-16 pointer' style={{ alignItems: 'center' }}>See All <ChevronRightRoundedIcon className='mt-2' /></div>
                            </Link>
                        </div>
                        <Grid container spacing={2}>
                            {cinescore.cinescoreDetailsByMediaTypeData.map((x, i) => {
                                if (i > 5 || (isMobileDevice() && i > 2)) {
                                    return null;
                                }
                                return <Grid xs={12} sm={6} md={4} item key={i}>
                                    <CinescoreCard data={x} />
                                </Grid>
                            })}
                        </Grid>

                    </div>
                }
                <MovieLovers />
            </Grid>
        </>
    )
}
