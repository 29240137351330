import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateLayout from "./layouts/PrivateLayout";
import PublicLayout from "./layouts/PublicLayout";
import Login from "./views/auth/Login";
import Register from "./views/auth/Register";
import Onboarding from "./views/onboarding/Onboarding";
import Trending from "./views/trending/Trending";
import MovieDetail from "./views/media/MovieDetail";
import Activity from "./views/activity/Activity";
import CastCrewDetail from "./views/castCrew/CastCrewDetail";
import News from "./views/common/News";
import MyCinescore from "./views/myCinescore/MyCinescore";
import Watchlist from "./views/watchlist/Watchlist";
import WatchlistDetail from "./views/watchlist/WatchlistDetail";
import UnWatchedWatchlist from "./views/watchlist/UnWatchedWatchlist";
import WatchlistSection from "./views/watchlist/WatchlistSection";
import Reviews from "./views/review/Reviews";
import Profile from "./views/profile/Profile";
import Search from "./views/search/Search";
import Connect from "./views/connect/Connect";
import ReviewDetail from "./views/review/ReviewDetail";
import Setting from "./views/settings/Setting";
import Fresh from "./views/trending/includes/Fresh";
import FreshDetails from "./views/trending/includes/FreshDetails";
import TopTenInner from "./views/trending/includes/TopTenInner";
import TrendingDetail from "./views/trending/includes/TrendingDetail";
import TrendingAllData from "./views/trending/includes/TrendingAllData";
import PreferLanguageInner from "./views/trending/includes/PreferLanguageInner";
import PreferLanguageDetail from "./views/trending/includes/PreferLanguageDetail";
import PreferGenreInner from "./views/trending/includes/PreferGenreInner";
import PrefergenreDetail from "./views/trending/includes/PrefergenreDetail";
import FreeTrending from "./views/trending/FreeTrending";
import PopularFrenzi from "./views/trending/includes/PopularFrenzi";
import CollectionDetail from "./views/trending/includes/CollectionDetail";
import HiddenGems from "./views/trending/includes/HiddenGems";
import HiddenGemsDetail from "./views/trending/includes/HiddenGemsDetail";
import PopularFrenziDetail from "./views/trending/includes/PopularFrenziDetail";
import PopularFriends from "./views/trending/includes/PopularFriends";
import ComingSoon from "./views/trending/includes/ComingSoon";
import ReviewInnerPage from "./views/review/ReviewInnerPage";
import ShareListsDetailPage from "./views/connect/ShareListsDetailPage";
import WatchlistListing from "./views/watchlist/includes/WatchlistListing";
import AboutUs from "./views/businesspages/AboutUs";
import TermsAndCondition from "./views/businesspages/TermsAndCondition";
import PrivacyPolicy from "./views/businesspages/PrivacyPolicy";
import Snipz from "./views/snipz/Snipz";
import PendingWatchlist from "./views/watchlist/PendingWatchlist";
import PendingRequests from "./views/watchlist/PendingRequests";
import PointSummary from "./views/settings/settingComponent/PointSummary";
import Career from "./views/businesspages/Career";
import AuthLayout from "./layouts/AuthLayout";
import Loader from "./views/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import PublicTrending from "./views/trending/public/PublicTrending";
import { toggleIsPublic } from "./slices/global";
import PublicFreeTrending from "./views/trending/public/PublicFreeTrending";
import ContactUs from "./views/businesspages/ContactUs";
// import PublicWatchlistDetail from "./views/watchlist/PublicWatchlistDetail";
import ReactGA from "react-ga4";
import SearchInner from "./views/search/SearchInner";
import SearchInnercast from "./views/search/SearchInnercast";
import CommonDetailGridList from "./views/common/CommonDetailGridList";
import Unsubscribe from "./views/common/Unsubscribe";

import WPblogs from "./views/WPblog/WPblogs";
import WPblogDetail from "./views/WPblog/WPblogDetail";
import MyCinescoreDetails from "./views/myCinescore/MyCinescoreDetails";
import WebStories from "./views/WPblog/WebStories";
import WebReelData from "./views/WPblog/WebReelData";
import loadable from '@loadable/component';
import Notifications from "./views/profile/Notifications";
import PartnerSearch from "./views/partner/PartnerSearch";
import PartnerSearchResults from "./views/partner/PartnerSearchResults";
import PartnerRecommendations from "./views/partner/PartnerRecommendations";
import PartnerLayout from "./layouts/PartnerLayout";

const LoadableHandpickDefault = loadable(() => import('./views/trending/HandpickDefault'), {
  preload: true
});

const LoadableMainNavBar = loadable(() => import('./views/component/MainNavBar'), {
  preload: true
});

LoadableHandpickDefault.preload();
LoadableMainNavBar.preload();

ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_GA4_TRACKING_ID,
  },
]);

function MyApp() {
  const auth = useSelector((state) => state.auth);
  const [isRender, setIsRender] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    let data = localStorage.getItem("frenzi_user_access");
    if (data) {
      data = JSON.parse(data);
      if (
        data &&
        data.access &&
        data.access !== "" &&
        auth.token &&
        auth.token !== "" &&
        auth.token === data.access
      ) {
        setIsRender(true);
        setIsPublic(false);
        dispatch(toggleIsPublic(false));
      } else {
        setIsRender(true);
        dispatch(toggleIsPublic(true));
      }
    } else {
      setIsRender(true);
      dispatch(toggleIsPublic(true));
    }
  }, []);

  return (
    <>
      {!isRender ? (
        <>
          <Loader />
          {window.location.pathname === '/' ? <>
            <LoadableMainNavBar />
            <LoadableHandpickDefault />
          </> : ''}
        </>
      ) : isPublic ? (
        <div>
          <Routes>
            <Route path="/onboarding" element={<Onboarding />} />

            <Route path="/" element={<AuthLayout title={"Login"} />}>
              <Route path="*" element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Register />} />
            </Route>

            <Route path="/" element={<PublicLayout />}>
              <Route index element={<PublicTrending isPublic={true} />} />
              <Route path="free" element={<PublicFreeTrending />} />
              <Route path="search" element={<Search />} />
              <Route
                path="search-results/media/:query"
                element={<SearchInner />}
              />
              <Route
                path="search-results/person/:query"
                element={<SearchInnercast />}
              />
              <Route path="movie/:name/:uuid" element={<MovieDetail />} />
              <Route path="movie/:name" element={<MovieDetail />} />
              <Route path="series/:name/:uuid" element={<MovieDetail />} />
              <Route path="series/:name" element={<MovieDetail />} />
              <Route path="new-release-on-ott" element={<Fresh />} />
              <Route
                path="new-release-on-ott/:type"
                element={<FreshDetails />}
              />
              <Route path="new-free-release-on-ott" element={<Fresh />} />
              <Route
                path="new-free-release-on-ott/:type"
                element={<FreshDetails />}
              />
              <Route
                path="trending-movies-series-on-ott-this-week"
                element={<TopTenInner />}
              />
              <Route path="platforms" element={<TrendingDetail />} />
              <Route path="platforms/:platform" element={<TrendingDetail />} />
              <Route
                path="platforms/:platform/:type"
                element={<TrendingAllData />}
              />
              <Route path="languages" element={<PreferLanguageInner />} />
              <Route
                path="languages/:language"
                element={<PreferLanguageInner />}
              />
              <Route
                path="languages/:language/:type"
                element={<PreferLanguageDetail />}
              />
              <Route path="genres" element={<PreferGenreInner />} />
              <Route path="genres/:genre" element={<PreferGenreInner />} />
              <Route
                path="genres/:genre/:type"
                element={<PrefergenreDetail />}
              />
              <Route
                path="upcoming-movies-and-series-on-ott"
                element={<ComingSoon />}
              />
              <Route path="popular-on-frenzi" element={<PopularFrenzi />} />
              <Route
                path="popular-today/detail/:type"
                element={<PopularFrenziDetail />}
              />
              <Route path="activity" element={<Activity is_public={true} />} />
              <Route
                path="person/:name/:uuid"
                element={<CastCrewDetail is_public={true} />}
              />
              <Route
                path="person/:name"
                element={<CastCrewDetail is_public={true} />}
              />
              <Route path="news" element={<News />} />
              {/* legal routes */}
              <Route path="tnc" element={<TermsAndCondition />} />

              <Route path="blog" element={<WPblogs />} />
              <Route path="blog/:name/:id" element={<WPblogDetail />} />
              <Route path="blog/:unique_title" element={<WPblogDetail />} />

              <Route path="stories" element={<WebStories />} />
              <Route path="stories/:unique_title" element={<WebReelData />} />

              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="aboutus" element={<AboutUs />} />
              <Route path="career" element={<Career />} />
              <Route path="contactus" element={<ContactUs />} />
              {/* extra pages end */}
              <Route path="unsubscribe" element={<Unsubscribe />} />
            </Route>
          </Routes>
        </div>
      ) : (
        <div>
          <Routes>
            <Route path="/onboarding" element={<Onboarding />} />

            {/* Private routes */}
            <Route path="/" element={<PrivateLayout />}>
              <Route path="*" element={<Trending />} />
              <Route index element={<Trending />} />

              <Route path="notification" element={<Notifications />} />
              <Route path="activity" element={<Activity />} />
              <Route path="movie/:name/:uuid" element={<MovieDetail />} />
              <Route path="movie/:name" element={<MovieDetail />} />
              <Route path="series/:name/:uuid" element={<MovieDetail />} />
              <Route path="series/:name" element={<MovieDetail />} />
              <Route path="person/:name/:uuid" element={<CastCrewDetail />} />
              <Route path="person/:name" element={<CastCrewDetail />} />
              <Route path="mycinescore" element={<MyCinescore />} />
              <Route
                path="cinescore/:mediaType"
                element={<MyCinescoreDetails />}
              />
              <Route path="watchlist" element={<Watchlist />} />
              <Route path="news" element={<News />} />
              <Route
                path="watchlist-detail/:id"
                element={<WatchlistDetail />}
              />
              <Route path="watchlists" element={<WatchlistSection />} />
              <Route
                path="unwatch-watchlist"
                element={<UnWatchedWatchlist />}
              />
              <Route path="reviews" element={<Reviews />} />
              <Route path="profile" element={<Profile />} />
              <Route path="profile/:id" element={<Profile />} />
              <Route path="search" element={<Search />} />
              <Route
                path="search-results/media/:query"
                element={<SearchInner />}
              />
              <Route
                path="search-results/person/:query"
                element={<SearchInnercast />}
              />
              <Route path="connect" element={<Connect />} />
              <Route path="free" element={<FreeTrending />} />
              <Route
                path="review-detail/:type/:category"
                element={<ReviewDetail />}
              />
              <Route path="reviews/:category" element={<ReviewDetail />} />
              {/* <Route path="review/:category" element={<ReviewInnerPage />} /> */}
              <Route
                path="review/:unique_title"
                element={<ReviewInnerPage />}
              />
              <Route path="settings" element={<Setting />} />
              <Route path="new-release-on-ott" element={<Fresh />} />
              <Route
                path="new-release-on-ott/:type"
                element={<FreshDetails />}
              />
              <Route path="new-free-release-on-ott" element={<Fresh />} />
              <Route
                path="new-free-release-on-ott/:type"
                element={<FreshDetails />}
              />
              <Route
                path="trending-movies-series-on-ott-this-week"
                element={<TopTenInner />}
              />
              <Route path="platforms" element={<TrendingDetail />} />
              <Route path="platforms/:platform" element={<TrendingDetail />} />
              <Route
                path="platforms/:platform/:type"
                element={<TrendingAllData />}
              />
              <Route
                path="platforms/:platform/languages/:type"
                element={<TrendingAllData />}
              />
              <Route
                path="languages/:language"
                element={<PreferLanguageInner />}
              />
              <Route
                path="languages/:language/:type"
                element={<PreferLanguageDetail />}
              />
              <Route
                path="decades/:decade/:type"
                element={
                  <CommonDetailGridList
                    path={"decademedia?decade="}
                    heading={
                      <h2 className="fs-28 fw-700 lh-28 m-0">
                        choose from these{" "}
                        <span className="text-selected">decade</span>
                      </h2>
                    }
                  />
                }
              />
              <Route path="genres" element={<PreferGenreInner />} />
              <Route path="genres/:genre" element={<PreferGenreInner />} />
              <Route
                path="genres/:genre/:type"
                element={<PrefergenreDetail />}
              />
              <Route path="popular-on-frenzi" element={<PopularFrenzi />} />
              <Route
                path="popular-today/detail/:type"
                element={<PopularFrenziDetail />}
              />
              <Route
                path="collection-media/:id"
                element={<CollectionDetail />}
              />
              <Route path="hidden-gems-on-ott" element={<HiddenGems />} />
              <Route
                path="hidden-gems-on-ott/:platform/:type"
                element={<HiddenGemsDetail />}
              />
              <Route
                path="popular-among-friends"
                element={<PopularFriends />}
              />
              <Route
                path="upcoming-movies-and-series-on-ott"
                element={<ComingSoon />}
              />
              <Route
                path="friends-sharelists"
                element={<ShareListsDetailPage />}
              />
              <Route
                path="watchlist-list/:type"
                element={<WatchlistListing />}
              />
              <Route path="aboutus" element={<AboutUs />} />
              <Route path="clips" element={<Snipz />} />
              <Route path="clips/:id" element={<Snipz />} />
              <Route path="tnc" element={<TermsAndCondition />} />

              <Route path="blog" element={<WPblogs />} />
              <Route path="blog/:name/:id" element={<WPblogDetail />} />
              <Route path="blog/:unique_title" element={<WPblogDetail />} />

              <Route path="stories" element={<WebStories />} />
              <Route path="stories/:unique_title" element={<WebReelData />} />


              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="pending-list" element={<PendingWatchlist />} />
              <Route path="career" element={<Career />} />
              <Route path="contactus" element={<ContactUs />} />

              <Route path="pending-requests" element={<PendingRequests />} />
              <Route
                path="followers"
                element={<Connect pageType={"followers"} hideAll={true} />}
              />
              <Route
                path="following"
                element={<Connect pageType={"following"} hideAll={true} />}
              />
              <Route
                path="points"
                element={<PointSummary indinvidualPage={true} />}
              />
              <Route path="unsubscribe" element={<Unsubscribe />} />
              <Route path="unsubscribe" element={<Unsubscribe />} />

              {/* extra pages start */}
            </Route>

            {/* Partner routes */}
            <Route path="/partner" element={<PartnerLayout />}>
              <Route index element={<PartnerSearch />} />
              <Route path="results" element={<PartnerSearchResults />} />
              <Route path="recommendations" element={<PartnerRecommendations />} />
            </Route>
          </Routes>
        </div>
      )}
    </>
  );
}

export default MyApp;
