import { Button } from '@mui/material';

import React from 'react'

const CustomButton = (props) => {
  return (
    <Button sx={props?.sx} className={props.className} disabled={props.disabled ? props.disabled : false} onClick={props.onClick}>
      {props.startIcon}
      {props.title}
    </Button>
  )
}

export default CustomButton