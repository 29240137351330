import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SingleMediaTile from '../../common/SingleMediaTile';
import { getFreshArrivalMovieData, getFreshArrivalTvData, getMostSearchedTitles } from '../../../slices/trending';
import CommonMenu from '../../common/CommonMenu';
import Loader from '../../common/Loader';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import SideFilter from '../../search/SideFilter';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Box } from '@mui/system';
import MyHelmet from '../../common/MyHelmet';
import MyAds from '../../adsense/MyAds';

const menuList = [
    {
        link: 'all',
        label: 'all'
    },
    {
        link: 'last_30_days',
        label: 'last 30 days'
    },
    {
        link: 'last_15_days',
        label: 'last 15 days'
    },
    {
        link: 'this_week',
        label: 'this week'
    }
]

export default function FreshDetails() {
    const [tempFilter, setTempFilter] = useState(false);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('');
    const [tabValue, setTabValue] = useState('all');
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const trending = useSelector(state => state.trending);
    const _global = useSelector(state => state.global);
    const { freshMovieData, freshMoviePageData, freshTvData, freshTvPageData, freshSearchedData, freshSearchedPageData, isLoading } = trending;
    const [searchParams] = useSearchParams();

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    const handleClickListItem2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleSort = (val) => {
        setSort(val);
        if (page == 1) {
            getData(val, tempFilter);
        } else {
            setPage(1);
        }
        handleClose2();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    useEffect(() => {
        getData(sort, tempFilter);
    }, [params, page, tabValue]);

    const getData = (sort, filter = tempFilter) => {
        const is_free = searchParams.get('is_free') && searchParams.get('is_free') == 'true' ? true : false;
        if (params && params.type && params.type != '') {
            if (params.type == 'movie') {
                dispatch(getFreshArrivalMovieData({ is_public: _global.is_public, is_free: is_free, body: filter, media_type: 'movie', sort: sort, date_range: tabValue, page: page, pagelimit: 20 }));
            }
            if (params.type == 'tv') {
                dispatch(getFreshArrivalTvData({ is_public: _global.is_public, is_free: is_free, body: filter, media_type: 'tv', sort: sort, date_range: tabValue, page: page, pagelimit: 20 }));
            }
            if (params.type == 'most-searched') {
                dispatch(getMostSearchedTitles({ is_public: _global.is_public, is_free: is_free, body: filter, media_type: 'all', sort: sort && sort != '' ? sort : 's', date_range: tabValue, page: page, pagelimit: 20 }));
            }
        }
    }

    const handleApplyFilter = (filter) => {
        if (filter) {
            setTempFilter({ ...filter });
            if (params && params.type && params.type != '') {
                if (page == 1) {
                    getData(sort, filter);
                } else {
                    setPage(1);
                }
            }
            window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        const currentPageData = params.type == 'movie' ? freshMoviePageData : params.type == 'tv' ? freshTvPageData : params.type == 'most-searched' ? freshSearchedPageData : null;
        const handleScroll = () => {
            if (currentPageData && currentPageData.pagination && currentPageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, trending]);

    const getPtype = (p) => {
        let res = '';
        res = p.type === "tv" ? 'Web Series' : 'Movies';
        return res;
    };

    const pType = useMemo(() => getPtype(params), [params]);

    return (
        <>
            <MyHelmet
                title={`Fresh ${global.is_public ? '& Free' : ''} ${pType} on OTT`}
                og_title={`Watch New Release Movies & Series ${global.is_public ? 'for Free' : ''} ${pType} on OTT`}
                description={`Find the latest free movies and series on OTT platforms. Stay updated with fresh ${global.is_public ? 'and free' : ''}  ${pType} on MyFrenzi.`}
                keywords={`fresh  ${pType}, fresh content, ${global.is_public ? 'free content' : ''}, OTT platforms, MyFrenzi`}
            />

            <MyHelmet
                title={'fresh arrivals'}
                description={'Stay up-to-date with the latest releases on OTT platforms. Explore our "Fresh Arrivals" section for the newest and most exciting new releases.'}
            />
            {isLoading && <Loader />}
            <Grid className='new-container mb-50'>
                <Grid container alignItems={'center'} className="pt-20" justifyContent='space-between'>
                    <Grid item>
                        <Grid container alignItems={'center'}>
                            <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                            <p className='fs-28 fw-700 lh-28 m-0'><span className='text-selected'>fresh</span> arrivals</p>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CommonMenu
                            setTabValeu={(val) => setTabValue(val)}
                            menuList={menuList}
                            tabValue={tabValue}
                            isBack={false}
                            isFull={true}
                        />
                    </Grid>
                </Grid>
                <Grid container className='pt-10 pb-20' alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item xs={10}>
                        <Grid container alignItems={'center'}>
                            <Grid item className=''>
                                <Grid container onClick={handleClickListItem2} alignItems={'center'} className="pointer">
                                    <p className='fw-700 fs-15 m-0 mr-6 '>
                                        sort
                                    </p>
                                    <SwapVertIcon />
                                </Grid>
                                <Popover
                                    id={'menu2'}
                                    open={open2}
                                    anchorEl={anchorEl2}
                                    onClose={handleClose2}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Box className='pb-20 pl-20 pr-20'>
                                        <Grid onClick={() => handleSort('d')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                            <p className={`fw-500 fs-16 m-0 ${sort === 'd' && 'text-selected'}`} >release date</p>
                                        </Grid>
                                        <Grid onClick={() => handleSort('l')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                            <p className={`fw-500 fs-16 m-0 ${sort === 'l' && 'text-selected'}`} >popular</p>
                                        </Grid>
                                        <Grid onClick={() => handleSort('r')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                            <p className={`fw-500 fs-16 m-0 ${sort === 'r' && 'text-selected'}`} >rating</p>
                                        </Grid>
                                    </Box>
                                </Popover>
                            </Grid>
                        </Grid>
                    </Grid>

                    <SideFilter
                        onSearchApply={(e) => handleApplyFilter(e)}
                        hideClearBtn={true}
                    />
                </Grid>

                <Grid container flexWrap={'nowrap'}>
                    <Grid item style={{ maxWidth: '100%' }} flex={1}>
                        <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px' }} justifyContent={'center'}>
                            <Grid container justifyContent={'center'}>
                                <MyAds type="banner_2" adv_type={'web_fresh_top_ad'} hideInMobile={true} />
                            </Grid>
                        </Grid>
                        {params.type == 'movie' && freshMovieData && freshMovieData.length > 0 &&
                            <Grid container spacing={2}>
                                {freshMovieData.map((x, i) => {
                                    return <>
                                        {(i !== 0 && i % 2 === 0 && i % 3 === 0) &&
                                            <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                                                <Grid container justifyContent={'center'}>
                                                    <MyAds type="banner_2" adv_type={'web_media_listing_ad'} />
                                                </Grid>
                                            </Grid>
                                        }

                                        <Grid item className='fresh-data-items' key={i} xs={12} sm={6} >
                                            <SingleMediaTile refPage={'Fresh Arrivals'} x={x} />
                                        </Grid>
                                    </>
                                })}
                            </Grid>
                        }
                        {params.type == 'tv' && freshTvData && freshTvData.length > 0 &&
                            <Grid container spacing={2}>
                                {freshTvData.map((x, i) => {
                                    return <>
                                        {(i !== 0 && i % 2 === 0 && i % 3 === 0) &&
                                            <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                                                <Grid container justifyContent={'center'}>
                                                    <MyAds type="banner_2" adv_type={'web_media_listing_ad'} />
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid className='fresh-data-items' item key={i} xs={12} sm={6} >
                                            <SingleMediaTile refPage={'Fresh Arrivals'} x={x} />
                                        </Grid>
                                    </>
                                })}
                            </Grid>
                        }
                        {params.type == 'most-searched' && freshSearchedData && freshSearchedData.length > 0 &&
                            <Grid container spacing={2}>
                                {freshSearchedData.map((x, i) => {
                                    return <>
                                        {(i !== 0 && i % 2 === 0 && i % 3 === 0) &&
                                            <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                                                <Grid container justifyContent={'center'}>
                                                    <MyAds type="banner_2" adv_type={'web_media_listing_ad'} />
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid className='fresh-data-items' item key={i} xs={12} sm={6} >
                                            <SingleMediaTile refPage={'Fresh Arrivals'} x={x} />
                                        </Grid>
                                    </>
                                })}
                            </Grid>
                        }
                    </Grid>
                    <Grid item sx={{ display: { xs: 'none', lg: 'block' } }} className="sticky-ads">
                        <MyAds type={'banner_1'} adv_type={'web_fresh_right_ad'} />
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}
