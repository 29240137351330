import axiosInstance from "../http-common";


const getClipsData = (id = '', section = 'post', include_viewed_clips = 0, page = 1, pagelimit = 10) => {
    let url = `native_main/discoverpostlist?page=${page}&pagelimit=${pagelimit}`;
    if (id && id != '') {
        if (section == 'post') {
            url += `&post_id=${id}`;
        }
        if (section == 'watchlist') {
            url += `&watchlist_id=${id}`;
        }
        if (section == 'media') {
            url += `&media=${id}`;
        }
        if (section == 'user') {
            url += `&created_user=${id}`;
        }
    }
    if (section == 'myself') {
        url += `&created_user=myself`;
    }
    url += `&include_viewed_clips=${include_viewed_clips}`;
    return axiosInstance.get(url);
};


const clipPostActivity = (action, body) => {
    let url = `native_main/discoverpostactivity?action=${action}`;
    return axiosInstance.post(url, body);
};

const getCommentLists = (id, page = 1, pagelimit = 20) => {
    let url = `native_main/discovercomment?post_id=${id}&page=${page}&pagelimit=${pagelimit}`;
    return axiosInstance.get(url);
};

const getRepliesList = (id, page = 1, pagelimit = 20) => {
    let url = `native_main/discoverreply?comment_id=${id}&page=${page}&pagelimit=${pagelimit}`;
    return axiosInstance.get(url);
};

const getReportReasons = () => {
    let url = `native_main/reportreason`;
    return axiosInstance.get(url);
};

const getDiscoverTags = (search = '') => {
    let url = `native_main/discoverposttag?search=${search}`;
    return axiosInstance.get(url);
};

const addClip = (body) => {
    let url = `native_main/discoverpostlist?section=app`;
    return axiosInstance.post(url, body);
};

const deleteCLipPost = (id) => {
    let url = `native_main/discoverpostdetail/${id}`;
    return axiosInstance.delete(url);
};

const ClipsDataService = {
    getClipsData,
    clipPostActivity,
    getCommentLists,
    getRepliesList,
    getReportReasons,
    getDiscoverTags,
    addClip,
    deleteCLipPost
};

export default ClipsDataService;