import { Box, Grid } from '@mui/material'
import React from 'react'
import { isMobileDevice } from '../../services/Utils'
import { Link } from 'react-router-dom';

const LargeSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/LargeSeeAll.svg";
const SmallSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/SmallSeeAll.svg";

export default function SeeAll() {

    return (
        <Grid>
            {/* <Link to="/trending-movies-series-on-ott-this-week"> */}
                <Box component={'img'} height={'30px'} src={isMobileDevice() ? SmallSeeAll : LargeSeeAll} sx={{ paddingTop: { xs: '5px', sm: '10px' } }} />
            {/* </Link> */}
        </Grid>
    )
}
