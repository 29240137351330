import { Accordion, AccordionSummary, Grid, Modal } from '@mui/material'
import React, { useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../setting.scss'
import Genre from '../../onboarding/Genre';
import Language from '../../onboarding/Language';
import Platform from '../../onboarding/Platform';
import { Box } from '@mui/system';

const prefrenceSettingList = [
    {
        id: 1,
        label: 'languages',
        sublabel: 'choose the languages you want to watch titles in'
    },
    {
        id: 2,
        label: 'platforms',
        sublabel: 'choose the platforms you want to watch titles in'
    },
    {
        id: 3,
        label: 'genres',
        sublabel: 'choose the genres you want to watch titles in'
    }
]

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
    maxWidth: { xs: '90%', sm: '75%', md: '50%' },
    bgcolor: 'transparent',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    zIndex: 999,
};

export default function PrefrenceSetting() {
    const [openModal, setOpenModal] = useState(false);
    const [activeSetting, setActiveSetting] = useState('languages');


    return (
        <Grid item>
            <Grid item>
                {
                    prefrenceSettingList.map((x, i) => {
                        return <Accordion onClick={() => {
                            setActiveSetting(x.label);
                            setOpenModal(true);
                        }} expanded={false} className='prefrence-setting-container'>
                            <AccordionSummary
                                expandIcon={<ArrowForwardIcon className='blue-icon' />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid item>
                                    <p className='fw-600 fs-20 m-0 text-light'>{x.label}</p>
                                    <p className='fw-400 fs-16 m-0 text-muted'>{x.sublabel}</p>
                                </Grid>
                            </AccordionSummary>
                        </Accordion>
                    })
                }
            </Grid>
            <div>
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        {activeSetting == 'genres' &&
                            <Genre setActiveSteps={() => setOpenModal(false)} activeSteps={3} />
                        }
                        {activeSetting == 'languages' &&
                            <Language setActiveSteps={() => setOpenModal(false)} activeSteps={1} />
                        }
                        {activeSetting == 'platforms' &&
                            <Platform setActiveSteps={() => setOpenModal(false)} activeSteps={2} />
                        }
                    </Box>
                </Modal>
            </div>
        </Grid>
    )
}
