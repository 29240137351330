import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import { useSearchParams } from 'react-router-dom';

import { GoogleOAuthProvider } from '@react-oauth/google';
import MyGoogleLogin from './MyGoogleLogin';

const fpPromise = FingerprintJS.load()

const clientId = process.env.REACT_APP_OAUTH_2_CLIENT_ID;

export default function CustomSocialLogin(props) {

    const [isMounted, setIsMounted] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const onPageLoad = () => {
            setIsMounted(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const getUniqueDeviceID = async () => {
        const fp = await fpPromise
        const result = await fp.get()
        console.log(result.visitorId)
        return result.visitorId;
    }

    const socialSubmitForm = async (tempForm) => {
        if (tempForm && !helpers.isEmptyObject(tempForm)) {
            props.performFormSubmitProcess(tempForm, 'login');
        }
    }

    const handleGoogleLogin = async (data, accessToken) => {
        let referred_by = searchParams.get('ref');

        if (data.email && data.given_name) {
            let name = data?.name || data?.given_name + data?.family_name;
            const defaultFormData = {
                username: name,
                email: data?.email,
                profile_image: data?.picture,
                device_type: 'web',
                device_id: await getUniqueDeviceID(),
                referred_by: referred_by || "",
                device_token: "",
                social_access_token: accessToken,
                social_type: "google",
                social_id: data?.sub
            }
            socialSubmitForm(defaultFormData);
        } else {
            helpers.toastError('Something went wrong!!!');
        }
        helpers.trackMoengageEvent('FR3_Land', { continue_with_google: MOENGAGE_ATTRIBUTE.YES })
    };

    return (
        <Grid className="social">
            <Grid item sx={{ marginTop: { xs: '12px', sm: '20px' } }}>
                {isMounted ?
                    <GoogleOAuthProvider clientId={clientId}>
                        <MyGoogleLogin onSuccess={handleGoogleLogin} />
                    </GoogleOAuthProvider>
                    :
                    ''
                }
            </Grid>
        </Grid>
    )
}
