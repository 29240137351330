import axiosInstance from "../http-common";
import helpers from "./helpers";
import { encryptData } from "./Utils";

const createAccount = (body) => {
    return axiosInstance.post(`native_main/createaccount`, body);
};

const login = (body) => {
    return axiosInstance.post(`native_main/loginotp`, body);
};

const loginWithOtp = (body) => {
    return axiosInstance.post(`native_main/otplogin`, body);
};

const resendOtp = (body) => {
    return axiosInstance.post(`native_main/resendotp`, body);
};

const resendVerificationOtp = (body) => {
    return axiosInstance.post(`native_main/resendverificationotpv3`, body);
};

const socialLogIn = (body) => {
    return axiosInstance.post(`native_main/socialloginencrypt`, body);
};

// onboarding

const getLanguageList = () => {
    return axiosInstance.get(`native_main/languagelist`);
};

const updateLangPreference = (body) => {
    return axiosInstance.post(`native_main/languagelist`, body);
};

const getPlatformList = async (is_free = false, is_public = false, page, pagelimit) => {
    var url = `native_main/${is_public ? 'public_' : ''}subscriptionlistv2?page=${page}&pagelimit=${pagelimit}`;
    if (is_free) {
        url += `&is_free=${is_free}`
    }
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();

    if (is_public) {
        let newBody = helpers.getQueryParams(url.split('?')[1]);
        url = url.split('?')[0];
        newBody = { ...newBody, captcha_token: captcha_token }
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(url, { data: encryptedData });
    } else {
        return axiosInstance.get(url);
    }
};

const updatePlatformPreference = (body) => {
    return axiosInstance.post(`native_main/subscriptionlistv2`, body);
};

const getGenreList = () => {
    return axiosInstance.get(`native_main/nativegenres`);
};

const updateGenrePreference = (body) => {
    return axiosInstance.post(`native_main/genrelist`, body);
};

const getOnboardingTitles = () => {
    return axiosInstance.get(`native_main/onboardingtitles?pagelimit=50`);
};

const updateOnboardingTitles = (body) => {
    return axiosInstance.post(`native_main/userbulklike`, body);
};

const getAllPreferences = async (is_public = false, is_free = false) => {
    let url = '';
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();
    if (is_public) {
        url = `native_main/public_allpreferences`;
        if (is_free) {
            let newBody = { is_free: true, captcha_token: captcha_token };
            let encryptedData = await encryptData(newBody);
            return axiosInstance.post(url, { data: encryptedData });
        } else {
            let newBody = { captcha_token: captcha_token };
            let encryptedData = await encryptData(newBody);
            return axiosInstance.post(url, { data: encryptedData });
        }
    } else {
        url = `native_main/allpreferences`;
        if (is_free) {
            url += `?is_free=true`;
        }
        return axiosInstance.get(url);
    }
};

const getExistingWatchlist = (media_id, page, pagelimit) => {
    return axiosInstance.get(`native_main/userwatchlistdetail?media=${media_id}&page=${page}&pagelimit=${pagelimit}`);
};

const getProfileData = () => {
    return axiosInstance.get(`native_main/profiledashboard`);
};

const getOnboardingMediaCount = () => {
    return axiosInstance.get(`native_main/onboardingmediacount`);
};

const collectLoginInfo = (body) => {
    return axiosInstance.post(`tracking/logininfo`, body);
};


const AuthDataService = {
    createAccount,
    login,
    loginWithOtp,
    resendOtp,
    resendVerificationOtp,
    socialLogIn,
    getLanguageList,
    updateLangPreference,
    getPlatformList,
    updatePlatformPreference,
    getGenreList,
    updateGenrePreference,
    getOnboardingTitles,
    updateOnboardingTitles,
    getAllPreferences,
    getExistingWatchlist,
    getProfileData,
    getOnboardingMediaCount,
    collectLoginInfo
};

export default AuthDataService;