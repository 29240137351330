import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Button, Box } from "@mui/material";
import "./onboarding.scss";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoginModal } from "../../slices/global";
import { HELPER_TEXT } from "../../lang/HelperText";
import {
  clearMessages,
  getOnboardingMediaCount,
  getPlatformList,
  updatePlatformPreference,
} from "../../slices/auth";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
// import rightImg from "../../assets/icons/right.svg";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

const rightImg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/right.svg";

function Platform(props) {
  const { isOnboarding, showTitle } = props;
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const platformList = [];
  const currentPage = 1;
  const pageLimit = 200;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const _global = useSelector((state) => state.global);

  useEffect(() => {
    if (!_global.is_public) {
      dispatch(
        getPlatformList({
          page: currentPage,
          pagelimit: pageLimit,
          is_public: _global.is_public,
        })
      );
      dispatch(getOnboardingMediaCount());
    }
  }, []);

  const { onboardingMediaCountData } = auth;

  useEffect(() => {
    if (auth.errMesg && auth.errMesg !== "") {
      helpers.toastError(auth.errMesg);
      dispatch(clearMessages());
    }
    if (auth.successMesg && auth.successMesg !== "") {
      helpers.toastSuccess(auth.successMesg, false);
      if (auth.successMesg === "platform preferences updated") {
        // dispatch(getPlatformList({ page: currentPage, pagelimit: pageLimit }));
        dispatch(getOnboardingMediaCount());
      }
      dispatch(clearMessages());
    }
  }, [auth]);
  useEffect(() => {
    if (
      auth.platformList &&
      auth.platformList.results &&
      auth.platformList.results.length > 0
    ) {
      // setPlatformList(helpers.removeDuplicate([...platformList, ...auth.platformList.results]));
      var temp = [];
      helpers
        .removeDuplicate([...platformList, ...auth.platformList.results])
        .map((x, i) => {
          if (x.existing_subscription) {
            temp.push(x.uuid);
          }
        });
      setSelectedPlatform([...temp]);
    }
  }, [auth.platformList]);

  const handleSelectedPlatform = () => {
    if (_global.is_public) {
      dispatch(
        toggleLoginModal({
          isOpen: true,
          content: HELPER_TEXT.NUDGE_TEXT.ADD_PLATFORM,
        })
      );
    } else {
      helpers.trackMoengageEvent("FR3_Pre_Ott_Pref", {
        ott_countinue_click: MOENGAGE_ATTRIBUTE.YES,
      });
      if (selectedPlatform.length > 0) {
        if (isOnboarding) {
          if (!showTitle) {
            var body = {
              platform: selectedPlatform,
            };
            dispatch(updatePlatformPreference(body)).then(() => {
              window.location.href = "/";
            });
          } else {
            window.location.href = "/";
          }
        } else {
          if (!showTitle) {
            var body = {
              platform: selectedPlatform,
            };
            dispatch(updatePlatformPreference(body));
          }
          props.setActiveSteps(props.activeSteps + 1);
        }
      } else {
        if (props.is_public) {
          dispatch(
            toggleLoginModal({
              isOpen: true,
              content: HELPER_TEXT.NUDGE_TEXT.ADD_PLATFORM,
            })
          );
        } else {
          helpers.toastError("Please select atleast one platform");
        }
      }
    }
  };

  const handlePlatformSelect = (data) => {
    const tempArr = selectedPlatform;
    if (tempArr.includes(data.uuid)) {
      const index = tempArr.indexOf(data.uuid);
      if (index > -1) {
        tempArr.splice(index, 1);
      }
      setSelectedPlatform([...tempArr]);
      if (showTitle) {
        var body = {
          platform: tempArr,
        };
        dispatch(updatePlatformPreference(body));
      }
    } else {
      tempArr.push(data.uuid);
      setSelectedPlatform([...tempArr]);
      if (showTitle) {
        var body = {
          platform: tempArr,
        };
        dispatch(updatePlatformPreference(body));
      }
    }
  };

  const platformListShow =
    auth.platformList?.results &&
    auth.platformList.results.length > 0 &&
    !_global.is_public
      ? auth.platformList.results
      : auth.allPreferences?.platforms &&
        auth.allPreferences.platforms.length > 0
      ? auth.allPreferences.platforms
      : [];

  return (
    <Paper elevation={10} className="bg-secondary p-10 pr-20 pl-20 mt-20 ">
      <Grid align="center" className="relative">
        {showTitle ? (
          <Grid container>
            <Grid item className="mt-20" >
              <ArrowBackRoundedIcon
                onClick={() => props.setActiveSteps(props.activeSteps - 1)}
                className="pointer"
              />
            </Grid>
            <Grid item xs={11} textAlign={'center'}>
            <Typography className="fs-32 fw-700 ls-1 lh-32 mt-20">
              {" "}
              <b>
                <span className="fs-32 fw-700 text-selected">
                  {onboardingMediaCountData}
                </span>{" "}
                titles available to watch
              </b>
            </Typography>

           
              <Typography variant="subtitle" className="fs-14 fw-600 pt-10">
                in languages, genre & platforms of your choice
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography className="fs-32 fw-700 ls-1 lh-32 mt-20">
            platforms
          </Typography>
        )}

        <Grid
          container
          justifyContent={"flex-start"}
          className="language-container mt-40 h-100 mhr-60 pr-10"
          //   lg={11}
          spacing={2}
        >
          {platformListShow.length > 0 &&
            platformListShow.map((x, i) => {
              return (
                <Grid item xs={4} sm={3} key={i}>
                  <Grid item className="relative d-inline-block">
                    {x.image && x.image !== "" ? (
                      <Box
                        component={"img"}
                        alt=""
                        src={x.image ? x?.image : ""}
                        sx={{
                          maxWidth: { xs: "60px", sm: "75px", md: "100px" },
                          maxHeight: { xs: "60px", sm: "75px", md: "100px" },
                        }}
                        className="rounded-container"
                        onClick={() => handlePlatformSelect(x)}
                      />
                    ) : (
                      <div
                        className="rounded-container-box rounded-container"
                        onClick={() => handlePlatformSelect(x)}
                      >
                        {x?.label}
                      </div>
                    )}
                    {selectedPlatform.includes(x.uuid) && (
                      <Grid
                        className="overlay-selected"
                        onClick={() => handlePlatformSelect(x)}
                        sx={{
                          maxWidth: { xs: "60px", sm: "75px", md: "100px" },
                          maxHeight: { xs: "60px", sm: "75px", md: "100px" },
                        }}
                      >
                        <Box
                          component={"img"}
                          alt=""
                          src={rightImg}
                          sx={{
                            maxWidth: { xs: "60px", sm: "75px", md: "100px" },
                            maxHeight: { xs: "60px", sm: "75px", md: "100px" },
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Typography className="fs-16 fw-600 pt-7">
                    {x?.label}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
        <Button
          onClick={() => handleSelectedPlatform()}
          className="rounded-btn bg-selected text-light mt-20 mb-20 fs-14 mw-320 fw-500"
        >
          Continue
        </Button>
      </Grid>
    </Paper>
  );
}

export default Platform;
