import { Grid, Box, Button, TextField, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import BdayPoster from '../../../assets/img/AddBirthday.png'
import { addActivityBirthday, getActivityHomeData, clearMessages as clearMessagesActicity } from '../../../slices/activity';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../../services/helpers';
import { addRemovePersonFan, clearMessages } from '../../../slices/cast';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { toggleLoginModal } from '../../../slices/global';
import { HELPER_TEXT } from '../../../lang/HelperText';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";

const BdayPoster = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/img/AddBirthday.png";

const breakpoints = {
    319: {
        slidesPerView: 2.5,
        spaceBetween: 15,
    },
    425: {
        slidesPerView: 2.7,
        spaceBetween: 15,
    },
    640: {
        slidesPerView: 3,
        spaceBetween: 15,
    },
    768: {
        slidesPerView: 4,
        spaceBetween: 20,
        slidesPerGroup: 3
    },
    1024: {
        slidesPerView: 7,
        spaceBetween: 20,
        slidesPerGroup: 4
    },
    1440: {
        slidesPerView: 7.5,
        spaceBetween: 20,
        slidesPerGroup: 6
    },
    1880: {
        slidesPerView: 9,
        spaceBetween: 30,
        slidesPerGroup: 8
    },
    2560: {
        slidesPerView: 11,
        spaceBetween: 30,
        slidesPerGroup: 10
    },
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'transparent',
    boxShadow: 24,
    borderRadius: 2,
    zIndex: 999,
};

export default function AddYourBirthday(props) {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [myDob, setMyDob] = useState('');
    const dispatch = useDispatch();
    const activity = useSelector(state => state.activity);
    const cast = useSelector(state => state.cast);
    const { activityHomeData } = activity;


    useEffect(() => {
        if (activityHomeData && activityHomeData.dob_data && activityHomeData.dob_data.dob) {
            setMyDob(dayjs(activityHomeData.dob_data.dob));
        }
    }, [activityHomeData])

    useEffect(() => {
        if (!props.hideToast) {
            if (activity.errMesg && activity.errMesg !== "") {
                helpers.toastError(activity.errMesg);
                dispatch(clearMessagesActicity());
            }
            if (activity.successMesg && activity.successMesg !== "") {
                helpers.toastSuccess(activity.successMesg, false);
                dispatch(clearMessagesActicity());
                dispatch(getActivityHomeData());
            }
        } else {
            if (activity.successMesg && activity.successMesg !== "") {
                dispatch(clearMessagesActicity());
                dispatch(getActivityHomeData());
            }
        }
    }, [activity])


    useEffect(() => {
        if (!props.hideToast) {
            if (cast.errMesg && cast.errMesg !== "") {
                helpers.toastError(cast.errMesg);
                dispatch(clearMessages());
            }
            if (cast.successMesg && cast.successMesg !== "") {
                helpers.toastSuccess(cast.successMesg, false);
                dispatch(clearMessages());
                dispatch(getActivityHomeData());
            }
        }
    }, [cast])

    useEffect(() => {
        if (!props.is_public) {
            dispatch(getActivityHomeData());
        }
    }, [])

    const handleFollowUnfollow = (id) => {
        dispatch(addRemovePersonFan({ body: { person: id } }));
    }

    const handleBirthdayChange = (e) => {
        var newd = dayjs(e);
        setMyDob(newd);
    }

    const handleSaveDob = () => {
        var dob = new Date(myDob);
        let m = dob.getMonth() + 1;
        m = m > 9 ? m : '0' + m;
        let d = dob.getDate();
        d = d > 9 ? d : '0' + d;
        var dobdate = dob.getFullYear() + "-" + m + '-' + d;
        dispatch(addActivityBirthday({ body: { dob: dobdate } }));
        setIsCalendarOpen(false);
    }

    return (
        <>
            {!props.is_public ?
                <>
                    {!activity.isLoading && activityHomeData && activityHomeData.dob_data &&
                        <Grid item className='section-border-bottom pb-20 app-section'>
                            {activityHomeData.dob_data.dob_available ?
                                <Grid item xs={12} className='app-container pt-10'>
                                    <h2 className="fw-700 fs-28 m-0"><span className='text-selected'>birthday</span> match</h2>
                                    {/* <h2 className="fw-500 fs-24 lh-24 mt-0 pt-5 mb-10 text-dark-grey">celebrities who share your birthday</h2> */}
                                    <Grid item xs={12}>
                                        <Swiper
                                            slidesPerView={2}
                                            spaceBetween={30}
                                            // slidesPerGroup={5}
                                            freeMode={true}
                                            speed={1000}
                                            navigation={true}
                                            breakpoints={breakpoints}
                                            modules={[FreeMode, Navigation]}
                                            className="mySwiper"
                                        >
                                            {activityHomeData.dob_data.person_data && activityHomeData.dob_data.person_data.length > 0 && activityHomeData.dob_data.person_data.map((x, i) => {
                                                return <SwiperSlide key={i}>
                                                    <Grid item key={i} style={{ paddingTop: '20px' }}>
                                                        <Link to={`/person/${x?.unique_title || x?.name.replace(/ /g, "-")}${x?.unique_title ? '' : '/' + x?.person}`}>
                                                            <Box
                                                                className='pointer scale d-block'
                                                                component="img"
                                                                sx={{
                                                                    width: '100%',
                                                                    maxHeight: '210px',
                                                                    borderRadius: '7px',
                                                                    objectFit: 'cover',
                                                                }}
                                                                alt="poster"
                                                                src={x.poster ? helpers.getFullUrl(x.poster, 185) : helpers.getDefaultImage('person')}
                                                            />
                                                        </Link>
                                                        <p className='p-0 m-0 fs-14 fw-400 ellipsis text-center'>{x?.name}</p>
                                                        <div className='text-center pt-10'>
                                                            {x.is_fan ?
                                                                <Button onClick={() => handleFollowUnfollow(x?.person)} className='rounded-btn pr-20 pl-20 small fs-14 bg-selected text-light'>following</Button>
                                                                :
                                                                <Button onClick={() => handleFollowUnfollow(x?.person)} className='rounded-btn pr-20 pl-20 small fs-14 bg-light text-selected'>follow</Button>
                                                            }
                                                        </div>
                                                    </Grid>
                                                </SwiperSlide>
                                            })}
                                        </Swiper>
                                        {/* <Grid item>
                                            <p className="fw-700 fs-28 m-0"><span className='text-selected'>birthday</span> match celebrities who share your birthday </p>
                                            <p className="fw-500 fs-24 m-0 text-dark-grey">find out which famous personality you share your birthday with?</p>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                :
                                <Grid item className='app-container pt-10' >
                                    <h2 className="fw-700 fs-28 m-0">make your birthday unforgettable</h2>
                                    <h2 className="fw-500 fs-24 m-0 text-dark-grey">update your birth date to match with your favorite celebrity and celebrate like a star!</h2>
                                    <Grid item className='mt-15 relative' >
                                        <Box
                                            className='pointer '
                                            component="img"
                                            sx={{
                                                width: '100%',
                                                borderRadius: '10px'
                                            }}
                                            alt='poster'
                                            src={BdayPoster}
                                        />
                                        {/* <Avatar alt="Remy Sharp" src={RoundedProfile} className='cinescore-avatar' /> */}
                                        <Grid container sx={{ position: 'absolute', right: '50px', bottom: '40px', zIndex: 9 }} justifyContent={'flex-end'}>
                                            <Button onClick={() => {
                                                setIsCalendarOpen(true);
                                                helpers.trackMoengageEvent('FR3_Land', { add_birthday_click: MOENGAGE_ATTRIBUTE.YES });
                                            }} className='rounded-btn w-100 mw-240 bg-selected text-light mt-10'>add your birthday</Button>
                                            <Modal
                                                open={isCalendarOpen}
                                                onClose={() => setIsCalendarOpen(false)}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style} className='dob-picker'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MobileDatePicker
                                                            // label="select dob"
                                                            open={isCalendarOpen}
                                                            onAccept={() => handleSaveDob()}
                                                            onClose={() => setIsCalendarOpen(false)}
                                                            inputFormat="MM/DD/YYYY"
                                                            value={myDob}
                                                            onChange={(e) => handleBirthdayChange(e)}
                                                            renderInput={(params) => <TextField style={{ background: '#1a1a2e', borderRadius: '3px' }} {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </Box>
                                            </Modal>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    }
                </>
                :
                <Grid item className='app-container app-section pt-10' >
                    {/* <p className="fw-700 fs-28 m-0">Update your profile with the following details</p> */}
                    <h2 className="fw-700 fs-28 m-0">make your birthday unforgettable</h2>
                    <h2 className="fw-500 fs-24 m-0 text-dark-grey">update your birth date to match with your favorite celebrity and celebrate like a star!</h2>
                    {/* <p className="fw-500 fs-24 m-0 text-dark-grey">this will help to better suggest content and expand your networkz</p> */}
                    <Grid item className='mt-15 relative' >
                        <Box
                            className='pointer '
                            component="img"
                            sx={{
                                width: '100%',
                                borderRadius: '10px'
                            }}
                            alt="poster"
                            src={BdayPoster}
                        />
                        <Grid container sx={{ position: 'absolute', right: '50px', bottom: '40px', zIndex: 9 }} justifyContent={'flex-end'}>
                            <Button onClick={() => dispatch(toggleLoginModal({ isOpen: true, content: HELPER_TEXT.NUDGE_TEXT.ADD_BIRTHDAY }))} className='rounded-btn w-100 mw-240 bg-selected text-light mt-10'>add your birthday</Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
}
