import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import './common.scss'
import { useDispatch } from 'react-redux';
import { toggleLoginModal } from '../../slices/global';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import { HELPER_TEXT } from '../../lang/HelperText';
import SectionHeading from '../component/headings/SectionHeading';

function useRouteMatch(patterns) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
}
export default function CommonMenu(props) {
    const { menuList, heading, is_public } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (newValue) => {
        if (is_public) {
            dispatch(toggleLoginModal({
                isOpen: true,
                content: HELPER_TEXT.NUDGE_TEXT.WATCHLIST
            }));
        } else {
            props.setTabValeu(newValue);
        }
    };

    const routeMatch = useRouteMatch(['/activity', "/mycinescore", "/reviews", "/watchlist"]);
    const currentTab = routeMatch?.pattern?.path;

    const checkUrl = (tab, link) => {
        if (tab !== undefined && tab !== "undefined") {
            if (tab !== "" && link !== "") {
                if (link === "/") {
                    if (link === tab) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (tab.indexOf(link) > -1) {
                        return true;
                    } else {
                        return false;
                    }
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    return (
        <div className='new-container pl-50'>
            {menuList && menuList.length > 0 ?
                <Grid container alignItems={'center'} justifyContent={props.justify ? props.justify : 'space-between'} className={`${props.isFull ? '' : 'app-container'} w-100`} sx={{ paddingTop: { xs: '10px', sm: '20px' } }}>
                    {props.isBack &&
                        <div>
                            <Grid container className='w-100' alignItems={'center'} sx={{ marginBottom: { xs: '12px', sm: '20px' } }}>
                                <Grid item className='pt-10'>
                                    <KeyboardBackspaceRoundedIcon onClick={() => navigate(-1)} className='pointer mr-20' />
                                </Grid>
                                <Grid item>
                                    {heading && heading !== '' &&
                                        <>
                                            {/* {heading} */}
                                            <SectionHeading title={heading} />
                                        </>

                                    }
                                </Grid> 
                            </Grid>
                        </div>
                    }
                    <div className='tab-menu'>
                        <div className='newly-tabs-button '>
                            {menuList.map((x, i) => {
                                return <Button sx={{ padding: { xs: '5px !important', md: '10px !important' } }} onClick={() => handleChange(x.link)} className={`${(props.tabValue === x.link) ? 'active' : ''} fs-14 pointer mb-20 pl-10 pr-10`}>
                                    {x.label}
                                </Button>
                            })}
                        </div>
                    </div>
                </Grid>
                :
                <Grid className='app-container w-100 pt-20' >
                    <div className='tab-menu'>
                        {!is_public ?
                            <div className='newly-tabs-button '>
                                <Link to="/activity" className={`${(currentTab === '/activity' || checkUrl(currentTab, '/activity')) ? 'active' : ''} fs-14 mb-20 p-10`} >
                                    all
                                </Link>
                                <Link to="/mycinescore" className={`${(currentTab === '/mycinescore' || checkUrl(currentTab, '/mycinescore')) ? 'active' : ''} fs-14 mb-20 p-10`}>
                                    my cinescore
                                </Link>
                                <Link to="/reviews" className={`${(currentTab === '/reviews' || checkUrl(currentTab, '/reviews')) ? 'active' : ''} fs-14 mb-20 p-10`}>
                                    reviews
                                </Link>
                                <Link to="/watchlist" className={`${(currentTab === '/watchlist' || checkUrl(currentTab, '/watchlist')) ? 'active' : ''} fs-14 mb-20 p-10`}>
                                    watchlist
                                </Link>
                                <Link onClick={() => helpers.trackMoengageEvent('FR3_Connect', { connect_click: MOENGAGE_ATTRIBUTE.YES })} to="/connect" className={`${(currentTab === '/connect' || checkUrl(currentTab, '/connect')) ? 'active' : ''} fs-14 mb-20 m-0 p-10`}>
                                    connect
                                </Link>
                            </div>
                            :
                            <div className='newly-tabs-button '>
                                <Button onClick={() => handleChange('activity')} className={`${(currentTab === '/activity' || checkUrl(currentTab, '/activity')) ? 'active' : ''} fs-14 mb-20 p-10`} >
                                    all
                                </Button>
                                <Button onClick={() => handleChange('mycinescore')} className={`${(currentTab === '/mycinescore' || checkUrl(currentTab, '/mycinescore')) ? 'active' : ''} fs-14 mb-20 p-10`}>
                                    my cinescore
                                </Button>
                                <Button onClick={() => handleChange('reviews')} className={`${(currentTab === '/reviews' || checkUrl(currentTab, '/reviews')) ? 'active' : ''} fs-14 mb-20 p-10`}>
                                    reviews
                                </Button>
                                <Button onClick={() => handleChange('watchlist')} className={`${(currentTab === '/watchlist' || checkUrl(currentTab, '/watchlist')) ? 'active' : ''} fs-14 mb-20 p-10`}>
                                    watchlist
                                </Button>
                                <Button onClick={() => handleChange('connect')} className={`${(currentTab === '/connect' || checkUrl(currentTab, '/connect')) ? 'active' : ''} fs-14 mb-20 p-10`}>
                                    connect
                                </Button>
                            </div>
                        }
                    </div>
                </Grid>
            }
        </div>
    )
}
