import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/system/Box";
import { Link } from "react-router-dom";
import MediumTile from "../common/MediumTile";
import Genre from "../onboarding/Genre";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import { useDispatch, useSelector } from "react-redux";
// import { toggleLoginModal } from "../../slices/global";
import helpers, {
  MEDIUM_TILE_BREAKPOINTS,
  MEDIUM_TILE_BREAKPOINTS_WITH_AD,
  MOENGAGE_ATTRIBUTE,
} from "../../services/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import NextButtonTile from '../common/NextButtonTile'
import "swiper/css/navigation";
import { FreeMode, Navigation, Pagination } from "swiper";
import SectionHeading from "../component/headings/SectionHeading";
// import { HELPER_TEXT } from "../../lang/HelperText";
import { isMobileDevice } from '../../services/Utils';
import SeeAll from "../common/SeeAll";

const LargeSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/LargeSeeAll.svg";
const SmallSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/SmallSeeAll.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "100%",
  transform: "translate(-50%, -50%)",
  maxWidth: { xs: "90%", sm: "75%", md: "50%" },
  bgcolor: "transparent",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  zIndex: 999,
};

export default function PreferMood(props) {
  const {
    data,
    genres,
    heading,
    subheading,
    isPublic,
    selectedGenre,
    selectedPlatformName,
    selectedPlatformOttName,
    selectedGenreName,
    setSelectedGenreName,
    nextLink,
    withAd
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [controlledSwiper, setControlledSwiper] = useState(null);


  // const _global = useSelector((state) => state.global);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (controlledSwiper && currentSlide === 0) {
      if (
        !controlledSwiper.destroyed &&
        controlledSwiper.slides &&
        controlledSwiper.slides.length
      ) {
        controlledSwiper.slideTo(0);
      }
    }
  }, [currentSlide, controlledSwiper]);

  useEffect(() => {
    if (genres && genres.length > 0) {
      props.setSelectedGenre(genres[0].uuid ? genres[0].uuid : "");
      props?.setSelectedGenreName && props?.setSelectedGenreName(genres[0].genre ? genres[0].genre : "")
    }
  }, [genres]);

  useEffect(() => {
    setCurrentSlide(0);
  }, [selectedGenre]);

  const handleAddPlatfrom = () => {
    helpers.trackMoengageEvent("FR3_Frenzi", {
      add_language_click: MOENGAGE_ATTRIBUTE.YES,
    });
    setOpenModal(true);
  };

  return (
    <>
      {!props.hideHeading && (
        <Grid
          container
          alignItems={"center"}
          sx={{ marginTop: { xs: "20px", sm: "30px" } }}
          justifyContent="space-between"
        >
          <Grid item>
              {heading && heading !== "" ? (
                <SectionHeading title={heading} />
              ) : (
                <>
                  {/* <span className="fs-28 fw-700 lh-28 m-0">trending in </span><span className="text-selected fs-28 fw-700 lh-28 m-0"> {selectedGenreName}</span> */}
                  <SectionHeading title={heading} />
                </>
              )}
            {/* <h2 className="fs-24 fw-500 mt-0 pt-5 text-dark-grey">
              {subheading && subheading !== "" ? (
                subheading
              ) : (
                <>content that goes with your mood</>
              )}
            </h2> */}
          </Grid>
          <Link
            to={
              nextLink && nextLink !== ""
                ? nextLink
                : `/genres/${selectedGenreName}${props.is_free ? '?is_free=true' : ''}`
            }
          >
            {/* <Box component={'img'} src={isMobileDevice() ? SmallSeeAll : LargeSeeAll} sx={{ paddingTop: { xs: '5px', sm: '10px' } }} /> */}
            <SeeAll />
          </Link>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid
          container
          alignItems={"center"}
          className="overflow-no-scroll w-100 pt-10"
          flexWrap={"nowrap"}
        >
          {!isPublic && (
            <Grid
              item
              onClick={() => handleAddPlatfrom()}
              sx={{
                maxWidth: { xs: 40, sm: 60 },
                minWidth: { xs: 40, sm: 60 },
                maxHeight: { xs: 40, sm: 60 },
                minHeight: { xs: 40, sm: 60 },
                borderRadius: "50%",
                position: "sticky",
                left: 0,
              }}
              className="mb-10 mr-20 add-language-container pointer"
            >
              <AddIcon />
            </Grid>
          )}
          {genres &&
            selectedGenre !== "" &&
            genres.length > 0 &&
            genres.map((x, i) => {
              if (isPublic && i > 5) {
                return true;
              }
              return (
                <Grid
                  sx={{
                    maxWidth: { xs: 40, sm: 64 },
                    minWidth: { xs: 40, sm: 60 },
                    maxHeight: { xs: 40, sm: 64 },
                    minHeight: { xs: 40, sm: 60 },
                    borderRadius: "50%",
                  }}
                  className={`mb-10 circle-lang-container ${selectedGenre == x.uuid ? "lang-selected" : ""
                    } pointer mr-20`}
                  onClick={() => {
                    props.setSelectedGenre(x.uuid)
                    setSelectedGenreName(x.genre)
                  }}
                  key={i}
                >
                  <Typography className="fw-500 text-center fs-14 lh-14 p-2">
                    {x.genre ? x.genre : "N/A"}
                  </Typography>
                </Grid>
              );
            })}
          {isPublic && (
            <Grid
              item
              onClick={() => handleAddPlatfrom()}
              sx={{
                maxWidth: { xs: 40, sm: 60 },
                minWidth: { xs: 40, sm: 60 },
                maxHeight: { xs: 40, sm: 60 },
                minHeight: { xs: 40, sm: 60 },
                borderRadius: "50%",
                position: "sticky",
                left: 0,
              }}
              className="mb-10 mr-20 add-language-container pointer"
            >
              <AddIcon />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        slidesPerView={"auto"}
        spaceBetween={10}
        speed={1000}
        navigation={true}
        modules={[Pagination, FreeMode, Navigation]}
        className="mySwiper"
        freeMode={true}
        onSlideChange={(e) => setCurrentSlide(e.activeIndex)}
        onSwiper={setControlledSwiper}
      >
        {data &&
          data.length > 0 &&
          data.map((x, i) => {
            return (
              <SwiperSlide key={i} className="pr-10" style={{ alignSelf: 'center', width: 'auto' }}>
                <Grid sx={{
                  maxWidth: { xs: 100, sm: 140 },
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  display: 'inline-block'
                }}
                >
                  <MediumTile selectedPlatformName={selectedPlatformName} selectedPlatformOttName={selectedPlatformOttName} refPage={props?.refPage} x={x} />
                </Grid>
              </SwiperSlide>
            );
          })}

        <SwiperSlide className="pr-10" style={{ width: 'auto', height: "auto" }} key={"next-btn"}>
          <Grid sx={{
            maxWidth: { xs: 100, sm: 140 },
            display: 'inline-block'
          }}
          >
            <NextButtonTile
              data={data}
              link={nextLink && nextLink !== ""
                ? nextLink
                : `/genres?genre=${selectedGenre}${props.is_free ? '&is_free=true' : ''}`}
            />
          </Grid>
        </SwiperSlide>
      </Swiper>
      <div>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Genre setActiveSteps={() => setOpenModal(false)} activeSteps={3} />
          </Box>
        </Modal>
      </div>
    </>
  );
}
