import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
// import FrenziLogo from "../../assets/icons/FrenziIcon.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../component/button/CustomButton";
import "./auth.scss";
import PhoneLogin from "./PhoneLogin";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import CustomSocialLogin from "./CustomSocialLogin";
import {
  socialLogIn,
  clearMessages,
  setAuthenticatedData,
  collectLoginInfo,
} from "../../slices/auth";
import { KMSClient, EncryptCommand, DecryptCommand } from "@aws-sdk/client-kms";
import { Buffer } from "buffer";
import VerifyOtp from "./VerifyOtp";
import axios from "axios";
import ReactGA from 'react-ga4';
import MyHelmet from "../common/MyHelmet";
import MyAds from "../adsense/MyAds";
import { toggleSignUpModal } from "../../slices/global";

const FrenziLogo = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/FrenziIcon.svg";

const clientParams = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const config = {
  credentials: clientParams,
  endPoint: "",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
  signatureVersion: 2,
  region: process.env.REACT_APP_AWS_REGION,
};

const client = new KMSClient(config);

export default function Login(props) {
  const { isModal } = props;
  const [phoneLogin, setPhoneLogin] = useState(false);
  const [otpLogin, setOtpLogin] = useState(false);
  const [mobile, setMobile] = useState("");
  const [loginType, setLoginType] = useState("mobile");
  const [currentOtpData, setCurrentOtpData] = useState({});
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    let referer = searchParams.get('referer');
    if (referer && referer !== '') {
      window.localStorage.setItem("referer", referer);
    }
    if(window.location.pathname.includes('/partner')){
      window.localStorage.setItem("referer", '/partner');
      setPhoneLogin(true)
    }
  }, [searchParams])

  useEffect(() => {
    if (auth.errMesg && auth.errMesg !== "") {
      helpers.toastError(auth.errMesg);
      dispatch(clearMessages());
    }
    if (auth.successMesg && auth.successMesg !== "") {
      helpers.toastSuccess(auth.successMesg);
      if (auth.successMesg === "otp sent") {
        setOtpLogin(true);
      }
      if (auth.successMesg === "Login Successful") {
        let tData = auth.verifiedData;
        if (tData.data) {
          handleSocialLogin(tData.data);
        }
      }
      dispatch(clearMessages());
    }
  }, [auth, dispatch]);

  const getIpData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    return res?.data?.IPv4;
  }

  const handleMoengageLoginEvent = async (data) => {
    const { username, uuid, email, mobile, profile_image } = data;
    // await window.Moengage.add_unique_user_id(uuid);
    // await window.Moengage.add_first_name(username);
    // await window.Moengage.add_email(email || '');
    // await window.Moengage.add_mobile(mobile || '');
    // await window.Moengage.add_user_attribute("login_type", 'google');
    // await window.Moengage.add_user_attribute("data_source", 'web');
    // await window.Moengage.add_user_attribute("last_login_date", new Date());
    // await window.Moengage.add_user_attribute("is_verified_mobile", data.mobile_verified ? true : false);

    ReactGA.set({
      userId: uuid,
      first_name: username,
      email: email,
      mobile: mobile,
      login_type: 'google',
      last_login_date: new Date(),
      is_verified_mobile: data.mobile_verified ? true : false,
    });

    if (profile_image && profile_image !== '') {
      // await window.Moengage.add_user_attribute("profile_image", profile_image);
      ReactGA.set({ profile_image: profile_image });
    } else {
      // await window.Moengage.add_user_attribute("profile_image", 'default');
      ReactGA.set({ profile_image: 'default' });
    }

    const propData = {
      client_id: uuid,
      login_date: new Date(),
      type: 'google',
      verified: MOENGAGE_ATTRIBUTE.YES,
      email: email,
      mobile: mobile
    }

    // await helpers.trackMoengageEvent('FR3_Login', propData);

    setTimeout(() => {
      let referer = window.localStorage.getItem('referer');
      if (referer === 'interactions') {
        window.location.href = "/rrvcfam";
      } else {
        if (data.langs_pref_set && data.platforms_pref_set) {
          window.location.href = "/";
          if (referer && referer !== '') {
            window.location.href = referer;
          } else {
            window.location.href = "/";
          }
        } else {
          window.location.href = "/onboarding";
        }
      }
    }, 1000)
  }

  const handleSocialLogin = async (responseText) => {
    var cipherText = _base64ToArrayBuffer(responseText);
    const input = {
      KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
      CiphertextBlob: cipherText,
    };
    try {
      const command = new DecryptCommand(input);
      const response = await client.send(command);
      // process data.
      if (response && response.Plaintext) {
        let win1251decoder = new TextDecoder("windows-1251");
        let bytes = new Uint8Array(response.Plaintext);
        var decryptedText = win1251decoder.decode(bytes);
        var jsonData = JSON.parse(decryptedText);
        if (jsonData && !helpers.isEmptyObject(jsonData)) {
          dispatch(setAuthenticatedData(jsonData));
          localStorage.setItem("frenzi_user_access", JSON.stringify(jsonData));
          let userData = jsonData;
          const payload = {
            "device": window?.navigator?.platform,
            "device_type": 'web',
            "ip_address": await getIpData()
          }
          dispatch(collectLoginInfo({ body: payload }));
          handleMoengageLoginEvent(userData);
        }
      } else {
        helpers.toastError("Data decryption failed !!!", !isModal);
      }
    } catch (error) {
      // error handling.\
      helpers.toastError(error, !isModal);
    }
  };
  useEffect(() => {
    if (auth.otpData && auth.otpData.data) {
      convertAgainOtpData(auth.otpData.data);
    }
  }, [auth.otpData]);

  const performFormSubmitProcess = async (tempForm, type = "login") => {
    const encoder = new TextEncoder();
    var unit8String = encoder.encode(JSON.stringify(tempForm));
    const input = {
      KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
      Plaintext: unit8String,
    };
    try {
      const command = new EncryptCommand(input);
      const response = await client.send(command);
      // process data.
      if (response && response.CiphertextBlob) {
        let buff = new Buffer(response.CiphertextBlob);
        let base64data = buff.toString("base64");
        if (type === "login") {
          helpers.trackMoengageEvent('FR3_Signup', { signup_source: 'google' });
          dispatch(socialLogIn({ data: base64data }));
        } else {
          // dispatch(socialSignupEncrypt({ data: base64data }))
        }
      } else {
        helpers.toastError("Data encryptiton failed !!!", !isModal);
      }
    } catch (error) {
      // error handling.\
      helpers.toastError(error, !isModal);
    }
  };

  function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    // return bytes.buffer;
    return bytes;
  }

  const convertAgainOtpData = async (responseText) => {
    var cipherText = _base64ToArrayBuffer(responseText);
    const input = {
      KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
      CiphertextBlob: cipherText,
    };
    try {
      const command = new DecryptCommand(input);
      const response = await client.send(command);
      // process data.
      if (response && response.Plaintext) {
        let win1251decoder = new TextDecoder("windows-1251");
        let bytes = new Uint8Array(response.Plaintext);
        var decryptedText = win1251decoder.decode(bytes);
        var jsonData = JSON.parse(decryptedText);
        if (jsonData && !helpers.isEmptyObject(jsonData)) {
          setCurrentOtpData(jsonData);
        }
      } else {
        helpers.toastError("Data decryption failed !!!", !isModal);
      }
    } catch (error) {
      // error handling.\
      helpers.toastError(error, !isModal);
    }
  };

  return (
    <>
      <MyHelmet
        title={"login in to frenzi"}
        description={"login into frenzi and discover the plethora of OTT content with 80+ Ott in 18+ languages"}
      />
      <Grid
        container
        justifyContent={"center"}
        sx={{ minHeight: isModal ? 'auto' : '100vh' }}
        alignItems={"center"}
      >
        {!isModal &&
          <ToastContainer />
        }
        <Grid item xs={isModal ? 12 : 11} sm={isModal ? 12 : 7} md={isModal ? 12 : 5} lg={isModal ? 12 : 4}>
          {!phoneLogin ? (
            <Paper elevation={10} className="bg-secondary p-40">
              <Grid align="center">
                {props.logo ?
                  props.logo
                  :
                  <Grid item justifyContent={'center'}>
                    <img alt="logo" src={FrenziLogo} />
                  </Grid>
                }
                {props.content ?
                  <Typography className={`fs-24 lh-28 ${isModal ? '' : 'mw-200'} mt-10 `}>
                    {props.content}
                    {/* <p className="p-0 m-0"><b>join the frenzi tribe</b></p> */}
                  </Typography>
                  :
                  <Typography className={`fs-24 lh-28 ${isModal ? '' : 'mw-200'} mt-10 `}>
                    we <b>simplify</b> what to watch on <b>ott</b>
                  </Typography>
                }
                {isModal ?
                  <div className="mt-30">
                    <MyAds type="banner_3" adv_type={'web_popup_ad'} />
                  </div>
                  : ''
                }
                <Box sx={{ minWidth: { xs: '200px', sm: '280px' } }} className={`mt-10 ${isModal ? 'mw-350' : ''}`}>
                  <CustomButton
                    title="sign up for free"
                    className="rounded-btn bg-selected text-light mt-44 fs-16"
                    sx={{
                      width: { xs: '100%', sm: '80%', md: '90%', lg: '100%' }
                    }}
                    onClick={() => dispatch(toggleSignUpModal(true))}
                  />
                  <CustomSocialLogin
                    setLoader={setLoader}
                    performFormSubmitProcess={performFormSubmitProcess}
                  />
                </Box>
                <Typography className="mt-35 fw-500 fs-20 ">
                  already a member?{" "}
                  <b onClick={() => setPhoneLogin(true)} className="pointer">
                    log in
                  </b>
                </Typography>
              </Grid>
            </Paper>
          ) : otpLogin ? (
            <VerifyOtp
              mobile={mobile}
              loginType={loginType}
              otpId={
                currentOtpData && currentOtpData.otp_id
                  ? currentOtpData.otp_id
                  : ""
              }
            />
          ) : (
            <PhoneLogin setMobile={setMobile} setLoginType={setLoginType} />
          )}
        </Grid>
      </Grid>
    </>
  );
}
