import axiosInstance from "../http-common";
import helpers from "./helpers";
import { encryptData } from "./Utils";

const getSearchAutoComplete = (query = '', page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/autocomplete?query=${query}&page=${page}&pagelimit=${pagelimit}`);
};

const getRecentSearches = (page = 1, pagelimit = 10, media_type = 'all') => {
    return axiosInstance.get(`native_main/visithistory?page=${page}&pagelimit=${pagelimit}&media_type=${media_type}`);
};

const getTopSearches = (page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/topsearches?page=${page}&pagelimit=${pagelimit}`);
};

const getAdvancedSearchResults = (body, page = 1, pagelimit = 10, is_public = false) => {
    return axiosInstance.post(`native_main/${is_public ? 'public_' : ''}advancedsearch?page=${page}&pagelimit=${pagelimit}`, body);
};

const getMediaSearch = async (query = '', is_public = false, page = 1, pagelimit = 10) => {
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();

    if (is_public) {
        let newBody = {
            query: query,
            page: page,
            pagelimit: pagelimit,
            captcha_token: captcha_token
        };
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(`native_main/public_mediasearch`, { data: encryptedData })
    } else {
        return axiosInstance.get(`native_main/mediasearch?query=${query}&page=${page}&pagelimit=${pagelimit}`)
    }
}

const getPersonSearch = async (query = '', is_public = false, page = 1, pagelimit = 10) => {
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();

    if (is_public) {
        let newBody = {
            query: query,
            page: page,
            pagelimit: pagelimit,
            captcha_token: captcha_token
        };
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(`native_main/public_personsearch`, { data: encryptedData })
    } else {
        return axiosInstance.get(`native_main/personsearch?query=${query}&page=${page}&pagelimit=${pagelimit}`)
    }
}

const SearchDataService = {
    getSearchAutoComplete,
    getRecentSearches,
    getTopSearches,
    getAdvancedSearchResults,
    getMediaSearch,
    getPersonSearch
};

export default SearchDataService;