import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import CommonMenu from "../common/CommonMenu";
import TextReviews from "./TextReviews";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessages,
  getFrenziAllReviews,
  getFrenziTopReviewers,
} from "../../slices/media";
import TopReviewers from "./TopReviewers";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import "./review.scss";
import { clearMessages as clearMessagesCast } from "../../slices/cast";
import { setReviewCategoryType } from "../../slices/global";
import MyAds from "../adsense/MyAds";
import MyHelmet from "../common/MyHelmet";
import LinearLoader from "../common/LinearLoader";

export default function Reviews() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const media = useSelector((state) => state.media);
  const cast = useSelector((state) => state.cast);
  const _global = useSelector((state) => state.global);
  const { reviewCategoryType } = _global;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (cast.errMesg && cast.errMesg != "") {
      helpers.toastError(cast.errMesg);
      dispatch(clearMessagesCast());
    }
    if (cast.successMesg && cast.successMesg != "") {
      helpers.toastSuccess(cast.successMesg);
      dispatch(clearMessagesCast());
    }
  }, [cast]);

  useEffect(() => {
    if (media.errMesg && media.errMesg != "") {
      helpers.toastError(media.errMesg);
      dispatch(clearMessages());
    }
    if (media.successMesg && media.successMesg != "") {
      helpers.toastSuccess(media.successMesg);
      // if (commentTypeList && commentTypeList.length > 0) {
      //   commentTypeList.map((x, i) => {
      //     dispatch(
      //       getFrenziAllReviews({
      //         category: reviewCategoryType,
      //         comment_type: x,
      //         page: 1,
      //         pagelimit: 6,
      //       })
      //     );
      //   });
      // }
      dispatch(
        getFrenziAllReviews({
          category: reviewCategoryType,
          comment_type: 'text',
          page: 1,
          pagelimit: 6,
        })
      );
      dispatch(clearMessages());
    }
  }, [media]);

  useEffect(() => {
    dispatch(getFrenziTopReviewers({ page: 1, pagelimit: 20 }));
  }, []);

  useEffect(() => {
    // if (commentTypeList && commentTypeList.length > 0) {
    //   commentTypeList.map((x, i) => {
    //     dispatch(
    //       getFrenziAllReviews({
    //         category: reviewCategoryType,
    //         comment_type: x,
    //         page: 1,
    //         pagelimit: 6,
    //       })
    //     );
    //   });
    // }
    dispatch(
      getFrenziAllReviews({
        category: reviewCategoryType,
        comment_type: 'text',
        page: 1,
        pagelimit: 6,
      })
    );
  }, [ reviewCategoryType]);

  return (
    <>
      <MyHelmet
        title={'Frenzi Reviews'}
        description={'Discover unbiased and insightful reviews on films and web series that provide valuable review for your next title, whether to watch the title or not.'}
      />
      {media.isLoading && <LinearLoader />}
      <div className="new-container">

        <Grid container className="pt-0">
          <CommonMenu />
          {media.frenziTopReviewersData &&
            media.frenziTopReviewersData.length > 0 && (
              <TopReviewers data={media.frenziTopReviewersData} />
            )}

          <Grid
            className="app-container app-section"
            container
            sx={{ paddingY: { xs: "20px", sm: "30px" } }}
          >
            <Button
              onClick={(e) => {
                dispatch(setReviewCategoryType("all"));
                helpers.trackMoengageEvent('FR3_View_Review', { all: MOENGAGE_ATTRIBUTE.YES });
              }}
              className={`rounded-border-btn pr-30 pl-30 mr-30 ${reviewCategoryType == "all" ? "active" : ""
                }`}
            >
              all reviews
            </Button>
            <Button
              onClick={(e) => {
                dispatch(setReviewCategoryType("myself"));
                helpers.trackMoengageEvent('FR3_View_Review', { myself: MOENGAGE_ATTRIBUTE.YES });
              }}
              className={`rounded-border-btn pr-30 pl-30 mr-30 ${reviewCategoryType == "myself" ? "active" : ""
                }`}
            >
              my review
            </Button>
            <Button
              onClick={(e) => {
                dispatch(setReviewCategoryType("friends"));
                helpers.trackMoengageEvent('FR3_View_Review', { friends: MOENGAGE_ATTRIBUTE.YES });
              }}
              className={`rounded-border-btn pr-30 pl-30 ${reviewCategoryType == "friends" ? "active" : ""
                }`}
            >
              friends
            </Button>
          </Grid>

          {media.frenziAllReviewData &&
            media.frenziAllReviewData["text"] &&
            media.frenziAllReviewData["text"].length > 0 && (
              <TextReviews
                category={reviewCategoryType}
                isme={reviewCategoryType == "myself"}
                data={media.frenziAllReviewData["text"]}
              />
            )}
          <Grid item xs={12} className='app-section mt-20 mb-20'>
            <Grid container justifyContent={'center'}>
              <MyAds type="banner_2" adv_type={'web_reviews_page_1st_ad'} />
            </Grid>
          </Grid>
        </Grid>

      </div>
    </>
  );
}
