import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import "./auth.scss";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import {
//   GoogleReCaptchaProvider,
//   GoogleReCaptcha,
// } from "react-google-recaptcha-v3";
import { countries } from "country-list-json";
import { useDispatch } from "react-redux";
import { createUser } from "../../slices/auth";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import { KMSClient, EncryptCommand } from "@aws-sdk/client-kms";
import { Buffer } from "buffer";
import CloseIcon from "@mui/icons-material/Close";
import { toggleLoginModal, toggleSignUpModal } from "../../slices/global";

const clientParams = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const config = {
  credentials: clientParams,
  endPoint: "",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
  signatureVersion: 2,
  region: process.env.REACT_APP_AWS_REGION,
};

const client = new KMSClient(config);
const defaultCountry = {
  name: "India",
  dial_code: "+91",
  code: "IN",
  flag: "🇮🇳",
};

function CreateAccount(props) {
  const [open, setOpen] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState(defaultCountry);
  const [name, setName] = React.useState("");
  // const [captchaToken, setCaptchaToken] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let referred_by = searchParams.get('ref');

  const handleChange = (value) => {
    setCountryCode(value);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getGuid = async () => {
    var nav = window.navigator;
    var screen = window.screen;
    var guid = nav.mimeTypes.length;
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';
    return guid;
  };

  const handleCreateAccount = async () => {
    if (
      countryCode.dial_code &&
      countryCode.dial_code !== "" &&
      phoneNumber &&
      phoneNumber !== "" &&
      phoneNumber.length === 10
    ) {
      const newNum = countryCode.dial_code + phoneNumber;
      props.setMobile(phoneNumber);
      if (!name || name === "") {
        helpers.toastError("Name is required");
        return true;
      }
      if (!isNaN(name)) {
        helpers.toastError("Invalid name");
        return true;
      }

      let deviceId = await getGuid();
      if (deviceId && deviceId !== '') {
        const tempForm = {
          mobile: newNum,
          username: name,
          referred_by: referred_by || '',
          device_type: "web",
          device_id: deviceId,
          device_token: "web_device_token",
          // captcha_token: captchaToken,
        };

        const encoder = new TextEncoder();
        var unit8String = encoder.encode(JSON.stringify(tempForm));
        const input = {
          KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
          Plaintext: unit8String,
        };
        try {
          const command = new EncryptCommand(input);
          const response = await client.send(command);
          // process data.
          if (response && response.CiphertextBlob) {
            let buff = new Buffer(response.CiphertextBlob);
            let base64data = buff.toString("base64");
            dispatch(createUser({ data: base64data }));
            helpers.trackMoengageEvent('FR3_Signup', { create_click: MOENGAGE_ATTRIBUTE.YES });
          } else {
            helpers.toastError("Data encryptiton failed !!!");
          }
        } catch (error) {
          helpers.toastError(error);
        }
      } else {
        helpers.toastError("Browser not supported");
      }
    } else {
      if (phoneNumber.length > 0) {
        helpers.toastError("phone number is not valid");
      } else {
        helpers.toastError("please enter phone number");
      }
    }
  };

  // function _base64ToArrayBuffer(base64) {
  //   var binary_string = window.atob(base64);
  //   var len = binary_string.length;
  //   var bytes = new Uint8Array(len);
  //   for (var i = 0; i < len; i++) {
  //     bytes[i] = binary_string.charCodeAt(i);
  //   }
  //   // return bytes.buffer;
  //   return bytes;
  // }

  // const convertAgain = async (responseText) => {
  //   var cipherText = _base64ToArrayBuffer(responseText);
  //   const input = {
  //     KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
  //     CiphertextBlob: cipherText,
  //   };
  //   try {
  //     const command = new DecryptCommand(input);
  //     const response = await client.send(command);
  //     // process data.
  //     if (response && response.Plaintext) {
  //       let win1251decoder = new TextDecoder("windows-1251");
  //       let bytes = new Uint8Array(response.Plaintext);
  //       var decryptedText = win1251decoder.decode(bytes);
  //       var jsonData = JSON.parse(decryptedText);
  //       if (jsonData && !helpers.isEmptyObject(jsonData)) {
  //         //   setLoader(false);
  //         setTimeout(() => {
  //           localStorage.setItem(
  //             "frenzi_user_access",
  //             JSON.stringify(jsonData)
  //           );
  //           // dispatch(setRegistrationToken(jsonData.access));
  //           let redirectUri = localStorage.getItem("redirect_url");
  //           if (redirectUri && redirectUri !== '') {
  //             window.location.href = redirectUri;
  //           } else {
  //             window.location.href = "/";
  //           }
  //         }, 2000);
  //       }
  //     } else {
  //       helpers.toastError("Data decryption failed !!!");
  //     }
  //   } catch (error) {
  //     // error handling.\
  //     helpers.toastError(error);
  //   }
  // };

  return (

    <Paper elevation={10} className="relative p-20" sx={{ maxWidth: { xs: "100%", sm: "350px", md: "400px", lg: "400px" } }}>
      <Grid align="center">
        {window.location.pathname == '/signup' ?
          <CloseIcon
            onClick={() => navigate(-1)}
            className='ml-3 pt-2 pointer absolute animate-close'
            color="light"
            style={{
              top: '10px',
              right: '10px'
            }}
          />
          :
          <CloseIcon
            onClick={() => dispatch(toggleSignUpModal(false))} className=' pt-2 pointer absolute animate-close'
            color="light"
            style={{
              top: '20px',
              right: '20px'
            }}
          />
        }
        <p className="fs-32 fw-500 m-0">
          <span className="fw-800">create</span> an account
        </p>
        <p className="fw-500 fs-14 m-0">we simplify your movie choices</p>
        <Box
          noValidate
          autoComplete="off"
          className="mw-350 mt-40"
        >
          <Grid sm={12} item>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-100"
              label="your name"
              variant="outlined"
            />
          </Grid>
          <Grid container spacing={1} className="mt-28">
            <Grid item xs={5}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth className="country-code-select">
                  <InputLabel id="demo-simple-select-label2">
                    Country Code
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label2"
                    id="country-code-select"
                    value={countryCode.dial_code}
                    label="country code"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                  >
                    {countries &&
                      countries.length > 0 &&
                      countries.map((x, i) => {
                        return (
                          <MenuItem onClick={() => handleChange(x)}>
                            <Grid container justifyContent={"space-between"}>
                              <Grid>{x.name}</Grid>
                              <Grid>{x.dial_code}</Grid>
                            </Grid>
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <Grid
                    className="country-selected"
                    onClick={() => handleOpen()}
                  >
                    <Grid container>
                      <Grid item className="flag">
                        {countryCode.flag}
                      </Grid>
                      <Grid item className="code fs-16 lh-24">
                        {countryCode.dial_code}
                      </Grid>
                    </Grid>
                  </Grid>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <TextField
                value={phoneNumber}
                onChange={(e) => {
                  if (e.target.value.length < 11 && !isNaN(e.target.value)) {
                    setPhoneNumber(e.target.value);
                  }
                }}
                label="mobile number"
                variant="outlined"
                inputMode={'numeric'}
              />
            </Grid>
          </Grid>
        </Box>
        <Button
          onClick={() => handleCreateAccount()}
          className="rounded-btn mw-350 bg-selected text-light mt-40 fs-14 fw-500"
        >
          Create
        </Button>
        <Typography className="fs-13 fw-500 mt-8">
          by creating an account, you agree to the terms of service.{" "}
        </Typography>
        <Typography variant="h3" className="fs-20 fw-500 mt-32">
          already have an account?
          <span className="pointer" onClick={() => {
            dispatch(toggleSignUpModal(false));
            dispatch(toggleLoginModal({ isOpen: true }));

            if (window.location.pathname == '/signup') {
              navigate('/');
            }
          }}>
            <b> log in</b>
          </span>
        </Typography>
      </Grid>
    </Paper>

    // <GoogleReCaptchaProvider
    //   reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
    //   // language="[optional_language]"
    //   // useRecaptchaNet="[optional_boolean_value]"
    //   // useEnterprise="[optional_boolean_value]"
    //   scriptProps={{
    //     async: false, // optional, default to false,
    //     defer: false, // optional, default to false
    //     appendTo: "head", // optional, default to "head", can be "head" or "body",
    //     // nonce: undefined // optional, default undefined
    //   }}
    //   container={{
    //     // optional to render inside custom element
    //     element: "recaptchaBox",
    //     parameters: {
    //       // badge: '[inline|bottomright|bottomleft]', // optional, default undefined
    //       theme: "dark", // optional, default undefined
    //     },
    //   }}
    // >
    // {/* <GoogleReCaptcha
    //   onVerify={(token) => {
    //     setCaptchaToken(token);
    //   }}
    // /> */}
    // </GoogleReCaptchaProvider>
  );
}

export default CreateAccount;
