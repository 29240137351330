import { Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFaqsList } from '../../../slices/settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../../services/helpers';

export default function FAQ() {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const { faqListData } = settings;

    useEffect(() => {
        dispatch(getFaqsList({ page: 1, pagelimit: 50 }));
    }, [])

    const handleOnchange = (data, open) => {
        if (open) {
            helpers.trackMoengageEvent('FR3_FAQ', {
                faq_click: MOENGAGE_ATTRIBUTE.YES,
                content: data?.answer,
                faq_open: MOENGAGE_ATTRIBUTE.YES,
            });
        } else {
            helpers.trackMoengageEvent('FR3_FAQ', {
                faq_click: MOENGAGE_ATTRIBUTE.YES,
                content: data?.answer,
                faq_close: MOENGAGE_ATTRIBUTE.YES,
            });
        }

    }

    return (
        <Grid item>
            <Grid item style={{ overflowY: 'auto', paddingRight: 10 }} className="mh-350">
                {faqListData && faqListData.length > 0 && faqListData.map((x, i) => {
                    return <Accordion onChange={(e, expanded) => handleOnchange(x, expanded)} className='faq-container' key={i}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid item>
                                <p className='fw-500 fs-20 m-0 text-light'>{x?.question}</p>
                            </Grid>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Grid item>
                                <p className='fw-300 fs-14 m-0 text-muted'>{x?.answer}</p>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                })}

            </Grid>
        </Grid>
    )
}
