import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import SingleMediaTile from '../common/SingleMediaTile'
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import NextButtonTile from './NextButtonTile';
import SectionHeading from '../component/headings/SectionHeading';
import { isMobileDevice } from "../../services/Utils";
import { Box } from '@mui/material';
import SeeAll from './SeeAll';

const LargeSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/LargeSeeAll.svg";
const SmallSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/SmallSeeAll.svg";

export default function CommonDetailTileListing(props) {
    const { heading, isShowHeading, nextLink, comingSoon, data } = props;
    const [controlledSwiper, setControlledSwiper] = useState(null);

    useEffect(() => {
        if (props?.currentSlide === 0 && controlledSwiper) {
            if (!controlledSwiper.destroyed && controlledSwiper.slides && controlledSwiper.slides.length) {
                controlledSwiper.slideTo(0);
            }
        }
    }, [props?.currentSlide, controlledSwiper]);

    return (
        <>
            {data && data.length > 0 &&
                <Grid item xs={12}>
                    {isShowHeading &&
                        <Grid container alignItems={'center'} justifyContent='space-between' sx={{ marginTop: { xs: '20px', sm: '30px' } }}>
                            <Grid item>
                                {/* <h2 className='fs-28 fw-700 lh-28 m-0'>{heading}</h2> */}
                                <SectionHeading title={heading} />
                            </Grid>
                            {nextLink && nextLink !== '' &&
                                <Link to={nextLink}>
                                    {/* <Box component={'img'} src={isMobileDevice() ? SmallSeeAll : LargeSeeAll} sx={{ paddingTop: { xs: '5px', sm: '10px' } }} /> */}
                                    <SeeAll />
                                </Link>
                            }
                        </Grid>
                    }
                    <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        slidesPerView={"auto"}
                        spaceBetween={10}
                        speed={1000}
                        navigation={true}
                        modules={[FreeMode, Navigation]}
                        className="mySwiper"
                        freeMode={true}
                        onSwiper={setControlledSwiper}
                        onSlideChange={(e) => props.setCurrentSlide && props?.setCurrentSlide(e.activeIndex)}
                    >
                        {data.map((x, i) => {
                            return <SwiperSlide key={i} style={{ maxWidth: '400px' }}>
                                <Grid key={i} sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                    <SingleMediaTile comingSoon={comingSoon} x={x}
                                        refPage={props.refPage}
                                        showNotifyButton={props.showNotifyButton}
                                    />
                                </Grid>
                            </SwiperSlide>
                        })}
                        {nextLink ?
                            <SwiperSlide style={{ width: 'auto', height: "auto" }} key={"next-btn"}>
                                <Grid className="h-100" sx={{
                                    maxWidth: { xs: 100, lg: 400 },
                                    display: 'inline-block'
                                }}
                                >
                                    <NextButtonTile yt={true} data={data} link={nextLink} />
                                </Grid>
                            </SwiperSlide>

                            : ''
                        }
                    </Swiper>
                </Grid >
            }
        </>
    )
}
