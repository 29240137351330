import React from 'react'
import { Button, CircularProgress, Grid, Modal } from '@mui/material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserName, updatePhoneEmail, verifyOtp } from '../../../slices/settings';
import { KMSClient, EncryptCommand, DecryptCommand } from "@aws-sdk/client-kms";
import { Buffer } from 'buffer'
import helpers from '../../../services/helpers';
import { useSelector } from 'react-redux';
import CloseIcon from "@mui/icons-material/Close";
import OTPInput from 'otp-input-react';



const clientParams = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}

const config = {
    credentials: clientParams,
    endPoint: "",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    signatureVersion: 2,
    region: process.env.REACT_APP_AWS_REGION
}
const otpLength = 6;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#16213E',
    boxShadow: 24,
    borderRadius: 2,
    width: '100%',
    maxWidth: { xs: '90%', sm: '50%', md: '45%', lg: '35%' },
    p: { xs: 2, sm: 5 }

};

export default function ChangeDetails() {

    const [changeUsernameDetailsPopup, setChangeUsernameDetailsPopup] = useState(false)
    const [changePhoneNumber, setChangePhoneNumber] = useState(false)
    const [changeEmailPopup, setChangeEmailPopup] = useState(false)
    const [currentLoading, setCurrentLoading] = useState(false);
    const [otpData, setOtpData] = useState(null);
    const [userName, setUserName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [emailId, setEmailId] = useState('')

    const [OTP, setOTP] = useState('');
    const dispatch = useDispatch()
    const client = new KMSClient(config);
    const settings = useSelector(state => state.settings);
    const { userDetails } = settings;

    const handleChangeDetails = () => {
        dispatch(updateUserName({ body: { unique_name: userName } })).then(() => {
            setCurrentLoading(false);
            handlePopUpModel();
        });
    }

    const handleuserNameChange = (e) => {
        setUserName(e.target.value)
    }

    const handlephoneNumberChange = (e) => {
        setPhoneNumber(e.target.value)
    }

    const handleEmailChange = (e) => {
        setEmailId(e.target.value)
    }

    const handlePopUpModel = () => {
        setChangeUsernameDetailsPopup(false);
        setChangePhoneNumber(false);
        setChangeEmailPopup(false);
        setPhoneNumber('');
        setOtpData(null);
        setEmailId('');
    }

    const finalFormSubmition = () => {
        if (otpData?.otp_id) {
            if (changePhoneNumber) {
                setCurrentLoading(true);
                const body = {
                    mobile: phoneNumber,
                    otp: OTP,
                    otp_id: otpData?.otp_id
                }
                handleVerifyOtp(body);
            }

            if (changeEmailPopup) {
                setCurrentLoading(true);
                const body = {
                    email: emailId,
                    otp: OTP,
                    otp_id: otpData?.otp_id
                }
                handleVerifyOtp(body);
            }
        } else {
            if (changeUsernameDetailsPopup) {
                setCurrentLoading(true);
                handleChangeDetails();
            }
            if (changePhoneNumber) {
                setCurrentLoading(true);
                performFormSubmitProcess({ mobile: phoneNumber });
            }

            if (changeEmailPopup) {
                setCurrentLoading(true);
                performFormSubmitProcess({ email: emailId });
            }
        }
    }

    const handleVerifyOtp = async (tempForm) => {
        const encoder = new TextEncoder();

        var unit8String = encoder.encode(JSON.stringify(tempForm));
        const input = {
            KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
            Plaintext: unit8String
        }
        try {
            const command = new EncryptCommand(input);
            const response = await client.send(command);
            // process data.
            if (response && response.CiphertextBlob) {
                let buff = new Buffer(response.CiphertextBlob);
                let base64data = buff.toString('base64');

                dispatch(verifyOtp({ body: { data: base64data } })).then((resp) => {
                    helpers.toastSuccess(resp?.payload?.message);
                    handlePopUpModel();
                    setCurrentLoading(false);
                });
            } else {
                helpers.toastError('Data encryptiton failed !!!');
                setCurrentLoading(false);
            }
        } catch (error) {
            // error handling.\userLogin
            console.log(error)
            helpers.toastError(JSON.stringify(error));
            setCurrentLoading(false);
        }
    }

    function _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        // return bytes.buffer;
        return bytes;
    }

    const handleOtpResponse = async (responseText) => {
        var cipherText = _base64ToArrayBuffer(responseText);

        const input = {
            KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
            CiphertextBlob: cipherText
        }
        try {
            const command = new DecryptCommand(input);
            const response = await client.send(command);
            // process data.
            if (response && response.Plaintext) {
                let win1251decoder = new TextDecoder('windows-1251');
                let bytes = new Uint8Array(response.Plaintext);
                var decryptedText = win1251decoder.decode(bytes);
                var jsonData = JSON.parse(decryptedText);
                if (jsonData && !helpers.isEmptyObject(jsonData)) {
                    setOtpData(jsonData);
                }
                else {
                    setOtpData(null);
                }
            } else {
                helpers.toastError('Data decryption failed !!!');
            }
        } catch (error) {
            // error handling.\
            helpers.toastError(error);
        }
    }

    const performFormSubmitProcess = async (tempForm) => {
        const encoder = new TextEncoder();

        var unit8String = encoder.encode(JSON.stringify(tempForm));
        const input = {
            KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
            Plaintext: unit8String
        }
        try {
            const command = new EncryptCommand(input);
            const response = await client.send(command);
            // process data.
            if (response && response.CiphertextBlob) {
                let buff = new Buffer(response.CiphertextBlob);
                let base64data = buff.toString('base64');
                dispatch(updatePhoneEmail({ body: { data: base64data } })).then((resp) => {
                    if (resp.payload?.message && resp.payload.message === 'otp sent') {
                        helpers.toastSuccess("Otp Sent");
                        handleOtpResponse(resp.payload?.data?.data);
                    }
                    if (!resp.payload?.status) {
                        helpers.toastError(resp.payload?.message);
                    }
                    setCurrentLoading(false);
                })
            } else {
                helpers.toastError('Data encryptiton failed !!!');
                setCurrentLoading(false);
            }
        } catch (error) {
            // error handling.\userLogin
            helpers.toastError(error);
            setCurrentLoading(false);
        }

    }

    return (
        <>
            <Grid container justifyContent={'space-between'} className='pb-20'>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <AccountCircleOutlinedIcon />
                        <Grid item className='pl-25'>
                            <p className='fw-500 fs-18 m-0'>change username</p>
                            <p className='fw-500 fs-12 m-0 text-green'>{userDetails?.unique_name}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={() => {
                        setUserName(userDetails?.unique_name);
                        setChangeUsernameDetailsPopup(true);
                    }} className='w-100 rounded-btn bg-light text-selected'>change</Button>
                </Grid>
            </Grid>

            <Grid container justifyContent={'space-between'} className='pb-20'>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <AccountCircleOutlinedIcon />
                        <Grid item className='pl-25'>
                            <p className='fw-500 fs-18 m-0'>change phone number</p>
                            <p className='fw-500 fs-12 m-0 text-green'>{userDetails?.mobile}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={() => {
                        setPhoneNumber(`${userDetails?.mobile}`);
                        setChangePhoneNumber(true);
                    }} className='w-100 rounded-btn bg-light text-selected'>change</Button>
                </Grid>
            </Grid>

            <Grid container justifyContent={'space-between'} className='pb-20'>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <AccountCircleOutlinedIcon />
                        <Grid item className='pl-25'>
                            <p className='fw-500 fs-18 m-0'>change email id</p>
                            <p className='fw-500 fs-12 m-0 text-green'>{userDetails?.email}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={() => {
                        setEmailId(userDetails?.email);
                        setChangeEmailPopup(true);
                    }} className='w-100 rounded-btn bg-light text-selected'>change</Button>
                </Grid>
            </Grid>

            <div>
                <Modal
                    open={changeUsernameDetailsPopup || changePhoneNumber || changeEmailPopup}
                    onClose={() => handlePopUpModel()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <CloseIcon onClick={() => handlePopUpModel()}
                            className='ml-3 pt-2 pointer absolute animate-close'
                            color='light'
                            style={{
                                top: '10px',
                                right: '10px'
                            }}
                        />
                        <p className='fw-700 fs-32 m-0'>change details</p>
                        <p className='fw-400 fs-24 mt-0 lh-22 mb-30 text-dark-grey'>are you sure you want to change</p>
                        <Grid item className='text-center pt-10'>
                            <Box
                                sx={{
                                    '& .MuiTextField-root': { marginBottom: '20px', width: '100%' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                {changeUsernameDetailsPopup &&
                                    <TextField
                                        error id="outlined-error-helper-text"
                                        label="Username"
                                        value={userName}
                                        onChange={(e) => handleuserNameChange(e)}
                                    // helperText="Incorrect entry."
                                    />
                                }

                                {changePhoneNumber && otpData?.otp_id ?
                                    <OTPInput className="otp-container" inputmode={'numeric'} value={OTP} onChange={setOTP} autoFocus OTPLength={otpLength} otpType="number" disabled={false} secure />
                                    :
                                    <>
                                        {changePhoneNumber &&
                                            <TextField
                                                error id="outlined-error-helper-text"
                                                label="Phone number"
                                                value={phoneNumber}
                                                onChange={(e) => handlephoneNumberChange(e)}
                                            />
                                        }
                                    </>
                                }

                                {changeEmailPopup && otpData?.otp_id ?
                                    <OTPInput className="otp-container" inputmode={'numeric'} value={OTP} onChange={setOTP} autoFocus OTPLength={otpLength} otpType="number" disabled={false} secure />
                                    :
                                    <>
                                        {changeEmailPopup &&
                                            <TextField
                                                error id="outlined-error-helper-text"
                                                label="Email-id"
                                                value={emailId}
                                                onChange={(e) => handleEmailChange(e)}
                                            />
                                        }
                                    </>
                                }
                            </Box>
                            <Button disabled={currentLoading} style={{ marginTop: '20px' }} onClick={() => finalFormSubmition()} className='rounded-btn bg-light text-selected mw-200'>
                                {currentLoading ? <CircularProgress color='success' /> : 'update'}
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
            </div>
        </>
    )
}
