import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from "./SearchBar";
import { DEFAULT_ADV_FILTER, YEARS } from "../partnerHelper";
import { getCastData, handleFilters } from "../../../slices/frenziPartner";
import helpers from "../../../services/helpers";
import { getGenreList, getLanguageList, getPlatformList } from "../../../slices/auth";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 790,
    bgcolor: "#000",
    border: "1px solid #fff",
    borderRadius: 7,
    boxShadow: 24,
    p: 2,
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function AdvancedSearch(props) {
    const { open, searchData, isLoading, input } = props
    const auth = useSelector((state) => state.auth);
    const [castData, setCastData] = useState([]);
    const [isCastLoading, setIsCastLoading] = useState(false);
    const { advFilters } = useSelector((state) => state.frenziPartner);

    const dispatch = useDispatch()
    const _global = useSelector((state) => state.global);

    let platforms =
        auth.allPreferences &&
            auth.allPreferences.platforms &&
            auth.allPreferences.platforms.length > 5
            ? auth.allPreferences.platforms
            : auth.platformList?.results
                ? auth.platformList?.results
                : [];

    let genreList =
        auth.genreList && auth.genreList.genres && auth.genreList.genres.length > 5
            ? auth.genreList.genres
            : auth.allPreferences?.genres
                ? auth.allPreferences?.genres
                : [];

    let languageList =
        auth.languageList &&
            auth.languageList.languages &&
            auth.languageList.languages.length > 5
            ? auth.languageList.languages
            : auth.allPreferences?.languages
                ? auth.allPreferences?.languages
                : [];

    // console.log("auth.languageList---", auth.languageList)
    // console.log("auth.allPreferences---", auth.allPreferences)


    const handleClearAll = () => {
        dispatch(handleFilters(DEFAULT_ADV_FILTER));
        props.handleApply(false)
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        const newArr = typeof value === "string" ? value.split(",") : value;
        dispatch(handleFilters({ ...advFilters, genres: newArr }));
    };

    const handleChangePlatform = (event) => {
        const {
            target: { value },
        } = event;

        const newArr = typeof value === "string" ? value.split(",") : value;
        dispatch(handleFilters({ ...advFilters, ott_platforms: newArr }));
    };

    const handleChangeLanguage = (event) => {
        const {
            target: { value },
        } = event;

        const newArr = typeof value === "string" ? value.split(",") : value;
        dispatch(handleFilters({ ...advFilters, available_languages: newArr }));
    };

    const handleChangeSort = (event) => {
        dispatch(handleFilters({ ...advFilters, sort: event.target.value }));
    };

    const handleChangeFromYear = (event) => {
        dispatch(handleFilters({
            ...advFilters,
            from_year: event.target.value,
        }));
    };

    const handleChangeToYear = (event) => {
        dispatch(handleFilters({ ...advFilters, to_year: event.target.value }));
    };

    const handleChangeRating = (event) => {
        dispatch(handleFilters({ ...advFilters, rating: event.target.value }));
    };

    const handleSelectBoxChange = (name, val, checked) => {
        if (checked) {
            let tempArr = advFilters[name];
            dispatch(handleFilters({ ...advFilters, [name]: [...tempArr, val] }));
        } else {
            let tempArr = advFilters[name];
            let index = tempArr.indexOf(val);
            let newArr = [...tempArr];
            newArr.splice(index, 1);
            dispatch(handleFilters({ ...advFilters, [name]: newArr }));
        }
    };

    const handleOnCastChange = (values) => {
        dispatch(handleFilters({ ...advFilters, contributors: values }));
    };

    useEffect(() => {
        getPersonData('')
    }, [])

    function debounce(func, delay) {
        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), delay);
        };
    }

    // Usage
    const handleInputChange = debounce((query) => {
        getPersonData(query)
    }, 300);

    const getPersonData = (q) => {
        setIsCastLoading(true);
        const data = {
            query: q,
            limit: 40,
            page: 1
        }
        dispatch(getCastData(data)).then((resp) => {
            if (resp.payload.data.results) {
                const tempArr = resp.payload.data.results.map((x) => {
                    return {
                        ...x,
                        label: x?.name,
                        value: x?.uuid
                    }
                })
                setCastData([...tempArr])
                setIsCastLoading(false);
            }
        }).catch((err) => {
            setIsCastLoading(false);
            console.log(err)
        })
    }

    useEffect(() => {
        dispatch(getLanguageList());
        dispatch(getPlatformList({ page: 1, pagelimit: 100, is_public: _global?.is_public }));
        dispatch(getGenreList());
    }, [open])

    return (
        <div>
            {/* Advanced search modal */}
            <Modal
                keepMounted
                open={open}
                onClose={props.handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h5"
                        component="h2"
                        className="mb-20 text-selected"
                    >
                        advanced search
                    </Typography>
                    {advFilters ?
                        <div>
                            <Grid
                                container
                                justifyContent={"center"}
                                sx={{
                                    marginTop: { xs: "10px" },
                                    marginBottom: "20px",
                                    width: "100%",
                                }}
                            >
                                <SearchBar
                                    suggestions={searchData}
                                    isLoading={isLoading}
                                    onChange={(val) => props.handleSeacrh(val)}
                                    onKeyUp={(e) => props.handleKeyUp(e)}
                                    setInput={props.setInput}
                                    input={input}
                                />
                            </Grid>
                            <Grid container gap={2} justifyContent={"space-between"}>
                                <Grid flex={1} item>
                                    <Grid
                                        container
                                        className="mb-10"
                                        style={{ alignItems: "center" }}
                                        justifyContent={"space-between"}
                                    >
                                        <Grid item className="mr-10">
                                            genres :
                                        </Grid>
                                        <Grid item>
                                            <div>
                                                {genreList && genreList.length ? (
                                                    <FormControl size="small" sx={{ m: 1, width: 200 }}>
                                                        <InputLabel id="demo-multiple-checkbox-label">
                                                            genres
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            value={advFilters?.genres || []}
                                                            onChange={handleChange}
                                                            input={<OutlinedInput label="genres" />}
                                                            renderValue={(selected) => selected.join(", ")}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {genreList.map((x, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={x.genre.toLowerCase()}
                                                                >
                                                                    <Checkbox
                                                                        checked={advFilters.genres?.includes(
                                                                            x.genre.toLowerCase()
                                                                        )}
                                                                    />
                                                                    <ListItemText primary={x.genre} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        className="mb-10"
                                        style={{ alignItems: "center" }}
                                        justifyContent={"space-between"}
                                    >
                                        <Grid item className="mr-10">
                                            platforms :
                                        </Grid>
                                        <Grid item>
                                            <div>
                                                {platforms && platforms.length ? (
                                                    <FormControl size="small" sx={{ m: 1, width: 200 }}>
                                                        <InputLabel id="demo-multiple-checkbox-label">
                                                            platforms
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            value={advFilters?.ott_platforms || []}
                                                            onChange={handleChangePlatform}
                                                            input={<OutlinedInput label="platforms" />}
                                                            renderValue={(selected) => selected.join(", ")}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {platforms.map((x, i) => (
                                                                <MenuItem key={i} value={x?.ott_name}>
                                                                    <Checkbox
                                                                        checked={advFilters.ott_platforms?.includes(
                                                                            x?.ott_name
                                                                        )}
                                                                    />
                                                                    <ListItemText
                                                                        primary={x?.ott_name || "N/A"}
                                                                    />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        className="mb-10"
                                        style={{ alignItems: "center" }}
                                        justifyContent={"space-between"}
                                    >
                                        <Grid item className="mr-10">
                                            original language :
                                        </Grid>
                                        <Grid item>
                                            <div>
                                                {languageList && languageList.length ? (
                                                    <FormControl size="small" sx={{ m: 1, width: 200 }}>
                                                        <InputLabel id="demo-multiple-checkbox-label">
                                                            language
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            value={advFilters?.available_languages || []}
                                                            onChange={handleChangeLanguage}
                                                            input={<OutlinedInput label="language" />}
                                                            renderValue={(selected) => selected.join(", ")}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {languageList.map((x, i) => (
                                                                <MenuItem key={i} value={x?.code}>
                                                                    <Checkbox
                                                                        checked={advFilters.available_languages?.includes(
                                                                            x?.code
                                                                        )}
                                                                    />
                                                                    <ListItemText
                                                                        primary={x?.language || "N/A"}
                                                                    />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        className="mb-10"
                                        style={{ alignItems: "center" }}
                                        justifyContent={"space-between"}
                                    >
                                        <Grid item className="mr-10">
                                            content type :
                                        </Grid>
                                        <Grid item>
                                            <Grid container justifyContent={"space-between"}>
                                                <Grid item>
                                                    <Grid container style={{ alignItems: "center" }} justifyContent={"space-between"}>
                                                        <Grid item>
                                                            <Typography className="fs-15 fw-500">
                                                                movies
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                className="m-0"
                                                                control={
                                                                    <Checkbox
                                                                        className=" text-selected"
                                                                        checked={advFilters.content_type?.includes(
                                                                            "movie"
                                                                        )}
                                                                        onChange={(e) =>
                                                                            handleSelectBoxChange(
                                                                                "content_type",
                                                                                "movie",
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container style={{ alignItems: "center" }} justifyContent={"space-between"}>
                                                        <Grid item>
                                                            <Typography className="fs-15 fw-500">
                                                                webseries
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                className="m-0 text-selected"
                                                                control={
                                                                    <Checkbox
                                                                        className=""
                                                                        checked={advFilters.content_type?.includes(
                                                                            "tv"
                                                                        )}
                                                                        onChange={(e) =>
                                                                            handleSelectBoxChange(
                                                                                "content_type",
                                                                                "tv",
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item flex={1}>
                                    <Grid
                                        container
                                        className="mb-10"
                                        style={{ alignItems: "center" }}
                                        justifyContent={"space-between"}
                                    >
                                        <Grid item className="mr-10">
                                            release year :
                                        </Grid>
                                        <Grid item>
                                            <Grid container justifyContent={"space-between"}>
                                                <Grid item>
                                                    <FormControl size="small" sx={{ m: 1, width: 100 }}>
                                                        <InputLabel id="demo-select-small-label">
                                                            from
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            label="from"
                                                            value={advFilters?.from_year}
                                                            onChange={handleChangeFromYear}
                                                        >
                                                            {YEARS.map((x, i) => (
                                                                <MenuItem key={i} value={x}>
                                                                    <ListItemText primary={x} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <FormControl size="small" sx={{ m: 1, width: 100 }}>
                                                        <InputLabel id="demo-select-small-label">
                                                            to
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            label="to"
                                                            value={advFilters?.to_year}
                                                            onChange={handleChangeToYear}
                                                        >
                                                            {YEARS.map((x, i) => (
                                                                <MenuItem key={i} value={x}>
                                                                    <ListItemText primary={x} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        className="mb-10"
                                        style={{ alignItems: "center" }}
                                        justifyContent={"space-between"}
                                    >
                                        <Grid item className="mr-10">
                                            {/* certificate : */}
                                        </Grid>
                                        <Grid item>
                                            <div></div>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        className="mb-10"
                                        style={{ alignItems: "center" }}
                                        justifyContent={"space-between"}
                                    >
                                        <Grid item className="mr-10">
                                            rating :
                                        </Grid>
                                        <Grid item>
                                            <FormControl size="small" sx={{ m: 1, width: 100 }}>
                                                <InputLabel id="demo-select-small-label">
                                                    rating
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    label="rating"
                                                    value={advFilters?.rating}
                                                    onChange={handleChangeRating}
                                                >
                                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x, i) => (
                                                        <MenuItem key={i} value={x}>
                                                            <ListItemText primary={x} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        className="mb-10"
                                        style={{ alignItems: "center" }}
                                        justifyContent={"space-between"}
                                    >
                                        <Grid item className="mr-10">
                                            sort by :
                                        </Grid>
                                        <Grid item>
                                            <div>
                                                <FormControl
                                                    sx={{ m: 1, minWidth: 200 }}
                                                    size="small"
                                                >
                                                    <InputLabel id="demo-select-small-label">
                                                        sort by
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={advFilters.sort}
                                                        label="sort by"
                                                        onChange={handleChangeSort}
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value={"r"}>rating</MenuItem>
                                                        <MenuItem value={"l"}>popularity</MenuItem>
                                                        <MenuItem value={"d"}>release date</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid container gap={2} justifyContent={"space-between"}>
                                <Grid item flex={1} >
                                    <Grid
                                        container
                                        className="mb-10"
                                        style={{ alignItems: "center" }}
                                        justifyContent={"space-between"}
                                    >
                                        <Grid item className="mr-10" sx={{ m: 1, width: 136 }}>
                                            cast :
                                        </Grid>
                                        <Grid item flex={1} sx={{ mr: 1 }}>
                                            <Autocomplete
                                                disablePortal
                                                id="autocomplete"
                                                value={advFilters.contributors || []}
                                                onChange={(e, values) => handleOnCastChange(values)}
                                                onInputChange={(e) => {
                                                    handleInputChange(e.target.value)
                                                }}
                                                loading={isCastLoading}
                                                options={castData}
                                                multiple={true}
                                                sx={{ width: '100%' }}
                                                size="small"
                                                renderInput={(params) => (
                                                    <TextField {...params} label="cast" />
                                                )}
                                                renderOption={(props, option) => {
                                                    return <Grid {...props} container style={{ alignItems: 'center' }} className="p-10">
                                                        <Grid item style={{ width: 50, marginRight: 10 }}>
                                                            <Box
                                                                component="img"
                                                                className='d-block'
                                                                sx={{
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    maxHeight: { xs: 40, sm: 60 },
                                                                    maxWidth: { xs: 20, sm: 40 },
                                                                    borderRadius: "7px",
                                                                    objectFit: 'cover',
                                                                    objectPosition: 'top'
                                                                }}
                                                                alt={option?.name || 'poster'}
                                                                src={option?.poster ? helpers.getFullUrl(option?.poster, 300) : helpers.getDefaultImage('trendingmedia')}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            {option.name}
                                                        </Grid>
                                                    </Grid>
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item flex={1}>
                                </Grid> */}
                            </Grid>
                        </div>
                        : ''
                    }
                    <Grid container justifyContent={"center"}>
                        <Button
                            style={{ marginBottom: "10px" }}
                            onClick={props.handleApply}
                            className="text-light bg-selected rounded-btn mt-10 mr-10 mw-100"
                        >
                            apply
                        </Button>
                        <Button
                            style={{ marginBottom: "10px" }}
                            onClick={() => handleClearAll()}
                            className="text-selected bg-light rounded-btn mt-10 ml-10 mw-100"
                        >
                            clear all
                        </Button>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}
