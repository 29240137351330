import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import "./onboarding.scss";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoginModal } from '../../slices/global';
import { HELPER_TEXT } from '../../lang/HelperText';
import {
  clearMessages,
  getLanguageList,
  getOnboardingMediaCount,
  updateLangPreference,
} from "../../slices/auth";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
// import rightImg from "../../assets/icons/right.svg";

const rightImg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/right.svg";

function Language(props) {
  const { showTitle } = props;
  const [selectedLang, setSelecetdLang] = useState([]);
  const dispatch = useDispatch();
  const _global = useSelector(state => state.global);
  const { is_public } = _global;

  useEffect(() => {
    dispatch(getLanguageList());
    dispatch(getOnboardingMediaCount());
  }, []);

  const auth = useSelector((state) => state.auth);
  const { onboardingMediaCountData } = auth;

  useEffect(() => {
    if (auth.errMesg && auth.errMesg !== "") {
      helpers.toastError(auth.errMesg);
      dispatch(clearMessages());
    }
    if (auth.successMesg && auth.successMesg !== "") {
      helpers.toastSuccess(auth.successMesg, false);
      if (auth.successMesg === "language preferences updated") {
        // dispatch(getLanguageList());
        dispatch(getOnboardingMediaCount());
      }
      dispatch(clearMessages());
    }
  }, [auth]);

  useEffect(() => {
    if (
      auth.languageList &&
      auth.languageList.languages &&
      auth.languageList.languages.length > 0
    ) {
      var temp = [];
      auth.languageList.languages.map((x, i) => {
        if (x.selected) {
          temp.push(x.uuid);
        }
      });
      setSelecetdLang([...temp]);
    }
  }, [auth.languageList]);

  const handleSelectedLanguage = () => {
    helpers.trackMoengageEvent('FR3_Pre_Lang_Pref', { lang_countinue_click: MOENGAGE_ATTRIBUTE.YES });
    if (selectedLang.length > 0) {
      if (!showTitle) {
        var body = {
          language: selectedLang,
        };
        dispatch(updateLangPreference(body));
      }
      if (is_public) {
        dispatch(toggleLoginModal({ isOpen: true, content: HELPER_TEXT.NUDGE_TEXT.ADD_LANGUAGE }))
      }
      props.setActiveSteps(props.activeSteps + 1);
    }
    else {
      if (is_public) {
        dispatch(toggleLoginModal({ isOpen: true, content: HELPER_TEXT.NUDGE_TEXT.ADD_LANGUAGE }))
      }
      else {
        helpers.toastError("Please select atleast one language");
      }
    }
  };

  const handleLanguageSelect = (data) => {
    const tempArr = selectedLang;
    if (tempArr.includes(data.uuid)) {
      const index = tempArr.indexOf(data.uuid);
      if (index > -1) {
        tempArr.splice(index, 1);
      }
      setSelecetdLang([...tempArr]);
      if (showTitle) {
        var body = {
          language: tempArr,
        };
        dispatch(updateLangPreference(body));
      }
    } else {
      tempArr.push(data.uuid);
      setSelecetdLang([...tempArr]);
      if (showTitle) {
        var body = {
          language: tempArr,
        };
        dispatch(updateLangPreference(body));
      }
    }
  };

  // console.log(languageList,"name")
  return (
    <Paper elevation={10} className="bg-secondary p-10 mt-20 ">
      <Grid align="center">
        {showTitle ?
          <>
            <Typography className="fs-32 fw-700 ls-1 lh-32 mt-20">
              {" "}
              <b>
                <span className="fs-32 fw-700 text-selected ls-1 lh-32">
                  {onboardingMediaCountData && onboardingMediaCountData > 0 ? onboardingMediaCountData : auth.languageList?.medias_count}
                </span>{" "}
                titles available to watch
              </b>
            </Typography>
            <Grid item className="pt-10">
              <Typography variant="subtitle" className="fs-14 fw-600">
                in languages of your choice
              </Typography>
            </Grid>
          </>
          :
          <Typography className="fs-32 fw-700 ls-1 lh-32 mt-20">
            languages
          </Typography>
        }
        <Grid
          container
          justifyContent={"flex-start"}
          className="language-container mt-30 pl-40 pr-40 h-100 mhr-60"
          spacing={2}
        >
          {auth.languageList &&
            auth.languageList.languages &&
            auth.languageList.languages.length > 0 &&
            auth.languageList.languages.map((x, i) => {
              return (
                <Grid item xs={4} sm={3} key={i}>
                  <Grid item className=" relative d-inline-block">
                    {x.image && x.image !== "" ? (
                      <Grid className="rounded-container"
                        sx={{
                          maxWidth: { xs: '60px', sm: '75px', md: '100px' },
                          maxHeight: { xs: '60px', sm: '75px', md: '123px' }
                        }}
                      >
                        <Box
                          component={'img'}
                          alt=""
                          src={x.image ? x?.image : ""}
                          sx={{
                            maxWidth: { xs: '60px', sm: '85px', md: '100px' },
                            maxHeight: { xs: '90px', sm: '118px', md: '123px' }
                          }}
                          onClick={() => handleLanguageSelect(x)}
                          className="rounded-container-img"
                        />
                      </Grid>
                    ) : (
                      <Grid
                        className="rounded-container-box rounded-container"
                        onClick={() => handleLanguageSelect(x)}
                        sx={{
                          maxWidth: { xs: '60px', sm: '85px', md: '100px' },
                          maxHeight: { xs: '90px', sm: '118px', md: '100px' }
                        }}
                      >
                        {x.language}
                      </Grid>
                    )}
                    {selectedLang.includes(x.uuid) && (
                      <Grid
                        className="overlay-selected"
                        onClick={() => handleLanguageSelect(x)}
                        sx={{
                          maxWidth: { xs: '60px', sm: '75px', md: '100px' },
                          maxHeight: { xs: '60px', sm: '75px', md: '100px' }
                        }}
                      >
                        <Box
                          component={'img'}
                          alt="" src={rightImg}
                          sx={{
                            maxWidth: { xs: '60px', sm: '75px', md: '100px' },
                            maxHeight: { xs: '60px', sm: '75px', md: '100px' }
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Typography className="fs-16 fw-600 lh-23 pt-7">
                    {x.language}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
        <Button
          onClick={() => handleSelectedLanguage()}
          className="rounded-btn bg-selected text-light mt-20 mb-20 fs-14 mw-320 fw-500"
        >
          Continue
        </Button>
      </Grid>
    </Paper>
  );
}

export default Language;
