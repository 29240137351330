import React, { useEffect, useState } from 'react'
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { useDispatch } from 'react-redux';
import { clipPostActivity } from '../../slices/clips';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';

export default function LikeAction(props) {
    let { is_liked, like_count, showCount, data, type } = props;

    const [isLiked, setIsLiked] = useState(is_liked);
    const [count, setCount] = useState(like_count);

    const dispatch = useDispatch();

    useEffect(() => {
        setIsLiked(is_liked);
        setCount(like_count);
    }, [is_liked, like_count])

    const handleActivity = (data, type) => {
        if (type == 'reply_like') {
            const body = {
                "reply_id": data?.reply_id
            }
            updateAction(body, type);
            helpers.trackMoengageEvent('FR3_Clips', {
                reply_like: MOENGAGE_ATTRIBUTE.YES,
            });
        }
        if (type == 'comment_like') {
            const body = {
                "comment_id": data?.comment_id
            }
            updateAction(body, type);
            helpers.trackMoengageEvent('FR3_Clips', {
                comment_like: MOENGAGE_ATTRIBUTE.YES,
            });
        }

        if (type == 'clip_like') {
            const body = {
                "post_id": data?.post_id,
                "is_liked": !isLiked
            }
            updateAction(body, 'like');
            helpers.trackMoengageEvent('FR3_Clips', {
                like_click: MOENGAGE_ATTRIBUTE.YES,
            });
            updateAction(body, 'like');
        }
    }

    const updateAction = (body, type) => {
        dispatch(clipPostActivity({ type, body })).then(() => {
            setIsLiked(!isLiked);
            if (isLiked) {
                setCount(count - 1);
            } else {
                setCount(count + 1);
            }
        })
    }



    return (
        <>
            <ThumbUpOutlinedIcon onClick={() => handleActivity(data, type)} className={`${isLiked && 'text-selected'} pointer`} />
            {showCount &&
                <p className='text-center pointer m-0 fs-14 fw-600'>{count}</p>

            }
        </>
    )
}
