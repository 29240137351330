import Box from "@mui/system/Box";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { clearMessages } from "../slices/settings";
import { openMobileVerify } from "../slices/global";
import MobileVerify from "../views/common/MobileVerify";
import Footer from "../views/common/Footer";
import PartnerHeader from "../views/component/PartnerHeader";

export default function PartnerLayout({ hideFooter }) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const _global = useSelector((state) => state.global);

  useEffect(() => {
    if (settings.errMesg && settings.errMesg == "mobile not verified") {
      dispatch(openMobileVerify(true));
      dispatch(clearMessages());
    }
  }, [settings]);

  useEffect(() => {
    let data = localStorage.getItem("frenzi_user_access");
    if (data) {
      data = JSON.parse(data);
      if (
        data &&
        data.access &&
        data.access != "" &&
        auth.token &&
        auth.token != "" &&
        auth.token == data.access
      ) {
        // setIsRender(true);
      } else {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  return (
    <>
      <PartnerHeader />
      <main className="fixed-full-view">
        <Box
          className="application-container h-100"
          style={{
            minHeight: "100vh",
          }}
        >
          <Outlet />
        </Box>
        {!hideFooter && <Footer />}
      </main>
      {_global.mobileVerify ? (
        <MobileVerify
          open={_global.mobileVerify}
          handleClose={() => dispatch(openMobileVerify(false))}
        />
      ) : (
        ""
      )}
    </>
  );
}
