import { Box, Button, Grid, Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
// import MoreTile from "../../assets/icons/MoreTile.svg";

const MoreTile = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/MoreTile.svg";

export default function NextButtonTile(props) {
  const { isBtn, link, data, yt, lable, isLarge } = props;

  const handleClick = () => {
    if (props?.onClick && props?.onClick) {
      props?.onClick();
    }
  };

  const sx = {
    height: 210,
    width: 140,
    maxHeight: { xs: 150, sm: 210 },
    maxWidth: { xs: 100, sm: 140 },
  }

  const sx1 = {
    height: 360,
    width: 270,
    maxHeight: { xs: 150, sm: 210, md: 360 },
    maxWidth: { xs: 100, sm: 140, md: 270 },
  }

  return (
    <>
      {data && data.length > 0 ? (
        data.length > 8 ? (
          <div className="h-100 w-100" style={{ padding: "20px 0px 20px 0px" }}>
            <div
              className={`next-slide ${isLarge ? "large-nxt" : "small-nxt"} ${yt && "h-100"
                }`}
            >
              <Grid
                container
                justifyContent={"center"}
                alignItems="center"
                sx={isLarge ? sx1 : sx}
              >
                <Grid item xs={12}>
                  <Grid container justifyContent={"center"}>
                    <Box
                      component={"img"}
                      src={MoreTile}
                      sx={{
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  </Grid>
                </Grid>
                {isBtn ? (
                  <Button
                    className={`rounded-btn w-100 ${isLarge ? "mw-120" : "mw-100 mh-32 fs-12"
                      } bg-light text-selected`}
                    onClick={handleClick}
                  >
                    {lable || "more titles"}
                  </Button>
                ) : (
                  <Link
                    className={`d-block w-100 ${isLarge ? "mw-120" : "mw-100"}`}
                    to={link}
                  >
                    <Button
                      className={`rounded-btn w-100 ${isLarge ? "" : "mw-100 mh-32 fs-12"
                        } bg-light text-selected`}
                    >
                      {lable || "more titles"}
                    </Button>
                  </Link>
                )}
              </Grid>
            </div>
          </div>
        ) : (
          ""
        )
      ) : (
        <div className="h-100 w-100" style={{ padding: "20px 0px 20px 0px" }}>
          <div
            className={`next-slide ${isLarge ? "large-nxt" : "small-nxt"} ${yt && "h-100"
              }`}
          >
            <Skeleton
              animation="wave"
              variant="rectangular"
              className={"ske"}
            />
          </div>
        </div>
      )}
    </>
  );
}
