import React, { useEffect, useState } from 'react'
import Language from './Language'
import Platform from './Platform'
import Handpick from './Handpick'
import { Grid } from '@mui/material'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../common/Loader';
import { getOnboardingMediaCount } from '../../slices/auth'
import MyHelmet from '../common/MyHelmet'

function Onboarding() {
    const dispatch = useDispatch();
    const [activeSteps, setActiveSteps] = useState(1);
    const auth = useSelector(state => state.auth);
    const common = useSelector(state => state.common);
    const { onboardingMediaCountData } = auth;

    useEffect(() => {
        dispatch(getOnboardingMediaCount());
    }, []);

    return (
        <>
        <MyHelmet
            title = {'onboarding'}
            description = {"Seamless onboarding. Explore a vast library of OTT movies, shows, and more. Enjoy entertainment at your fingertips with our app."}
        />
            {(auth.isLoading || common.isLoading) &&
                <Loader />
            }
            <ToastContainer />
            <Grid container justifyContent={'center'} className="vh-100" alignItems={'center'} >
                <Grid item xs={11} sm={9} md={7} lg={6} className={"mw-650"} >
                    {activeSteps === 1 &&
                        <Language showTitle={true} mediaCount={onboardingMediaCountData} setActiveSteps={(val) => setActiveSteps(val)} activeSteps={activeSteps} />
                    }
                    {/* {activeSteps == 2 &&
                        <Genre showTitle={true} setActiveSteps={(val) => setActiveSteps(val)} activeSteps={activeSteps} />
                    } */}
                    {activeSteps === 2 &&
                        <Platform showTitle={true} mediaCount={onboardingMediaCountData} setActiveSteps={(val) => setActiveSteps(val)} activeSteps={activeSteps} isOnboarding={true} />
                    }
                    {activeSteps === 3 &&
                        <Handpick showTitle={true} mediaCount={onboardingMediaCountData} setActiveSteps={(val) => setActiveSteps(val)} activeSteps={activeSteps} />
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default Onboarding