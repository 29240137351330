import React from "react";
import { Box, Grid, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomSocialLogin from "../auth/CustomSocialLogin";
import CustomButton from "../component/button/CustomButton";
import { socialLogIn } from "../../slices/auth";
import helpers from "../../services/helpers";
import { KMSClient, EncryptCommand } from "@aws-sdk/client-kms";
import { useDispatch } from "react-redux";
import MyAds from "../adsense/MyAds";
import { toggleSignUpModal } from "../../slices/global";

const clientParams = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const config = {
  credentials: clientParams,
  endPoint: "",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
  signatureVersion: 2,
  region: process.env.REACT_APP_AWS_REGION,
};

const client = new KMSClient(config);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: { xs: "90vw", sm: "75vw", md: "600px" },
  maxHeight: "97vh",
  overflowY: "auto",
  bgcolor: "#1A1A2E",
  boxShadow: 24,
  borderRadius: 5,
  zIndex: 999,
};

export default function CreateAcoountPopup(props) {
  const { isModal } = props;
  const dispatch = useDispatch();

  const performFormSubmitProcess = async (tempForm, type = "login") => {
    const encoder = new TextEncoder();
    var unit8String = encoder.encode(JSON.stringify(tempForm));
    const input = {
      KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
      Plaintext: unit8String,
    };
    try {
      const command = new EncryptCommand(input);
      const response = await client.send(command);
      // process data.
      if (response && response.CiphertextBlob) {
        let buff = new Buffer(response.CiphertextBlob);
        let base64data = buff.toString("base64");
        if (type === "login") {
          dispatch(socialLogIn({ data: base64data }));
        } else {
          // dispatch(socialSignupEncrypt({ data: base64data }))
        }
      } else {
        helpers.toastError("Data encryptiton failed !!!", !isModal);
      }
    } catch (error) {
      // error handling.\
      helpers.toastError(error, !isModal);
    }
  };

  return (
    <div>
      <Modal
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <CloseIcon
            onClick={props?.handleClose}
            className="ml-3 pt-2 pointer absolute animate-close"
            color="light"
            style={{
              top: "10px",
              right: "10px",
            }}
          />
          <Grid
            container
            sx={{ p: { xs: 1, sm: 3, md: 5 } }}
            justifyContent={"center"}
          >
            <Grid item className="text-center">
              <h2 className="fw-500 fs-16 m-0">
                Create An Account
              </h2>
              <h2 className="fw-700 fs-22 m-0">Rate, Review, Create Watchlists/Sharelists, and more</h2>

              <Grid item className="mt-15 mw-440">
              </Grid>
              <Grid container justifyContent={"center"} className="mt-10 mb-10">
                <div className="mt-30">
                  <MyAds type="banner_3" adv_type={'web_popup_ad'} />
                </div>
              </Grid>
              <h2 className="fw-600 fs-16 m-0">
                Watchlist all your favourite movies/series
              </h2>
            </Grid>
          </Grid>

          <Grid
            item
            className="text-center"
          >
            <Box
              sx={{ p: { xs: 1, sm: 3, md: 5 } }}
              className="bg-secondary d-flex jc-center"
            >
              <div className="mw-370 w-100">
                <CustomButton
                  title="sign up for free"
                  className="rounded-btn bg-selected text-light fs-16"
                  onClick={() => dispatch(toggleSignUpModal(true))}
                />
                <CustomSocialLogin
                  performFormSubmitProcess={performFormSubmitProcess}
                />
              </div>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
