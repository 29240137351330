import { Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import helpers from '../../services/helpers';
import MyHelmet from '../common/MyHelmet';

export default function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
        <MyHelmet
            title = {"privacy policy"}
            description = {"Explore our comprehensive policy to understand how we safeguard your information for a secure and trustworthy experience at Frenzi."}
        />
            <Grid container className='app-container'>
                <Grid item className='pl-48 pr-15' >
                    <Grid container sx={{ justifyContent: { xs: 'center', sm: 'start' } }}>
                        <Grid item xs={8}>
                            <h1 style={{ fontSize: '42px', fontWeight: '800px' }}>Privacy Policy</h1>
                        </Grid>
                    </Grid>

                    <Grid item>

                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4}
                                sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300'
                                    sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>We collect information from and about you</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>
                                    Contact information. For example, we might collect your email address if you sign up for our emails.

                                    Information you submit or post. For example, we collect the information you post in a public space on our website. We also collect information if you respond to a survey.

                                    Demographic information. We may collect your zip code to help you find sales relevant to you.

                                    Other information. If you use our website, we may collect information about your IP address and the browser you’re using. We might look at what site you came from, or what site you visit when you leave us. If you use our mobile app, we may collect your GPS location. We might look at how often you use the app and where you downloaded it.

                                    We collect information from you passively. We use tracking tools like browser cookies and web beacons to collect information from you.

                                    We get information about you from third parties. For example, our business partners may give us information about you. Social media platforms may also give us information about you.
                                </p>

                            </Grid>
                        </Grid>

                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4}
                                sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300'
                                    sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>We may share information with the third parties</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>
                                    We will share information with our business partners. For example, we may share information with third parties who operate our “find ads near me” feature. We may also share information with third parties who co-sponsor a promotion. We may also share information with our retail partners. This might include those whose circulars and ads appear on our platforms. </p>
                                <p>
                                    We will share information if we think we have to in order to comply with the law or to protect ourselves For example, we will share information to respond to a court order or subpoena. We may share it if a government agency or investigatory body requests. We might share information when we are investigating potential fraud. </p>

                                <p>We may share information with any successor to all or part of our business. For example, if part of our business was sold we may give our customer list as part of that transaction.</p>

                                <p>We may share information for other reasons we may describe to you</p>

                            </Grid>
                        </Grid>


                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4}
                                sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300'
                                    sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>You have certain choices about sharing and marketing practices</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>
                                    You can opt out of receiving our marketing emails. To stop receiving our promotional emails, follow the instructions in any promotional message you get from us. Don’t worry – even if you opt out of getting marketing messages, we will still send you transactional messages. These include responses to your questions or information about your account. You can control cookies and tracking tools.
                                </p>

                            </Grid>
                        </Grid>


                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4}
                                sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300'
                                    sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>We use standard security measures</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>
                                    The Internet is not 100% secure. We cannot promise that your use of our sites will be completely safe. We encourage you to use caution when using the Internet. This includes not sharing your passwords. We keep personal information as long as it is necessary or relevant for the practices described in this Policy. We also keep information as otherwise required by law.
                                </p>

                            </Grid>
                        </Grid>


                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4}
                                sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300'
                                    sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>We may link to other sites or have third party services on our site we don’t control</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>
                                    If you click on a link to a third party site, you will be taken to websites we do not control. This includes social media and OTT platforms. This policy does not apply to the privacy practices of these websites. Read the privacy policy of other websites and insurance carriers carefully. We are not responsible for these third party practices.</p>

                                <p>Our site and apps may also link to and/or serve third party ads or other content that contains their own cookies or tracking technologies.
                                </p>

                            </Grid>
                        </Grid>



                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4}
                                sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300' sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>We use tracking technologies for many reasons.</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>
                                    To understand the activities and behaviours of our consumers and website visitors. </p>

                                <p>To recognize new visitors to our websites and apps. </p>

                                <p>To recognize past customers. </p>

                                <p>To serve you with customized or interest-based advertising. These ads may appear on our website or others you visit </p>

                                <p> So we can better understand our audience, our customers, our website visitors, and their respective interests.
                                </p>

                            </Grid>
                        </Grid>




                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4}
                                sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300' sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>We engage in interest-based advertising.</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>
                                    We may serve interest-based advertising using personal and other information gathered about you over time across multiple websites or other platforms. This might include apps. These ads are served on websites or apps. They might also be served in emails. Interest-based or “online behavioural advertising” includes ads that are served to you after you leave our website, encouraging you to return. They also include ads we think are relevant to you based on your shopping habits or online activities.
                                </p>

                            </Grid>
                        </Grid>




                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4}
                                sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300' sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>How do we gather relevant information for interest based advertising?</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>
                                    To decide what is relevant to you, we might look at your purchases, collect certain mobile unique operating system IDs or browsing behaviours. We might look at these activities on our platforms or the platforms of others. This information is gathered using the tracking tools described above. We work with third parties who help gather this information. These third parties might link your name or email address to other information they collect. That might include past purchases made offline or online. Or, it might include online usage information.
                                </p>

                            </Grid>
                        </Grid>




                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4}
                                sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300' sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>How to control tracking technologies?</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>
                                    You can control certain tracking tools. Many browsers give you the ability to delete or reject cookies. How you do so depends on the type of cookie. You can configure your browser to reject browser cookies. Why? Because flash cookies do not reside in your browser, and thus your browser settings will not affect them. Not all of the tracking described here will stop if cookies are deleted or blocked. You can also control certain tracking tools on your mobile devices. These opportunities are subject to the offerings made available by the third-party operating system providers and subject to their privacy policies, and not any offerings by or the privacy policy of Frenzi. </p>

                                <p>NOTE: If you configure your computer to block cookies, you may not be able to access certain functionality on our site or the toolbar. If you block or delete cookies, not all of the tracking activities we have described here will stop. Choices you make are both browser and device-specific.
                                    For convenience, the following are instructions that may apply to your mobile device, though please note that the setting features may vary from device to device.
                                </p>

                            </Grid>
                        </Grid>




                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4}
                                sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300' sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>Google Android</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>
                                    Devices with Google Play services 4.0 and above use the Google Android Advertising ID (AAID). To learn more about limiting ad tracking using this identifier:
                                </p><ol>
                                    <li>Open the Google Settings app on your device</li>
                                    <li>Select Ads</li>
                                    <li>Select Opt out of interest-based ads </li>
                                </ol>
                                <p></p>

                            </Grid>
                        </Grid>



                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300' sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>Apple iOS</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>
                                    Devices with iOS 6 and above use the Apple Advertising Identifier (IDFA). To learn more about limiting ad tracking using this identifier:
                                </p><ol>
                                    <li>Open Settings app on your device</li>
                                    <li>Select Privacy</li>
                                    <li>Select Advertising</li>
                                    <li>Select Limit Ad Tracking</li>
                                </ol>
                                <p></p>

                            </Grid>
                        </Grid>



                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300' sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>You have options over the interest-based ads you see</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p><span>The Self-Regulatory Program for Online Behavioural Advertising program provides consumers with the ability to opt-out of having their online behaviour recorded and used for advertising purposes. If you want to opt out, visit&nbsp;</span><a href="http://www.aboutads.info/choices/"><span className='text-selected'>this page</span></a><span >. Choices you make are both browser and device-specific. Some web browsers have a “Do Not Track” feature. This feature lets you tell websites you visit that you do not want to have your online activity tracked. These features are not yet uniform across browsers. Our sites are not currently set up to respond to those signals.&nbsp;</span></p>
                                <p><span>If you have any questions about this Policy, please email us at&nbsp;</span><a href="mailto:feedback@Frenzi.in"><span className='text-selected' >feedback@Frenzi.in</span></a><span>.</span></p>

                            </Grid>
                        </Grid>



                        <Grid container className='mb-48 jc-center'>
                            <Grid item xs={8} sm={4}
                                sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                                <Typography className='fs-24 lh-20 fw-300' sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    <h4 className='mt-0'>We may update this Policy</h4>
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} className='pl-45'>

                                <p>From time to time we may change our privacy policies. We will notify you of any material changes to our Policy as required by law. We will also post an updated copy on our website. Please check our site periodically for updates.</p>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
