import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getPopularTodayMovie, getPopularTodaySearched, getPopularTodayTv } from '../../../slices/trending';
import CommonMediumTileListing from '../../common/CommonMediumTileListing';
import Loader from '../../common/Loader';
import Selection from './Selection';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CommonDetailTileListing from '../../common/CommonDetailTileListing';
import MyHelmet from '../../common/MyHelmet';
import MyAds from '../../adsense/MyAds';
import ContentAccordion from '../../common/ContentAccordion';

// const page = 1;
export default function PopularFrenzi() {
  const page = 1;
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedPlatformOttName, setSelectedPlatformOttName] = useState('');
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const is_free = searchParams.get('is_free') && searchParams.get('is_free') === 'true' ? true : false;
  const trending = useSelector(state => state.trending);
  const auth = useSelector(state => state.auth);
  const global = useSelector(state => state.global);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (selectedPlatform && selectedPlatform !== "") {
      var body = {
        page: page,
        pagelimit: 20,
        is_free: is_free,
        media_type: 'movie',
        is_public: global.is_public,
        body: { service: [selectedPlatform] }
      }
      dispatch(getPopularTodayMovie(body));
    }
    setCurrentSlide(0);
  }, [selectedPlatform])

  useEffect(() => {
    if (selectedPlatform && selectedPlatform !== "") {
      var body = {
        page: page,
        pagelimit: 20,
        is_free: is_free,
        media_type: 'tv',
        is_public: global.is_public,
        body: { service: [selectedPlatform] }
      }
      dispatch(getPopularTodayTv(body));
    }
  }, [selectedPlatform])

  useEffect(() => {
    if (selectedPlatform && selectedPlatform !== "") {
      var body = {
        page: page,
        pagelimit: 20,
        is_free: is_free,
        media_type: 'all',
        sort: 's',
        is_public: global.is_public,
        body: { service: [selectedPlatform] }
      }
      dispatch(getPopularTodaySearched(body));
    }
  }, [selectedPlatform])

  return (
    <>

      <MyHelmet
        title={"popular on frenzi"}
        description={'Discover trending content recommended by Frenzi. Explore our "Popular on Frienzi" section for top recommendations from like minded moviebuffs'}
      />
      {(trending.isLoading || auth.isLoading) && <Loader />}
      <div className="new-container">
        <ContentAccordion
          isFull={true}
          title={'POPULAR_H'}
          data={'POPULAR'}
        />
      </div>
      <div className='new-container'>
        <Grid container className='app-container pt-10 pb-40' justifyContent={'center'} >
          <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px' }} justifyContent={'center'}>
            <Grid container justifyContent={'center'}>
              <MyAds type="banner_2" adv_type={'web_popular_top_ad'} hideInMobile={true} />
            </Grid>
          </Grid>

          <Grid container alignItems={'center'} className="pt-20" justifyContent='space-between'>
            <Grid item>
              <Grid container alignItems={'center'}>
                <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                <h2 className='fs-28 fw-700 lh-28 m-0'><span className='text-selected'>popular</span> on frenzi</h2>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className='pt-20'>
            <Selection
              platforms={auth.allPreferences && auth.allPreferences.platforms ? auth.allPreferences.platforms : []}
              setSelectedPlatform={setSelectedPlatform}
              setSelectedPlatformUuid={(val) => { }}
              setSelectedPlatformName={(val) => { }}
              setSelectedPlatformOttName={setSelectedPlatformOttName}
              selectedPlatform={selectedPlatform}
              is_free={is_free}
              p_name={''}
            />

            <CommonMediumTileListing
              heading={'movies'}
              subheading=''
              isShowHeading={true}
              nextLink={`/popular-today/detail/movie${is_free ? '?is_free=true' : ''}`}
              data={trending.popularTodayMovieData}
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
              refPage="Popular on Frenzi"
            />

            <CommonMediumTileListing
              heading={'web series'}
              subheading=''
              isShowHeading={true}
              nextLink={`/popular-today/detail/tv${is_free ? '?is_free=true' : ''}`}
              data={trending.popularTodayTvData}
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
              refPage="Popular on Frenzi"
            />

            <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px' }} justifyContent={'center'}>
              <Grid container justifyContent={'center'}>
                <MyAds type="banner_2" adv_type={'web_popular_middle_ad'} hideInMobile={true} />
              </Grid>
            </Grid>

            <CommonDetailTileListing
              heading={'title that interest others'}
              subheading='titles that others plan to binge watch'
              isShowHeading={true}
              nextLink={`/popular-today/detail/search${is_free ? '?is_free=true' : ''}`}
              data={trending.popularTodaySearchData}
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
              refPage="Popular on Frenzi"
            />

            <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px' }} justifyContent={'center'}>
              <Grid container justifyContent={'center'}>
                <MyAds type="banner_2" adv_type={'web_popular_middle_ad'} hideInMobile={true} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
