import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SingleMediaTile from '../../common/SingleMediaTile';
import { getComingSoon, getHandPicked, getPublicHandpicked } from '../../../slices/trending';
import Loader from '../../common/Loader';
// import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
// import SideFilter from '../../search/SideFilter';
import { clearMessages } from '../../../slices/media';
import helpers from '../../../services/helpers';
import MyHelmet from '../../common/MyHelmet';
import MyAds from '../../adsense/MyAds';
import { isMobileDevice } from '../../../services/Utils';
import HandpickDefault from '../HandpickDefault';
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { HandpickSliderNew } from '../HandpickSliderNew';
import SingleMediaTileLoader from '../../common/SingleMediaTileLoader';

const defaultSearchMediaFilter = {
    service: [],
    genre: [],
    language: [],
    content_type: [],
    exclude_already_watched: '',
    sort: '',
    apply_filter: false
}

export default function ComingSoon() {
    const [defaultSearchFilter, setDefaultSearchFilter] = useState(defaultSearchMediaFilter);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [controlledSwiper, setControlledSwiper] = useState(null);
    const [searchParams] = useSearchParams();
    const is_free = searchParams.get('is_free') && searchParams.get('is_free') == 'true' ? true : false;
    const [removedId, setRemovedId] = useState([]);

    const media = useSelector(state => state.media);
    const trending = useSelector(state => state.trending);
    const _global = useSelector(state => state.global);
    const { comingSoonData, comingSoonPageData, isLoading } = trending;

    useEffect(() => {
        if (media.errMesg && media.errMesg != "") {
            helpers.toastError(media.errMesg);
            dispatch(clearMessages());
        }
        if (media.successMesg && media.successMesg != "") {
            helpers.toastSuccess(media.successMesg);
            dispatch(clearMessages());
        }
    }, [media])

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (_global.is_public) {
            var body = {
                page: page,
                pagelimit: 10,
                is_free: false,
                is_public: true,
            };
            dispatch(getPublicHandpicked());
        } else {
            var body = {
                page: 1,
                pagelimit: 10,
                is_free: false,
            };
            dispatch(getHandPicked({ ...body, no_filter: true }));
        }
    }, [_global.is_public])

    useEffect(() => {
        getData();
    }, [page])

    const getData = async (filter = defaultSearchFilter) => {
        var body = {
            page: page,
            pagelimit: 20,
            body: filter.apply_filter ? filter : {},
            is_free: is_free,
            is_public: _global.is_public
        }
        dispatch(getComingSoon(body));
    }

    useEffect(() => {
        const handleScroll = () => {
            if (comingSoonPageData && comingSoonPageData.pagination && comingSoonPageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                    console.log("comingSoonPageData==",comingSoonPageData)
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, trending]);


    // const handleApplyFilter = async (val) => {
    //     setDefaultSearchFilter({ ...val });
    //     if (val) {
    //         if (params && params.type && params.type != '') {
    //             if (page == 1) {
    //                 await getData(val);
    //             } else {
    //                 setPage(1);
    //             }
    //         } else {
    //             await getData(val);
    //         }
    //         window.scrollTo(0, 0);
    //     }
    // }

    const handleStop = () => {
        controlledSwiper.autoplay.stop();
    };

    const handlePlay = () => {
        controlledSwiper.autoplay.start();
    };

    const handpickData = _global.is_public ? trending?.handpickedPageData && trending?.handpickedPageData.length > 0 ? trending?.handpickedPageData : [] : trending.handpickedPageData?.handpicked_response &&
        trending.handpickedPageData.handpicked_response.response && trending.handpickedPageData.handpicked_response.response.length > 0 ? trending.handpickedPageData.handpicked_response.response : [];

    return (
        <>
            <MyHelmet
                title={'Upcoming on OTT'}
                og_title={'Upcoming Movies & Series on OTT Platforms - MyFrenzi'}
                description={"Be the first to know about upcoming movies and series on your favourite OTT platforms. Stay ahead with MyFrenzi's updates."}
                keywords={'coming soon, OTT platforms, upcoming movies, upcoming series, MyFrenzi'}
            />
            {isLoading && <Loader />}

            <Grid
                item
                xs={12}
                className="app-section"
                style={{ overflow: "hidden" }}>

                {handpickData.length >
                    0 ? (
                    <>
                        <Grid item xs={12}>
                            <Swiper
                                centeredSlides={true}
                                fadeEffect={{ crossFade: true }}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: true,
                                }}
                                // loop={true}
                                // speed={1500}
                                // navigation={true}
                                effect={"fade"}
                                modules={[Autoplay, Navigation, EffectFade, Pagination]}
                                className="new-swiper"
                                onSwiper={setControlledSwiper}
                                pagination={{
                                    clickable: true,
                                }}
                            >
                                {handpickData.map((x, i) => {
                                    if (!removedId.includes(x.media)) {
                                        return <SwiperSlide key={i}>
                                            <HandpickSliderNew
                                                data={x}
                                                isShowTag={false}
                                                setRemovedIds={(id) => setRemovedId([...removedId, id])}
                                                handlePlay={handlePlay}
                                                handleStop={handleStop}
                                            />
                                        </SwiperSlide>
                                    }
                                })}
                            </Swiper>
                        </Grid>
                    </>
                ) : (
                    <HandpickDefault />
                )}
            </Grid>

            {/* <Grid className='new-container'>
                <Grid container className='pt-10 pb-20' alignItems={'center'} justifyContent={'flex-end'}>
                    <SideFilter
                        onSearchApply={(val) => handleApplyFilter(val)}
                    />
                </Grid>
            </Grid> */}

            <Grid container className="new-container pb-30 pt-30">
                <Grid flex={1} className="left-container pt-10">
                    <Grid item style={{ maxWidth: '100%' }} flex={1}>
                        {comingSoonData && comingSoonData.length > 0 &&
                            <Grid container spacing={2}>
                                {comingSoonData.map((x, i) => {
                                    return <>
                                        {(i !== 0 && i % 2 === 0 && i % 3 === 0) &&
                                            <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                                                <Grid container justifyContent={'center'}>
                                                    <MyAds type="banner_2" adv_type={'web_media_listing_ad'} />
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid item className='coming-soon-items' key={i} xs={12} sm={6} >
                                            <SingleMediaTile refPage={'Coming Soon'} x={x} showNotifyButton={true} />
                                        </Grid>
                                    </>
                                })}
                                {isLoading ?
                                    <Grid item key={'loading'} xs={12} sm={6} >
                                        <SingleMediaTileLoader />
                                    </Grid>
                                    : ''
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
                {!isMobileDevice() ?
                    <Grid className="right-container">
                        <Grid className="right-container-ad">
                            <MyAds type="banner_1" adv_type={'web_coming_soon_right_ad'} />
                        </Grid>
                    </Grid>
                    : ''}
            </Grid>
        </>
    )
}
