import {Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { webStoriesList, webStoriesReelList } from '../../slices/blog';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import helpers from '../../services/helpers';


export default function WebStories() {

    const webStoriesListData = useSelector(state => state.blog.webStoriesListData);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(webStoriesList())
        dispatch(webStoriesReelList())

    }, [])

    return (
        <>
            <Grid  rowSpacing={3} className='new-container pb-20 pt-50'>
                <Grid item xs={12}>
                    <Typography className='fs-20 fw-500'>latest Blogs</Typography>
                </Grid>
                {webStoriesListData && webStoriesListData.results && webStoriesListData.results.length > 0 && webStoriesListData.results.map((x, i) => {
                    return <>
                        <Grid item>
                            <Grid container className='m-10'>
                                <Link to={`/stories/${x.unique_title}`} >
                                    {x.reels && x.reels[0] && x.reels[0].s3_image_url ?
                                    <img src={helpers.getFullUrl(x.reels[0].s3_image_url)} />
                                     : 
                                    <img src={helpers.getFullUrl('poster')} /> }
                                    <p>{x.title}</p>
                                </Link>
                            </Grid>
                        </Grid>
                    </>
                })}
            </Grid>
        </>

    )
}
