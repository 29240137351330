import axiosInstance from "../http-common";

const getFreeDetail = (page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/free?page=${page}&pagelimit=${pagelimit}`);
};


const FreeDataService = {
    getFreeDetail
};

export default FreeDataService;