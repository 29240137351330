import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { getComingSoon } from '../../slices/trending';
import helpers from '../../services/helpers';
import SingleMediaTile from '../common/SingleMediaTile'
import { clearMessages as clearMessagesMedia } from '../../slices/media';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";

const breakpoints = {
    319: {
        slidesPerView: 1,
        spaceBetween: 15,
    },
    425: {
        slidesPerView: 1.5,
        spaceBetween: 15,
    },
    490: {
        slidesPerView: 1.8,
        spaceBetween: 15,
    },
    602: {
        slidesPerView: 1.5,
        spaceBetween: 15,
    },

    690: {
        slidesPerView: 1.7,
        spaceBetween: 15,
    },
    755: {
        slidesPerView: 2,
        spaceBetween: 20,
        slidesPerGroup: 1
    },
    1024: {
        slidesPerView: 2.5,
        spaceBetween: 20,
        slidesPerGroup: 1
    },
    1410: {
        slidesPerView: 3,
        spaceBetween: 20,
        slidesPerGroup: 2
    },
    1680: {
        slidesPerView: 4,
        spaceBetween: 30,
        slidesPerGroup: 2
    },
    2560: {
        slidesPerView: 4.2,
        spaceBetween: 30,
        slidesPerGroup: 3
    },
}
const page = 1;
export default function ReleaseWeek({ type }) {

    const dispatch = useDispatch();
    const trending = useSelector(state => state.trending);
    const media = useSelector(state => state.media);
    const global = useSelector(state => state.global);

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if (media.errMesg && media.errMesg !== "") {
            helpers.toastError(media.errMesg);
            dispatch(clearMessagesMedia());
        }
        if (media.successMesg && media.successMesg !== "") {
            helpers.toastSuccess(media.successMesg);
            getData();
            dispatch(clearMessagesMedia());
        }
    }, [media, dispatch])

    const getData = () => {
        var body = {
            page: page,
            pagelimit: 10,
            is_free: false,
            date_range: 'this_week',
            is_public: global.is_public
        }
        dispatch(getComingSoon(body))
    }

    return (
        <Grid container className='app-container app-section section-border-bottom' >
            {trending.comingSoonData && trending.comingSoonData.length > 0 &&
                <Grid container className='pt-30 pb-30'>
                    <Grid item xs={12}>
                        <h2 className='fs-28 fw-700 lh-28 m-0 '><span className='text-selected'>releasing this week</span> on ott</h2>
                        {/* <h2 className='fs-24 fw-500 lh-24 mt-0 pt-5 mb-10 text-light'>We will Send you a notification </h2> */}
                    </Grid>
                    {type === 'grid' ?
                        trending.comingSoonData.map((x, i) => {
                            return <Grid xs={12} sm={6} md={4} key={i} sx={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                <SingleMediaTile x={x} refPage={'Activity'} />
                            </Grid>
                        })
                        :
                        <Grid item xs={12}>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={20}
                                // slidesPerGroup={5}
                                freeMode={true}
                                speed={1000}
                                navigation={true}
                                breakpoints={breakpoints}
                                modules={[FreeMode, Navigation]}
                                className="mySwiper"
                            >
                                {trending.comingSoonData.map((x, i) => {
                                    return <SwiperSlide key={i}>
                                        <Grid key={i} sx={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                            <SingleMediaTile x={x} refPage={'Activity'} />
                                        </Grid>
                                    </SwiperSlide>
                                })}
                            </Swiper>
                        </Grid >
                    }
                </Grid >
            }
        </Grid >
    )
}
