import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import CarouselTile from "../common/CarouselTile";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { isMobileDevice } from '../../services/Utils';
import { Box } from "@mui/material";
import SeeAll from "../common/SeeAll";

const LargeSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/LargeSeeAll.svg";
const SmallSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/SmallSeeAll.svg";

export default function YourWatchlist(props) {
  const [sctiveWatchlistData, setActiveWatchlistData] = useState({});
  const { data, heading } = props;

  useEffect(() => {
    handleAction(data[0]);
  }, [data])

  const handleAction = (data) => {
    if (data && data.created_at) {
      setActiveWatchlistData({
        days: data.created_at,
        watchlist_name: data?.watchlist_name
      })
    } else {
      setActiveWatchlistData({
        days: 'N/A',
        watchlist_name: data?.watchlist_name
      })
    }
  };


  return (
    <Grid>
      <Grid
        container
        className="app-container"
        justifyContent={"center"}
        sx={{ marginTop: { xs: "20px" } }}
      >
        <Grid item xs={12}>
          <Grid container alignItems={"center"} justifyContent="space-between">
            <Grid item>
              <p className="fs-24 fw-700 lh-28 m-0 ">
                {heading && heading !== "" ? (
                  heading
                ) : (
                  <>
                    <span className="text-selected">in your</span> watchlist
                  </>
                )}
              </p>
            </Grid>
            <Link to={"/watchlists"}>
              {/* <Box component={'img'} src={isMobileDevice() ? SmallSeeAll : LargeSeeAll} sx={{ paddingTop: { xs: '5px', sm: '10px' } }} /> */}
              <SeeAll/>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ marginTop: '20px' }} className="pb-30 w-100 section-border-bottom mb-20">
        <Grid className='app-container'>
          <Swiper
            // spaceBetween={30}
            centeredSlides={true}
            slidesPerView={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
            // pagination={{
            //   clickable: true,
            // }}
            speed={1500}
            navigation={true}
            modules={[Autoplay, Navigation]}
            onSlideChange={(e) => {
              handleAction(data[e.activeIndex]);
            }}
            className="mySwiper"
          >
            {data.map((x, i) => {
              return <SwiperSlide key={i}>
                <CarouselTile
                  ref_page={'Frenzi Watchlist'}
                  x={x}
                  isMediaSubDetails={true}
                  withAd={props?.withAd}
                />
              </SwiperSlide>
            })}
          </Swiper>
        </Grid>
        <Grid item className="app-container  mt-10 text-center mt-10">
          <Grid container justifyContent={"center"}>
            <Grid container alignItems={'center'} className="timer-container pt-10 pb-10">
              <TimerOutlinedIcon />
              <Grid item>
                <Typography className="fs-16 fw-700 lh-16">{!isNaN(sctiveWatchlistData?.days) ? sctiveWatchlistData?.days : 0} DAYS</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="mt-10">
            <p className="fs-18 fw-600 m-0 ">
              since you added to your "{sctiveWatchlistData?.watchlist_name}" watchlist
            </p>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
