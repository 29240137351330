import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  // Pagination,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MyHelmet from "../common/MyHelmet";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { useDispatch, useSelector } from "react-redux";
import { getSearchData, handleFilters, resetPartnerData } from "../../slices/frenziPartner";
// import MediumTile from "../common/MediumTile";

import "./_partner-search.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import SideFilter from "../search/SideFilter";
import helpers from "../../services/helpers";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import AdvancedSearch from "./components/AdvancedSearch";
import { getGenreList, getLanguageList, getPlatformList } from "../../slices/auth";
import { DEFAULT_ADV_FILTER } from "./partnerHelper";
import SearchBar from "./components/SearchBar";

const PAGINATION_TYPE = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

const loadingArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

function PartnerSearchResults() {
  const [page, setPage] = useState(1);
  // const [pageLimit, setPageLimit] = useState(20);
  // const [tempFilter, setTempFilter] = useState({});
  const [searchData, setSearchData] = useState({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchDataList, setSearchDataList] = useState([]);
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { advFilters } = useSelector((state) => state.frenziPartner);
  const _global = useSelector((state) => state.global);

  // const startIndex = (page - 1) * pageLimit;
  // const endIndex = Math.min(startIndex + pageLimit, pagination?.total_entries);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(getLanguageList());
    dispatch(getPlatformList({ page: 1, pagelimit: 100, is_public: _global?.is_public }));
    dispatch(getGenreList());
  }, [])

  // const detectDevice = () => {
  //   if (window.matchMedia("(max-width: 767px)").matches) {
  //     return PAGINATION_TYPE.SMALL;
  //   } else if (
  //     window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches
  //   ) {
  //     return PAGINATION_TYPE.MEDIUM;
  //   } else {
  //     return PAGINATION_TYPE.LARGE;
  //   }
  // };

  // const handlePageLimitChange = (event) => {
  //   setPageLimit(event.target.value);
  // };

  const getData = (string, log_queue, page, isNew = false, isFilter = true) => {
    if (isLoading) {
      return true;
    }
    setIsLoading(true);


    let allFilters = helpers.getEsSearchFilters(advFilters);
    var body = {
      query: string,
      // q: input || string,
    }

    if (allFilters && allFilters.length && allFilters.length > 0) {
      // newArr = newArr.concat([{ match: { [key]: x?.available_countries } }])
      allFilters = allFilters.concat([{ match: { available_countries: advFilters.available_countries } }])
    }

    if (allFilters && allFilters.length && isFilter) {
      // if (allFilters && (allFilters.should || allFilters.must || allFilters.sort || allFilters.filter)) {
      body.custom_filters_es = allFilters
      // body.custom_filters_es = {
      //   any: [
      //     {
      //       all: allFilters
      //     }
      //   ]
      // }
    }

    if (log_queue) {
      body.log_queue = log_queue
    }
    if (page > 1) {
      body.page = page
    }
    dispatch(getSearchData(body)).then((resp) => {
      // setPage(resp?.payload?.p || 1)
      const payloadData = resp?.payload?.data || null
      if (payloadData) {
        setSearchData(payloadData)
        if (payloadData?.results && payloadData.results.length > 0) {
          if (isNew) {
            setSearchDataList([...payloadData.results])
          } else {
            setSearchDataList([...searchDataList, ...payloadData.results])
          }
        } else {
          setIsError(true)
        }
        setIsLoading(false);
      } else {
        setIsError(true);
        setIsLoading(false);
        helpers.toastError('No data found!!!')
      }
      if (payloadData && payloadData?.error) {
        setIsError(true);
        setIsLoading(false);
        helpers.toastError(payloadData?.error)
      }
    }).catch((err) => {
      setIsLoading(false);
      setIsError(true);
      console.log(err);
    });
  };

  useEffect(() => {
    const string = searchParams.get('q');
    if (string != input) {
      setInput(string)
      setSearchDataList([])
      setSearchData({})
      setPage(1);
      getData(string, null, 1, true);
    } else {
      getData(string, searchData?.log_queue || null, page);
    }
    setIsError(false)
  }, [page, searchParams]);

  // useEffect(() => {
  //   const string = searchParams.get('q');
  //   setInput(string)
  //   setSearchDataList([])
  //   setSearchData({})
  //   setPage(1);
  //   getData(string, null || null, 1, true);
  //   setIsError(false)
  // }, [searchParams]);

  // const handleApplyFilter = (filter) => {
  //   setTempFilter({ ...filter });
  // };

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const handleScroll = () => {
    if (!isLoading && !isError) {
      if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
        setPage((prev) => { return prev + 1 });
      }
    }
  }

  const handleScrollNew = () => {
    const elements = document.getElementsByClassName('tile-container');
    if (elements.length > 0) {
      const lastElement = elements[elements.length - 1];
      const rect = lastElement.getBoundingClientRect();

      const isVisible = rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
      if (isVisible) {
        setPage((prev) => { return prev + 1 });
      }
    }
  }


  const debouncedScrollHandler = debounce(handleScrollNew, 200);

  useEffect(() => {
    window.addEventListener("scroll", debouncedScrollHandler, { passive: true });
    return () => window.removeEventListener("scroll", debouncedScrollHandler);
  }, [page]);

  const handleChangeSort = (event) => {
    dispatch(handleFilters({ ...advFilters, sort: event.target.value }))
    // setSearchMediaFilter({ ...searchMediaFilter, sort: event.target.value });
  };

  const loadMore = () => {
    if (!isLoading && !isError) {
      setPage((prev) => { return prev + 1 });
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      helpers.setSearchHistoryData(input)
      handleClose();
      navigate(`/partner/results?q=${input}`);
    }
  }

  const handleApply = (isFilter) => {
    handleClose();
    dispatch(resetPartnerData())
    setSearchDataList([])
    setSearchData({})
    navigate(`/partner/results?q=${input}`)
    setInput(input);
    setPage(1);
    getData(input, null, 1, true, isFilter);
    setIsError(false);
  };

  const handleSeacrh = () => { }

  return (
    <>
      <MyHelmet
        title={"Partner search page"}
      // description={
      //   "Unveil the Hottest OTT Trends, Stay Updated on the Latest Streaming Buzz and Discover What's Trending in the OTT Worlds"
      // }
      />
      <Grid container className="new-container pb-10">
        <div className="partner-search-box-container">
          <Grid container style={{ alignItems: "center" }}>
            <Grid item flex={1}>
              <Grid container style={{ alignItems: "center" }}>
                <Grid item>
                  <KeyboardBackspaceRoundedIcon
                    onClick={() => {
                      dispatch(handleFilters(DEFAULT_ADV_FILTER));
                      navigate('/partner')
                    }}
                    fontSize="large"
                    className="pointer mr-10 ml-10 scale-1"
                  />
                </Grid>
                <Grid item>
                  <Grid container>
                    <Typography className="fw-700 fs-28 lh-28 text-selected">
                      search results
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Typography className="fw-500 fs-18 lh-28 text-muted">
                      {input}
                    </Typography>
                  </Grid>
                </Grid>

                {/* <Grid item>
                  <div className="search-container">
                    <Grid
                      container
                      justifyContent={"center"}
                      sx={{
                        marginTop: { xs: "10px" },
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    >
                      <SearchBar
                        suggestions={searchData}
                        isLoading={isLoading}
                        onChange={(val) => handleSeacrh(val)}
                        onKeyUp={(e) => handleKeyUp(e)}
                        setInput={setInput}
                        input={input}
                      />
                    </Grid>
                  </div>
                </Grid> */}

              </Grid>
            </Grid>

            <Grid item>
              <TuneRoundedIcon
                onClick={() => {
                  setOpen(true);
                }}
              />
            </Grid>
          </Grid>
          <div className="search-results-container">
            {/* <Grid
              container
              justifyContent={"space-between"}
              style={{ alignItems: "center" }}
            >
              <div>
                <Grid item>
                  <div>
                    <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        sort by
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={advFilters.sort}
                        label="sort by"
                        onChange={handleChangeSort}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"r"}>rating</MenuItem>
                        <MenuItem value={"l"}>popularity</MenuItem>
                        <MenuItem value={"d"}>release date</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              </div>
              <div className="pointer">
                <TuneRoundedIcon
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </div>
            </Grid> */}
            <div>
              <Grid container spacing={2} justifyContent="flex-start">
                {searchDataList && searchDataList?.length ? searchDataList.map((x, index) => {
                  if (!x.poster_path || x.poster_path === "") {
                    return null
                  }
                  return (
                    <Grid title={x?.title} item key={index} id={`${index + 1}-movie-list-item`} className="tile-container">
                      <Grid
                        sx={{
                          maxWidth: { xs: 140, sm: 180 },
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          display: "inline-block",
                        }}
                      >
                        {/* <MediumTile
                          x={{
                            ...data,
                            poster: data?.poster_path
                          }}
                          isLoading={isLoading} /> */}

                        <Grid item className='scale-1'>
                          <Grid item className='movie-container'>
                            <Link target="_blank" onClick={() => {
                              if (x.media_type === 'movie' || x.media_type === 'tv') {
                                helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', 'partner_search');
                              } else if (x.watchlist_id && x.watchlist_id !== "") {
                                helpers.watchlistClick(x?.category_name || x?.watchlist, x?.watchlist_id, 'partner_search');
                              }
                            }} to={helpers.getDynamicLink({ ...x, uuid: x.id })}>
                              <Box
                                component="img"
                                className='d-block'
                                sx={{
                                  height: 270,
                                  width: 180,
                                  maxHeight: { xs: 200, sm: 270 },
                                  maxWidth: { xs: 140, sm: 180 },
                                  borderRadius: "7px",
                                  objectFit: 'cover',
                                  objectPosition: 'top'
                                }}
                                alt={x?.title || 'poster'}
                                src={x.poster_path ? helpers.getFullUrl(x.poster_path, 300) : helpers.getDefaultImage('trendingmedia')}
                              />
                            </Link>
                          </Grid>

                        </Grid>

                      </Grid>
                    </Grid>
                  );
                })
                  : !isLoading ? <p className="text-selected w-100 text-center mt-50 fs-30">No Results Found !!!</p> : ''
                }

                {isLoading ? (
                  <>
                    {loadingArray.map((x) => {
                      return (
                        <Grid item key={x} className="tile-container">
                          <Grid
                            sx={{
                              maxWidth: { xs: 140, sm: 180 },
                              paddingTop: "20px",
                              paddingBottom: "20px",
                              display: "inline-block",
                            }}
                          >
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              sx={{
                                height: 270,
                                width: 180,
                                maxHeight: { xs: 200, sm: 270 },
                                maxWidth: { xs: 140, sm: 180 },
                                borderRadius: "7px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </Grid>
              {!isError ?
                <Grid container justifyContent={'center'}>
                  <Button
                    style={{ marginBottom: "10px" }}
                    onClick={() => loadMore()}
                    disabled={isError}
                    className="text-light bg-selected rounded-btn mt-10 mr-10 mw-100"
                  >
                    load more
                  </Button>
                </Grid>
                : ''}
            </div>

            {/* <div className="pagination-container">
              <Grid
                container
                justifyContent={"space-between"}
                style={{ alignItems: "center" }}
              >
                <Grid item>
                  <Grid container style={{ alignItems: "center" }}>
                    <div>
                      <p>
                        showing results from {startIndex + 1} - {endIndex} out
                        of {pagination?.total_entries}
                      </p>
                    </div>
                    <div>
                      <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                        <InputLabel id="demo-select-small-label">
                          data per page
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={pageLimit}
                          label="data per page"
                          onChange={handlePageLimitChange}
                        >
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                          <MenuItem value={500}>500</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>

                <Pagination
                  count={pagination?.total_pages || 10}
                  page={page}
                  // siblingCount={2}
                  onChange={(e, val) => setPage(val)}
                  variant="outlined"
                  color="secondary"
                  shape="rounded"
                  size={detectDevice()}
                />
              </Grid>
            </div> */}

            {/* advanced search */}
            <AdvancedSearch
              open={open}
              searchData={searchData}
              isLoading={isLoading}
              input={input}
              handleClose={handleClose}
              handleSeacrh={handleSeacrh}
              handleKeyUp={handleKeyUp}
              setInput={setInput}
              handleApply={handleApply}
            />
          </div>
        </div>
      </Grid>
    </>
  );
}

export default PartnerSearchResults;
