import Grid from "@mui/material/Grid";
import Box from "@mui/system/Box";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonMenu from "../common/CommonMenu";
import AddYourBirthday from "./profileComponent/AddYourBirthday";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PrivateAccount from "./PrivateAccount";
import { Link, useParams } from "react-router-dom";
import {
  clearMessages,
  ClearProfileData,
  getFrenziUserAllReviews,
  getOtherUserDetails,
  getUserRecommendedData,
  getUserWatchlist,
} from "../../slices/settings";
import Loader from "../common/Loader";
import helpers from "../../services/helpers";
import MyProfile from "./profileComponent/MyProfile";
import UserProfile from "./profileComponent/UserProfile";
import CommonMediumTiles from "../common/CommonMediumTiles";
import MyReview from "./profileComponent/MyReview";
import MyWatchlist from "./profileComponent/MyWatchlist";
import MyTitles from "./profileComponent/MyTitles";
import TextReviewTile from "../review/includes/TextReviewTile";
import { getCinescoreData } from "../../slices/cinescore";
import { clearMessages as clearMessagesCast } from "../../slices/cast";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import ClipsSlider from "../common/ClipsSlider";
import { getClipsCustomData } from "../../slices/clips";
import MyHelmet from "../common/MyHelmet";
import MyAds from "../adsense/MyAds";

const breakpoints = {
  319: {
    slidesPerView: 1.5,
    spaceBetween: 15,
  },
  425: {
    slidesPerView: 2,
    spaceBetween: 15,
  },
  640: {
    slidesPerView: 2.2,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 2.5,
    spaceBetween: 20,
    slidesPerGroup: 1,
  },
  1024: {
    slidesPerView: 2.7,
    spaceBetween: 20,
    slidesPerGroup: 1.5,
  },
  1440: {
    slidesPerView: 3,
    spaceBetween: 20,
    slidesPerGroup: 2,
  },
  1880: {
    slidesPerView: 4,
    spaceBetween: 30,
    slidesPerGroup: 3,
  },
  2560: {
    slidesPerView: 5,
    spaceBetween: 30,
    slidesPerGroup: 3,
  },
};

const menuList = [
  {
    link: "profile",
    label: "profile info",
  },
  {
    link: "clips",
    label: "clips",
  },
  {
    link: "reviews",
    label: "reviews",
  },
  {
    link: "watchlist",
    label: "watchlist",
  },
];

export default function Profile() {
  const [isOwner, setIsOwner] = useState(true);
  const [tabValue, setTabValue] = useState("profile");
  const [hideProfile, setHideProfile] = useState(false);
  const [currentLoading, setCurrentLoading] = useState(false);

  const [clipsData, setClipsData] = useState([]);
  const [clipsPagination, setClipsPagination] = useState({});
  const [isClipLoading, setIsClipLoading] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const cast = useSelector((state) => state.cast);
  const cinescore = useSelector((state) => state.cinescore);
  const {
    profileUserDetails,
    userWatchlistData,
    userReviewData,
    recommendedData,
  } = settings;

  useEffect(() => {
    setCurrentLoading(true);
    setHideProfile(false);
    getData();
    window.scrollTo(0, 0);
  }, [params]);

  useEffect(() => {
    if (cast.errMesg && cast.errMesg !== "") {
      dispatch(clearMessagesCast());
    }
    if (cast.successMesg && cast.successMesg !== "") {
      refreshData();
      dispatch(clearMessagesCast());
    }
  }, [cast]);

  useEffect(() => {
    if (settings.errMesg && settings.errMesg !== "") {
      helpers.toastError(settings.errMesg);
      if (
        settings.errMesg === "blocked by user" ||
        settings.errMesg === "private profile" ||
        settings.errMesg === "You are not allowed for this action"
      ) {
        setHideProfile(true);
      }
      dispatch(clearMessages());
    }
    if (settings.successMesg && settings.successMesg !== "") {
      helpers.toastSuccess(settings.successMesg);
      refreshData();
      dispatch(clearMessages());
    }
  }, [settings, params]);

  const refreshData = () => {
    let currentUser = localStorage.getItem("frenzi_user_access");
    var currentUserData = JSON.parse(currentUser);
    if (
      params &&
      params.id &&
      params.id !== "" &&
      params.id !== currentUserData.uuid
    ) {
      setIsOwner(false);
      dispatch(getOtherUserDetails({ id: params.id }));
    } else {
      let currentUser = localStorage.getItem("frenzi_user_access");
      var currentUserData = JSON.parse(currentUser);
      if (currentUserData && currentUserData.uuid) {
        setIsOwner(true);
        // dispatch(getUserDetails());
        dispatch(getOtherUserDetails());
      }
    }
  };

  const getData = () => {
    let currentUser = localStorage.getItem("frenzi_user_access");
    var currentUserData = JSON.parse(currentUser);
    if (
      params &&
      params.id &&
      params.id !== "" &&
      params.id !== currentUserData.uuid
    ) {
      setIsOwner(false);
      dispatch(ClearProfileData());
      dispatch(
        getUserRecommendedData({ category: "total_likes", user: params.id })
      );
      dispatch(getOtherUserDetails({ id: params.id })).then((res) => {
        const { username, bio } = res.payload.data;
        helpers.trackMoengageEvent("FR3_ProSettings", {
          full_name: username,
          bio,
        });
        setTimeout(() => {
          setCurrentLoading(false);
        }, 500);
      });
      dispatch(
        getFrenziUserAllReviews({
          comment_type: "",
          user: params.id,
          page: 1,
          pagelimit: 20,
        })
      );
      dispatch(
        getUserWatchlist({
          privacy: "",
          sort: "",
          user: params.id,
          page: 1,
          pagelimit: 20,
        })
      );
    } else {
      if (!profileUserDetails?.uuid || profileUserDetails.uuid !== "") {
        let currentUser = localStorage.getItem("frenzi_user_access");
        var currentUserData = JSON.parse(currentUser);
        if (currentUserData && currentUserData.uuid) {
          setIsOwner(true);
          getClipData(1, currentUserData.uuid);
          // dispatch(getUserDetails());
          dispatch(getOtherUserDetails()).then((res) => {
            const { username, bio } = res.payload.data;
            helpers.trackMoengageEvent("FR3_ProSettings", {
              full_name: username,
              bio,
            });
            setTimeout(() => {
              setCurrentLoading(false);
            }, 500);
          });
          dispatch(getUserRecommendedData({ category: "total_likes" }));
          dispatch(getCinescoreData());
        }
      }
    }
  };

  const getClipsUserData = (page, id) => {
    setIsClipLoading(true);
    if (!isClipLoading) {
      const body = {
        id: id,
        page: page,
        section: "myself",
        include_viewed_clips: 1,
        pagelimit: 20,
      };
      dispatch(getClipsCustomData(body)).then((resp) => {
        if (resp.payload && resp.payload.data) {
          const { pagination, results } = resp.payload.data;
          setClipsPagination(pagination);
          setClipsData(helpers.removeDuplicate([...clipsData, ...results]));
          // setClipsData(helpers.removeDuplicate(results));
          setIsClipLoading(false);
        }
      });
    }
  };

  const getClipData = (page, id = "") => {
    if (id !== "") {
      getClipsUserData(page, id);
    } else {
      let currentUser = localStorage.getItem("frenzi_user_access");
      var currentUserData = JSON.parse(currentUser);
      getClipsUserData(page, currentUserData.uuid);
    }
  };


  return (
    <>
      <MyHelmet
        title={"Visit your profile"}
        description={"Personalize Your Experience, Explore Your Profile and Customize Settings for a Smooth OTT Journey!"}
      />
      <div className={`${!hideProfile ? "pb-50" : "h-100"} new-container mt-80`}>
        {currentLoading && <Loader />}
        <>
          {isOwner && (
            <CommonMenu
              setTabValeu={(val) => setTabValue(val)}
              menuList={menuList}
              tabValue={tabValue}
              justify={"flex-end"}
            />
          )}
          <Grid container className="app-container">
            {isOwner ? (
              <MyProfile
                cinescoreData={cinescore?.cinescoreResults}
                profileUserDetails={profileUserDetails}
              />
            ) : (
              <UserProfile
                hideProfile={hideProfile}
                profileUserDetails={profileUserDetails}
              />
            )}
          </Grid>

          {hideProfile ? (
            <PrivateAccount
              open={hideProfile}
              item={profileUserDetails}
              userId={params.id}
            />
          ) : (
            <>
              {tabValue === "profile" && (
                <>
                  {isOwner ? (
                    <>
                      <AddYourBirthday />

                      <Grid
                        container
                        justifyContent={"center"}
                        className="app-section"
                        style={{ marginTop: "20px" }}
                      >
                        <MyAds type="banner_2" adv_type={"web_profile_fold_ad"} />
                      </Grid>

                      {clipsData.length > 0 && (
                        <div className="section-border-bottom pt-20 pb-30">
                          <Grid
                            xs={12}
                            className="app-container list-clip-player"
                          >
                            {/* <ClipsSlider
                              isOwner={isOwner}
                              heading={"my clips"}
                              clipsData={helpers.removeDuplicate([...clipsData])}
                              getClipData={() => {
                                if (clipsPagination.is_next_page) {
                                  getClipData(clipsPagination.page + 1);
                                }
                              }}
                            /> */}
                          </Grid>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {userReviewData && userReviewData.length > 0 && (
                        <Grid item className="app-container mt-20">
                          <Grid
                            container
                            justifyContent={"center"}
                            className="app-section"
                            style={{ marginTop: "20px" }}
                          >
                            <MyAds type="banner_2" adv_type={"web_profile_fold_ad"} />
                          </Grid>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <p className="fw-700 fs-28 mt-8 mb-10">
                              {profileUserDetails?.username}'s reviews
                            </p>
                            <ArrowForwardIcon
                              className="pointer"
                              onClick={() => setTabValue("reviews")}
                            />
                          </Grid>
                          <Grid xs={12}>
                            <Swiper
                              slidesPerView={1}
                              spaceBetween={20}
                              // slidesPerGroup={5}
                              freeMode={true}
                              speed={1000}
                              navigation={true}
                              breakpoints={breakpoints}
                              modules={[FreeMode, Navigation]}
                              className="mySwiper"
                            >
                              {userReviewData &&
                                userReviewData.length > 0 &&
                                userReviewData.map((x, i) => {
                                  return (
                                    <SwiperSlide key={i}>
                                      <div key={i}>
                                        {x.comment_type === "text" && (
                                          <TextReviewTile x={x} />
                                        )}
                                      </div>
                                    </SwiperSlide>
                                  );
                                })}
                            </Swiper>
                          </Grid>
                        </Grid>
                      )}

                      {userWatchlistData && userWatchlistData.length > 0 && (
                        <Grid item className="app-container mt-20">
                          <Grid
                            container
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <p className="fw-700 fs-28 mb-10 mt-10">
                              {profileUserDetails?.username}'s watchlists
                            </p>
                            <ArrowForwardIcon
                              className="pointer"
                              onClick={() => setTabValue("watchlist")}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Swiper
                              slidesPerView={1}
                              spaceBetween={20}
                              // slidesPerGroup={5}
                              freeMode={true}
                              speed={1000}
                              navigation={true}
                              breakpoints={breakpoints}
                              modules={[FreeMode, Navigation]}
                              className="mySwiper"
                            >
                              {userWatchlistData.map((x, i) => {
                                return (
                                  <SwiperSlide key={i}>
                                    <div className={" pointer"} key={i}>
                                      <div>
                                        <Link
                                          onClick={() =>
                                            helpers.watchlistClick(
                                              x?.category_name,
                                              x?.uuid,
                                              "Profile"
                                            )
                                          }
                                          to={`/watchlist-detail/${x.uuid}`}
                                        >
                                          <Box
                                            component="img"
                                            className="d-block"
                                            sx={{
                                              height: "100%",
                                              width: "100%",
                                              maxHeight: 190,
                                              borderTopLeftRadius: "8px",
                                              borderTopRightRadius: "8px",
                                            }}
                                            alt=""
                                            src={
                                              x.backdrop
                                                ? helpers.getFullUrl(x.backdrop)
                                                : helpers.getDefaultImage(
                                                  "watchlist"
                                                )
                                            }
                                          />
                                        </Link>
                                        <Box
                                          className="d-block"
                                          sx={{
                                            height: "100%",
                                            width: "100%",
                                            borderBottomLeftRadius: "8px",
                                            borderBottomRightRadius: "8px",
                                            backgroundColor: "#16213E",
                                          }}
                                        >
                                          <Grid
                                            container
                                            justifyContent={"space-between"}
                                            className="w-100 pl-16 pr-16 h-100"
                                            alignItems={"center"}
                                          >
                                            <Grid item xs={10}>
                                              <p
                                                title={x?.category_name}
                                                className="p-0 m-0 fs-16 fw-600 ellipsis"
                                              >
                                                {x?.category_name}
                                              </p>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              className={"text-right"}
                                            >
                                              <p className="p-0 m-0 fs-12 fw-400">
                                                {x?.total_titles} titles
                                              </p>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                );
                              })}
                            </Swiper>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                  {recommendedData && recommendedData.length > 0 && (
                    <Grid item className="app-container">
                      <CommonMediumTiles
                        data={recommendedData}
                        heading={`movies that ${!isOwner ? profileUserDetails?.username : "I've "
                          } liked`}
                        subheading=""
                        refPage={"Profile"}
                        isNextBtn={true}
                        handleNextBtn={() => setTabValue("mytitle")}
                      />
                    </Grid>
                  )}
                </>
              )}
              {tabValue === "reviews" && (
                <MyReview
                  userId={params.id}
                  isOwner={isOwner}
                  handleBack={() => setTabValue("profile")}
                />
              )}
              {tabValue === "clips" && (
                <>
                  <div className="section-border-bottom pt-20 pb-30">
                    <Grid xs={12} className="app-container list-clip-player">
                      <ClipsSlider
                        isOwner={isOwner}
                        heading={"my clips"}
                        clipsData={clipsData}
                        getClipData={() => {
                          if (clipsPagination.is_next_page) {
                            getClipData(clipsPagination.page + 1);
                          }
                        }}
                      />
                    </Grid>
                  </div>
                </>
              )}

              {tabValue === "watchlist" && (
                <MyWatchlist
                  userId={params.id}
                  isOwner={isOwner}
                  handleBack={() => setTabValue("profile")}
                />
              )}
              {tabValue === "mytitle" && (
                <MyTitles
                  userId={params.id}
                  isOwner={isOwner}
                  handleBack={() => setTabValue("profile")}
                />
              )}
            </>
          )}
        </>
      </div>
    </>
  );
}
