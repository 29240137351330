import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import '../trending.scss'
// import TopTen from '../TopTen';
import NewlyAdded from '../NewlyAdded';
import TrendingPlatform from '../TrendingPlatform';
import FrenziPopular from '../FrenziPopular';
import PreferLanguage from '../PreferLanguage';
import { useDispatch, useSelector } from 'react-redux';
import helpers from '../../../services/helpers';
import { clearMessages, getComingSoon, getFrenziWatchLists, getGenreMedia, getLanguageMedia, getNewlyAdded, getPlatformMedia, getPopularToday, getPublicHandpicked } from '../../../slices/trending';
import { getAllPreferences, getPlatformList } from '../../../slices/auth';
import { clearMessages as clearMessagesWatchlist } from '../../../slices/watchlist';
import PreferMood from '../PreferMood';
import YourWatchlist from '../YourWatchlist';
// import CommonMediumTileListing from '../../common/CommonMediumTileListing';
// import { CustomCarousel } from '../../component/custom3DCarousel/CustomCarousel';
import { clearMessages as clearMessagesMedia } from '../../../slices/media';
import MyAds from '../../adsense/MyAds';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HandpickSliderNew } from '../HandpickSliderNew';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import "swiper/css/effect-fade";
import MyHelmet from '../../common/MyHelmet';
import LinearLoader from '../../common/LinearLoader';
import HandpickDefault from '../HandpickDefault';
import { isMobileDevice } from '../../../services/Utils';

const page = 1;

function PublicFreeTrending() {
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [selectedPlatformOttName, setSelectedPlatformOttName] = useState("")
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedLanguageName, setSelectedLanguageName] = useState("")
    const [selectedGenre, setSelectedGenre] = useState('');
    const [selectedGenreName, setSelectedGenreName] = useState("")
    const [controlledSwiper, setControlledSwiper] = useState(null);
    const dispatch = useDispatch();
    const trending = useSelector(state => state.trending);
    const watchlist = useSelector(state => state.watchlist);
    const media = useSelector(state => state.media);
    const auth = useSelector(state => state.auth);
    const [currentLoading, setCurrentLoading] = useState(false);

    useEffect(() => {
        let isDone = false;
        setTimeout(() => {
            let y = localStorage.getItem("pffsy") || 0;
            window.scrollTo(0, y);
            isDone = true;
        }, 0)

        function handleScroll() {
            if (isDone) {
                const scrolledY = window.scrollY;
                localStorage.setItem("pffsy", scrolledY);
            }
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setCurrentLoading(true);
        dispatch(getPlatformList({ is_free: true, page: 1, pagelimit: 50, is_public: true }));
    }, [])

    useEffect(() => {
        if (trending.errMesg && trending.errMesg != "") {
            if (!trending.errMesg.includes('captcha')) {
                helpers.toastError(trending.errMesg);
            }
            dispatch(clearMessages());
        }
        if (trending.successMesg && trending.successMesg != "") {
            helpers.toastSuccess(trending.successMesg);
            dispatch(clearMessages());
        }
    }, [trending])

    useEffect(() => {
        if (media.errMesg && media.errMesg != "") {
            helpers.toastError(media.errMesg);
            dispatch(clearMessagesMedia());
        }
        if (media.successMesg && media.successMesg != "") {
            helpers.toastSuccess(media.successMesg);
            dispatch(clearMessagesMedia());
        }
    }, [media])

    useEffect(() => {
        if (watchlist.errMesg && watchlist.errMesg != "") {
            helpers.toastError(watchlist.errMesg);
            dispatch(clearMessagesWatchlist());
        }
        if (watchlist.successMesg && watchlist.successMesg != "") {
            helpers.toastSuccess(watchlist.successMesg);
            dispatch(clearMessagesWatchlist());
        }
    }, [watchlist])

    useEffect(() => {
        if (selectedPlatform && selectedPlatform != "") {
            var body = {
                page: page,
                pagelimit: 10,
                id: selectedPlatform,
                is_free: true,
                is_public: true
            }
            dispatch(getPlatformMedia(body));
        }
    }, [selectedPlatform])

    useEffect(() => {
        if (selectedLanguage && selectedLanguage != "") {
            var body = {
                page: page,
                pagelimit: 10,
                id: selectedLanguage,
                is_free: true,
                is_public: true
            }
            dispatch(getLanguageMedia(body));
        }
    }, [selectedLanguage])

    useEffect(() => {
        if (selectedGenre && selectedGenre != "") {
            var body = {
                page: page,
                pagelimit: 10,
                id: selectedGenre,
                is_free: true,
                is_public: true
            }
            dispatch(getGenreMedia(body));
        }
    }, [selectedGenre])

    useEffect(() => {
        var body = {
            page: page,
            pagelimit: 10,
            is_free: true,
            is_public: true
        }
        dispatch(getPublicHandpicked({ is_free: true })).then(() => {
            setCurrentLoading(false);
        });
        setTimeout(() => {
            dispatch(getNewlyAdded(body));
        }, 3000)

        dispatch(getAllPreferences({ is_public: true, is_free: true }));
        dispatch(getPopularToday(body));
        dispatch(getComingSoon(body));
        dispatch(getFrenziWatchLists(body));
    }, [])

    const handleStop = () => {
        controlledSwiper.autoplay.stop();
    }

    const handlePlay = () => {
        controlledSwiper.autoplay.start();
    }

    return (
        <>
            <MyHelmet
                title={"What's free to watch on your preferred platform"}
                description={"Discover Free Movies and Series on Your Favorite Platform, Find out What's Available to Watch on OTT without Spending a Dime!"}
            />
            {currentLoading && <LinearLoader />}
            <div className='app-section'>
                {trending?.handpickedPageData.length > 0 ?
                    <Grid xs={12}>
                        <Swiper
                            centeredSlides={true}
                            fadeEffect={{ crossFade: true }}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: true,
                            }}
                            // loop={true}
                            // speed={1500}
                            // navigation={true}
                            effect={"fade"}
                            modules={[Autoplay, Navigation, EffectFade, Pagination]}
                            className="new-swiper"
                            onSwiper={setControlledSwiper}
                            pagination={{
                                clickable: true,
                            }}
                        >
                            {trending.handpickedPageData.map(
                                (x, i) => {
                                    return (
                                        <SwiperSlide key={i}>
                                            <HandpickSliderNew
                                                handlePlay={handlePlay}
                                                handleStop={handleStop}
                                                data={x}
                                                isShowTag={false}
                                            // title={
                                            //   <p className="fs-28 m-0 shadow fw-700 lh-28">
                                            //     trending{" "}
                                            //     <span className="text-selected">free</span>{" "}
                                            //     titles
                                            //   </p>
                                            // }
                                            />
                                        </SwiperSlide>
                                    );
                                }
                            )}
                        </Swiper>
                    </Grid>
                    :
                    <HandpickDefault isPublic={true} />
                }
                <Grid container justifyContent={'center'}>
                    <MyAds type="banner_2" mobtype={"mobile_1"} slotId={'5721372290'} adv_type={'web_free_page_top_ad'} />
                </Grid>
            </div>

            <Grid container className="new-container mt-30">
                <Grid flex={1} className="left-container">
                    <Grid container className='app-container  w-100 pt-10 pb-40' justifyContent={'center'} >
                        <Grid item xs={12} className='app-section'>
                            {/* <TopTen
                        is_free={true}
                        isTopTen={true}
                    /> */}
                            {trending.freshPageData && trending.freshPageData.results && trending.freshPageData.results.length > 0 &&
                                <NewlyAdded
                                    data={trending.freshPageData.results}
                                    heading={<><span className='text-selected'>fresh & free</span> on OTT</>}
                                    subheading={"start building your wathclist"}
                                    is_free={true}
                                />
                            }

                            <TrendingPlatform
                                isShowHeading={true}
                                platforms={auth.allPreferences && auth.allPreferences.platforms ? auth.allPreferences.platforms : []}
                                setSelectedPlatform={setSelectedPlatform}
                                is_public={true}
                                selectedPlatform={selectedPlatform}
                                selectedPlatformOttName={selectedPlatformOttName}
                                setSelectedPlatformOttName={setSelectedPlatformOttName}
                                data={trending.platformMediaData ? trending.platformMediaData : []}
                                heading={<>trending free on <span className='text-selected'>{selectedPlatformOttName}</span></>}
                                subheading={'popular free content across platforms'}
                                is_free={true}
                                withAd={true}
                            />

                            {/* {trending.freshPageData && trending.freshPageData.results && trending.freshPageData.results.length > 0 &&
                        <NewlyAdded
                            data={trending.freshPageData.results}
                            heading={<><span className='text-selected'>fresh & free</span> on OTT</>}
                            subheading={"start building your wathclist"}
                            is_free={true}
                        />
                    } */}

                            <Grid container justifyContent={'center'}>
                                <MyAds type="banner_2" mobtype={"mobile_1"} slotId={'8155963946'} adv_type={'web_free_fold_ad'} />
                            </Grid>

                            {trending.popularTodayData && trending.popularTodayData.length > 0 &&
                                <FrenziPopular data={trending.popularTodayData}
                                    isShowHeading={true}
                                    adv_type={'web_popular_free_ad'}
                                    is_free={true}
                                    withAd={true}
                                    heading={
                                        <>
                                            <span className="text-selected">popular </span> &
                                            <span className="text-selected"> free </span>on frenzi
                                        </>
                                    }
                                />
                            }

                            {/* <CommonMediumTileListing
                                heading={<><span className='text-selected'>hidden</span> gems</>}
                                subheading="don't miss these!"
                                isShowHeading={true}
                                nextLink={`/hidden-gems-on-ott?is_free=true`}
                                data={trending.hiddenGemsData}
                                refPage={'Trending'}
                                withAd={true}

                            /> */}

                            {/* <CommonDetailTileListing
                        heading={<><span className='text-selected'>coming soon</span> on ott</>}
                        subheading='get notified on release'
                        isShowHeading={true}
                        nextLink='/upcoming-movies-and-series-on-ott'
                        data={trending.comingSoonData}
                        showNotifyButton={true}
                        refPage="Trending"
                    /> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='app-section'>
                        {trending.allWatchlistData && trending.allWatchlistData.length > 0 &&
                            <YourWatchlist data={trending.allWatchlistData} withAd={true} />
                        }
                    </Grid>

                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"8198258178"} height={100} adv_type={'web_free_fold_ad'} />
                    </Grid>

                    <Grid container className='app-container pt-20 pb-40' justifyContent={'center'} >
                        <Grid item xs={12} className='app-section'>
                            <PreferLanguage
                                languages={auth.allPreferences && auth.allPreferences.languages ? auth.allPreferences.languages : []}
                                setSelectedLanguage={setSelectedLanguage}
                                selectedLanguage={selectedLanguage}
                                selectedLanguageName={selectedLanguageName}
                                setSelectedLanguageName={setSelectedLanguageName}
                                data={trending.langMediaData ? trending.langMediaData : []}
                                heading={<>popular free titles in <span className='text-selected'>{selectedLanguageName}</span></>}
                                subheading={'get language specific content'}
                                is_free={true}
                                isPublic={true}
                                withAd={true}
                            />

                            <Grid container justifyContent={'center'}>
                                <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"2945931490"} height={100} adv_type={'web_free_fold_ad'} />
                            </Grid>

                            <PreferMood
                                genres={auth.allPreferences && auth.allPreferences.genres ? auth.allPreferences.genres : []}
                                setSelectedGenre={setSelectedGenre}
                                selectedGenre={selectedGenre}
                                selectedGenreName={selectedGenreName}
                                setSelectedGenreName={setSelectedGenreName}
                                data={trending.genreMediaData ? trending.genreMediaData : []}
                                heading={<>popular free titles in <span className='text-selected'>{selectedGenreName}</span></>}
                                subheading={'let your mood decide'}
                                is_free={true}
                                isPublic={true}
                                withAd={true}
                            />
                            {/* {trending.collectionMetaData && trending.collectionMetaData.length > 0 && trending.collectionMetaData.map((x, i) => {
                        return <div key={i}>
                            <CollectionMedia data={x} is_free={true} />
                        </div>
                    })} */}
                            <Grid container justifyContent={'center'}>
                                <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"4133275680"} width={300} height={250} adv_type={'web_free_page_bottom_ad'} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!isMobileDevice() ?
                    <Grid className="right-container">
                        <Grid className="right-container-ad">
                            <MyAds type="banner_1" adv_type={'web_trending_top_ad'} />
                        </Grid>
                    </Grid>
                    : ''}
            </Grid>
        </>
    )
}

export default PublicFreeTrending