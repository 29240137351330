import { Modal } from '@mui/material'
import { Box } from '@mui/system';
import React, { useState } from 'react'
import ReactPlayer from 'react-player';
import helpers from '../../services/helpers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '75%',
    height: '75%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'transparent',
    boxShadow: 24,
};

export default function YoutubeModalPlayer(props) {
    const { ytkey } = props;
    const [open, setOpen] = useState(false);


    return (
        <div>
            <Box
                onClick={() => setOpen(true)}
                component="img"
                className='d-block pointer'
                sx={{
                    width: '100%',
                    maxHeight: '280px',
                    borderRadius: '8px'
                }}
                alt='thumbnail'
                // src={helpers.getYtThumbnailUrl(ytkey)}
            />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='bg-secondary jc-center' sm={4} xs={12}>
                    <ReactPlayer
                        url={helpers.getYtVideolUrl(ytkey)}
                        style={{ width: "100%", height: "100%" }}
                        controls={true}
                        playing={open}
                        width="100%"
                        height="100%"
                    />
                </Box>

            </Modal>
        </div>
    )
}
