import { Grid, Skeleton } from '@mui/material'
import React from 'react'

export default function SingleMediaTileLoader(props) {
    const { size } = props;


    return (
        <>
            {size === "sm" ? (
                <Grid container flexWrap={"nowrap"} className="mb-20">
                </Grid>
            ) :
                (
                    <Grid container flexWrap={"nowrap"} sx={{ borderRadius: '15px' }} className="h-100 bg-secondary p-20">
                        <Grid
                            item
                            className="scale-1 pointer"
                            xs={6}
                            sx={{ maxWidth: { xs: 120, sm: 149 }, marginRight: "20px", }}
                        >
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                sx={{
                                    height: 215,
                                    width: "100%",
                                    maxHeight: { xs: 180, sm: 215 },
                                    maxWidth: { xs: 120, sm: 149 },
                                    minWidth: { xs: 120, sm: 149 },
                                    borderRadius: "4px",
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                sx={{
                                    height: 20,
                                    width: "85%",
                                }}
                            />
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                className='mt-10'
                                sx={{
                                    height: 32,
                                    width: "65%",
                                }}
                            />
                            <Skeleton
                                animation="wave"
                                className='mt-10'
                                variant="rectangular"
                                sx={{
                                    height: 20,
                                    width: "95%",
                                }}
                            />
                        </Grid>

                    </Grid>
                )
            }
        </>
    )
}
