import React, { useState } from 'react'
import helpers from '../../services/helpers';
import BookmarkAction from '../watchlist/BookmarkAction';
import { Link } from 'react-router-dom';
import RateMovie, { ratingEmoji, ratingText } from '../common/RateMovie';
import ReveiwMovie from '../common/ReveiwMovie';
import { Box } from '@mui/system';
// import GrayScaleEmoji from "../../assets/icons/GrayScaleEmoji.png";
import parse from 'html-react-parser';

const GrayScaleEmoji = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/GrayScaleEmoji.png";
const showFull = true;
const size = 'sm';

export default function CinescoreCard(props) {
    const { data } = props;
    const [addedId, setAddeddId] = useState("");
    const [ratingValue, setRatingValue] = useState(0);

    return (
        <div className='bg-secondary' style={{ borderRadius: '7px', padding: '15px' }}>
            <div className='row'>
                <div style={{ paddingRight: '15px' }}>
                    <Link
                        onClick={() =>
                            helpers.mediaClick(
                                data?.title,
                                data.media ? data.media : data.uuid ? data.uuid : "",
                                props?.refPage
                            )
                        }
                        to={helpers.getMediaLink(data)}
                    >
                        <img src={data.poster && data.poster !== ""
                            ? helpers.getFullUrl(data?.poster)
                            : helpers.getDefaultImage("poster")}

                            alt={data?.title}

                            className=""
                            style={{
                                maxWidth: '115px',
                                // maxHeight: '150px',
                                borderRadius: '3px',
                                width: '100%',
                                // height: '100%',
                            }}
                        />
                    </Link>
                </div>
                <div style={{ flex: '1' }}>
                    <div className='h-100' style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <div>
                            <p className='m-0 fs-18'>{data?.title}</p>
                            <div className='text-muted'>
                                {data.genres && data.genres.length > 0 ?
                                    <>
                                        {data.genres && data.genres.length > 0 ?
                                            <>
                                                {data.genres.length > 2 && !showFull ?
                                                    <span key={'oppp'} className={`${size === 'sm' ? 'fs-14' : 'fs-18'} fw-400 text-light m-0`}>{data.genres[0]?.name} +{data.genres.length - 1}</span>
                                                    : data.genres.map((x, i) => {
                                                        return <span key={i} className={`${size === 'sm' ? 'fs-14' : 'fs-18'} fw-400 text-light m-0`}>{x.name}{i < (data.genres.length - 1) ? parse("&nbsp;|&nbsp;") : ''}</span>
                                                    })
                                                }

                                            </>
                                            :
                                            ''
                                        }
                                        {(data.release_year) || (data.language && data.language !== "") ?
                                            <span className="ott-dot"></span>
                                            :
                                            ''
                                        }
                                    </>
                                    :
                                    ''
                                }
                            </div>
                        </div>

                        <div className='text-center'>
                            {data?.is_rated || ratingValue ?
                                <>
                                    <Box
                                        alt={ratingValue}
                                        component="img"
                                        sx={{
                                            height: "100%",
                                            width: "100%",
                                            maxHeight: { xs: 42, sm: 48 },
                                            maxWidth: { xs: 42, sm: 48 },
                                            verticalAlign: 'bottom'
                                        }}
                                        src={
                                            ratingEmoji.filter(
                                                (item) => item.valeu === parseInt(ratingValue) / 2
                                            ) &&
                                                ratingEmoji.filter(
                                                    (item) => item.valeu === parseInt(ratingValue) / 2
                                                )[0]
                                                ? ratingEmoji.filter(
                                                    (item) => item.valeu === parseInt(ratingValue) / 2
                                                )[0]?.emoji
                                                : ""
                                        }
                                    />
                                    <p className="fw-400 fs-14 p-0" style={{ marginTop: '-7px' }}>
                                        {ratingValue <= 2 && ratingText[0]}
                                        {ratingValue > 2 && ratingValue <= 4 && ratingText[1]}
                                        {ratingValue > 4 && ratingValue <= 6 && ratingText[2]}
                                        {ratingValue > 6 && ratingValue <= 8 && ratingText[3]}
                                        {ratingValue > 8 && ratingText[4]}
                                    </p>
                                </>
                                :
                                <>
                                    <Box
                                        alt={data?.user_rating}
                                        component="img"
                                        sx={{
                                            height: "100%",
                                            width: "100%",
                                            maxHeight: { xs: 42, sm: 48 },
                                            maxWidth: { xs: 42, sm: 48 },
                                            verticalAlign: 'bottom'
                                        }}
                                        src={GrayScaleEmoji}
                                    />
                                    <p className="fw-400 fs-14 text-muted p-0" style={{ marginTop: '-7px' }}>Rate this title to earn Points</p>
                                </>
                            }
                        </div>

                        <div>
                            <div className='row' style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>
                                    <RateMovie
                                        data={{ ...data, is_rated: ratingValue > 0 ? ratingValue : data?.user_rating }}
                                        type={'btn'}
                                        onChange={(val) => setRatingValue(val)}
                                        hideLabel={true}
                                        refPage={"Media Detail"} />
                                </div>
                                <ReveiwMovie data={data} />
                                <BookmarkAction
                                    sx={{ position: 'relative', right: '0px', top: '0px' }}
                                    data={data}
                                    addedId={addedId}
                                    setAddedId={setAddeddId}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
