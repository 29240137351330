import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'

import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
} from "react-share";

import {
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
// import { useDispatch } from 'react-redux';
// import { getReferalLink } from '../../slices/common';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import helpers from '../../services/helpers';

// const link = "https://redirect.myfrenzi.com/";
// media/f2dbe143-a965-4fce-af1e-988c3dc4fb28/movie
// const suffix = "https://redirect.myfrenzi.com";

export default function InviteWith(props) {
    const [referelLink, setReferelLink] = useState('');
    // const dispatch = useDispatch();

    useEffect(() => {
        createDynamicLink();
    }, [])

    const getUserId = async () => {
        var data = localStorage.getItem("frenzi_user_access");
        var token = data && JSON.parse(data).uuid;
        return token;
    }

    const createDynamicLink = async () => {
        let uid = await getUserId();
        setReferelLink(`${window.location.host}/signup?ref=${uid}`);
        // dispatch(getReferalLink()).then((resp) => {
        //     if (resp.payload && resp.payload.data) {
        //         if (resp.payload.data.shortLink) {
        //             setReferelLink(resp.payload.data.shortLink);
        //         } else {
        //             setReferelLink(window.location.href);
        //         }
        //     } else {
        //         setReferelLink(window.location.href);
        //     }
        // });
    }

    // const createDynamicLink_old = (path) => {
    //     const baseUrl = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" + process.env.REACT_APP_FIREBASE_API_KEY;

    //     const body = {
    //         "dynamicLinkInfo": {
    //             "domainUriPrefix": suffix,
    //             "link": link + path,
    //             "androidInfo": {
    //                 "androidPackageName": "com.frenzee.app"
    //             },
    //             "iosInfo": {
    //                 "iosBundleId": "com.frenzee.app"
    //             }
    //         }
    //     }

    //     if (body && baseUrl !== '') {
    //         var config = {
    //             method: 'post',
    //             url: baseUrl,
    //             data: body
    //         };
    //         axios(config)
    //             .then(function (response) {
    //                 if (response.data && response.data.shortLink) {
    //                     setReferelLink(response.data.shortLink);
    //                 } else {
    //                     setReferelLink(window.location.href);
    //                 }
    //             })
    //             .catch(function (error) {
    //                 setReferelLink(window.location.href);
    //                 console.log(error);
    //             });
    //     }
    // }

    return (
        <Grid container spacing={2} alignItems='center'>
            {referelLink && referelLink !== '' && <>
                <Grid item >
                    <FacebookShareButton style={{ textAlign: 'center' }} url={referelLink} >
                        <FacebookIcon size={32} round={true} />
                        {/* <FacebookShareCount url={referelLink} /> */}
                        <p className="p-0 m-0 fs-14 lh-14">facebook</p>
                    </FacebookShareButton>
                </Grid>
                <Grid item>
                    <WhatsappShareButton style={{ textAlign: 'center' }} url={referelLink} >
                        <WhatsappIcon size={32} round={true} />
                        <p className="p-0 m-0 fs-14 lh-14">whatsapp</p>
                    </WhatsappShareButton>
                </Grid>
                <Grid item>
                    <TwitterShareButton style={{ textAlign: 'center' }} url={referelLink} >
                        <TwitterIcon size={32} round={true} />
                        <p className="p-0 m-0 fs-14 lh-14">twitter</p>
                    </TwitterShareButton>
                </Grid>
                <Grid item>
                    <CopyToClipboard text={referelLink}
                        onCopy={() => helpers.toastSuccess('referral link copied')}>
                        <div>
                            <ContentCopyRoundedIcon className="copy-share-icon pointer" />
                            <p className="p-0 m-0 fs-14 text-center lh-14">copy</p>
                        </div>
                    </CopyToClipboard>
                </Grid>
            </>}
        </Grid>
    )
}
