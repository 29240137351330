import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SingleMediaTile from '../../common/SingleMediaTile';
import Loader from '../../common/Loader';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import SideFilter from '../../search/SideFilter';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Box } from '@mui/system';
import { getHiddenGemsMovie, getHiddenGemsTv } from '../../../slices/trending';
import MyHelmet from '../../common/MyHelmet';

const defaultFilter = {
    service: [],
    genre: [],
    language: [],
    content_type: [],
    exclude_already_watched: '',
    sort: '',
    apply_filter: false
}

export default function PrefergenreDetail() {
    const [tempFilter, setTempFilter] = useState(defaultFilter);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const is_free = searchParams.get('is_free') && searchParams.get('is_free') == 'true' ? true : false;

    const trending = useSelector(state => state.trending);
    const { hiddenGemsTvPageData, hiddenGemsTvData, hiddenGemsMoviePageData, hiddenGemsMovieData, hiddenGemsSearchPageData, hiddenGemsSearchData, isLoading } = trending;

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    const handleClickListItem2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleSort = (val) => {
        setSort(val);
        if (page == 1) {
            getData(val, tempFilter);
        } else {
            setPage(1);
        }
        handleClose2();
    }

    useEffect(() => {
        getData(sort, tempFilter);
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        getData(sort, tempFilter);
    }, [page])

    const getData = (sort, filter = tempFilter) => {

        var body = {
            page: page,
            pagelimit: 20,
            // id: mid,
            sort: sort && sort != '' ? sort : params.type == 'search' ? 's' : '',
            body: filter,
            is_free: is_free
        }

        if (params && params.type && params.type != '') {
            if (params.type == 'movie') {
                dispatch(getHiddenGemsMovie({ ...body, media_type: 'movie' }));
            }
            if (params.type == 'tv') {
                dispatch(getHiddenGemsTv({ ...body, media_type: 'tv' }));
            }
            if (params.type == 'search') {
                dispatch(getHiddenGemsTv({ ...body, media_type: 'all' }));
            }
        }
    }

    useEffect(() => {
        const currentPageData = params.type == 'movie' ? hiddenGemsMoviePageData : params.type == 'tv' ? hiddenGemsTvPageData : params.type == 'search' ? hiddenGemsSearchPageData : null;
        const handleScroll = () => {
            if (currentPageData && currentPageData && currentPageData.pagination && currentPageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, trending]);

    const handleApplyFilter = (filter) => {
        if (filter) {
            setTempFilter({ ...filter });
            if (params && params.type && params.type != '') {
                if (page == 1) {
                    getData(sort, filter);
                } else {
                    setPage(1);
                }
            }
            window.scrollTo(0, 0);
        }
    }

    const getPtype = (p) => {
        let res = '';
        res = p.type === "tv" ? 'Web Series' : 'Movies';
        return res;
    };

    const pType = useMemo(() => getPtype(params), [params]);

    return (
        <>
            <MyHelmet
                title={`Hidden Gems ${pType} You Should Not Miss`}
                description={`Uncover hidden gems ${pType} among free content across OTT platforms. Discover underrated movies and series for free with MyFrenzi.`}
                og_title={`Hidden Gems: Free ${pType} on OTT you should watch`}
                keywords={`hidden gems,free ${pType}, free content, OTT platforms, MyFrenzi`}
            />

            {isLoading && <Loader />}
            <Grid className='new-container'>
                <Grid container alignItems={'center'} className="pt-20" justifyContent='space-between'>
                    <Grid item>
                        <Grid container alignItems={'center'}>
                            <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                            <p className='fs-28 fw-700 lh-28 m-0'><span className='text-selected'>hidden</span> gems</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className='pt-10 pb-20' alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item xs={10}>
                        <Grid container alignItems={'center'}>
                            <Grid item className=''>
                                <Grid container onClick={handleClickListItem2} alignItems={'center'} className="pointer">
                                    <p className='fw-700 fs-15 m-0 mr-6 '>
                                        sort
                                    </p>
                                    <SwapVertIcon />
                                </Grid>
                                <Popover
                                    id={'menu2'}
                                    open={open2}
                                    anchorEl={anchorEl2}
                                    onClose={handleClose2}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Box className='pb-20 pl-20 pr-20'>
                                        <Grid onClick={() => handleSort('d')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                            <p className={`fw-500 fs-16 m-0 ${sort === 'd' && 'text-selected'}`} >release date</p>
                                        </Grid>
                                        <Grid onClick={() => handleSort('l')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                            <p className={`fw-500 fs-16 m-0 ${sort === 'l' && 'text-selected'}`} >popular</p>
                                        </Grid>
                                        <Grid onClick={() => handleSort('r')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                            <p className={`fw-500 fs-16 m-0 ${sort === 'r' && 'text-selected'}`} >rating</p>
                                        </Grid>
                                    </Box>
                                </Popover>
                            </Grid>
                        </Grid>
                    </Grid>

                    <SideFilter
                        onSearchApply={(e) => handleApplyFilter(e)}
                        hideClearBtn={true}
                    />
                </Grid>
                {!isLoading &&
                    <>
                        {params.type === 'movie' && hiddenGemsMovieData && hiddenGemsMovieData.length > 0 &&
                            <Grid container spacing={2}>
                                {hiddenGemsMovieData.map((x, i) => {
                                    return <Grid item key={i} xs={12} sm={6} md={4} >
                                        <SingleMediaTile refPage={'Hidden Gems'} x={x} />
                                    </Grid>
                                })}
                            </Grid>
                        }
                        {params.type === 'tv' && hiddenGemsTvData && hiddenGemsTvData.length > 0 &&
                            <Grid container spacing={2}>
                                {hiddenGemsTvData.map((x, i) => {
                                    return <Grid item key={i} xs={12} sm={6} md={4} >
                                        <SingleMediaTile refPage={'Hidden Gems'} x={x} />
                                    </Grid>
                                })}
                            </Grid>
                        }
                        {params.type === 'search' && hiddenGemsSearchData && hiddenGemsSearchData.length > 0 &&
                            <Grid container spacing={2}>
                                {hiddenGemsSearchData.map((x, i) => {
                                    return <Grid item key={i} xs={12} sm={6} md={4} >
                                        <SingleMediaTile refPage={'Hidden Gems'} x={x} />
                                    </Grid>
                                })}
                            </Grid>
                        }
                    </>
                }
            </Grid>
        </>
    )
}

