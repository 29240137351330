import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTrendingInFriends } from '../../../slices/trending';
import CommonMenu from '../../common/CommonMenu';
import ContentAccordion from '../../common/ContentAccordion';
import Loader from '../../common/Loader';
import MyHelmet from '../../common/MyHelmet';
import SingleMediaTile from '../../common/SingleMediaTile';
// import TopTen from '../TopTen';

const menuList = [
    {
        link: 'all',
        label: 'all'
    },
    {
        link: 'movie',
        label: 'movies'
    },
    {
        link: 'tv',
        label: 'web series'
    }
]

export default function PopularFriends() {
    const [tabValue, setTabValue] = useState('all');
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const trending = useSelector(state => state.trending);

    useEffect(() => {
        window.scrollTo(0, 0);
        getData(1);
    }, [tabValue])

    useEffect(() => {
        getData(page);
    }, [page])

    const getData = (p) => {
        var body = {
            page: p,
            pagelimit: 10,
            is_free: false,
            media_type: tabValue
        }
        dispatch(getTrendingInFriends(body));
    }

    useEffect(() => {
        const handleScroll = () => {
            if (trending.trendingInFriendsPageData && trending.trendingInFriendsPageData && trending.trendingInFriendsPageData.pagination && trending.trendingInFriendsPageData.pagination.is_next_page && !trending.isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, trending]);

    return (
        <Grid className='new-container pb-20'>
            <MyHelmet
                title={"popular by friends"}
                description={'Explore "Popular by Friends" for recommendations and curated content trusted by your friends, bringing you the latest trends and hidden gems.'}
            />
            {trending.isLoading && <Loader />}
            <div className="new-container">
                <ContentAccordion
                    isFull={true}
                    title={'POPULAR_FR_H'}
                    data={'POPULAR_FR'}
                />
            </div>
            <CommonMenu
                setTabValeu={(val) => setTabValue(val)}
                menuList={menuList}
                tabValue={tabValue}
                isBack={true}
                heading={<h2 className='fs-28 fw-700 lh-28 m-0'>popular among <span className='text-selected'>friends</span></h2>}
            />
            <Grid className='app-container'>
                {trending.trendingInFriendsData && trending.trendingInFriendsData && trending.trendingInFriendsData.length > 0 &&
                    <Grid container spacing={2}>
                        {trending.trendingInFriendsData.map((x, i) => {
                            return <Grid item xs={12} sm={6} md={6} lg={4} key={i} style={{ paddingRight: '10px' }}>
                                <SingleMediaTile refPage={'Popular In Friends'} x={x} />
                            </Grid>
                        })}
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}
