import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FrenziPartnerDataService from "../services/frenzi-partner.services";
import helpers from "../services/helpers";
import { DEFAULT_ADV_FILTER } from "../views/partner/partnerHelper";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    searchData: {},
    advFilters: DEFAULT_ADV_FILTER
};

export const getSearchData = createAsyncThunk(
    "native_main/topsearches_test",
    async (data, { rejectWithValue }) => {
        try {
            const response = await FrenziPartnerDataService.getSearchData(data)
            return { ...response.data, p: data?.page };
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getCastData = createAsyncThunk(
    "native_main/topsearches_test",
    async (data, { rejectWithValue }) => {
        try {
            const response = await FrenziPartnerDataService.getCastData(data?.query, data?.limit, data?.page)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const frenziPartnerSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        },
        handleFilters: (state, action) => {
            state.advFilters = action.payload
        },
        resetPartnerData: (state) => {
            state.searchData = {}
        }
    },
    extraReducers: {
        // getSearchData
        [getSearchData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getSearchData.fulfilled]: (state, action) => {
            state.searchData = getPayloadData(state, action);
        },
        [getSearchData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        }
    }
})

export const { clearMessages, handleFilters, resetPartnerData } = frenziPartnerSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default frenziPartnerSlice.reducer;