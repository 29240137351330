import React from 'react'
import { useEffect, useState } from "react";
import { Box, Grid, Modal, Skeleton } from "@mui/material";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import "./media.scss";
import MediaSubdetails from "../common/MediaSubdetails";
import MediaOverView from "../common/MediaOverView";
import SeasonView from "../common/SeasonView";
import RateMovie from "../common/RateMovie";
import MediaTrailerBtn from "../common/MediaTrailerBtn";
import SeenMovie from "../common/SeenMovie";
import { getViewingOptions } from "../../slices/media";
// import LikeMovie from "../common/LikeMovie";
import ShareMovie from "../common/ShareMovie";
// import AddFriends from "../../assets/icons/add-friends.png";
// import ReportError from "../common/ReportError";
import BookmarkAction from "../watchlist/BookmarkAction";
import { useDispatch, useSelector } from "react-redux";
// import { mediaIntermediateData } from "../../slices/media";
// import InviteUsers from "../common/InviteUsers";
// import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { isMobileDevice } from "../../services/Utils";
import MyAds from "../adsense/MyAds";
import ReveiwMovie from '../common/ReveiwMovie';
import ReportError from '../common/ReportError';
import ShareIcon from "@mui/icons-material/Share";


const ShareIconMovieDetail = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/ShareIconMovieDetail.svg";

// const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: "100%",
//     maxWidth: { xs: "90%", sm: "760px" },
//     bgcolor: "#16213E",
//     boxShadow: 24,
//     // p: { xs: 2, sm: 4 },
//     borderRadius: "7px",
//     maxHeight: "95vh",
//     overflowY: "auto",
// };

export default function MovieBanner(props) {

    const { mediaData, data } = props;
    const [addedId, setAddeddId] = useState("");
    // const [freindsData, setFreindsData] = useState({});
    const dispatch = useDispatch();
    // const [open, setOpen] = useState(false);
    // const handleClose = () => setOpen(false);
    const global = useSelector((state) => state.global);
    const media = useSelector((state) => state.media);


    useEffect(() => {
        getData(mediaData);
    }, [mediaData]);

    const getData = (data, section = 'mobile') => {
        let mid = data?.uuid || data?.media;
        if (mid) {
            dispatch(getViewingOptions({ id: mid, section: section, is_public: global.is_public })).then((data) => {
                if (data.payload && data.payload.data) {
                    props?.setCurrentViewingOptions({ ...data.payload.data });
                }
            })
        }
    }

    // useEffect(() => {
    //     if (data.uuid && data.uuid !== "" && !global.is_public) {
    //         dispatch(
    //             mediaIntermediateData({
    //                 tmdb_id: data.uuid,
    //                 media_type: data.media_type,
    //             })
    //         ).then((resp) => {
    //             if (resp.payload && resp.payload.status) {
    //                 setFreindsData(resp.payload.data);
    //             }
    //         });
    //     }
    // }, [data.uuid]);

    // console.log('amanji',data)

    return (
        <>
            <Grid container justifyContent='center' >
                {isMobileDevice() ?
                    <>
                        <Grid className='relative w-100'>
                            <Grid item className='w-100'>
                                <Box
                                    component={'img'}
                                    src={data.backdrop && data.backdrop !== ""
                                        ? helpers.getFullUrlOriginal(data?.backdrop || data?.poster_path, 500)
                                        : helpers.getDefaultImage('backdrop')
                                    }
                                    alt=""
                                    className='w-100 d-block'
                                    style={{ maxHeight: data.backdrop && data.backdrop !== "" ? 'initial' : '180px' }}
                                />
                                <Grid item className='absolute' sx={{ top: '10px', right: '20px' }}>
                                    {/* <Box
                                        sx={{ height: 20, width: 20 }}
                                        component={'img'}
                                        src={ShareIconMovieDetail}
                                        onClick={() => {
                                            helpers.trackMoengageEvent("FR3_Searches", {
                                                title: data.title,
                                                share: MOENGAGE_ATTRIBUTE.YES,
                                            });
                                        }}
                                    >
                                    </Box>  */}
                                    <ShareMovie
                                        width={30}
                                        size={30}
                                        height={30}
                                        hideShare={true}
                                        myComponent={true}
                                        className={'pt-10 pb-10'}
                                        justifyContent="space-between"
                                        onClick={() => {
                                            helpers.trackMoengageEvent("FR3_Searches", {
                                                title: data.title,
                                                share: MOENGAGE_ATTRIBUTE.YES,
                                            });
                                        }}
                                        customComponent={
                                            <>
                                                <ShareIcon fontSize="30px" className="mt-3" />
                                            </>
                                        }
                                    />
                                </Grid>
                                <Grid container className="absolute"
                                    sx={{
                                        bottom: "0px",
                                        paddingTop: '20px',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        paddingBottom: '10px',
                                        boxShadow: 'inset 0 -50px 10px rgba(0, 0, 0, 0.6)'
                                    }}
                                >

                                    <Grid item xs={12}>
                                        <MediaSubdetails showFull={true} data={data} />
                                        <h1 className="fw-600 ellipsis fs-22 lh-28 m-0">{data?.title}</h1>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReportError
                                            data={media?.mediaData}
                                            media={
                                                media.mediaData && media.mediaData.uuid ? media.mediaData.uuid : ""
                                            }
                                            type={'text'}
                                            subtype={'red'}
                                            reported={media?.mediaData && media?.mediaData?.report_error}
                                            className={'fs-14 mt-15 text-right'}
                                            title={'report an error'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            className='pt-20 pb-30'>
                            {data.season && data.season[1] && (
                                <SeasonView
                                    style={{ maxHeight: '170px', overflowY: 'auto' }}
                                    data={data}
                                />
                            )}
                        </Grid>
                        <Grid item xs={10}>
                            <Grid
                                container
                                justifyContent={"space-between"}
                                alignItems={"flex-end"}
                                className="w-100 h-100" >
                                <Grid item xs={12} sm={6} md={6} lg={8}>
                                    <Grid
                                        container
                                        justifyContent={"space-between"}
                                        className="w-100"
                                        sx={{ maxWidth: { xs: "100%", sm: "400px" } }}
                                    >
                                        <div>
                                            <SeenMovie
                                                data={data}
                                                width={35}
                                                size={25}
                                                height={35} />
                                        </div>
                                        <div>
                                            <RateMovie
                                                data={data}
                                                width={35}
                                                size={25}
                                                type={'emoji'}
                                                height={35}
                                                refPage={"Media Detail"} />
                                        </div>
                                        <div className='text-center mt-30'>
                                            <ReveiwMovie
                                                data={data}
                                                size={25}
                                                showLabel={true}
                                                styling={'mt-5'}
                                            />
                                        </div>
                                        {/* <div>
                                                                <LikeMovie
                                                                    data={data}
                                                                    width={35}
                                                                    size={23}
                                                                    height={35} />
                                                            </div> */}
                                        <div className="no-absolute media-bookmark">
                                            <div>
                                                <Grid container justifyContent={"center"}>
                                                    <BookmarkAction
                                                        addedId={addedId}
                                                        setAddedId={setAddeddId}
                                                        size={42}
                                                        data={{
                                                            ...data,
                                                            media: data.media ? data.media : data?.uuid,
                                                        }} />
                                                </Grid>
                                                <span className="d-block text-center pointer fs-14 fw-600">
                                                    Watchlist
                                                </span>
                                            </div>
                                        </div>
                                        {/* <div>
                                            <ShareMovie
                                                data={data}
                                                width={35}
                                                size={25}
                                                height={35}
                                                type={'onlyIcon'}
                                                onClick={() => {
                                                    helpers.trackMoengageEvent("FR3_Searches", {
                                                        title: data.title,
                                                        share: MOENGAGE_ATTRIBUTE.YES,
                                                    });
                                                }} />
                                        </div> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Grid item
                        sx={{
                            backgroundImage: {
                                xs: `url(${data.poster_path ? helpers.getFullUrlOriginal(data.poster_path, 500) : helpers.getDefaultImage('poster')
                                    })`,
                                sm: `url(${(data.backdrop || data.poster_path) ? helpers.getFullUrlOriginal(data.backdrop || data.poster_path, 780) : helpers.getDefaultImage('backdrop')
                                    })`,
                                lg: `url(${(data.backdrop || data.poster_path) ? helpers.getFullUrlOriginal(data.backdrop || data.poster_path, 1280) : helpers.getDefaultImage('backdrop')
                                    })`,
                                xl: `url(${(data.backdrop || data.poster_path) ? helpers.getFullUrlOriginal(data.backdrop || data.poster_path) : helpers.getDefaultImage('backdrop')
                                    })`
                            },
                            minHeight: {
                                md: '430px', xs: 'auto'
                            }
                        }}
                        className="movie-detail-banner-container app-section"
                        xs={12}>
                        <Grid style={{ background: 'rgba(22, 33, 62, 0.80)' }} item className="pt-30 pb-30 w-100 new-container relative">
                            <Grid container sx={{ paddingLeft: { md: '25px', lg: '25px' } }} className="relative" justifyContent={'space-between'}>
                                <Grid container justifyContent={'center'}>
                                    <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"3422829246"} adv_type={"web_movie_detail_top_ad"} />
                                </Grid>
                                <Grid container className='mt-20' >
                                    <Grid item xs={12} sm={4} md={4} lg={3} className="relative ">
                                        {data.poster_path ? (
                                            <Box
                                                alt="banner"
                                                component="img"
                                                className="d-block"
                                                sx={{
                                                    height: "100%",
                                                    width: "100%",
                                                    maxHeight: {
                                                        xs: "100%",
                                                        sm: "460px",
                                                        md: "520px",
                                                        xl: "75vh",
                                                    },
                                                    maxWidth: { xs: "100%" },
                                                    borderRadius: "10px",
                                                    objectFit: "cover",
                                                    objectPosition: "top",
                                                }}
                                                src={data.poster_path && data.poster_path !== ""
                                                    ? helpers.getFullUrl(data?.poster_path)
                                                    : helpers.getDefaultImage("poster")}
                                            />
                                        ) : (
                                            <Skeleton
                                                animation="wave"
                                                variant="rectangular"
                                                sx={{
                                                    height: "100vh",
                                                    width: "100%",
                                                    maxHeight: {
                                                        xs: "70vh",
                                                        sm: "460px",
                                                        md: "520px",
                                                        xl: "70vh",
                                                    },
                                                    maxWidth: { xs: "100%" },
                                                    borderRadius: "10px",
                                                    objectFit: "cover",
                                                    objectPosition: "top",
                                                }}
                                            />
                                        )}
                                        {data?.trailer && !helpers.isEmptyObject(data?.trailer) ?
                                            <Grid container justifyContent={'center'}>
                                                <div
                                                    className={
                                                        "w-50 absolute top-centered text-center"
                                                    }
                                                >
                                                    <MediaTrailerBtn
                                                        controls={true}
                                                        isPlay={true}
                                                        data={data?.trailer}
                                                        from="media"
                                                        ref_page="Movie Detail"
                                                    />
                                                </div>
                                            </Grid>
                                            : ''
                                        }
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                alignItems={"center"}
                                                sx={{ minWidth: { xs: 30, xl: 80 } }}
                                                flexWrap={"nowrap"}
                                                className="bottom-right w-100"
                                            >
                                                <p
                                                    style={{ textTransform: "uppercase" }}
                                                    className="m-0 fs-10 pl-10"
                                                >
                                                    {" "}
                                                    {data.media_type ? data.media_type : ""}{" "}
                                                </p>
                                                <p className="m-0 fs-10 pl-5 pr-5"> | </p>{" "}
                                                <p
                                                    className="m-0 fs-10 pr-10"
                                                    style={{ textTransform: "uppercase" }}
                                                >
                                                    {" "}
                                                    {data.og_lang ? data.og_lang : ""}
                                                </p>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} lg={9} >
                                        <Grid container className='pl-20 h-100' justifyContent={'space-between'} >
                                            <Grid item xs={12}>
                                                <Grid item className="" xs={12}>
                                                    <MediaSubdetails showFull={true} data={data} />
                                                    <h1 className="fw-600 fs-24 lh-28 m-0">{data?.title}</h1>
                                                </Grid>

                                                <Grid
                                                    item
                                                    className="text-center"
                                                    alignItems={"center"}
                                                    sx={{ marginTop: "10px", marginBottom: "30px" }}
                                                    xs={12}>

                                                    {/* <Grid container alignItems="center">
                                                    {data.friends_data && data.friends_data.length > 0 && (
                                                        <Stack
                                                            direction="row"
                                                            sx={{ marginRight: "10px" }}
                                                            className="ai-center"
                                                        >
                                                            {data.friends_data.map((item, j) => {
                                                                if (j < 3) {
                                                                    return (
                                                                        <Avatar
                                                                            key={j}
                                                                            sx={{
                                                                                width: 36,
                                                                                height: 36,
                                                                                maxWidth: { xs: 24, sm: 36 },
                                                                                maxHeight: { xs: 24, sm: 36 },
                                                                            }}
                                                                            alt="Remy Sharp"
                                                                            src={
                                                                                item.profile_image
                                                                                    ? helpers.getFullUrl(item.profile_image)
                                                                                    : helpers.getDefaultImage(
                                                                                        "halfsmallprofile"
                                                                                    )
                                                                            }
                                                                            className="ott-avatar"
                                                                        />
                                                                    );
                                                                }
                                                            })}
                                                        </Stack>
                                                    )}
                                                    {!freindsData.friends ? (
                                                        <Box
                                                            onClick={() => handleAddFreind()}
                                                            component={"img"}
                                                            className="d-block pointer"
                                                            src={AddFriends}
                                                            sx={{
                                                                maxWidth: "35px",
                                                                maxHeight: "35px",
                                                                marginRight: "7px",
                                                            }}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                    <p
                                                        onClick={() => handleAddFreind()}
                                                        className={`fw-600 m-0 p-0 fs-18 ${global.is_public ? "pointer" : ""
                                                            }`}
                                                    >
                                                        {helpers.setUpFaceRating(
                                                            freindsData.rating,
                                                            freindsData.friends,
                                                            data?.friends_rated > data?.friends_data
                                                                ? data?.friends_rated - data?.friends_data.length
                                                                : 0,
                                                            data?.media_type
                                                        )}
                                                        <br />
                                                    </p>

                                                    {freindsData.friends && freindsData.rating === 0 ? (
                                                        <p
                                                            onClick={handleOpen}
                                                            className="fw-600 fs-16 text-left ask-friends text-selected pointer mt-0 mb-0"
                                                            style={{ marginLeft: '30px' }}
                                                        >
                                                            ask friends
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}

                                                </Grid> */}
                                                </Grid>

                                                <Grid item className='mt-40'>
                                                    <MediaOverView
                                                        isLess={true}
                                                        className=""
                                                        text={data.media_overview ? data.media_overview : ""}
                                                        fullHeight={true}
                                                        max={500} />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={8}
                                                sx={{ paddingTop: { md: "10px", lg: "10px" } }}>
                                                {data.season && data.season[1] && (
                                                    <SeasonView
                                                        style={{ maxHeight: '170px', overflowY: 'auto' }}
                                                        data={data}
                                                    />
                                                )}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent={"space-between"}
                                                    alignItems={"flex-end"}
                                                    className="w-100 h-100" >
                                                    <Grid item sm={7} md={7} lg={7}>
                                                        <Grid
                                                            container
                                                            justifyContent={"space-between"}
                                                            className="w-100"
                                                            sx={{ maxWidth: { xs: "100%", sm: "400px" } }}
                                                        >
                                                            <div style={{ paddingTop: '2px' }}>
                                                                <SeenMovie
                                                                    data={data}
                                                                    width={35}
                                                                    size={25}
                                                                    height={35} />
                                                            </div>
                                                            <div>
                                                                <RateMovie
                                                                    data={data}
                                                                    width={35}
                                                                    size={25}
                                                                    type={'emoji'}
                                                                    height={35}
                                                                    refPage={"Media Detail"} />
                                                            </div>
                                                            <div className='text-center' style={{ paddingTop: '1px' }}>
                                                                <ReveiwMovie
                                                                    data={data}
                                                                    size={25}
                                                                    showLabel={true}
                                                                    styling={'mt-5'}
                                                                />
                                                            </div>
                                                            {/* <div>
                                                                <LikeMovie
                                                                    data={data}
                                                                    width={35}
                                                                    size={23}
                                                                    height={35} />
                                                            </div> */}
                                                            <div className="no-absolute media-bookmark">
                                                                <div>
                                                                    <Grid container justifyContent={"center"}>
                                                                        <BookmarkAction
                                                                            addedId={addedId}
                                                                            setAddedId={setAddeddId}
                                                                            size={42}
                                                                            data={{
                                                                                ...data,
                                                                                media: data.media ? data.media : data?.uuid,
                                                                            }} />
                                                                    </Grid>
                                                                    <span className="d-block text-center pointer fs-14 fw-600">
                                                                        Watchlist
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <ShareMovie
                                                                    data={data}
                                                                    width={35}
                                                                    size={25}
                                                                    height={35}
                                                                    onClick={() => {
                                                                        helpers.trackMoengageEvent("FR3_Searches", {
                                                                            title: data.title,
                                                                            share: MOENGAGE_ATTRIBUTE.YES,
                                                                        });
                                                                    }} />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sm={5} lg={4}>
                                                        {/* <img src={ReportErrIcon} height={40} alt="" /> */}
                                                        <ReportError
                                                            data={media?.mediaData}
                                                            media={
                                                                media.mediaData && media.mediaData.uuid ? media.mediaData.uuid : ""
                                                            }
                                                            type={'text'}
                                                            subtype={'red'}
                                                            reported={media?.mediaData && media?.mediaData?.report_error}
                                                            className={'fs-14 mt-15 text-right text-light t-underline'}
                                                            title={'Is something wrong? Report an error'}
                                                        />
                                                    </Grid>

                                                    {/* <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={"auto"}
                                                        sx={{ paddingTop: { md: "20px", lg: "0px" } }}>
                                                        {data.season && data.season[1] && (
                                                            <Grid item xs={12}>
                                                                <SeasonView data={data} />
                                                            </Grid>
                                                        )}
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                }
            </Grid>

            {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <InviteUsers onClose={handleClose} />
                </Box>
            </Modal> */}
        </>
    )
}
