import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MediumTile from "../common/MediumTile";
import { MEDIUM_TILE_BREAKPOINTS, MEDIUM_TILE_BREAKPOINTS_WITH_AD } from "../../services/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation, Pagination } from "swiper";
import NextButtonTile from "../common/NextButtonTile"
import SectionHeading from "../component/headings/SectionHeading";
import { isMobileDevice } from '../../services/Utils';
import { Box } from "@mui/material";
import SeeAll from "../common/SeeAll";

const LargeSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/LargeSeeAll.svg";
const SmallSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/SmallSeeAll.svg";

export default function FrenziPopular(props) {
  const { data, is_free, heading, withAd, nextLink } = props;

  return (
    <>
      {props.isShowHeading && (
        <Grid
          container
          alignItems={"center"}
          sx={{ marginTop: { xs: "20px", sm: "30px" } }}
          justifyContent="space-between"
        >
          <Grid item>
              {" "}
              {heading ? (
                <SectionHeading title={heading} />
              ) : (
                <>
                  {/* <span className="text-selected">popular </span> on frenzi */}
                  <SectionHeading title={heading} />
                </>
              )}{" "}
            {/* <h2 className="fs-24 fw-500 mt-0 pt-5 mb-10 text-dark-grey">
              discover the most liked titles
            </h2> */}
          </Grid>
          <Link to={`/popular-on-frenzi${is_free ? '?is_free=true' : ''}`}>
            {/* <Box component={'img'} src={isMobileDevice() ? SmallSeeAll : LargeSeeAll} sx={{ paddingTop: { xs: '5px', sm: '10px' } }} /> */}
            <SeeAll />
          </Link>
        </Grid>
      )}
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        slidesPerView={"auto"}
        spaceBetween={10}
        speed={1000}
        navigation={true}
        modules={[Pagination, FreeMode, Navigation]}
        className="mySwiper"
        freeMode={true}
      >
        {data &&
          data.length > 0 &&
          data.map((x, i) => {
            return (
              <SwiperSlide key={i} className="pr-10" style={{ alignSelf: 'center', width: 'auto' }}>
                <Grid sx={{
                  maxWidth: { xs: 100, sm: 140 },
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  display: 'inline-block'
                }}
                >
                  <MediumTile refPage={props?.refPage} x={x} />
                </Grid>
              </SwiperSlide>
            );
          })}
        {!nextLink ? (
          <SwiperSlide className="pr-10" style={{ width: 'auto', height: "auto" }} key={"next-btn"}>
            <Grid sx={{
              maxWidth: { xs: 100, sm: 140 },
              display: 'inline-block'
            }}
              className='h-100'
            >
              <NextButtonTile
                link={`/popular-on-frenzi${is_free ? '?is_free=true' : ''}`}
                data={data}
              />
            </Grid>
          </SwiperSlide>
        ) : (
          ""
        )}
      </Swiper>
    </>
  );
}
