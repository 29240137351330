import React, { useEffect, useState } from 'react'
import './watchlist.scss';
import { Grid, Button, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ProgressBar from '../component/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { getFirstWatchlistData } from '../../slices/watchlist';
import helpers from '../../services/helpers';
import CreateForm from './CreateForm';
import { Link } from 'react-router-dom';
import { setCreateWatchlistFormOpen } from '../../slices/global';
import BookmarkAction from './BookmarkAction';
import { MEDIUM_TILE_BREAKPOINTS } from '../../services/helpers';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import MyAds from '../adsense/MyAds';

export default function CreateWatchlist() {
    const dispatch = useDispatch();
    const [addedId, setAddeddId] = useState('');

    const watchlist = useSelector(state => state.watchlist);
    const _global = useSelector(state => state.global);
    const { isCreateWatchlistFormOpen } = _global;

    useEffect(() => {
        dispatch(getFirstWatchlistData({ page: 1, pagelimit: 10 }));
    }, [])


    return (
        <>
            <Grid item className='bg-gunpowder create-watchlist mt-10' xs={12}>
                <Grid item className='app-container pt-10 pb-10'>
                    <Grid container alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item xs={10}>
                            <p className='fw-700 lh-25 fs-28 m-0'>create your first watchlist</p>
                            <p className='fw-500 fs-24 m-0'>My Watchlist</p>
                        </Grid>
                        {/* <Grid item className='pt-10'>
                            <Link to={`/watchlist-detail/${data.watchlist_id}`}>
                                <ArrowForwardIcon />
                            </Link>
                        </Grid> */}
                    </Grid>
                    {watchlist.firtsWatchlistData && watchlist.firtsWatchlistData.length > 0 &&
                        <Grid item className='mt-40'>
                            <Swiper
                                slidesPerView={2}
                                spaceBetween={20}
                                // slidesPerGroup={5}
                                freeMode={true}
                                navigation={true}
                                speed={1000}
                                breakpoints={MEDIUM_TILE_BREAKPOINTS}
                                modules={[FreeMode, Navigation]}
                                className="mySwiper"
                            >
                                {watchlist.firtsWatchlistData.map((x, i) => {
                                    return <>
                                        <SwiperSlide key={i}>
                                            <div style={{ padding: '20px 0px 20px 0px' }} key={i}>
                                                <Grid item className='text-center'>
                                                    <Grid item className='relative'>
                                                        <Link onClick={() => helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', 'Create Watchlist')} to={helpers.getMediaLink(x)}>
                                                            <Box
                                                                component="img"
                                                                sx={{
                                                                    height: 210,
                                                                    width: '100%',

                                                                    maxHeight: { xs: 210, sm: 210 },
                                                                    maxWidth: { xs: '100%', sm: 155 },
                                                                    borderRadius: '9px',
                                                                }}
                                                                alt="poster"
                                                                src={x.poster ? helpers.getFullUrl(x.poster) : helpers.getDefaultImage('watchlistsection')}
                                                            />
                                                        </Link>
                                                        {/* <BookmarkAction data={x} addedId={addedId} setAddedId={setAddeddId} /> */}
                                                    </Grid>
                                                    <Grid container alignItems={'flex-end'} justifyContent={'space-between'} className=''>
                                                        <p className='m-0 fs-14 fw-400'>
                                                        {/* {helpers.getOttOrInTheatre(x)} */}
                                                        </p>
                                                        <ProgressBar value={x.rating ? Number(x.rating) * 10 : 0} />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </SwiperSlide>
                                        {/* {(watchlist.firtsWatchlistData.length > 2 ? i === 2 : watchlist.firtsWatchlistData.length - 1 === i) &&
                                            <SwiperSlide style={{minWidth:'300px'}} key={`ads=${i}`}>
                                                <MyAds type="banner_3" adv_type={'web_watchlist_create_section_ad'} />
                                            </SwiperSlide>
                                        } */}
                                    </>
                                })}
                            </Swiper>
                        </Grid>
                    }
                </Grid>

                <Accordion expanded={isCreateWatchlistFormOpen} className='p-0 m-0 bg-gunpowder custom-accordian'>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className='p-0 m-0 header'
                    >
                        <Grid container className='app-container w-100'>
                            <Grid item xs={12}>
                                <Grid container justifyContent={'flex-end'} className="w-100">
                                    <Button onClick={() => dispatch(setCreateWatchlistFormOpen({ open: !isCreateWatchlistFormOpen, type: true }))} className='rounded-btn bg-selected text-light fs-14 mw-320 fw-500'>create a new watchlist</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className='p-0 m-0'>
                        <CreateForm />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </>
    )
}
