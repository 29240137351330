import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './app/store';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import { hydrate, render } from "react-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import MyApp from './MyApp';
import { HelmetProvider } from 'react-helmet-async';

// import './assets/fonts/fonts.scss';
import './style.scss';
import './_common.scss';

const container = document.getElementById('frenzi-root');
const root = createRoot(container);

const ROOT = (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
          <MyApp />
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);


// if (container.hasChildNodes()) {
//   hydrate(ROOT, container);
// } else {
//   render(ROOT, container);
// }

root.render(ROOT);

serviceWorkerRegistration.register();
reportWebVitals();
