import axiosInstance from "../http-common";
import helpers from "./helpers";
import { encryptData } from "./Utils";

const getHandPicked = (page = 1, pagelimit = 10, is_free = false) => {
    return axiosInstance.post(`native_main/handpicked?page=${page}&pagelimit=${pagelimit}&is_free=${is_free}&is_web=true&exclude_already_watched=yes&prefered_platforms=yes`);
};

const getNewlyAdded = (is_free = false, page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/fresh?page=${page}&pagelimit=${pagelimit}&is_free=${is_free}&exclude_already_watched=yes&prefered_platforms=yes`);
};


const getTopTen = (media_type = 'all', is_free = false, page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/topten?page=${page}&pagelimit=${pagelimit}&is_free=${is_free}&media_type=${media_type}&exclude_already_watched=yes&prefered_platforms=yes`);
};

const getPlatformMedia = (is_free = false, body = {}, media_type = "all", sort = "", platform = "", page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/platformmedia?platform=${platform}&page=${page}&pagelimit=${pagelimit}&media_type=${media_type}&sort=${sort}&is_free=${is_free}&exclude_already_watched=yes&prefered_platforms=yes`, body);
};

const getGenreMedia = (is_free = false, body = {}, media_type = "all", sort = "", genre = "", page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/genremedia?genre=${genre}&page=${page}&pagelimit=${pagelimit}&media_type=${media_type}&sort=${sort}&is_free=${is_free}&exclude_already_watched=yes&prefered_platforms=yes`, body);
};

const getLanguageMedia = (is_free = false, body = {}, media_type = "all", sort = "", language = "", page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/languagemedia?language=${language}&page=${page}&pagelimit=${pagelimit}&media_type=${media_type}&sort=${sort}&is_free=${is_free}&exclude_already_watched=yes&prefered_platforms=yes`, body);
};

const getPopularToday = (is_free = false, body = {}, media_type = "all", page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/populartoday?page=${page}&pagelimit=${pagelimit}&exclude_already_watched=yes&prefered_platforms=yes&is_free=${is_free}&media_type=${media_type}`, body);
};

const getTrendingInFriends = (page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/trendinginfriends?page=${page}&pagelimit=${pagelimit}&exclude_already_watched=yes&prefered_platforms=yes`);
};

const getComingSoon = (page = 1, pagelimit = 10, date_range = "") => {
    return axiosInstance.post(`native_main/comingsoon?page=${page}&pagelimit=${pagelimit}&date_range=${date_range}&exclude_already_watched=yes&prefered_platforms=yes`);
};

const getFrenziWatchLists = async (is_public = false, page = 1, pagelimit = 10) => {
    if (is_public) {
        let captcha_token = '';
        // let captcha_token = helpers.getCaptchaToken();
        let newBody = {
            page: page,
            pagelimit: pagelimit,
            exclude_already_watched: 'yes',
            prefered_platforms: 'yes',
            captcha_token: captcha_token
        };
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(`native_main/public_frenziwatchlists`, { data: encryptedData });
    } else {
        return axiosInstance.get(`native_main/frenziwatchlists?page=${page}&pagelimit=${pagelimit}&exclude_already_watched=yes&prefered_platforms=yes`);
    }
};

const getAllWatchLists = (is_free = false, page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/allwatchlistmedia?page=${page}&pagelimit=${pagelimit}&exclude_already_watched=yes&prefered_platforms=yes&is_free=${is_free}`);
};

const getFreshArrivalData = (is_free = false, body = {}, mediaType = '', sort = '', date_range = 'all', page = 1, pagelimit = 10) => {
    var path = `native_main/fresh?page=${page}&pagelimit=${pagelimit}&date_range=${date_range}&exclude_already_watched=yes&prefered_platforms=yes&is_free=${is_free}`;
    if (mediaType && mediaType != "") {
        path += `&media_type=${mediaType}`;
    }
    if (sort && sort != "") {
        path += `&sort=${sort}`;
    }

    return axiosInstance.post(path, body);
};

const getFreshTrailers = (is_free = false, page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/freshtrailers?page=${page}&pagelimit=${pagelimit}&is_web=true&exclude_already_watched=yes&prefered_platforms=yes&is_free=${is_free}`);
};

const getHiddenGems = (is_free = false, body = {}, mediaType = 'all', sort = '', page = 1, pagelimit = 10) => {
    return axiosInstance.post(`native_main/hiddengems?page=${page}&pagelimit=${pagelimit}&exclude_already_watched=yes&prefered_platforms=yes&is_free=${is_free}&sort=${sort}&media_type=${mediaType}`, body);
};


const getCollectionMetadata = (is_trending = 0) => {
    return axiosInstance.get(`native_main/collection_metadata?is_trending_collection=${is_trending}`);
};

const getCollectionMediaData = (date_range = "all", path = '', is_free = false, body = {}, media_type = "all", sort = '', page = 1, pagelimit) => {
    let url = `native_main/${path}?page=${page}&pagelimit=${pagelimit}&exclude_already_watched=yes&prefered_platforms=yes`;
    if (is_free) {
        url += `&is_free=${is_free}`;
    }
    if (sort && sort != "") {
        url += `&sort=${sort}`;
    }
    return axiosInstance.post(url, body);
};

const getCommonForAllData = async (is_public = false, date_range = "all", path = '', is_free = false, body = {}, media_type = "all", sort = '', page = 1, pagelimit, no_filter = false) => {
    let show_latest_first = 'yes';
    let url = `native_main/`;
    let captcha_token = '';
    if (path === 'fresh') {
        captcha_token = await helpers.getCaptchaToken();
        window.generateNewToken();
    }

    if (is_public) {
        url += `public_${path}`;

        const params = helpers.getQueryParams(url.split('?')[1]);
        url = url.split('?')[0];

        let newBody = {
            ...body,
        }

        if (path === 'fresh') {
            newBody = {
                ...body,
                captcha_token: captcha_token
            }
        }

        if (params) {
            newBody = {
                ...newBody,
                ...params
            }
        }

        if (!no_filter) {
            newBody = {
                ...newBody,
                prefered_platforms: 'yes',
                is_web: 'yes',
                page: page,
                show_latest_first: show_latest_first
            }

            if (body?.exclude_already_watched && body?.exclude_already_watched != '') {
                newBody.exclude_already_watched = body?.exclude_already_watched;
            } else {
                newBody.exclude_already_watched = 'yes';
            }

            if (!(body?.content_type) || (body?.content_type.length == 0)) {
                if (media_type && media_type != "") {
                    newBody.media_type = media_type;
                }
            } else {
                if (body?.content_type && body?.content_type.length == 1) {
                    newBody.media_type = body?.content_type[0];
                }
                if (body?.content_type && body?.content_type.length == 2) {
                    newBody.media_type = 'all';
                }
            }
            if (sort && sort != "") {
                newBody.sort = sort;
            }
            if (is_free && is_free != "") {
                newBody.is_free = is_free;
            }
            if (pagelimit && pagelimit != "") {
                newBody.pagelimit = pagelimit;
            }
            if (date_range && date_range != "") {
                newBody.date_range = date_range;
            }
        }

        console.log(path,newBody)

        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(url, { data: encryptedData });

    } else {
        url += path;

        if (!no_filter) {
            if (url.indexOf("?") > -1) {
                url += `&page=${page}&prefered_platforms=yes&is_web=yes`
            } else {
                url += `?page=${page}&prefered_platforms=yes&is_web=yes`
            }
            if (body?.exclude_already_watched && body?.exclude_already_watched != '') {
                url += `&exclude_already_watched=${body?.exclude_already_watched}`;
            } else {
                url += `&exclude_already_watched=yes`;
            }
            if (!(body?.content_type) || (body?.content_type.length == 0)) {
                if (media_type && media_type != "") {
                    url += `&media_type=${media_type}`;
                }
            } else {
                if (body?.content_type && body?.content_type.length == 1) {
                    url += `&media_type=${body?.content_type[0]}`;
                }
                if (body?.content_type && body?.content_type.length == 2) {
                    url += `&media_type=all`;
                }
            }
            if (sort && sort != "") {
                url += `&sort=${sort}`;
            }
            if (is_free && is_free != "") {
                url += `&is_free=${is_free}`;
            }
            if (pagelimit && pagelimit != "") {
                url += `&pagelimit=${pagelimit}`;
            }
            if (date_range && date_range != "") {
                url += `&date_range=${date_range}`;
            }
            url += `&show_latest_first=${show_latest_first}`
        }

        return axiosInstance.post(url, body);

    }
}

const getCommonForAllDataOld = async (is_public = false, date_range = "all", path = '', is_free = false, body = {}, media_type = "all", sort = '', page = 1, pagelimit, no_filter = false) => {
    let show_latest_first = 'yes';
    let url = `native_main/`;
    if (is_public) {
        url += `public_${path}`;
    } else {
        url += path;
    }

    if (!no_filter) {
        if (url.indexOf("?") > -1) {
            url += `&page=${page}&prefered_platforms=yes&is_web=yes`
        } else {
            url += `?page=${page}&prefered_platforms=yes&is_web=yes`
        }
        if (body?.exclude_already_watched && body?.exclude_already_watched != '') {
            url += `&exclude_already_watched=${body?.exclude_already_watched}`;
        } else {
            url += `&exclude_already_watched=yes`;
        }
        if (!(body?.content_type) || (body?.content_type.length == 0)) {
            if (media_type && media_type != "") {
                url += `&media_type=${media_type}`;
            }
        } else {
            if (body?.content_type && body?.content_type.length == 1) {
                url += `&media_type=${body?.content_type[0]}`;
            }
            if (body?.content_type && body?.content_type.length == 2) {
                url += `&media_type=all`;
            }
        }
        if (sort && sort != "") {
            url += `&sort=${sort}`;
        }
        if (is_free && is_free != "") {
            url += `&is_free=${is_free}`;
        }
        if (pagelimit && pagelimit != "") {
            url += `&pagelimit=${pagelimit}`;
        }
        if (date_range && date_range != "") {
            url += `&date_range=${date_range}`;
        }
        url += `&show_latest_first=${show_latest_first}`
    }

    return axiosInstance.post(url, body);

}

const getCertificateMedia = (is_public = false, section = 'new') => {
    return axiosInstance.get(`native_main/${is_public ? 'public_' : ''}certificatemedia?section=${section}`);
};

const getPublicHandpicked = async (is_free = false) => {
    let url = `native_main/public_handpicked_web`;
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();
    if (is_free) {
        let newBody = { is_free: true, captcha_token };
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(url, { data: encryptedData });
    } else {
        let newBody = { captcha_token };
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(url, { data: encryptedData });
    }
};

const TrendingDataService = {
    getHandPicked,
    getNewlyAdded,
    getTopTen,
    getPlatformMedia,
    getGenreMedia,
    getLanguageMedia,
    getPopularToday,
    getTrendingInFriends,
    getComingSoon,
    getFrenziWatchLists,
    getAllWatchLists,
    getFreshArrivalData,
    getFreshTrailers,
    getHiddenGems,
    getCollectionMetadata,
    getCollectionMediaData,
    getCommonForAllData,
    getCertificateMedia,
    getPublicHandpicked
};

export default TrendingDataService;