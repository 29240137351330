import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import helpers from "../../services/helpers";
import { Link } from "react-router-dom";
import SeenMovie from "../common/SeenMovie";
import BookmarkAction from "../watchlist/BookmarkAction";
import { isMobileDevice } from "../../services/Utils";

export default function WatchedSlides(props) {
  const { data } = props;
  const [addedId, setAddeddId] = useState("");

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12}>
        <Box
          className="relative movie-card wv-100"
          sx={{
            maxHeight: { xs: '330px', sm: '400px', md: '550px' },
            minWidth: '100%',
            backgroundImage: {
              xs: `url(${data.poster
                ? helpers.getFullUrl(data?.poster)
                : data.poster
                  ? helpers.getFullUrlOriginal(data?.backdrop)
                  : helpers.getDefaultImage("carousel")
                })`,
              sm: `url(${data.backdrop
                ? helpers.getFullUrlOriginal(data?.backdrop)
                : data.poster
                  ? helpers.getFullUrl(data?.poster)
                  : helpers.getDefaultImage("carousel")
                })`,
            },
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top'
          }}
        >
          <Box className="gradient">
            <Grid
              justifyContent={"end"}
              container
              flexDirection={"column"}
              className="p-15 h-100 "
            >
              <Link
                to={helpers.getMediaLink(data)}
                onClick={() => helpers.mediaClick(data?.title, data.media ? data.media : data.uuid ? data.uuid : '', 'Watchlist')}
              >
                <h2 className="fw-700 lh-48 fs-48 m-0 ">
                  {data.title ? data.title : ""}
                </h2>
              </Link>
              <Grid sx={{ paddingBottom: { xs: '30px', md: '30px' } }}>
                <h2 className="fw-400 text-left w-100 fs-24 m-0">
                  {data.release_year ? data.release_year + " |" : ""}{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {data.media_type ? data.media_type + " |" : ""}
                  </span>
                  <span>
                    {" "}
                    {data.data && data.data.length > 0
                      ? data.data[0].ott_name
                      : ""}
                  </span>
                </h2>
              </Grid>
              {isMobileDevice() ? "" :
                <Grid container spacing={2} className="mt-2" sx={{ marginBottom: { xs: '15px' } }}>
                  <Grid item xs={10} lg={2.5} sm={4.5} md={3} xl={3} >
                    <SeenMovie
                      data={data}
                      width={24}
                      size={15}
                      type={"button"}
                      height={24}
                    />
                  </Grid>
                  <Grid item xs={10} lg={2} sm={3} md={2.2} >
                    <BookmarkAction
                      sx={{ top: "inherit", right: "10px" }}
                      data={data}
                      width={24}
                      size={24}
                      height={24}
                      addedId={addedId}
                      setAddedId={setAddeddId}
                      btnWithLabel="left"
                    />
                  </Grid>
                </Grid>
              }
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
