import { Grid } from '@mui/material';
// import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPreferences } from '../../../slices/auth';
import Selection from './Selection'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CommonMediumTileListing from '../../common/CommonMediumTileListing';
import { getGenreMedia, getLanguageMedia, getPlatformMediaMovie, getPlatformMediaSearched, getPlatformMediaTv } from '../../../slices/trending';
import Loader from '../../common/Loader';
import PreferMood from '../PreferMood';
import PreferLanguage from '../PreferLanguage';
import MyHelmet from '../../common/MyHelmet';
import MyAds from '../../adsense/MyAds';
import ContentAccordion from '../../common/ContentAccordion';

export default function TrendingDetail() {
    const page = 1;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedLanguageName, setSelectedLanguageName] = useState("")
    const [selectedGenre, setSelectedGenre] = useState('');
    const [selectedGenreName, setSelectedGenreName] = useState("")
    const [currentSlide, setCurrentSlide] = useState(0);
    const auth = useSelector(state => state.auth);
    const trending = useSelector(state => state.trending);
    const _global = useSelector(state => state.global);

    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [selectedPlatformUuid, setSelectedPlatformUuid] = useState('');
    const [selectedPlatformOttName, setSelectedPlatformOttName] = useState('');
    const [searchParams] = useSearchParams();

    const params = useParams();
    const platform_name = params.platform && params.platform == 'Prime Video' ? 'Amazon Prime Video' : params.platform;

    const is_free = searchParams.get('is_free') && searchParams.get('is_free') === 'true' ? true : false;
    // const platformId = searchParams.get('platform') ? searchParams.get('platform') : '';

    const platformUuid = useMemo(() => {
        if (auth.allPreferences && auth.allPreferences.platforms && platform_name && platform_name != '') {
            let tempData = auth.allPreferences.platforms.filter((x) => x.ott_name === platform_name)[0];
            return tempData && tempData?.uuid ? tempData.uuid : null;
        } else {
            return null;
        }
    }, [platform_name, auth.allPreferences.platforms]);

    useEffect(() => {
        if (selectedPlatformOttName) {
            navigate(`/platforms/${selectedPlatformOttName.replace(/ /g, "-")}${window.location.search}`);
        }
    }, [selectedPlatformOttName])

    useEffect(() => {
        window.scrollTo(0, 0);
        if (platformUuid) {
            setSelectedPlatformUuid(platformUuid);
            setSelectedPlatform(platformUuid);
        }
    }, [platformUuid])

    useEffect(() => {
        if (auth.successMesg && auth.successMesg !== "") {
            dispatch(getAllPreferences({ is_public: _global.is_public }));
        }
    }, [auth])

    useEffect(() => {
        setCurrentSlide(0);
        if (selectedPlatformUuid && selectedPlatformUuid !== "") {
            var body = {
                page: page,
                pagelimit: 10,
                id: selectedPlatformUuid,
                is_free: is_free ? true : false,
                is_public: _global.is_public
            }
            dispatch(getPlatformMediaMovie({ ...body, media_type: 'movie' }));
            dispatch(getPlatformMediaTv({ ...body, media_type: 'tv' }));
            dispatch(getPlatformMediaSearched({ ...body, media_type: 'all', sort: 's' }));
        }
    }, [selectedPlatformUuid]);

    useEffect(() => {
        if (selectedLanguage && selectedLanguage !== "") {
            var body = {
                page: page,
                pagelimit: 10,
                id: selectedLanguage,
                body: { service: [selectedPlatform] },
                is_free: is_free ? true : false,
                is_public: _global.is_public
            }
            dispatch(getLanguageMedia(body));
        }
    }, [selectedLanguage, selectedPlatform])

    useEffect(() => {
        if (selectedGenre && selectedGenre !== "") {
            var body = {
                page: page,
                pagelimit: 10,
                id: selectedGenre,
                body: { service: [selectedPlatform] },
                is_free: is_free ? true : false,
                is_public: _global.is_public
            }
            dispatch(getGenreMedia(body));
        }
    }, [selectedGenre, selectedPlatform])


    useEffect(() => {
        dispatch(getAllPreferences({ is_public: _global.is_public }));
    }, [])

    return (
        <>
            <MyHelmet
                title={`Trending on ${selectedPlatformOttName}`}
                description={`Explore the latest trends on ${selectedPlatformOttName}. From movies to series, we cover it all at MyFrenzi.`}
                og_title={`Discover What's Trending on ${selectedPlatformOttName} - MyFrenzi`}
                keywords={`${selectedPlatformOttName}, trending, movies, series, MyFrenzi`}
            />
            {(trending.isLoading) && <Loader />}
            <div className="new-container">
                <ContentAccordion
                    isFull={true}
                    title={'PLATFORM_H'}
                    data={'PLATFORM'}
                />
            </div>
            <Grid item xs={12} className='app-section mt-20'>
                <Grid container justifyContent={'center'}>
                    <MyAds type="banner_2" adv_type={'web_trending_platform_detail_top_ad'} hideInMobile={true} />
                </Grid>
            </Grid>
            <Grid className='new-container pb-50'>
                <Grid container alignItems={'center'} className="pt-20" justifyContent='space-between'>
                    <Grid item>
                        <Grid container alignItems={'center'}>
                            <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                            {!is_free ?
                                <p className='fs-24 fw-700 lh-28 m-0'>what's <span className='text-selected'>trending</span></p>
                                :
                                <p className='fs-24 fw-700 lh-28 m-0'><span className='text-selected'>free</span> across platform</p>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Selection
                    platforms={
                        auth.allPreferences && auth.allPreferences.platforms
                            ? auth.allPreferences.platforms
                            : []
                    }
                    setSelectedPlatform={setSelectedPlatform}
                    setSelectedPlatformUuid={setSelectedPlatformUuid}
                    setSelectedPlatformOttName={setSelectedPlatformOttName}
                    selectedPlatform={selectedPlatform}
                    selectedPlatformUuid={selectedPlatformUuid}
                    is_free={is_free}
                    p_name={platform_name || ''}
                />

                <CommonMediumTileListing
                    heading={<>popular <span className='text-selected'>movies</span> on {selectedPlatformOttName}</>}
                    subheading=''
                    selectedPlatformOttName={selectedPlatformOttName}
                    isShowHeading={true}
                    nextLink={`/platforms/${selectedPlatformOttName.replace(/ /g, "-")}/trending-movies${is_free ? '?is_free=true' : ''}`}
                    data={trending.platformMovieData ? trending.platformMovieData : []}
                    currentSlide={currentSlide}
                    setCurrentSlide={setCurrentSlide}
                    refPage={'Trending'}
                    isLoading={trending.isLoading}
                />
                <Grid item key={`ads-last`} style={{ marginTop: '10px' }} >
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_trending_platform_detail_fold_ad'} />
                    </Grid>
                </Grid>

                <CommonMediumTileListing
                    heading={<>popular <span className='text-selected'>web series</span> on {selectedPlatformOttName}</>}
                    subheading=''
                    isShowHeading={true}
                    nextLink={`/platforms/${selectedPlatformOttName.replace(/ /g, "-")}/trending-series${is_free ? '?is_free=true' : ''}`}
                    data={trending.platformTvData ? trending.platformTvData : []}
                    currentSlide={currentSlide}
                    selectedPlatformOttName={selectedPlatformOttName}
                    setCurrentSlide={setCurrentSlide}
                    refPage={'Trending'}
                    isLoading={trending.isLoading}
                />

                <Grid item className=' mt-20'>
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_trending_platform_detail_fold_ad'} />
                    </Grid>
                </Grid>

                {!_global.is_public &&
                    <>
                        <PreferLanguage
                            languages={auth.allPreferences && auth.allPreferences.languages ? auth.allPreferences.languages : []}
                            heading={<>trending in <span className='text-selected'>{selectedLanguageName}</span> on {selectedPlatformOttName}</>}
                            subheading='content that speaks to you'
                            setSelectedLanguage={setSelectedLanguage}
                            selectedLanguage={selectedLanguage}
                            setSelectedLanguageName={setSelectedLanguageName}
                            selectedPlatformOttName={selectedPlatformOttName}
                            data={trending.langMediaData ? trending.langMediaData : []}
                            is_free={is_free}
                            refPage={'Trending'}
                            // nextLink={`/trending/details/searched${is_free ? '&is_free=true' : ''}`}
                            nextLink={`/platforms/${selectedPlatformOttName}/trending-movies&lang=${selectedLanguage}${is_free ? '&is_free=true' : ''}`}
                            isLoading={trending.isLoading}
                        />
                        <Grid item className='app-section mt-20'>
                            <Grid container justifyContent={'center'}>
                                <MyAds type="banner_2" adv_type={'web_trending_platform_detail_fold_ad'} />
                            </Grid>
                        </Grid>
                    </>
                }
                <CommonMediumTileListing
                    heading={<>most <span className='text-selected'>searched</span> on {selectedPlatformOttName}</>}
                    subheading='titles popular with like-minded fans'
                    isShowHeading={true}
                    nextLink={`/platforms/${selectedPlatformOttName.replace(/ /g, "-")}/most-searched${is_free ? '?is_free=true' : ''}`}
                    data={trending.platformMediaSearchedData ? trending.platformMediaSearchedData : []}
                    currentSlide={currentSlide}
                    selectedPlatformOttName={selectedPlatformOttName}
                    setCurrentSlide={setCurrentSlide}
                    refPage={'Trending'}
                    adv_type={'web_trending_platform_detail_ad'}
                    isLoading={trending.isLoading}
                />
                {!_global.is_public &&
                    <>
                        <Grid item className='app-section mt-20'>
                            <Grid container justifyContent={'center'}>
                                <MyAds type="banner_2" adv_type={'web_trending_platform_detail_fold_ad'} />
                            </Grid>
                        </Grid>
                        <PreferMood
                            genres={auth.allPreferences && auth.allPreferences.genres ? auth.allPreferences.genres : []}
                            heading={<>trending in <span className='text-selected'>{selectedGenreName}</span> on {selectedPlatformOttName}</>}
                            subheading='content that appeals to you'
                            setSelectedGenre={setSelectedGenre}
                            selectedGenre={selectedGenre}
                            setSelectedGenreName={setSelectedGenreName}
                            data={trending.genreMediaData ? trending.genreMediaData : []}
                            is_free={is_free}
                            selectedPlatformOttName={selectedPlatformOttName}
                            refPage={'Trending'}
                            // nextLink={`/trending/details/searched${is_free ? '&is_free=true' : '' ? true : ''}`}
                            nextLink={`/platforms/${selectedPlatformOttName}/trending-movies&genre=${selectedGenreName}${is_free ? '&is_free=true' : ''}`}
                            isLoading={trending.isLoading}
                        />
                    </>
                }

                <Grid item className=' mt-20'>
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_trending_platform_detail_bottom_ad'} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
