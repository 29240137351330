import { Grid } from '@mui/material';
import React from 'react'
import helpers from '../../services/helpers';
import ProgressBar from '../component/ProgressBar';
import parse from 'html-react-parser';

export default function MediaSubdetails(props) {
    const { data, showFull, size } = props;

    // console.log("data--",data)

    return (
        <Grid container alignItems={'center'} className={`mt-5 ai-center ${props.className}`}
        // sx={{height:{xs:50, sm:67}}}
        >
            {data.rating && data.rating > 0 ?
                <>
                    <Grid item style={{ minWidth: 30 }}>
                        <ProgressBar value={data.rating ? Number(data.rating) * 10 : 0} width={30} />
                    </Grid>
                    {(data.movie_runtime && data.movie_runtime !== "") || (data.genres && data.genres.length > 0) || (data.release_year) || (data.language && data.language !== "") ?
                        <span className="ott-dot"></span>
                        :
                        ''
                    }
                </>
                :
                ''
            }
            {data.movie_runtime > 0 && data.movie_runtime && data.movie_runtime !== "" ?
                <>
                    <Grid item>
                        <p className={`${size === 'sm' ? 'fs-10' : 'fs-14'} fw-400 text-light m-0`} >
                            {helpers.getMovieRunTime(data.movie_runtime)}
                        </p>
                    </Grid>
                    {(data.genres && data.genres.length > 0) || (data.release_year) || (data.language && data.language !== "") ?
                        <span className="ott-dot"></span>
                        :
                        ''
                    }
                </>
                : ''
            }
            {data.genres && data.genres.length > 0 ?
                <>
                    {data.genres && data.genres.length > 0 ?
                        <>
                            {data.genres.length > 2 && !showFull ?
                                <span key={'oppp'} className={`${size === 'sm' ? 'fs-10' : 'fs-14'} fw-400 text-light m-0`}>{data.genres[0]?.name} +{data.genres.length - 1}</span>
                                : data.genres.map((x, i) => {
                                    return <span key={i} className={`${size === 'sm' ? 'fs-10' : 'fs-14'} fw-400 text-light m-0`}>{x.name}{i < (data.genres.length - 1) ? parse("&nbsp;|&nbsp;") : ''}</span>
                                })
                            }

                        </>
                        :
                        ''
                    }
                    {(data.release_year) || (data.og_lang && data.og_lang !== "") ?
                        <>
                            <p className={`${size === 'sm' ? 'fs-10' : 'fs-14'} fw-400 text-light m-0 pl-5 pr-5`}> | </p>
                            {" "}
                            <p
                                className={`${size === 'sm' ? 'fs-10' : 'fs-14'} fw-400 text-light m-0`}
                                style={{ textTransform: "uppercase" }}
                            >
                                {" "}
                                {data.og_lang ? data.og_lang : ""}
                            </p>
                        </>
                        :
                        ''
                    }
                </>
                :
                ''
            }
            {data.release_year ?
                <>
                    <Grid item >
                        <p className={`${size === 'sm' ? 'fs-10' : 'fs-14'} fw-400 text-light m-0`}>
                            {data.release_year}
                        </p>
                    </Grid>
                    {data.language && data.language !== "" ?
                        <span className="ott-dot"></span>
                        :
                        ''
                    }
                </>
                :
                ''
            }
            {data.language && data.language !== "" ?
                <>
                    <Grid item >
                        <p className={`${size === 'sm' ? 'fs-10' : 'fs-14'} fw-400 text-light m-0`}>
                            {helpers.getLanguage(data.language)}
                        </p>
                    </Grid>
                </>
                :
                ''
            }
        </Grid>
    )
}
