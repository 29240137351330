import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ConnectDataService from "../services/connect.services";
import helpers from "../services/helpers";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    cinephilesUserPageData: {},
    cinephilesUserData: [],
    frenziUserPageData: {},
    frenziUserData: [],
    suggestedPageData: {},
    suggestedData: [],
    suggestedFrenziData: [],
    friendsWatchListPageData: {},
    friendsWatchListData: [],
    followersPageData: {},
    followersData: [],
    followingsPageData: {},
    followingsData: []
};

export const getCinephilesUserDetails = createAsyncThunk(
    "native_main/cinephiles",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ConnectDataService.getCinephilesUserDetails(data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const getFrenziUsers = createAsyncThunk(
    "native_main/frenziusersearch",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ConnectDataService.getFrenziUsers(data?.query, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getSuggestedUserDetails = createAsyncThunk(
    "native_main/suggested",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ConnectDataService.getSuggestedUserDetails(data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getSuggestedFrenziUserDetails = createAsyncThunk(
    "native_main/frenzisuggested",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ConnectDataService.getSuggestedFrenziUserDetails(data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFriendsWatchlist = createAsyncThunk(
    "native_main/friendswatchlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ConnectDataService.getFriendsWatchlist(data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFollowers = createAsyncThunk(
    "native_main/followers",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ConnectDataService.getFollowers(data?.search, data?.userId, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFollowing = createAsyncThunk(
    "native_main/following",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ConnectDataService.getFollowing(data?.search, data?.userId, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const connectSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        }
    },
    extraReducers: {
        [getCinephilesUserDetails.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getCinephilesUserDetails.fulfilled]: (state, action) => {
            state.cinephilesUserPageData = getPayloadData(state, action);
            if (action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.cinephilesUserData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.cinephilesUserData = helpers.removeDuplicate(state.cinephilesUserData.concat(getArrayData(state, action)));
            }
        },
        [getCinephilesUserDetails.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFrenziUsers
        [getFrenziUsers.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFrenziUsers.fulfilled]: (state, action) => {
            state.frenziUserPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.results) {
                if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                    state.frenziUserData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
                } else {
                    state.frenziUserData = helpers.removeDuplicate(state.frenziUserData.concat(getArrayData(state, action)));
                }
            } else {
                state.frenziUserData = [];
            }
        },
        [getFrenziUsers.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getSuggestedUserDetails
        [getSuggestedUserDetails.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getSuggestedUserDetails.fulfilled]: (state, action) => {
            state.suggestedPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.results) {
                if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                    state.suggestedData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
                } else {
                    state.suggestedData = helpers.removeDuplicate(state.suggestedData.concat(getArrayData(state, action)));
                }
            } else {
                state.suggestedData = [];
            }
        },
        [getSuggestedUserDetails.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getSuggestedFrenziUserDetails
        [getSuggestedFrenziUserDetails.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getSuggestedFrenziUserDetails.fulfilled]: (state, action) => {
            state.suggestedFrenziData = getPayloadData(state, action);
        },
        [getSuggestedFrenziUserDetails.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFriendsWatchlist
        [getFriendsWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFriendsWatchlist.fulfilled]: (state, action) => {
            state.friendsWatchListPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.results) {
                if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                    state.friendsWatchListData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
                } else {
                    state.friendsWatchListData = helpers.removeDuplicate(state.friendsWatchListData.concat(getArrayData(state, action)));
                }
            } else {
                state.friendsWatchListData = [];
            }
        },
        [getFriendsWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFollowers
        [getFollowers.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFollowers.fulfilled]: (state, action) => {
            state.followersPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.results) {
                if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                    state.followersData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
                } else {
                    state.followersData = helpers.removeDuplicate(state.followersData.concat(getArrayData(state, action)));
                }
            } else {
                state.followersData = [];
            }
        },
        [getFollowers.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFollowing
        [getFollowing.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFollowing.fulfilled]: (state, action) => {
            state.followingsPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.results) {
                if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                    state.followingsData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
                } else {
                    state.followingsData = helpers.removeDuplicate(state.followingsData.concat(getArrayData(state, action)));
                }
            } else {
                state.followingsData = [];
            }
        },
        [getFollowing.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        }

    }
})

export const { clearMessages } = connectSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default connectSlice.reducer;