import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import CreateWatchlist from './CreateWatchlist';
import CommonMenu from '../common/CommonMenu';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessages, getWatchlistLandingDetail, getWatchlistSection } from '../../slices/watchlist';
import SectionMedia from './SectionMedia';
import LandingWatchlist from './LandingWatchlist';
import helpers from '../../services/helpers';
// import Loader from '../common/Loader';
import MyHelmet from '../common/MyHelmet';
import LinearLoader from '../common/LinearLoader';

export default function Watchlist() {
    const [page, setPage] = useState(1);
    const [currentSection, setCurrentSection] = useState('frenzi');

    const dispatch = useDispatch();
    const watchlist = useSelector(state => state.watchlist);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (watchlist.errMesg && watchlist.errMesg != "") {
            helpers.toastError(watchlist.errMesg);
            dispatch(clearMessages());
        }
        if (watchlist.successMesg && watchlist.successMesg != "") {
            helpers.toastSuccess(watchlist.successMesg);
            dispatch(getWatchlistLandingDetail({ section: 'my_watchlist' }));
            dispatch(getWatchlistSection({ section: currentSection, page: 1, pagelimit: 10 }));
            dispatch(clearMessages());
        }
    }, [watchlist])

    useEffect(() => {
        dispatch(getWatchlistLandingDetail({ section: 'my_watchlist' }));
    }, [])

    useEffect(() => {
        dispatch(getWatchlistSection({ section: currentSection, page: page, pagelimit: 10 }));
    }, [page])

    useEffect(() => {
        const handleScroll = () => {
            if (watchlist.watchlistSectionPageData && watchlist.watchlistSectionPageData[currentSection] && watchlist.watchlistSectionPageData[currentSection].pagination && watchlist.watchlistSectionPageData[currentSection].pagination.is_next_page && !watchlist.isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, watchlist]);


    return (
        <>
            <MyHelmet
                title={'Watchlist'}
                description={'Create Your Frenzi Watchliist: Curate and Share Your Personalized List of Favorites with People and Enjoy the Collective Movie Experience!'}
            />
            {watchlist.isLoading && <LinearLoader />}
            <div className='new-container'>
                <CommonMenu />
                {watchlist.watchlistLandingData && watchlist.watchlistLandingData.watchlist_created ?
                    <LandingWatchlist data={watchlist.watchlistLandingData} />
                    :
                    <CreateWatchlist />
                }
                {/* {watchlist.watchlistLandingData && watchlist.watchlistLandingData.pending_suggestions &&
                    <PendingWatchlist />
                } */}
                <Grid item className='app-container section-border-bottom pt-10 pb-10'>
                    <p className='fw-500 fs-20 m-0'>Recommendations from Frenzi</p>
                </Grid>
                {watchlist.watchlistSectionData && watchlist.watchlistSectionData[currentSection] && watchlist.watchlistSectionData[currentSection].length > 0 && watchlist.watchlistSectionData[currentSection].map((item, i) => {
                    return <SectionMedia refPage={'Watchlist'} data={item} key={i} />
                })}
            </div>
        </>

    )
}
