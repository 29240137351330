import React, { useState } from 'react'
import './watchlist.scss';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ProgressBar from '../component/ProgressBar';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import CreateForm from './CreateForm';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCreateWatchlistFormOpen } from '../../slices/global';
import BookmarkAction from './BookmarkAction';
import { joinWatchlist } from '../../slices/watchlist';
import SuggestTitles from './includes/SuggestTitles';
import AddRemoveFriends from './includes/AddRemoveFriends';
import { MEDIUM_TILE_BREAKPOINTS } from '../../services/helpers';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import MyAds from '../adsense/MyAds';



export default function LandingWatchlist(props) {
    const { data } = props;
    const [addedId, setAddeddId] = useState('');

    const dispatch = useDispatch();
    const _global = useSelector(state => state.global);
    const { isCreateWatchlistFormOpen } = _global;

    const handleJoinWatchlist = (item) => {
        if (item.watchlist_id && item.watchlist_id != "") {
            helpers.trackMoengageEvent('FR3_Join_Watchlist', { join_btn_click: MOENGAGE_ATTRIBUTE.YES, watchlist_name: item?.category_name });
            dispatch(joinWatchlist({ id: item.watchlist_id, body: {} }));
        }
    }

    return (
        <>
            <Grid item className='bg-gunpowder create-watchlist mt-10 p-20 ' xs={12}>
                <Grid item className='app-container pt-10 pb-10'>
                    <Grid container alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item xs={10}>
                            <Grid container alignItems={'center'}>
                                <p className='fw-700 lh-25 fs-28 m-0'>{data.watchlist ? data.watchlist : 'first watchlist'} </p>
                                {!data.is_public &&
                                    <span style={{
                                        borderRadius: '15px',
                                        paddingRight: '10px',
                                        paddingLeft: '10px',
                                        paddingBottom: '1px',
                                        marginLeft: '20px'
                                    }} className='fs-12 lh-12 bg-secondary text-muted'>PRIVATE</span>
                                }
                            </Grid>
                            {/* <p className='fw-500 fs-24 m-0'>My Watchlist</p> */}
                        </Grid>
                        <Grid item className=''>
                            <Link onClick={() => {
                                helpers.trackMoengageEvent('FR3_View_Watchlist', {
                                    watchlist_click: MOENGAGE_ATTRIBUTE.YES,
                                    watchlist_name: data?.category_name
                                });
                                helpers.watchlistClick(data?.category_name, data.watchlist_id, 'Watchlist');
                            }} to={`/watchlist-detail/${data.watchlist_id}`}>
                                <ArrowForwardIcon />
                            </Link>
                        </Grid>
                    </Grid>
                    {data.medias && data.medias.length > 0 &&
                        <Grid item className='mt-20'>
                            <Swiper
                                slidesPerView={2}
                                spaceBetween={30}
                                // slidesPerGroup={5}
                                freeMode={true}
                                navigation={true}
                                speed={1000}
                                breakpoints={MEDIUM_TILE_BREAKPOINTS}
                                modules={[FreeMode, Navigation]}
                                className="mySwiper"
                            >
                                {data.medias.map((x, i) => {
                                    return <>
                                        <SwiperSlide key={i}>
                                            <div style={{ padding: '20px 0px 20px 0px' }} key={i}>
                                                <Grid item className='text-center' sx={{
                                                    height: 225,
                                                    width: '100%',
                                                    maxHeight: { xs: 175, sm: 225 },
                                                    maxWidth: { xs: 100, sm: 155 }
                                                }}>
                                                    <Grid item className='relative'>
                                                        <Link onClick={() => helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', 'Watchlist')} to={helpers.getMediaLink(x)}>
                                                            <Box
                                                                component="img"
                                                                sx={{
                                                                    height: 200,
                                                                    width: '100%',
                                                                    maxHeight: { xs: 150, sm: 200 },
                                                                    maxWidth: { xs: 100, sm: 155 },
                                                                    borderRadius: '9px',
                                                                }}
                                                                alt="poster"
                                                                src={x.poster ? helpers.getFullUrl(x.poster) : helpers.getDefaultImage('watchlistsection')}
                                                            />
                                                        </Link>
                                                        <BookmarkAction data={x} addedId={addedId} setAddedId={setAddeddId} />

                                                    </Grid>
                                                    <Grid container alignItems={'left'} justifyContent={'flex-end'} className=''>
                                                        {/* <p className='m-0 fs-14 fw-400'>{helpers.getOttOrInTheatre(x)}</p> */}
                                                        <ProgressBar value={x.rating ? Number(x.rating) * 10 : 0} />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </SwiperSlide>

                                        {/* {(data.medias.length > 2 ? i === 2 : data.medias.length - 1 === i) &&
                                            <SwiperSlide style={{minWidth:'300px'}} key={`ads=${i}`}>
                                                <MyAds type="banner_3" adv_type={'web_watchlist_create_section_ad'} />
                                            </SwiperSlide>
                                        } */}
                                    </>
                                })}
                            </Swiper>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Accordion expanded={isCreateWatchlistFormOpen} className='p-0 m-0 bg-gunpowder custom-accordian'>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='p-0 m-0 header'
                >
                    <Grid container sx={{justifyContent:{xs:'center',lg:'flex-end'}}} className='app-container w-100'>
                        <Grid item xs={12} sm={4} lg={3}>
                            {!props.section || props.section != "watchlist-section" ?
                                <Link to="/watchlists">
                                    <Grid container sx={{justifyContent:{xs:'center',lg:'flex-end'}}} className="w-100 pr-20">
                                        <Button className='pl-30 pr-30 rounded-btn bg-light text-selected fs-14 mw-320 mb-10 mt-10 w-100 fw-500'>explore watchlist/sharelist</Button>
                                    </Grid>
                                </Link>
                                :
                                !data.is_member ?
                                    <Grid container sx={{justifyContent:{xs:'center',lg:'flex-end'}}} className="w-100 pr-20">
                                        <Button onClick={() => handleJoinWatchlist(data)} className='pl-30 pr-30 rounded-btn bg-light text-selected fs-14 mw-320 w-100 fw-500'>join watchlists</Button>
                                    </Grid>
                                    :
                                    data.role == "owner" ?
                                        <Grid container sx={{justifyContent:{xs:'center',lg:'flex-end'}}} className="w-100 pr-20">
                                            <AddRemoveFriends data={data} isWhite={true} />
                                        </Grid>
                                        :
                                        <Grid container sx={{justifyContent:{xs:'center',lg:'flex-end'}}} className="w-100 pr-20">
                                            <SuggestTitles className={'mw-320'} data={data} />
                                        </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} sm={4} lg={3} xl={3}>
                            <Grid container sx={{justifyContent:{xs:'center',lg:'flex-end'}}} className="w-100">
                                <Button onClick={() => dispatch(setCreateWatchlistFormOpen({ open: !isCreateWatchlistFormOpen, type: true }))} className='pl-30 pr-30 mt-10 mb-10 rounded-btn bg-selected text-light fs-14 mw-320 w-100 fw-500'>create a new watchlist</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className='p-0 m-0'>
                    <CreateForm />
                </AccordionDetails>
            </Accordion>
        </>
    )
}
