import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import "./trending.scss";
import TopTen from "./TopTen";
import NewlyAdded from "./NewlyAdded";
import TrendingPlatform from "./TrendingPlatform";
import FrenziPopular from "./FrenziPopular";
import PreferLanguage from "./PreferLanguage";
import { useDispatch, useSelector } from "react-redux";
import helpers from "../../services/helpers";
import {
  clearMessages,
  getAllWatchLists,
  getCollectionMetadata,
  getFrenziWatchLists,
  getGenreMedia,
  getHandPicked,
  getHiddenGems,
  getLanguageMedia,
  getNewlyAdded,
  getPlatformMedia,
  getPopularToday,
  getTopTen,
  getTrendingInFriends,
} from "../../slices/trending";
import { getAllPreferences, getPlatformList } from "../../slices/auth";
import { clearMessages as clearMessagesWatchlist } from "../../slices/watchlist";
import PreferMood from "./PreferMood";
// import YourWatchlist from "./YourWatchlist";
import CommonMediumTileListing from "../common/CommonMediumTileListing";
import CollectionMedia from "./CollectionMedia";
import { clearMessages as clearMessagesMedia } from "../../slices/media";
import MyAds from "../adsense/MyAds";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import { HandpickSliderNew } from "./HandpickSliderNew";
// import {
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
// } from "@mui/material";
// import CreateWatchlistBox from "./includes/CreateWatchlistBox";
// import CreateForm from "../watchlist/CreateForm";
import "swiper/css/effect-fade";
import MyHelmet from "../common/MyHelmet";
import LinearLoader from "../common/LinearLoader";
import HandpickDefault from "./HandpickDefault";
import { isMobileDevice } from "../../services/Utils";

function FreeTrending(props) {
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedPlatformOttName, setSelectedPlatformOttName] = useState("");
  const [selectedGenreName, setSelectedGenreName] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [currentLoading, setCurrentLoading] = useState(false);
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [removedId, setRemovedId] = useState([]);

  const dispatch = useDispatch();
  const [selectedLanguageName, setSelectedLanguageName] = useState("");
  const trending = useSelector((state) => state.trending);
  const watchlist = useSelector((state) => state.watchlist);
  const media = useSelector((state) => state.media);
  const auth = useSelector((state) => state.auth);
  const _global = useSelector((state) => state.global);
  // const { isCreateWatchlistFormOpen } = _global;

  useEffect(() => {
    let isDone = false;
    setTimeout(() => {
      let y = localStorage.getItem("ffsy") || 0;
      window.scrollTo(0, y);
      isDone = true;
    }, 0)

    function handleScroll() {
      if (isDone) {
        const scrolledY = window.scrollY;
        localStorage.setItem("ffsy", scrolledY);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    setCurrentLoading(true);
    dispatch(
      getPlatformList({
        is_free: true,
        page: 1,
        pagelimit: 50,
        is_public: _global.is_public,
      })
    );
  }, []);

  useEffect(() => {
    if (auth.successMesg && auth.successMesg != "") {
      dispatch(
        getAllPreferences({ is_public: _global.is_public, is_free: true })
      );
    }
  }, [auth]);

  useEffect(() => {
    if (trending.errMesg && trending.errMesg != "") {
      helpers.toastError(trending.errMesg);
      dispatch(clearMessages());
    }
    if (trending.successMesg && trending.successMesg != "") {
      helpers.toastSuccess(trending.successMesg);
      dispatch(clearMessages());
    }
  }, [trending]);

  useEffect(() => {
    if (media.errMesg && media.errMesg != "") {
      helpers.toastError(media.errMesg);
      dispatch(clearMessagesMedia());
    }
    if (media.successMesg && media.successMesg != "") {
      helpers.toastSuccess(media.successMesg);
      refreshData();
      dispatch(clearMessagesMedia());
    }
  }, [media]);

  useEffect(() => {
    if (watchlist.errMesg && watchlist.errMesg != "") {
      helpers.toastError(watchlist.errMesg);
      dispatch(clearMessagesWatchlist());
    }
    if (watchlist.successMesg && watchlist.successMesg != "") {
      helpers.toastSuccess(watchlist.successMesg);
      dispatch(clearMessagesWatchlist());
    }
  }, [watchlist]);

  useEffect(() => {
    if (selectedPlatform && selectedPlatform != "") {
      var body = {
        page: 1,
        pagelimit: 10,
        id: selectedPlatform,
        is_free: true,
      };
      dispatch(getPlatformMedia(body));
    }
  }, [selectedPlatform]);

  useEffect(() => {
    if (selectedLanguage && selectedLanguage != "") {
      var body = {
        page: 1,
        pagelimit: 10,
        id: selectedLanguage,
        is_free: true,
      };
      dispatch(getLanguageMedia(body));
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (selectedGenre && selectedGenre != "") {
      var body = {
        page: 1,
        pagelimit: 10,
        id: selectedGenre,
        is_free: true,
      };
      dispatch(getGenreMedia(body));
    }
  }, [selectedGenre]);

  useEffect(() => {
    var body = {
      page: 1,
      pagelimit: 10,
      is_free: true,
    };
    dispatch(getHandPicked(body)).then((x) => {
      setCurrentLoading(false);
    });
    dispatch(getNewlyAdded(body));
    dispatch(getTopTen(body));
    dispatch(
      getAllPreferences({ is_public: _global.is_public, is_free: true })
    );
    dispatch(getPopularToday(body));
    dispatch(getTrendingInFriends(body));
    dispatch(getFrenziWatchLists(body));
    dispatch(getAllWatchLists(body));
    dispatch(getHiddenGems(body));
    dispatch(getCollectionMetadata({ is_trending: 0 }));
  }, []);

  const refreshData = () => {
    var body = {
      page: 1,
      pagelimit: 10,
      is_free: true,
    };
    dispatch(getHandPicked(body));
  };

  const handleStop = () => {
    controlledSwiper.autoplay.stop();
  };

  const handlePlay = () => {
    controlledSwiper.autoplay.start();
  };

  return (
    <>
      <MyHelmet
        title={"What's free to watch on your preferred platform"}
        description={
          "Discover Free Movies and Series on Your Favorite Platform, Find out What's Available to Watch on OTT without Spending a Dime!"
        }
      />
      {(currentLoading || auth.isLoading) && <LinearLoader />}
      <Grid item xs={12} className="app-section" style={{ overflow: "hidden" }}>
        {trending.handpickedPageData?.handpicked_response &&
          trending.handpickedPageData.handpicked_response?.response &&
          trending.handpickedPageData.handpicked_response.response.length >
          0 ? (
          <>
            <Grid xs={12}>
              <Swiper
                centeredSlides={true}
                fadeEffect={{ crossFade: true }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                }}
                // loop={true}
                // speed={1500}
                // navigation={true}
                effect={"fade"}
                modules={[Autoplay, Navigation, EffectFade, Pagination]}
                className="new-swiper"
                onSwiper={setControlledSwiper}
                pagination={{
                  clickable: true,
                }}
              >
                {trending.handpickedPageData.handpicked_response.response.map(
                  (x, i) => {
                    if (!removedId.includes(x.media)) {
                      return (
                        <SwiperSlide key={i}>
                          <HandpickSliderNew
                            handlePlay={handlePlay}
                            handleStop={handleStop}
                            data={x}
                            isShowTag={false}
                            setRemovedIds={(id) => setRemovedId([...removedId, id])}
                          />
                        </SwiperSlide>
                      );
                    }
                  }
                )}
              </Swiper>
            </Grid>
          </>
        )
          :
          <HandpickDefault />
        }
      </Grid>

      <Grid container className="new-container">

        <Grid flex={1} className="left-container">
          <Grid
            container
            className="app-container  w-100 pt-10 pb-40"
            justifyContent={"space-between"}
          >
            <Grid item xs={12} className="app-section">
              {trending.freshPageData?.results &&
                trending.freshPageData.results.length > 0 && (
                  <NewlyAdded
                    data={trending.freshPageData.results}
                    heading={
                      <>
                        <span className="text-selected">fresh & free</span><span className=""> on OTT</span>
                      </>
                    }
                    subheading={"start building your wathclist"}
                    is_free={true}
                  />
                )}

              <TrendingPlatform
                isShowHeading={true}
                platforms={
                  auth.allPreferences && auth.allPreferences.platforms
                    ? auth.allPreferences.platforms
                    : []
                }
                setSelectedPlatform={setSelectedPlatform}
                selectedPlatform={selectedPlatform}
                setSelectedPlatformOttName={setSelectedPlatformOttName}
                selectedPlatformOttName={selectedPlatformOttName}
                data={trending.platformMediaData ? trending.platformMediaData : []}
                heading={
                  <>
                    <span className="">trending free on</span>{" "}
                    <span className="text-selected">{selectedPlatformOttName}</span>
                  </>
                }
                subheading={"trending free content across platforms"}
                is_free={true}
                withAd={true}
              />

              <Grid container justifyContent={'space-between'}>
                <MyAds type="banner_2" mobtype={"mobile_1"} slotId={'8155963946'} adv_type={'web_free_fold_ad'} />
              </Grid>

              {trending.popularTodayData &&
                trending.popularTodayData.length > 0 && (
                  <FrenziPopular
                    data={trending.popularTodayData}
                    isShowHeading={true}
                    is_free={true}
                    adv_type={"web_popular_free_ad"}
                    heading={
                      <>
                        <span className="text-selected">popular </span> &
                        <span className="text-selected"> free </span>on frenzi
                      </>
                    }
                    withAd={true}
                  />
                )}

              {/* {trending.freshPageData?.results &&
            trending.freshPageData.results.length > 0 && (
              <NewlyAdded
                data={trending.freshPageData.results}
                heading={
                  <>
                    <span className="text-selected">fresh & free</span> on OTT
                  </>
                }
                subheading={"start building your wathclist"}
                is_free={true}
              />
            )} */}

              <CommonMediumTileListing
                heading={
                  <>
                    <span className="text-selected">hidden</span> gems
                  </>
                }
                subheading="don't miss these!"
                isShowHeading={true}
                nextLink={`/hidden-gems-on-ott?is_free=true`}
                data={trending.hiddenGemsData}
                refPage={"Trending"}
                withAd={true}
              />

              <Grid container justifyContent={'space-between'}>
                <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"8198258178"} height={100} adv_type={'web_free_fold_ad'} />
              </Grid>

              {trending.collectionMetaData &&
                trending.collectionMetaData.length > 0 &&
                trending.collectionMetaData.map((x, i) => {
                  return (
                    <div key={i}>
                      <CollectionMedia withAd={true} data={x} is_free={true} />
                    </div>
                  );
                })
              }

              {trending.toptenPageData?.results &&
                trending.toptenPageData.results.length > 0 && (
                  <>
                    <TopTen withAd={true} is_free={true} isTopTen={true} />
                    <Grid container justifyContent={'center'}>
                      <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"2945931490"} height={100} adv_type={'web_free_fold_ad'} />
                    </Grid>
                  </>
                )
              }

            </Grid>
          </Grid>

          <Grid
            container
            className="app-container pt-20 pb-40"
            justifyContent={"space-between"}
          >
            <Grid item xs={12} className="app-section">
              <PreferLanguage
                languages={
                  auth.allPreferences && auth.allPreferences.languages
                    ? auth.allPreferences.languages
                    : []
                }
                setSelectedLanguage={setSelectedLanguage}
                selectedLanguage={selectedLanguage}
                selectedLanguageName={selectedLanguageName}
                setSelectedLanguageName={setSelectedLanguageName}
                data={trending.langMediaData ? trending.langMediaData : []}
                heading={
                  <>
                    <span className="">trending free titles in</span>{" "}
                    <span className="text-selected ">{selectedLanguageName}</span>
                  </>
                }
                subheading={"get language specific content"}
                is_free={true}
                refPage={"Trending"}
                withAd={true}
              />

              <Grid container justifyContent={'space-between'}>
                <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"4133275680"} width={300} height={250} adv_type={'web_free_fold_ad'} />
              </Grid>

              <PreferMood
                refPage={"Trending"}
                genres={
                  auth.allPreferences && auth.allPreferences.genres
                    ? auth.allPreferences.genres
                    : []
                }
                setSelectedGenre={setSelectedGenre}
                selectedGenreName={selectedGenreName}
                setSelectedGenreName={setSelectedGenreName}
                selectedGenre={selectedGenre}
                data={trending.genreMediaData ? trending.genreMediaData : []}
                heading={
                  <>
                    <span className="">trending free titles in</span>{" "}
                    <span className="text-selected ">{selectedGenreName}</span>
                  </>
                }
                subheading={"let your mood decide"}
                is_free={true}
                withAd={true}
              />

              <Grid container className="app-section" justifyContent={"center"}>
                <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"8128361012"} width={250} height={250} adv_type={"web_free_page_bottom_ad"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {!isMobileDevice() ?
          <Grid className="right-container">
            <Grid className="right-container-ad">
              <MyAds type="banner_1" adv_type={'web_trending_top_ad'} />
            </Grid>
          </Grid>
          : ''}
      </Grid>
    </>
  );
}

export default FreeTrending;
