import axiosInstance from "../http-common";


const getCommonForAllPublicData = (date_range = "all", path = '', is_free = false, body = {}, media_type = "all", sort = '', page = 1, pagelimit) => {
    let url = `native_main/${path}`;
    if (url.indexOf("?") > -1) {
        url += `&page=${page}&prefered_platforms=yes&is_web=yes`
    } else {
        url += `?page=${page}&prefered_platforms=yes&is_web=yes`
    }
    if(body?.exclude_already_watched && body?.exclude_already_watched != ''){
        url += `&exclude_already_watched=${body?.exclude_already_watched}`;
    }else{
        url += `&exclude_already_watched=yes`;
    }
    if (media_type && media_type != "") {
        url += `&media_type=${media_type}`;
    }
    if (sort && sort != "") {
        url += `&sort=${sort}`;
    }
    if (is_free && is_free != "") {
        url += `&is_free=${is_free}`;
    }
    if (pagelimit && pagelimit != "") {
        url += `&pagelimit=${pagelimit}`;
    }
    if (date_range && date_range != "") {
        url += `&date_range=${date_range}`;
    }
    return axiosInstance.post(url, body);
}


const PublicDataService = {
    getCommonForAllPublicData
}

export default PublicDataService;
