import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import { followUser, unFollowUser } from '../../slices/cast';
import CustomButton from './CustomButton'

export default function FollowUnfollowPerson(props) {
    const { data } = props;
    const dispatch = useDispatch();
    const [currentData, setCurrentData] = useState({});


    useEffect(() => {
        if (data.uuid) {
            const newData = {
                has_requested: data.has_requested ? data.has_requested : false,
                is_followed: data.is_followed ? data.is_followed : false,
                is_following: data.is_following ? data.is_following : false,
                is_requested: data.is_requested ? data.is_requested : false,
                uuid: data.uuid ? data.uuid : ''
            }
            setCurrentData({ ...newData });
        }
    }, [data])

    const handleFollowUnfollowUser = (data, type) => {
        props.onClick && props?.onClick();
        let tracKKey = type && type !== '' ? type.replace(/ /g, "_") : 'button';
        helpers.trackMoengageEvent('FR3_Connect', { [`${tracKKey}_click`]: MOENGAGE_ATTRIBUTE.YES });

        if (type === "follow" || type === "follow back") {
            dispatch(followUser({ body: { target_user: data.uuid } })).then((e) => {
                if (e.payload && e.payload.data) {
                    setCurrentData({ ...e.payload.data, uuid: data.uuid });
                }
            });
        } else if (type === "unfollow" || type === "following" || type === "requested") {
            dispatch(unFollowUser({ body: { target_user: data.uuid } })).then((e) => {
                if (e.payload && e.payload.data) {
                    setCurrentData({ ...e.payload.data, uuid: data.uuid });
                }
            });
        } else if (type === "chat") {

        }
    }

    const btnColour = props.isInverted ? 'bg-selected text-light' : 'bg-light text-selected';
    const btnColourActive = props.isInverted ? 'bg-light text-selected' : 'bg-selected text-light';

    return (
        <>
            {props?.iconBtn ?
                <>
                    <button onClick={() => handleFollowUnfollowUser(currentData, helpers.filterActionName(currentData))} className='btn icon-btn bg-selected text-light pointer'>
                        {helpers.filterActionName(currentData) === 'follow' ?
                            <>
                                <svg className='invert mr-10' width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.625 10.6251H19.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M17.5 8.75006V12.5001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.4375 12.5001C11.0263 12.5001 13.125 10.4014 13.125 7.81256C13.125 5.22373 11.0263 3.12506 8.4375 3.12506C5.84867 3.12506 3.75 5.22373 3.75 7.81256C3.75 10.4014 5.84867 12.5001 8.4375 12.5001Z" stroke="white" stroke-width="2" stroke-miterlimit="10" />
                                    <path d="M1.73438 15.6249C2.55573 14.6463 3.5814 13.8595 4.73932 13.3196C5.89724 12.7798 7.15933 12.5001 8.43691 12.5001C9.71449 12.5001 10.9766 12.7798 12.1345 13.3197C13.2924 13.8595 14.3181 14.6464 15.1394 15.6249" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                follow
                            </>
                            :
                            helpers.filterActionName(currentData)
                        }
                    </button>
                </>
                :
                <>
                    {props.isLarge ?
                        <Button style={{ width: props.isFullWidth ? '100%' : '80%' }} onClick={() => handleFollowUnfollowUser(currentData, helpers.filterActionName(currentData))}
                            className={`rounded-btn ${(helpers.filterActionName(currentData) === 'following' || helpers.filterActionName(currentData) === 'requested') ? btnColourActive : btnColour} pointer`}
                        >
                            {helpers.filterActionName(currentData)}
                        </Button>
                        :
                        <CustomButton
                            label={helpers.filterActionName(currentData)}
                            selected={(helpers.filterActionName(currentData) === 'following' || helpers.filterActionName(currentData) === 'requested') ? true : false}
                            onClick={() => handleFollowUnfollowUser(currentData, helpers.filterActionName(currentData))}
                        />
                    }
                </>
            }
        </>
    )
}
