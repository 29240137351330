import Grid from "@mui/material/Grid";
import React from "react";
import ReactPlayer from "react-player";
// import MyAds from '../adsense/MyAds'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, FreeMode, Navigation, Pagination, Scrollbar } from "swiper";
import helpers from "../../services/helpers";

const breakpoints = {
  319: {
    slidesPerView: 1,
    spaceBetween: 15,
  },
  425: {
    slidesPerView: 1.5,
    spaceBetween: 15,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 2.5,
    spaceBetween: 20,
    slidesPerGroup: 1
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 20,
    slidesPerGroup: 2
  },
  1440: {
    slidesPerView: 3,
    spaceBetween: 20,
    slidesPerGroup: 1
  },
  1880: {
    slidesPerView: 4,
    spaceBetween: 30,
    slidesPerGroup: 2
  },
  2560: {
    slidesPerView: 5,
    spaceBetween: 30,
    slidesPerGroup: 3
  },
}

const breakpoints2 = {
  319: {
    slidesPerView: 1,
    spaceBetween: 15,
  },
  425: {
    slidesPerView: 1.5,
    spaceBetween: 15,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 2.5,
    spaceBetween: 20,
    slidesPerGroup: 1
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 20,
    slidesPerGroup: 2
  },
  1440: {
    slidesPerView: 3,
    spaceBetween: 20,
    slidesPerGroup: 1
  },
  1880: {
    slidesPerView: 3.5,
    spaceBetween: 30,
    slidesPerGroup: 2
  },
  2560: {
    slidesPerView: 4,
    spaceBetween: 30,
    slidesPerGroup: 3
  },
}


export default function Trailers(props) {
  const { data, mediaData, withAd } = props;
  return (
    <Grid
      className="pt-20 pb-30 app-section"
    >
      <Grid item xs={12}>
        <h2 className="fs-20 fw-600 mb-10 pb-14">
          <span className="">trailers</span>
        </h2>
      </Grid>
      <Grid item xs={12}>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          slidesPerView={2.5}
          spaceBetween={10}
          // centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          // loop={true}
          // navigation={true}
          modules={[Autoplay, Navigation, Pagination]}
          id='trailerCard'
          className="mySwiper align-miidle-wrapper"
        >
          {data.length > 0 &&
            data.map((x, i) => {
              return (<>
                {/* {(data.length > 3 ? i === 2 : data.length - 1 === i) &&
                  <SwiperSlide style={{ minWidth: '300px' }} key={`ads-${i}`}>
                    <MyAds type="banner_3" adv_type={'web_movie_detail_trailers_ad'} />
                  </SwiperSlide>
                } */}
                <SwiperSlide key={i}>
                  <Grid item>
                    <ReactPlayer
                      url={`https://www.youtube.com/watch?v=${x.key}`}
                      // light={VideoBan}
                      className={"mw-400 mh-200"}
                      style={{ borderRadius: 8 }}
                      controls={true}
                      onPlay={() => {
                        let myData = mediaData;
                        let trackData = {
                          title: myData?.title,
                          type: myData?.media_type,
                          ref_page: document?.referrer || '',
                        }
                        helpers.trackMoengageEvent('FR3_Trailer', trackData)
                      }}
                    />
                  </Grid>
                </SwiperSlide>
              </>);
            })}
        </Swiper>
      </Grid>
    </Grid>
  );
}
