
let limit = new Date().getFullYear() - 1954

export const YEARS = Array.from({ length: limit }, (_, i) =>
  (1955 + i).toString()
);

export const DEFAULT_ADV_FILTER = {
  genres: [],
  ott_platforms: [],
  available_languages: [],
  content_type: [],
  exclude_already_watched: "",
  sort: "",
  rating: 0,
  apply_filter: false,
  from_year: '',
  contributors: [],
  to_year: '',
  // to_year: new Date().getFullYear(),
  certification: [],
  available_countries : 'IN'
};
