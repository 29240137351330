import { LinearProgress } from '@mui/material'
import React from 'react'

export default function Loader() {
  return (
    <div className="line-loader">
      <LinearProgress />
    </div>
  )
}
