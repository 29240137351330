import React, { useEffect, useState } from 'react'
import Detail from './Detail'
import './cast.scss';
import Fans from './Fans';
import Films from './Films';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clearCastData, clearMessages, getCastDetail, getPersonMedia, getTopFans } from '../../slices/cast';
import { clearMessages as clearMessagesMedia } from '../../slices/media';
import helpers from '../../services/helpers';
import MyAds from '../adsense/MyAds';
import { Grid } from '@mui/material';
import MyHelmet from '../common/MyHelmet';
import { getActorSchemaMarkup } from '../../services/Utils';
import LinearLoader from '../common/LinearLoader';

export default function CastCrewDetail(props) {
    const [page, setPage] = useState(1);
    const [currentLoading, setCurrentLoading] = useState(false);
    const dispatch = useDispatch();
    const cast = useSelector(state => state.cast);
    const { castData, topFansData, personMediaData, personMediaPageData } = cast;

    const params = useParams();
    const media = useSelector(state => state.media);

    useEffect(() => {
        dispatch(clearCastData());
        setCurrentLoading(true);
        var mid = params?.uuid;
        var body = {
            id: mid,
            unique_title: !mid ? params?.name : null,
            is_public: props?.is_public
        }
        dispatch(getCastDetail(body)).then((res) => {
            let actorData = res.payload.data;
            let trackData = {
                actor_name: actorData?.name,
                name: actorData?.name,
                dob: actorData?.birthday,
                department: actorData?.known_for_department,
                ref_page: 'Movie Detail',
                ref_url: document?.referrer,
            }
            helpers.trackMoengageEvent('FR3_Celebrity', trackData);
            setCurrentLoading(false);
            dispatch(getTopFans({ id: actorData?.uuid, page: 1, pagelimit: 20 }));
        });
        window.scrollTo(0, 0);
    }, [params, dispatch])

    useEffect(() => {
        if (media.errMesg && media.errMesg !== "") {
            helpers.toastError(media.errMesg);
            dispatch(clearMessagesMedia());
        }
        if (media.successMesg && media.successMesg !== "") {
            helpers.toastSuccess(media.successMesg);
            var mid = castData?.uuid;
            if (mid) {
                var body = {
                    id: mid,
                    page: 1,
                    pagelimit: 10,
                    is_public: props?.is_public
                }
                dispatch(getPersonMedia(body));
                var body2 = {
                    id: mid,
                    unique_title: !mid ? params?.name : null,
                    is_public: props?.is_public
                }
                dispatch(getCastDetail(body2));
            }
            dispatch(clearMessagesMedia());
        }
    }, [media, castData, dispatch])

    useEffect(() => {
        if (cast.errMesg && cast.errMesg !== "") {
            helpers.toastError(cast.errMesg);
            dispatch(clearMessages());
        }
        if (cast.successMesg && cast.successMesg !== "") {
            helpers.toastSuccess(cast.successMesg);
            var mid = castData?.uuid;
            if (mid) {
                var body = {
                    id: mid,
                    unique_title: !mid ? params?.name : null,
                    is_public: props?.is_public
                }
                dispatch(getCastDetail(body));
                dispatch(getTopFans({ id: mid, page: 1, pagelimit: 20 }));
            }
            dispatch(clearMessages());
        }
    }, [cast, castData, dispatch])

    useEffect(() => {
        var mid = castData?.uuid || null;
        if (mid) {
            var body = {
                id: mid,
                page: page,
                pagelimit: 10,
                is_public: props?.is_public
            }
            dispatch(getPersonMedia(body));
        }
    }, [page, castData, dispatch])


    useEffect(() => {
        const handleScroll = () => {
            if (personMediaPageData && personMediaPageData && personMediaPageData.pagination && personMediaPageData.pagination.is_next_page && !cast.isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, cast]);

    return (
        <div className='mb-30 new-container'>
            <MyHelmet
                title={castData ? castData?.name : ''}
                description={`Explore the Filmography of ${castData ? castData?.name : 'Actor'}, The Achievements, The Critically Acclaimed Shows, The Highest Rated Movies, Series and more.`}
                schemaMarkup={getActorSchemaMarkup(castData && castData.name ? castData : null)}
                og_image={castData.poster
                    ? helpers.getFullUrl(castData.poster)
                    : helpers.getDefaultImage("cinescoregenres")}
            />
            {currentLoading && <LinearLoader />}
            <Detail data={castData ? castData : {}} />
            <Grid container style={{ marginTop: '20px' }} justifyContent={'center'}>
                <MyAds type="banner_2" adv_type={'web_cast_page_banner_ad'} />
            </Grid>
            {topFansData && topFansData.length > 0 &&
                <Fans data={topFansData} name={castData.name ? castData.name : ''} />
            }
            {personMediaData && personMediaData.length > 0 &&
                <Films title={castData ? castData?.name : ''} data={personMediaData} isLoading={cast.isLoading} />
            }
            <Grid container style={{ marginTop: '20px' }} justifyContent={'center'}>
                <MyAds type="banner_2" adv_type={'web_cast_page_bottom_ad'} />
            </Grid>
        </div>
    )
}
