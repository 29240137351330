import {Grid} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserRecommendedData } from '../../../slices/settings';
import SingleMediaTile from '../../common/SingleMediaTile';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

export default function MyTitles(props) {
    const { userId, isOwner } = props;
    const [page, setPage] = useState(1);

    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const { recommendedData, userReviewPageData, isLoading } = settings;

    useEffect(() => {
        if (userId && userId !== "") {
            dispatch(getUserRecommendedData({ category: 'total_likes', user: userId, page: page, pagelimit: 20 }));
        } else {
            if (isOwner) {
                dispatch(getUserRecommendedData({ category: 'total_likes', user: '', page: page, pagelimit: 20 }));
            }
        }
    }, [userId, isOwner, page])

    useEffect(() => {
        const handleScroll = () => {
            if (userReviewPageData && userReviewPageData && userReviewPageData.pagination && userReviewPageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, settings]);

    return (
        <Grid item className='app-container mt-20'>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <h2 className='fw-700 fs-28 m-0 ellipsis'>movies that I’ve liked</h2>
                <ArrowBackRoundedIcon className='pointer' onClick={props.handleBack} />
            </Grid>
            {/* <Grid container justifyContent={'space-between'} className='mt-20'>
                <Button className='rounded-btn mw-240 bg-light text-selected fs-14 fw-500 '>
                    explore more titles
                </Button>
                <Button className='rounded-btn mw-120 bg-selected text-light fs-14 fw-500 '>
                    filter
                </Button>
            </Grid> */}
            <Grid container spacing={3} style={{ marginTop: 10 }}>
                {recommendedData && recommendedData.length > 0 ? recommendedData.map((x, i) => {
                    return <Grid item xs={12} sm={6} md={4} key={i}>
                        <SingleMediaTile refPage={'Profile'} x={x} />
                    </Grid>
                })
                    : !isLoading &&
                    <p className='fs-14 text-muted text-center'>No Data</p>
                }
            </Grid>
        </Grid >
    )
}

