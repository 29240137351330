import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import FollowUnfollowPerson from '../common/FollowUnfollowPerson';
import { useSelector } from 'react-redux';

export default function UserData(props) {
    const { x } = props;
    const settings = useSelector((state) => state.settings);
    const { userDetails } = settings;


    return (
        <Grid item xs={12}>
            <Grid container className='mt-20'>
                <Grid item sx={{ maxWidth: 80, marginRight: '20px', }}>

                    <Grid item sx={{ height: { xs: '60px', sm: '80px' } }}>
                        <Link onClick={() => helpers.trackMoengageEvent('FR3_Connect', { user_pic_click: MOENGAGE_ATTRIBUTE.YES })} to={`/profile/${x.uuid}`}>
                            <Box
                                className="pointer"
                                component="img"
                                sx={{
                                    height: 80,
                                    width: 80,
                                    maxHeight: { xs: 60, sm: 80 },
                                    maxWidth: { xs: 60, sm: 80 },
                                    borderRadius: "50%",
                                    border: '1px solid #fff',
                                    // objectFit: 'cover',
                                    // objectPosition: x.profile_image && x.profile_image !== '' ? 'top' : 'center'
                                }}
                                alt={x?.username || 'profile'}
                                src={x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage('user')}
                            />
                        </Link>
                    </Grid>

                    {/* <Grid item className='mt-20'>
                        <Stack
                            direction="row"
                            className="ai-center ml-20"
                        >
                            {x.follow_users_profile_img && x.follow_users_profile_img.length > 0 && x.follow_users_profile_img.map((y, k) => {
                                return (
                                    <img
                                        key={k}
                                        alt=""
                                        src={y && y != "" ? helpers.getFullUrl(y) : helpers.getDefaultImage('user')}
                                        className='activity-avatar'
                                    />
                                );
                            })}
                        </Stack>
                        <p className="fs-11 fw-500 pl-5 m-0 pt-4">
                            {x.follow_users_count && x.follow_users_count > 3 ? "+" + (x?.follow_users_count - 3) : ''} followers
                        </p>
                    </Grid> */}
                </Grid>
                <Grid item xs={8.2} sm="auto">
                    {/* <p className='fw-500 fs-12 m-0 text-dark-grey'>{x?.cinematch}% cinematch</p> */}
                    <Link onClick={() => helpers.trackMoengageEvent('FR3_Connect', { username_click: MOENGAGE_ATTRIBUTE.YES })} to={`/profile/${x.uuid}`}>
                        <Typography title={x?.username} className='fw-700 fs-20 lh-25 m-0 ellipsis' sx={{ width: { xs: '20ch', sm: '12ch' } }} >{x?.username}</Typography>
                    </Link>
                    {x.unique_name !== 'frenziofficial' &&
                        <Grid container sx={{ marginTop: '10px' }} >
                            <Grid item xs={12} sx={{ marginRight: { xs: '20px', sm: '0px' } }}>
                                <Stack
                                    direction="row"
                                    className="ai-center ml-20 mw-47 w-100"
                                >
                                    {x.follow_users_profile_img && x.follow_users_profile_img.length > 0 && x.follow_users_profile_img.map((y, k) => {
                                        return (
                                            <img
                                                key={k}
                                                alt=""
                                                src={y && y !== "" ? helpers.getFullUrl(y) : helpers.getDefaultImage('user')}
                                                className='activity-avatar'
                                            />
                                        );
                                    })}
                                </Stack>
                                <p className="fs-11 fw-500 pl-5 m-0 pt-4">
                                    {x.follow_users_count && x.follow_users_count > 3 ? "+" + (x?.follow_users_count - 3) : ''} followers
                                </p>
                            </Grid>
                            {x.uuid !== userDetails.uuid &&
                                <Grid item xs={12} className='mt-18 w-100'>
                                    {/* <Button className="rounded-btn w-100 mw-110 bg-secondary text-light fs-12 fw-700 mb-15">
                            watchlist
                        </Button> */}
                                    <div className='w-100'>
                                        <FollowUnfollowPerson isLarge={false} isFullWidth={true} data={x} />
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    }

                    {/* <Grid container justifyContent={'space-between'} className='mt-12'>
                        <Grid item className='w-100 mw-110'>
                            <Grid container>
                                <Grid sx={{ border: '1px solid white', borderRadius: '50px 0px 0px 50px' }} item className='bg-light w-100 mw-60 text-center p-5'>
                                    <p className='fw-600 fs-11 m-0 text-dark'>cinescore</p>
                                </Grid>
                                <Grid sx={{ border: '1px solid white', borderRadius: '0px 50px 50px 0px' }} item className='bg-secondary w-100 mw-45 text-center p-5'>
                                    <p className='fw-600 fs-11 m-0'>{x?.cinescore}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className='w-100 mw-110'>
                            <Grid container>
                                <Grid sx={{ border: '1px solid white', borderRadius: '50px 0px 0px 50px' }} item className='bg-light w-100 mw-60 text-center p-5'>
                                    <p className='fw-600 fs-11 m-0 text-dark'>reviews</p>
                                </Grid>
                                <Grid sx={{ border: '1px solid white', borderRadius: '0px 50px 50px 0px' }} item className='bg-secondary w-100 mw-45 text-center p-5'>
                                    <p className='fw-600 fs-11 m-0'>3251</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> */}

                </Grid>
            </Grid>
        </Grid>
    )
}
