import React, { useEffect, useState } from 'react'
import { Grid, Box, Button, Stack, Avatar, Typography, Modal, Popover, TextField, Switch, FormGroup, FormControlLabel } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowBack from '@mui/icons-material/ArrowBackRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { clearMessages, clearWatchlistTitleData, deleteLeaveWatchlist, getWatchlistTitleDetail, joinWatchlist, updateWatchlist } from '../../slices/watchlist';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import Loader from '../common/Loader';
import ConfirmDialog from '../common/ConfirmDialog';
import WatchlistMember from './WatchlistMember';
import WatchlistDrawerFilter from '../component/WatchlistDrawerFilter';
import { updateWatchlistMediaFilter } from '../../slices/global';
import MovieTile from '../common/MovieTile';
import SuggestTitles from './includes/SuggestTitles';
import AddRemoveTitles from './includes/AddRemoveTitles';
import AddRemoveFriends from './includes/AddRemoveFriends';
import PendingSuggestions from './includes/PendingSuggestions';
import ClearIcon from '@mui/icons-material/Clear';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import ShareMovie from '../common/ShareMovie';
import { getClipsCustomData } from '../../slices/clips';
import ClipsSlider from '../common/ClipsSlider';
import MyHelmet from '../common/MyHelmet';
import MyAds from '../adsense/MyAds';

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '75%',
    height: '75%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'transparent',
    boxShadow: 24,
    borderRadius: '7px',
    p:4
};

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: { xs: '90%', sm: '50%', md: '40%' },
    maxHeight: { xs: '75%', sm: '90%' },
    transform: 'translate(-50%, -50%)',
    bgcolor: 'transparent',
    boxShadow: 24,
    borderRadius: '7px',
};

const anchor = "right";

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: pink[600],
        '&:hover': {
            backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: pink[600],
    },
}));

export default function WatchlistDetail(props) {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [memberView, setMemberView] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [editWatchlistName, setEditWatchlistName] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [confirmActionType, setConfirmActionType] = useState('');
    const [page, setPage] = useState(1);
    const [openPendingSuggestions, setOpenPendingSuggestions] = useState(false);
    const [openEditWatchlist, setOpenEditWatchlist] = useState(false);
    const [isClipLoading, setIsClipLoading] = useState(false);
    const [clipsPagination, setClipsPagination] = useState({});
    const [clipsData, setClipsData] = useState([]);
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const watchlist = useSelector(state => state.watchlist);
    const _global = useSelector(state => state.global);
    const { watchlistMediaFilter } = _global;

    const [currentLoading, setCurrentLoading] = useState(true);

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        dispatch(clearWatchlistTitleData());
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (!watchlist.isLoading) {
            setTimeout(() => {
                setCurrentLoading(false);
            }, 700)
        }
    }, [watchlist])

    const handleClickListItem2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const toggleDrawer = (open) => {
        setDrawerOpen(open);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    useEffect(() => {
        if (watchlist.errMesg && watchlist.errMesg !== "") {
            helpers.toastError(watchlist.errMesg);
            dispatch(clearMessages());
        }
        if (watchlist.successMesg && watchlist.successMesg !== "") {
            helpers.toastSuccess(watchlist.successMesg);
            if (params.id && params.id !== "") {
                setPage(1);
                dispatch(getWatchlistTitleDetail({ id: params.id, page: 1, pagelimit: 12, sort: watchlistMediaFilter.sort, body: watchlistMediaFilter, is_public: props?.is_public }));
            }
            setOpenPendingSuggestions(false);
            setOpenEditWatchlist(false);
            dispatch(clearMessages());
        }
    }, [watchlist])

    useEffect(() => {
        if (params.id && params.id !== "") {
            dispatch(getWatchlistTitleDetail({ id: params.id, page: page, pagelimit: 12, sort: watchlistMediaFilter.sort, body: watchlistMediaFilter, is_public: props?.is_public })).then((res) => {
                const { title, total_members, total_titles, username } = res.payload.data.status_flag;
                helpers.trackMoengageEvent('FR3_Watchlist_Click', {
                    watchlist_name: title,
                    watchlist_id: params.id,
                    created_by: username,
                    title_counts: total_titles,
                    member_count: total_members,
                    ref_page: props?.refPage || '',
                    ref_url: document?.referrer,
                    tag_name: 'watchlisttitle'
                });
            })
            getClipData(1, params.id);
        }
    }, [params])

    const getClipData = (page, id) => {
        setIsClipLoading(true);
        if (!isClipLoading) {
            const body = {
                id: id,
                page: page,
                section: 'watchlist',
                include_viewed_clips: 1,
                pagelimit: 20
            }
            dispatch(getClipsCustomData(body)).then((resp) => {
                if (resp.payload && resp.payload.data) {
                    const { pagination, results } = resp.payload.data;
                    setClipsPagination(pagination);
                    // setClipsData(helpers.removeDuplicate([...clipsData, ...results]));
                    setClipsData(helpers.removeDuplicate(results));
                    setIsClipLoading(false);
                }
            });
        }
    }

    useEffect(() => {
        if (params.id && params.id !== "" && page > 1) {
            dispatch(getWatchlistTitleDetail({ id: params.id, page: page, pagelimit: 12, sort: watchlistMediaFilter.sort, body: watchlistMediaFilter, is_public: props?.is_public }));
        }
    }, [page, params])

    useEffect(() => {
        if (watchlistMediaFilter.apply_filter) {
            if (page !== 1) {
                setPage(1);
            } else {
                dispatch(getWatchlistTitleDetail({ id: params.id, page: page, pagelimit: 12, sort: watchlistMediaFilter.sort, body: watchlistMediaFilter, is_public: props?.is_public }))
            }
        }
    }, [watchlistMediaFilter])

    useEffect(() => {
        const handleScroll = () => {
            if (watchlist.watchlistTitlePageData && watchlist.watchlistTitlePageData && watchlist.watchlistTitlePageData.pagination && watchlist.watchlistTitlePageData.pagination.is_next_page && !watchlist.isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, watchlist]);

    const handleDelete = (id) => {
        if (id === params.id) {
            setDeleteId(id);
            setConfirmActionType("delete");
            setConfirmDialogOpen(true);
            handleClose();
        }
    }

    const handleLeave = (id) => {
        if (id === params.id) {
            setDeleteId(id);
            setConfirmActionType("leave");
            setConfirmDialogOpen(true);
            handleClose();
        }
    }

    const handleManageMembers = (id) => {
        handleClose();
        if (!memberView) {
            window.scrollTo(0, 150);
        }
        setMemberView(!memberView);
    }

    const handleSort = (val) => {
        dispatch(updateWatchlistMediaFilter({ name: 'sort', value: val }));
        dispatch(updateWatchlistMediaFilter({ name: 'apply_filter', value: true }));
        handleClose2();
    }
    const handleJoinWatchlist = (item) => {
        let id = params.id;
        if (id && id !== "") {
            helpers.trackMoengageEvent('FR3_Watchlist_Click', {
                join_watchlist_click: MOENGAGE_ATTRIBUTE.YES,
                watchlist_id: id
            });
            dispatch(joinWatchlist({ id: id, body: {} }));
        }
    }


    const data = watchlist.watchlistTitlePageData && watchlist.watchlistTitlePageData.status_flag ? watchlist.watchlistTitlePageData.status_flag : {}

    const handleEditWatchlist = () => {
        setEditWatchlistName(data?.title);
        setIsPublic(data?.is_public);
        setOpenEditWatchlist(true);
    }

    const handleUpdate = (id) => {
        const body = {
            "is_public": isPublic,
            "category_name": editWatchlistName
        }
        dispatch(updateWatchlist({ uuid: id, body: body }));
    }

    return (
        <>
            <MyHelmet
                title={data?.title}
                og_image={data && data.backdrops && data.backdrops.length > 0 ? helpers.getFullUrlOriginal(data.backdrops[0]) : null}
                description={`Join ${data?.title}, suggest titles, and discover hidden gems with fellow film lovers on frenzi`}
            />
            {currentLoading && <Loader />}
            {!currentLoading &&
                <Grid container className='new-container ml-25'>
                    <Grid item xs={12} className='bg-secondary'>
                        <div className='sticky-top'>
                            <Carousel
                                showThumbs={false}
                                showArrows={true}
                                showStatus={false}
                                showIndicators={false}
                                interval={3000}
                                autoPlay={true}
                                infiniteLoop={true}
                            >
                                {data && data.backdrops && data.backdrops.length > 0 ? data.backdrops.map((img, i) => {
                                    return <Grid item key={i} className='relative'>
                                        <Box
                                            component="img"
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                maxHeight: '75vh',
                                                minHeight: '300px',
                                                objectFit: 'cover',
                                                objectPosition: 'top'
                                            }}
                                            alt="banner"
                                            src={img ? helpers.getFullUrlOriginal(img) : helpers.getDefaultImage("fullbanner")}
                                        />
                                    </Grid>
                                })
                                    :
                                    <Grid item key={'default-img'} className='relative'>
                                        <Box
                                            component="img"
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                maxHeight: '75vh',
                                                minHeight: '300px',
                                            }}
                                            alt="banner"
                                            src={helpers.getDefaultImage("fullbanner")}
                                        />
                                    </Grid>
                                }
                            </Carousel>
                            <Grid container className='watchlist-banner-btns' sx={{p:{xs:'0px 25px'}}} justifyContent={'space-between'}>
                                <ArrowBack className='pointer' onClick={() => navigate(-1)} />
                                <div className='relative'>
                                    <ShareMovie onClick={() => {
                                        helpers.trackMoengageEvent('FR3_Watchlist_Click', {
                                            share_click: MOENGAGE_ATTRIBUTE.YES
                                        });
                                    }} height={32} size={32} width={32} hideShare={true} />
                                </div>
                            </Grid>
                        </div>
                        <div className='sticky-bottom'>
                            <Grid item className='sticky-top'>
                                <Grid container className='watchlist-container-heading app-container' sx={{ padding: { xs: '15px 80px', lg: '15px 80px' } }} justifyContent={'space-between'}>
                                    <Grid item xs={8} className='' sx={{ p: { xs: '5px 0px 5px 20px', lg: '0px' } }}>
                                        <h2 className='fw-600 fs-24 m-0'>{data && data.title ? data.title : ''}</h2>
                                        <h2 className='fw-400 fs-12 text-muted m-0'>{data && data.is_public ? 'PUBLIC' : 'PRIVATE'}</h2>
                                        <h2 className='fw-400 fs-18 m-0'>{data && data.username ? "Created by " + data.username : ''}</h2>
                                    </Grid>
                                    <Grid item  sx={{ p: { xs: '5px 20px 5px 10px', lg: '0px' } }}>
                                        <Grid className='' sx={{textAlign:{xs:'center' , sm:'right'}}}>
                                            <MoreVertIcon className='pointer' onClick={handleClickListItem} />
                                            <Popover
                                                id={'menbu'}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Box className='pt-10 pb-20 pl-20 pr-20'>
                                                    <p className='fw-700 fs-24 m-0'>{data && data.title ? data.title : ''}</p>
                                                    <p className='fw-500 fs-12 m-0 text-dark-grey'>{data && data.username ? "Created by " + data.username : ''}</p>
                                                    {data.role === "owner" &&
                                                        <>
                                                            <Grid onClick={() => handleEditWatchlist()} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                <EditIcon className='text-selected mr-10' />
                                                                <p className='fw-500 fs-16 m-0' >Edit Watchlist</p>
                                                            </Grid>
                                                            <Grid onClick={() => setOpenPendingSuggestions(true)} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                <AddRoundedIcon className='text-selected mr-10' />
                                                                <p className='fw-500 fs-16 m-0' >Pending Suggestions</p>
                                                            </Grid>
                                                            <Grid onClick={() => handleManageMembers(params.id)} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                <SettingsIcon className='text-selected mr-10' />
                                                                <p className='fw-500 fs-16 m-0' >Manage Members</p>
                                                            </Grid>
                                                            <Grid container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                <ShareMovie
                                                                    width={16}
                                                                    size={16}
                                                                    height={16}
                                                                    hideShare={true}
                                                                    myComponent={true}
                                                                    customComponent={
                                                                        <>
                                                                            <ShareIcon className='text-selected mr-10' />
                                                                            <p className='fw-500 fs-16 m-0'>Share with friend(s)</p>
                                                                        </>
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid container onClick={() => handleDelete(params.id)} className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                <DeleteIcon className='text-selected mr-10' />
                                                                <p className='fw-500 fs-16 m-0'>Delete {data.is_public ? 'sharelist' : 'watchlist'}</p>
                                                            </Grid>
                                                        </>
                                                    }
                                                    {data.is_member && data.role !== "owner" &&
                                                        <>
                                                            <Grid container onClick={() => handleManageMembers(params.id)} className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                <VisibilityIcon className='text-selected mr-10' />
                                                                <p className='fw-500 fs-16 m-0'>{memberView ? 'Hide' : 'View'} Members</p>
                                                            </Grid>
                                                            <Grid container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                <ShareMovie
                                                                    width={16}
                                                                    size={16}
                                                                    height={16}
                                                                    hideShare={true}
                                                                    myComponent={true}
                                                                    customComponent={
                                                                        <>
                                                                            <ShareIcon className='text-selected mr-10' />
                                                                            <p className='fw-500 fs-16 m-0'>Share with friend(s)</p>
                                                                        </>
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid container onClick={() => handleLeave(params.id)} className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                <LogoutIcon className='text-selected mr-10' />
                                                                <p className='fw-500 fs-16 m-0'>Leave watchlist</p>
                                                            </Grid>
                                                        </>
                                                    }
                                                    {!data.is_member && data.role !== "owner" &&
                                                        <>
                                                            <Grid onClick={() => handleJoinWatchlist(data)} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                <AddRoundedIcon className='text-selected mr-10' />
                                                                <p className='fw-500 fs-16 m-0'>Join {data.is_public ? 'sharelist' : 'watchlist'}</p>
                                                            </Grid>
                                                            <Grid container onClick={() => handleManageMembers(params.id)} className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                <VisibilityIcon className='text-selected mr-10' />
                                                                <p className='fw-500 fs-16 m-0'>{memberView ? 'Hide' : 'View'} Members</p>
                                                            </Grid>
                                                            <Grid container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                <ShareMovie
                                                                    width={16}
                                                                    size={16}
                                                                    height={16}
                                                                    hideShare={true}
                                                                    myComponent={true}
                                                                    customComponent={
                                                                        <>
                                                                            <ShareIcon className='text-selected mr-10' />
                                                                            <p className='fw-500 fs-16 m-0'>Share with friend(s)</p>
                                                                        </>
                                                                    }
                                                                />
                                                            </Grid>
                                                        </>
                                                    }
                                                </Box>
                                            </Popover>
                                        </Grid>

                                        <Grid item sx={{paddingLeft:{xs:'8px', sm:0}}} >
                                            <p className='fw-600 fs-20 m-0'>{data.total_titles ? data.total_titles : 0} titles</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* ADD TITLE TO WATCHLIST SECTION */}
                            <div className='app-container' >
                                <Box sx={{ paddingLeft: { md: '25px' }, paddingRight: { md: '25px' } }} >
                                    {memberView ?
                                        <WatchlistMember goBack={() => setMemberView(false)} wathlist_id={params.id} role={data.role} />
                                        :
                                        <>
                                            <Grid item className=' section-border-bottom pt-15 pb-15 pl-100 pr-100' >
                                                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                                                    {data.total_members && data.follow_users_profile_img && data.follow_users_profile_img.length > 0 &&
                                                        <Grid item style={{ paddingLeft: '25px' }}>
                                                            <Stack direction="row" onClick={() => handleManageMembers(params.id)} className='ai-center pointer ml-15 mt-10 '>
                                                                {
                                                                    data.follow_users_profile_img.map((img, i) => {
                                                                        if (i < 3) {
                                                                            return <Avatar alt="Remy Sharp" src={img ? helpers.getFullUrl(img) : helpers.getDefaultImage('user')} className='watchlist-avatar' />
                                                                        }
                                                                    })
                                                                }
                                                                {data.total_members > 3 &&
                                                                    <Typography className='fs-16 fw-500 lh-16 ml-8'> +{data.total_members > 3 ? data.total_members - 3 : data.total_members} people joined</Typography>
                                                                }
                                                            </Stack>
                                                        </Grid>
                                                    }
                                                    {data.role === "owner" ?
                                                        <Grid item>
                                                            <AddRemoveTitles data={{ watchlist_id: params.id }} className='mw-320 pl-40 pr-40' />
                                                        </Grid>
                                                        :
                                                        data.is_member ?
                                                            <Grid item>
                                                                <SuggestTitles data={{ watchlist_id: params.id }} className='mw-320 mt-0 w-100 pl-40 pr-40' />
                                                            </Grid>
                                                            :
                                                            <Grid item>
                                                                <Button onClick={() => handleJoinWatchlist(data)} className='pl-30 pr-30 rounded-btn bg-selected text-light w-100 fs-14 mw-320 fw-500'>
                                                                    {data.join_request ?
                                                                        'requested'
                                                                        :
                                                                        data.is_public ? 'join this sharelist' : 'join this watchlist'
                                                                    }
                                                                </Button>
                                                            </Grid>
                                                    }
                                                    {data.role && data.role === "owner" &&
                                                        <Grid item >
                                                            <AddRemoveFriends data={{ watchlist_id: params.id }} />
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Grid container className='mt-10' justifyContent={'center'}>
                                                <MyAds type="banner_2" adv_type={'web_watchlist_top_ad'} />
                                            </Grid>

                                            {clipsData.length > 0 &&
                                                <Grid xs={12} className="app-container list-clip-player">
                                                    <ClipsSlider clipsData={clipsData} />
                                                </Grid>
                                            }

                                            {watchlist.watchlistTitleData && watchlist.watchlistTitleData.length > 0 &&
                                                <div className='relative pb-50'>
                                                    <Grid item className='app-container pt-20 pb-20'>
                                                        <Grid container justifyContent={'space-between'} alignItems={'center'}>
                                                            <Grid item className=''>
                                                                <Grid container onClick={handleClickListItem2} alignItems={'center'} className="pointer">
                                                                    <p className='fw-700 fs-15 m-0 mr-6 '>
                                                                        sort
                                                                    </p>
                                                                    <SwapVertIcon />
                                                                </Grid>
                                                                <Popover
                                                                    id={'menu2'}
                                                                    open={open2}
                                                                    anchorEl={anchorEl2}
                                                                    onClose={handleClose2}
                                                                    anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}
                                                                >
                                                                    <Box className='pb-20 pl-20 pr-20'>
                                                                        <Grid onClick={() => handleSort('d')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                            <p className={`fw-500 fs-16 m-0 ${watchlistMediaFilter.sort === 'd' ? 'text-selected' : ''}`}>release date</p>
                                                                        </Grid>
                                                                        <Grid onClick={() => handleSort('l')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                            <p className={`fw-500 fs-16 m-0 ${watchlistMediaFilter.sort === 'l' ? 'text-selected' : ''}`} >popular</p>
                                                                        </Grid>
                                                                        <Grid onClick={() => handleSort('r')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                                            <p className={`fw-500 fs-16 m-0 ${watchlistMediaFilter.sort === 'r' ? 'text-selected' : ''}`} >rating</p>
                                                                        </Grid>
                                                                    </Box>
                                                                </Popover>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button onClick={() => toggleDrawer(!drawerOpen)} className='rounded-btn pl-40 pr-40 bg-selected text-light w-120 fs-14 fw-500'>Filter</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className='app-container pb-20'
                                                    >
                                                        {watchlist.watchlistTitleData.map((x, i) => {
                                                            if (data.role !== "owner" && !data.is_member && i > 2) {
                                                                return true;
                                                            }
                                                            return <Grid className='watchlist-detail-titles' item key={i} xs={12} sm={6} md={4}>
                                                                <MovieTile refPage={'Watchlist'} x={x} hideAction={true} />
                                                            </Grid>
                                                        })}
                                                    </Grid>
                                                    {data.role !== "owner" && !data.is_member &&
                                                        <div className='overlay'>
                                                            {/* <Button onClick={() => handleJoinWatchlist(data)} className='pl-30 pr-30 rounded-btn bg-selected w-100 text-light fs-14 mw-320 fw-500'>join this {data.is_public ? 'sharelist' : 'watchlist'}</Button> */}
                                                        </div>
                                                    }
                                                </div>
                                            }

                                        </>
                                    }
                                </Box>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            }
            <>
                <ConfirmDialog
                    open={confirmDialogOpen}
                    handleClose={(value) => {
                        setConfirmDialogOpen(false);
                        if (value && deleteId && deleteId != "") {
                            dispatch(deleteLeaveWatchlist({ uuid: deleteId }));
                            navigate(-1);
                        }
                    }}
                    title={confirmActionType === 'delete' ? "Delete Watchlist" : "Leave Watchlist"}
                    mesg={"Are you sure want to " + confirmActionType + " this watchlist?"}
                />
                <WatchlistDrawerFilter toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} anchor={anchor} />

                <Modal
                    open={openPendingSuggestions}
                    onClose={() => setOpenPendingSuggestions(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style2} className='bg-secondary jc-center' sm={4} xs={12}>
                        <PendingSuggestions wid={params.id} onClose={() => setOpenPendingSuggestions(false)} />
                    </Box>
                </Modal>

                <Modal
                    open={openEditWatchlist}
                    onClose={() => setOpenEditWatchlist(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style3} className='bg-secondary jc-center' sm={4} xs={12}>
                        <Grid container className='h-100 w-100'>
                            <Grid item sm={12} className='h-100 w-100'>
                                <Grid container className='bg-secondary pt-0' sx={{ position: 'sticky', top: '10px', padding: '10px', marginTop: '10px', zIndex: 99 }} justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid item>
                                        <p className='fw-700 fs-28 m-0'>Edit watchlist</p>
                                    </Grid>
                                    <Grid item>
                                        <ClearIcon className='pointer' onClick={() => setOpenEditWatchlist(false)} />
                                    </Grid>
                                </Grid>
                                <Grid className='pt-10' sx={{ padding: '30px' }} >
                                    <TextField id="watchlist-name" className='w-100 mt-20' label={`${isPublic ? 'Sharelist' : 'Watchlist'} name`} variant="outlined" onChange={(e) => setEditWatchlistName(e.target.value)} value={editWatchlistName} />
                                    <Grid container justifyContent={'flex-end'} className='text-right'>
                                        <FormGroup className='text-right'>
                                            <FormControlLabel label={isPublic ? 'sharelist' : 'watchlist'} control={<GreenSwitch checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} />} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid container justifyContent={'center'}>
                                        <Button onClick={() => handleUpdate(params.id)} className='rounded-btn mt-10 bg-selected text-light'>confirm</Button>
                                        <Button className='rounded-btn mt-20 bg-light text-selected' onClick={() => setOpenEditWatchlist(false)}>cancel</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </>
        </>
    )
}
