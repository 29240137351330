import { Grid } from "@mui/material";
import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Box } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import helpers from "../../services/helpers";
import { Link } from "react-router-dom";
import TextReviewTile from "../review/includes/TextReviewTile";


export default function Reviews(props) {
  const [fullText, setFullText] = useState([]);
  // const params = useParams();
  // const media = useSelector((state) => state.media);
  const { data, mediaData, type, size, unique_title } = props;

  const handleFullText = (i) => {
    if (fullText.includes(i)) {
      var tempArr = fullText;
      const index = tempArr.indexOf(i);
      if (index > -1) {
        tempArr.splice(index, 1);
        setFullText([...tempArr]);
      }
    } else {
      setFullText([...fullText, i]);
    }
  };

  let mid = mediaData?.media ? "?id=" + mediaData?.media : "?id=" + mediaData?.uuid;

  return (
    <div>
      {type === "grid" && size === "sm" ? (
        <Grid
          container
          className="app-section mt-50 pb-40"
          justifyContent={"center"}
        >
          <Grid item xs={12}>
            <Grid container justifyContent={"space-between"} className="mb-20">
              <Grid item >
                <p className="fs-20 text-left fw-600 m-0">
                  <span className="text-selected"> look at reviews </span>  {`(${mediaData.review_counts})`}
                </p>
                {/* <p className="fs-14 lh-18 text-muted m-0"> 
                We will Send you a notification 
                </p> */}
              </Grid>
              <Link
                to={`/review/${unique_title ? unique_title : mediaData?.title}${!unique_title ? mid : ''}`}
              >
                <ArrowForwardIcon />
              </Link>
            </Grid>
            <Grid container spacing={2}>
              {data &&
                data.length > 0 &&
                data.map((x, i) => {
                  return (
                    <Grid item xs={12} sm={6} key={i} className="mb-20">
                      <TextReviewTile x={x} isme={false} />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          className="mt-30 bg-secondary app-section pt-40 pb-40 review-carousel-container"
          justifyContent={"center"}
        >
          <Grid item xs={12} className="app-container">
            <Grid container justifyContent={"space-between"}>
              <p className="fs-20 text-left fw-600 m-0">
                look at reviews {`(${mediaData.review_counts})`}
              </p>
              <Link
                to={`/review/all?id=${mediaData?.media ? mediaData?.media : mediaData?.uuid
                  }`}
              >
                <ArrowForwardIcon />
              </Link>
            </Grid>
            {data && data.length > 0 && (
              <Carousel
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                interval={3000}
                autoPlay={true}
                infiniteLoop={true}
              >
                {data.map((x, i) => {
                  return (
                    <Grid container key={i}>
                      <Grid item xs={12} className="pb-50 pt-20">
                        <div>
                          <Grid container flexWrap={"nowrap"}>
                            <Grid item sm={1} xs={4}>
                              <Box
                                component="img"
                                sx={{
                                  height: 120,
                                  width: 101,
                                  maxHeight: { xs: 71, sm: 120 },
                                  maxWidth: { xs: 71, sm: 101 },
                                  borderRadius: "8px",
                                }}
                                alt="banner"
                                src={
                                  x.poster
                                    ? helpers.getFullUrl(x.poster)
                                    : helpers.getDefaultImage("reviews")
                                }
                              />
                              <p className="fs-20 fw-600 text-center m-0">
                                {x.user_rating ? x.user_rating : ""}
                              </p>
                            </Grid>
                            {x?.comment_type === "text" && (
                              <Grid sm={11} xs={8} item className="ml-25">
                                <p
                                  style={{ color: "#979797" }}
                                  className="fs-12 fw-500 text-left "
                                >
                                  {x.user_name ? x.user_name : ""}
                                </p>
                                <p className="fs-20 text-left m-0 fw-600">
                                  {helpers.getRatingText(Number(x.user_rating))}
                                </p>
                                {x.comment && x.comment !== "" && (
                                  <p
                                    style={{ color: "#D9D9D9" }}
                                    className="fs-14 fw-400 text-left"
                                  >
                                    “
                                    {fullText.includes(i)
                                      ? x.comment
                                      : x.comment.substring(0, 300)}{" "}
                                    {x.comment.length > 300 && (
                                      <span
                                        onClick={() => handleFullText(i)}
                                        className="text-selected pointer"
                                      >
                                        {fullText.includes(i) ? "LESS" : "MORE"}
                                      </span>
                                    )}
                                    ”
                                  </p>
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Carousel>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
}
