import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getHiddenGemsMovie, getHiddenGemsSearch, getHiddenGemsTv } from '../../../slices/trending';
import CommonMediumTileListing from '../../common/CommonMediumTileListing';
import Loader from '../../common/Loader';
import Selection from './Selection';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CommonDetailTileListing from '../../common/CommonDetailTileListing';
import MyHelmet from '../../common/MyHelmet';
import ContentAccordion from '../../common/ContentAccordion';

const page = 1;

export default function HiddenGems() {
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [currentSlide, setCurrentSlide] = useState(0);
    const [selectedPlatformOttName, setSelectedPlatformOttName] = useState('');
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const is_free = searchParams.get('is_free') && searchParams.get('is_free') === 'true' ? true : false;
    const trending = useSelector(state => state.trending);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        if (selectedPlatform && selectedPlatform !== "") {
            var body = {
                page: page,
                pagelimit: 20,
                is_free: is_free,
                media_type: 'movie',
                body: { service: [selectedPlatform] }
            }
            dispatch(getHiddenGemsMovie(body));
        }
        setCurrentSlide(0);
    }, [selectedPlatform])

    useEffect(() => {
        if (selectedPlatform && selectedPlatform !== "") {
            var body = {
                page: page,
                pagelimit: 20,
                is_free: is_free,
                media_type: 'tv',
                body: { service: [selectedPlatform] }
            }
            dispatch(getHiddenGemsTv(body));
        }
    }, [selectedPlatform])

    useEffect(() => {
        if (selectedPlatform && selectedPlatform !== "") {
            var body = {
                page: page,
                pagelimit: 20,
                is_free: is_free,
                media_type: 'all',
                sort: 's',
                body: { service: [selectedPlatform] }
            }
            dispatch(getHiddenGemsSearch(body));
        }
    }, [selectedPlatform])

    return (
        <>
            <MyHelmet
                title={'Hidden Gems You Should Not Miss'}
                description={"Uncover hidden gems among free content across OTT platforms. Discover underrated movies and series for free with MyFrenzi."}
                og_title={'Hidden Gems: Free Content on OTT you should watch'}
                keywords={'hidden gems, free content, OTT platforms, MyFrenzi'}
            />

            {(trending.isLoading || auth.isLoading) && <Loader />}
            <div className="new-container">
                <ContentAccordion
                    isFull={true}
                    title={'HIDDEN_H'}
                    data={'HIDDEN'}
                />
            </div>
            <Grid className='new-container pt-10 pb-40' justifyContent={'center'} >
                <Grid container alignItems={'center'} className="pt-20" justifyContent='space-between'>
                    <Grid item>
                        <Grid container alignItems={'center'}>
                            <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                            <p className='fs-28 fw-700 lh-28 m-0'><span className='text-selected'>hidden</span> gems on {selectedPlatformOttName}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='pt-20'>
                    <Selection
                        platforms={auth.allPreferences && auth.allPreferences.platforms ? auth.allPreferences.platforms : []}
                        setSelectedPlatform={setSelectedPlatform}
                        setSelectedPlatformUuid={(val) => { }}
                        setSelectedPlatformName={(val) => { }}
                        selectedPlatform={selectedPlatform}
                        setSelectedPlatformOttName={setSelectedPlatformOttName}
                        selectedPlatformOttName={selectedPlatformOttName}
                        is_free={is_free}
                        p_name={''}
                    />

                    <CommonMediumTileListing
                        heading={'movies'}
                        subheading=''
                        isShowHeading={true}
                        nextLink={`/hidden-gems-on-ott/${selectedPlatformOttName}/movie${is_free ? '?is_free=true' : ''}`}
                        data={trending.hiddenGemsMovieData}
                        currentSlide={currentSlide}
                        setCurrentSlide={setCurrentSlide}
                        refPage="Hidden Gems"
                    />

                    <CommonMediumTileListing
                        heading={'web series'}
                        subheading=''
                        isShowHeading={true}
                        nextLink={`/hidden-gems-on-ott/${selectedPlatformOttName}/tv${is_free ? '?is_free=true' : ''}`}
                        data={trending.hiddenGemsTvData}
                        currentSlide={currentSlide}
                        setCurrentSlide={setCurrentSlide}
                        refPage="Hidden Gems"
                    />
                    <CommonDetailTileListing
                        heading={'title that interest others'}
                        subheading='titles that others plan to binge watch'
                        isShowHeading={true}
                        nextLink={`/hidden-gems-on-ott/${selectedPlatformOttName}/search${is_free ? '?is_free=true' : ''}`}
                        data={trending.hiddenGemsSearchData}
                        currentSlide={currentSlide}
                        setCurrentSlide={setCurrentSlide}
                        refPage="Hidden Gems"
                    />
                </Grid>
            </Grid>
        </>
    )
}
