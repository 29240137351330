import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CreateAccount from "./CreateAccount";
import VerifyOtp from "./VerifyOtp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearMessages } from "../../slices/auth";
import { useDispatch, useSelector } from "react-redux";
import helpers from "../../services/helpers";
import { KMSClient, DecryptCommand } from "@aws-sdk/client-kms";
import MyHelmet from "../common/MyHelmet";

const clientParams = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const config = {
  credentials: clientParams,
  endPoint: "",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
  signatureVersion: 2,
  region: process.env.REACT_APP_AWS_REGION,
};

const client = new KMSClient(config);

export default function Register({ isFull }) {
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [currentOtpData, setCurrentOtpData] = useState({});
  const [mobile, setMobile] = useState("");

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    let referer = searchParams.get("referer");
    if (referer && referer !== "") {
      window.localStorage.setItem("referer", referer);
    }
  }, [searchParams]);

  useEffect(() => {
    if (auth.errMesg && auth.errMesg !== "") {
      helpers.toastError(auth.errMesg);
      dispatch(clearMessages());
    }
    if (auth.successMesg && auth.successMesg !== "") {
      helpers.toastSuccess(auth.successMesg);
      if (auth.successMesg === "otp sent") {
        setVerifyOtp(true);
      }
      dispatch(clearMessages());
    }
  }, [auth, dispatch]);

  useEffect(() => {
    if (auth.otpData && auth.otpData.data) {
      convertAgainOtpData(auth.otpData.data);
    }
  }, [auth.otpData]);

  function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    // return bytes.buffer;
    return bytes;
  }

  const convertAgainOtpData = async (responseText) => {
    var cipherText = _base64ToArrayBuffer(responseText);
    const input = {
      KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
      CiphertextBlob: cipherText,
    };
    try {
      const command = new DecryptCommand(input);
      const response = await client.send(command);
      // process data.
      if (response && response.Plaintext) {
        let win1251decoder = new TextDecoder("windows-1251");
        let bytes = new Uint8Array(response.Plaintext);
        var decryptedText = win1251decoder.decode(bytes);
        var jsonData = JSON.parse(decryptedText);
        if (jsonData && !helpers.isEmptyObject(jsonData)) {
          console.log("otp data after conversion-", jsonData);
          setCurrentOtpData(jsonData);
        }
      } else {
        helpers.toastError("Data decryption failed !!!");
      }
    } catch (error) {
      // error handling.\
      helpers.toastError(error);
    }
  };

  return (
    <>
      <MyHelmet />
      <Grid
        container
        justifyContent={"center"}
        className={isFull ? '' : "vh-100"}
        alignItems={"center"}
      >
        {isFull ? '' :
          <ToastContainer />
        }
        <Grid item xs={isFull ? 12 : 11} sm={isFull ? 12 : 7} md={isFull ? 12 : 5} lg={isFull ? 12 : 4}>
          {verifyOtp ? (
            <VerifyOtp
              loginType={"mobile"}
              mobile={mobile}
              otpId={
                currentOtpData && currentOtpData.otp_id
                  ? currentOtpData.otp_id
                  : ""
              }
              event={"signup"}
            />
          ) : (
            <CreateAccount setMobile={setMobile} />
          )}
        </Grid>
      </Grid>
    </>
  );
}
