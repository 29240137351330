import React from "react";
import Grid from "@mui/material/Grid";
import ProgressBar from "../component/ProgressBar";
import Box from "@mui/system/Box";
import helpers from "../../services/helpers";
import { Link } from "react-router-dom";
import NextButtonTile from "../common/NextButtonTile"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Pagination, Navigation } from "swiper";
import SectionHeading from "../component/headings/SectionHeading";
// import MyAds from "../adsense/MyAds";
import SeeAll from "../common/SeeAll";


export default function NewlyAdded(props) {
  const { data, heading, hideNext } = props;

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        sx={{ marginTop: { xs: "20px", sm: "30px" } }}
        justifyContent="space-between"
      >
        <Grid item>
          {heading && heading !== "" ? (
            <SectionHeading title={heading} />
          ) : (
            <>
              {/* <span className="text-selected fs-28 fw-700 lh-28 m-0">fresh</span><span className="fs-28 fw-700 lh-28 m-0"> arrivals</span> */}
              <SectionHeading title={heading} />
            </>
          )}
          {/* <h2 className="fs-24 fw-500 lh-24 mt-0 mb-10 pt-5 text-dark-grey">
            {subheading && subheading != "" ? (
              subheading
            ) : (
              <>on your preferred platforms</>
            )}
          </h2> */}
        </Grid>
        <Link to={`/new-${props?.is_free ? 'free-' : ''}release-on-ott${props?.is_free ? "?is_free=true" : ""}`}>
          <SeeAll />
        </Link>
      </Grid>

      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        slidesPerView={"auto"}
        spaceBetween={10}
        speed={1000}
        navigation={true}
        freeMo
        modules={[Pagination, FreeMode, Navigation]}
        className="mySwiper"
        freeMode={true}
      >
        {data &&
          data.length > 0 &&
          data.map((x, i) => {
            return (<>
              {/* {(data.length > 3 ? i === 2 : data.length - 1 === i) &&
                <SwiperSlide style={{ minWidth: '300px', alignSelf: 'center' }} key={`ads-${i}`}>
                  <MyAds type="banner_3" adv_type={'web_movie_detail_trailers_ad'} />
                </SwiperSlide>
              } */}
              <SwiperSlide key={i} className="pr-20" style={{ alignSelf: 'center', width: 'auto' }}>
                <Grid sx={{
                  maxWidth: { xs: 100, sm: 140 },
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  display: 'inline-block'
                }}
                >
                  <Grid item className="scale-1">
                    <Grid item className="movie-container">
                      <Link
                        onClick={() =>
                          helpers.mediaClick(
                            x?.title,
                            x.media ? x.media : x.uuid ? x.uuid : "",
                            "Fresh Arrivals"
                          )
                        }
                        to={helpers.getMediaLink(x)}
                      >
                        <Box
                          component="img"
                          className="d-block"
                          sx={{
                            // height: 360,
                            // width: 270,
                            // maxHeight: { xs: 150, sm: 210, md: 360 },
                            // maxWidth: { xs: 100, sm: 140, md: 270 },
                            height: 210,
                            width: 140,
                            maxHeight: { xs: 150, sm: 210 },
                            maxWidth: { xs: 100, sm: 140 },
                            borderRadius: "8px",
                            objectFit: 'cover',
                            objectPosition: 'top'
                          }}
                          alt="poster"
                          src={
                            x.poster
                              ? helpers.getFullUrl(x.poster, 300)
                              : helpers.getDefaultImage("trending")
                          }
                        />
                      </Link>
                      {/* {x.media_type &&
                        (x.media_type == "movie" || x.media_type == "tv") && (
                          <BookmarkAction
                            size={42}
                            data={x}
                            addedId={addedId}
                            setAddedId={setAddeddId}
                          />
                        )} */}
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems={"center"}
                          sx={{
                            minWidth: { xs: "50px", sm: "60px", xl: "80px" },
                          }}
                          flexWrap={"nowrap"}
                          className="bottom-right w-100"
                        >
                          <p
                            style={{ textTransform: "uppercase" }}
                            className="m-0 fs-10 pl-10"
                          >
                            {" "}
                            {x.media_type ? x.media_type : ""}{" "}
                          </p>
                          <p className="m-0 fs-10 pl-5 pr-5"> | </p>{" "}
                          <p
                            className="m-0 fs-10 pr-10"
                            style={{ textTransform: "uppercase" }}
                          >
                            {" "}
                            {x.language ? x.language : ""}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className="jc-sb ai-center"
                      sx={{ marginTop: "6px" }}
                    >
                      <Grid item xs={8}>
                        {/* <p className="fs-18 fw-400 lh-14 pb-7 m-0 ellipsis">
                          {x.data && x.data[0] && x.data[0].ott_name
                            ? x.data[0].ott_name.length > 16
                              ? x.data[0].ott_name.substring(0, 16) + "..."
                              : x.data[0].ott_name
                            : ""}
                        </p> */}
                      </Grid>
                      <ProgressBar
                        value={x.rating ? Number(x.rating) * 10 : 0}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </SwiperSlide>
            </>);
          })}
        {!hideNext ? (
          <SwiperSlide style={{ width: 'auto', height: "auto" }} key={"next-btn"}>
            <Grid className="h-100" sx={{
              maxWidth: { xs: 100, sm: 140 },
              display: 'inline-block'
            }}
            >
              <NextButtonTile data={data} link={`/new-${props?.is_free ? 'free-' : ''}release-on-ott${props?.is_free ? "?is_free=true" : ""}`} />
            </Grid>
          </SwiperSlide>
        ) : (
          ""
        )}
      </Swiper>
    </>
  );
}
