import { Grid, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getCommonForAllData } from '../../slices/trending';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Loader from './Loader';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Box } from '@mui/system';
import SideFilter from '../search/SideFilter';
import SingleMediaTile from './SingleMediaTile';
import helpers from '../../services/helpers';
import MyHelmet from './MyHelmet';
import MyAds from '../adsense/MyAds';
import MediaDetailLoader from './MediaDetailLoader';

const defaultFilter = {
    service: [],
    genre: [],
    language: [],
    content_type: [],
    exclude_already_watched: '',
    sort: '',
    apply_filter: false
}

export default function CommonDetailGridList(props) {
    const { path, heading } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [currentPageData, setCurrentPageData] = useState({});
    const [mediaList, setMediaList] = useState([]);
    const [tempFilter, setTempFilter] = useState(defaultFilter);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('');
    const open2 = Boolean(anchorEl2);
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    const handleClickListItem2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();

    const handleSort = (val) => {
        setSort(val);
        setIsLoading(true);
        if (page === 1) {
            getData(val, tempFilter);
        } else {
            setPage();
        }
        handleClose2();
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        setIsLoading(true);
    }, [])

    useEffect(() => {
        getData();
    }, [page])

    const getData = (s = sort, filter = tempFilter) => {
        var mid = searchParams.get('id');
        var body = {
            page: page,
            pagelimit: 20,
            path: path,
            id: mid,
            sort: s,
            body: filter,
            media_type: 'movie',
            is_free: false
        }
        dispatch(getCommonForAllData(body)).then((resp) => {
            setIsLoading(false);
            if (resp.payload.status && resp.payload.data.results && resp.payload.data.results.length > 0) {
                setCurrentPageData({ ...resp.payload.data });
                if (page === 1) {
                    setMediaList([...resp.payload.data.results]);
                } else {
                    setMediaList(helpers.removeDuplicate([...mediaList, ...resp.payload.data.results]));
                }
            }
        });
    }

    useEffect(() => {
        const handleScroll = () => {
            if (currentPageData && currentPageData && currentPageData.pagination && currentPageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, dispatch]);

    const handleApplyFilter = (filter) => {
        if (filter) {
            setIsLoading(true);
            setTempFilter({ ...filter });
            if (page === 1) {
                getData(sort, filter);
            } else {
                setPage(1);
            }
            window.scrollTo(0, 0);
        }
    }


    return (
        <div>
            <>
                <MyHelmet
                    title={`${params?.decade} movies`}
                    description={`Relive the past decade's best films, curated for your enjoyment. Unforgettable stories and exceptional performances await as we celebrate the movies that defined a generation.`}
                    og_title={`Top Movies of the Decade ${params?.decade}: A Cinematic Journey Through the Years`}
                    keywords={`${params?.decade}, trending, Decades, MyFrenzi`}
                />
                {isLoading && <Loader />}
                <Grid className='new-container mb-30'>
                    <Grid container alignItems={'center'} className="pt-20" justifyContent='space-between'>
                        <Grid item>
                            <Grid container alignItems={'center'}>
                                <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                                {heading ? heading
                                    :
                                    <h2 className='fs-28 fw-700 lh-28 m-0'><span className='text-selected'>trending titles</span> for you</h2>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className='pt-10 pb-20' alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item xs={10}>
                            <Grid container alignItems={'center'}>
                                <Grid item className=''>
                                    <Grid container onClick={handleClickListItem2} alignItems={'center'} className="pointer">
                                        <h2 className='fw-700 fs-15 m-0 mr-6 '>
                                            sort
                                        </h2>
                                        <SwapVertIcon />
                                    </Grid>
                                    <Popover
                                        id={'menu2'}
                                        open={open2}
                                        anchorEl={anchorEl2}
                                        onClose={handleClose2}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Box className='pb-20 pl-20 pr-20'>
                                            <Grid onClick={() => handleSort('d')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                <h2 className={`fw-500 fs-16 m-0 ${sort === 'd' && 'text-selected'}`} >release date</h2>
                                            </Grid>
                                            <Grid onClick={() => handleSort('l')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                <h2 className={`fw-500 fs-16 m-0 ${sort === 'l' && 'text-selected'}`} >popular</h2>
                                            </Grid>
                                            <Grid onClick={() => handleSort('r')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                                <h2 className={`fw-500 fs-16 m-0 ${sort === 'r' && 'text-selected'}`} >rating</h2>
                                            </Grid>
                                        </Box>
                                    </Popover>
                                </Grid>
                            </Grid>
                        </Grid>
                        <SideFilter
                            onSearchApply={(e) => handleApplyFilter(e)}
                            hideClearBtn={true}
                        />
                    </Grid>
                    <Grid container flexWrap={'nowrap'}>
                        <Grid item style={{ maxWidth: '100%' }} flex={1}>
                            <Grid item xs={12} style={{ marginBottom: '10px' }} justifyContent={'center'}>
                                <Grid container justifyContent={'center'}>
                                    <MyAds type="banner_2" adv_type={'web_decade_detail_top_ad'} hideInMobile={true} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                {mediaList.map((x, i) => {
                                    return <Grid item key={i} xs={12} sm={6} >
                                        <SingleMediaTile refPage={'Decades'} x={x} />
                                    </Grid>
                                })}

                            </Grid>
                            {isLoading ?
                                <MediaDetailLoader />
                                : ''
                            }
                        </Grid>
                        <Grid item sx={{ display: { xs: 'none', lg: 'block' } }} className="sticky-ads">
                            <MyAds type={'banner_1'} adv_type={'web_decade_detail_right_ad'} />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </div>
    )
}
