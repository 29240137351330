import { Button, Grid, Modal, Slider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import { rateAndLikeMedia } from "../../slices/media";
import { toggleLoginModal, updateToggleRate, updateToggleReview } from "../../slices/global";
import MediumTile from "./MediumTile";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";
import { HELPER_TEXT } from "../../lang/HelperText";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import FinalReview from "../component/review/FinalReview";

import "swiper/css";
import "swiper/css/navigation";
import "./common.scss";

const CongratsIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingCongratulation.svg";
const ThrilledIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/thrilled.svg";
const AmusedIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/amused.svg";
const ShockedIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/shocked.svg";
const FrustrtedIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/frustrated.svg";
const SadIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/sad.svg";
const TouchedIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/touched.svg";
const TenseIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/tense.svg";
const ScaredIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/scared.svg";
const BoredIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/bored.svg";
const UnderstoodIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/understood.svg";
const ConfusedIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/confused.svg";
const ReflectiveIocn = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/reflective.svg";
const HandpicIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/HandpicIcon.svg";
const GrayScaleEmoji = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/GrayScaleEmoji.png";

const FinalEmoji0 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00000-min.png";
const FinalEmoji1 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00001-min.png";
const FinalEmoji2 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00002-min.png";
const FinalEmoji3 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00003-min.png";
const FinalEmoji4 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00004-min.png";
const FinalEmoji5 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00005-min.png";
const FinalEmoji6 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00006-min.png";
const FinalEmoji7 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00007-min.png";
const FinalEmoji8 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00008-min.png";
const FinalEmoji9 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00009-min.png";
const FinalEmoji10 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00010-min.png";
const FinalEmoji11 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00011-min.png";
const FinalEmoji12 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00012-min.png";
const FinalEmoji13 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00013-min.png";
const FinalEmoji14 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00014-min.png";
const FinalEmoji15 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00015-min.png";
const FinalEmoji16 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00016-min.png";
const FinalEmoji17 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00017-min.png";
const FinalEmoji18 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00018-min.png";
const FinalEmoji19 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00019-min.png";
const FinalEmoji20 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00020-min.png";
const FinalEmoji21 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00021-min.png";
const FinalEmoji22 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00022-min.png";
const FinalEmoji23 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00023-min.png";
const FinalEmoji24 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00024-min.png";
const FinalEmoji25 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00025-min.png";
const FinalEmoji26 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00026-min.png";
const FinalEmoji27 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00027-min.png";
const FinalEmoji28 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00028-min.png";
const FinalEmoji29 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00029-min.png";
const FinalEmoji30 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00030-min.png";
const FinalEmoji31 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00031-min.png";
const FinalEmoji32 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00032-min.png";
const FinalEmoji33 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00033-min.png";
const FinalEmoji34 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00034-min.png";
const FinalEmoji35 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00035-min.png";
const FinalEmoji36 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00036-min.png";
const FinalEmoji37 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00037-min.png";
const FinalEmoji38 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00038-min.png";
const FinalEmoji39 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00039-min.png";
const FinalEmoji40 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00040-min.png";
const FinalEmoji41 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00041-min.png";
const FinalEmoji42 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00042-min.png";
const FinalEmoji43 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00043-min.png";
const FinalEmoji44 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00044-min.png";
const FinalEmoji45 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00045-min.png";
const FinalEmoji46 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00046-min.png";
const FinalEmoji47 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00047-min.png";
const FinalEmoji48 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00048-min.png";
const FinalEmoji49 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00049-min.png";


const breakpoints = {
  319: {
    slidesPerView: 2,
    spaceBetween: 15,
  },
  425: {
    slidesPerView: 2,
    spaceBetween: 15,
  },
  640: {
    slidesPerView: 2.5,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 2.7,
    spaceBetween: 20,
    slidesPerGroup: 1.7
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 20,
    slidesPerGroup: 2
  },
  1440: {
    slidesPerView: 3,
    spaceBetween: 20,
    slidesPerGroup: 2
  },
  1880: {
    slidesPerView: 4,
    spaceBetween: 30,
    slidesPerGroup: 3
  },
  2560: {
    slidesPerView: 5,
    spaceBetween: 30,
    slidesPerGroup: 4
  },
}

export const ratingText = ["Fried", "Frown", "Fair", "Fab", "Frenzied"];

export const ratingEmoji = [
  {
    id: 0,
    title: "Fried",
    valeu: 0,
    emoji: FinalEmoji0,
  },

  {
    id: 1,
    title: "Fried",
    valeu: 0.1,
    emoji: FinalEmoji1,
  },

  {
    id: 2,
    title: "Fried",
    valeu: 0.2,
    emoji: FinalEmoji2,
  },
  {
    id: 3,
    title: "Fried",
    valeu: 0.3,
    emoji: FinalEmoji3,
  },
  {
    id: 4,
    title: "Fried",
    valeu: 0.4,
    emoji: FinalEmoji4,
  },
  {
    id: 5,
    title: "Fried",
    valeu: 0.5,
    emoji: FinalEmoji5,
  },
  {
    id: 6,
    title: "Fried",
    valeu: 0.6,
    emoji: FinalEmoji6,
  },
  {
    id: 7,
    title: "Fried",
    valeu: 0.7,
    emoji: FinalEmoji7,
  },
  {
    id: 8,
    title: "Fried",
    valeu: 0.8,
    emoji: FinalEmoji8,
  },
  {
    id: 9,
    title: "Fried",
    valeu: 0.9,
    emoji: FinalEmoji9,
  },
  {
    id: 10,
    title: "Fried",
    valeu: 1,
    emoji: FinalEmoji10,
  },
  {
    id: 11,
    title: "Frown",
    valeu: 1.1,
    emoji: FinalEmoji11,
  },
  {
    id: 12,
    title: "Frown",
    valeu: 1.2,
    emoji: FinalEmoji12,
  },
  {
    id: 13,
    title: "Frown",
    valeu: 1.3,
    emoji: FinalEmoji13,
  },
  {
    id: 14,
    title: "Frown",
    valeu: 1.4,
    emoji: FinalEmoji14,
  },
  {
    id: 15,
    title: "Frown",
    valeu: 1.5,
    emoji: FinalEmoji15,
  },
  {
    id: 16,
    title: "Frown",
    valeu: 1.6,
    emoji: FinalEmoji16,
  },

  {
    id: 17,
    title: "Frown",
    valeu: 1.7,
    emoji: FinalEmoji17,
  },

  {
    id: 18,
    title: "Frown",
    valeu: 1.8,
    emoji: FinalEmoji18,
  },

  {
    id: 19,
    title: "Frown",
    valeu: 1.9,
    emoji: FinalEmoji19,
  },
  {
    id: 20,
    title: "Frown",
    valeu: 2,
    emoji: FinalEmoji20,
  },
  {
    id: 21,
    title: "Fair",
    valeu: 2.1,
    emoji: FinalEmoji21,
  },
  {
    id: 22,
    title: "Fair",
    valeu: 2.2,
    emoji: FinalEmoji22,
  },
  {
    id: 23,
    title: "Fair",
    valeu: 2.3,
    emoji: FinalEmoji23,
  },
  {
    id: 24,
    title: "Fair",
    valeu: 2.4,
    emoji: FinalEmoji24,
  },
  {
    id: 25,
    title: "Fair",
    valeu: 2.5,
    emoji: FinalEmoji25,
  },
  {
    id: 26,
    title: "Fair",
    valeu: 2.6,
    emoji: FinalEmoji26,
  },
  {
    id: 27,
    title: "Fair",
    valeu: 2.7,
    emoji: FinalEmoji27,
  },
  {
    id: 28,
    title: "Fair",
    valeu: 2.8,
    emoji: FinalEmoji28,
  },
  {
    id: 29,
    title: "Fair",
    valeu: 2.9,
    emoji: FinalEmoji29,
  },
  {
    id: 30,
    title: "Fair",
    valeu: 3,
    emoji: FinalEmoji30,
  },
  {
    id: 31,
    title: "Fab",
    valeu: 3.1,
    emoji: FinalEmoji31,
  },
  {
    id: 32,
    title: "Fab",
    valeu: 3.2,
    emoji: FinalEmoji32,
  },
  {
    id: 33,
    title: "Fab",
    valeu: 3.3,
    emoji: FinalEmoji33,
  },
  {
    id: 34,
    title: "Fab",
    valeu: 3.4,
    emoji: FinalEmoji34,
  },
  {
    id: 35,
    title: "Fab",
    valeu: 3.5,
    emoji: FinalEmoji35,
  },
  {
    id: 36,
    title: "Fab",
    valeu: 3.6,
    emoji: FinalEmoji36,
  },
  {
    id: 37,
    title: "Fab",
    valeu: 3.7,
    emoji: FinalEmoji37,
  },
  {
    id: 38,
    title: "Fab",
    valeu: 3.8,
    emoji: FinalEmoji38,
  },
  {
    id: 39,
    title: "Fab",
    valeu: 3.9,
    emoji: FinalEmoji39,
  },
  {
    id: 40,
    title: "Fab",
    valeu: 4,
    emoji: FinalEmoji40,
  },
  {
    id: 41,
    title: "Frenzied",
    valeu: 4.1,
    emoji: FinalEmoji41,
  },
  {
    id: 42,
    title: "Frenzied",
    valeu: 4.2,
    emoji: FinalEmoji42,
  },
  {
    id: 43,
    title: "Frenzied",
    valeu: 4.3,
    emoji: FinalEmoji43,
  },
  {
    id: 44,
    title: "Frenzied",
    valeu: 4.4,
    emoji: FinalEmoji44,
  },
  {
    id: 45,
    title: "Frenzied",
    valeu: 4.5,
    emoji: FinalEmoji45,
  },
  {
    id: 46,
    title: "Frenzied",
    valeu: 4.6,
    emoji: FinalEmoji46,
  },
  {
    id: 47,
    title: "Frenzied",
    valeu: 4.7,
    emoji: FinalEmoji47,
  },
  {
    id: 48,
    title: "Frenzied",
    valeu: 4.8,
    emoji: FinalEmoji48,
  },
  {
    id: 49,
    title: "Frenzied",
    valeu: 4.9,
    emoji: FinalEmoji49,
  },
  {
    id: 50,
    title: "Frenzied",
    valeu: 5,
    emoji: FinalEmoji49,
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '100%', sm: '70%', md: '47%', lg: '50%' },
  bgcolor: "#16213E",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  maxHeight: "90vh",
  overflowY: "auto",
  zIndex: 999,
};

const titleChracter = [
  {
    id: 1,
    label: "Drama ",
    icon: HandpicIcon,
  },
  {
    id: 2,
    label: "Sports",
    icon: HandpicIcon,
  },
  {
    id: 3,
    label: "Comedy",
    icon: HandpicIcon,
  },
  {
    id: 4,
    label: "Action",
    icon: HandpicIcon,
  },
  {
    id: 5,
    label: "Crime",
    icon: HandpicIcon,
  },
  {
    id: 6,
    label: "Marathi",
    icon: HandpicIcon,
  },
  {
    id: 7,
    label: "Documentary",
    icon: HandpicIcon,
  },
  {
    id: 8,
    label: "Gujrati",
    icon: HandpicIcon,
  },
];

const titleReview = [
  {
    id: 1,
    label: "Thrilled ",
    icon: ThrilledIcon,
  },
  {
    id: 2,
    label: "Amused          ",
    icon: AmusedIcon,
  },
  {
    id: 3,
    label: "Shocked      ",
    icon: ShockedIcon,
  },
  {
    id: 4,
    label: "Frustrated ",
    icon: FrustrtedIcon,
  },
  {
    id: 5,
    label: "Sad",
    icon: SadIcon,
  },
  {
    id: 6,
    label: "Touched",
    icon: TouchedIcon,
  },
  {
    id: 7,
    label: "Tense ",
    icon: TenseIcon,
  },
  {
    id: 8,
    label: "Scared",
    icon: ScaredIcon,
  },
  {
    id: 9,
    label: "Bored",
    icon: BoredIcon,
  },
  {
    id: 10,
    label: "Understood",
    icon: UnderstoodIcon,
  },
  {
    id: 11,
    label: "Confused",
    icon: ConfusedIcon,
  },
  {
    id: 12,
    label: "Reflective",
    icon: ReflectiveIocn,
  },
];

export default function RateMovie(props) {
  const { data, height, width, type, size, hideLabel, justifyContent } = props;
  const [reviewedPopup, setReviewedPopup] = useState(false);
  const [isRatedIds, setIsRatedIds] = useState([]);
  const [mediaData, setMediaData] = useState(data);
  const [activeStep, setActiveStep] = useState(0);
  const [updateReview, setUpdateReview] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [ratingPopup, setRatingPopup] = useState(false);
  const dispatch = useDispatch();
  const global = useSelector((state) => state.global);
  const activity = useSelector((state) => state.activity);
  const { haveYouWatchedData } = activity;
  const [refreshFlag, setRefreshFlag] = useState(false);

  useEffect(() => {
    setMediaData({...data});
  }, [data]);

  const handleRateTitle = (mediaData) => {
    setMediaData(mediaData);
    setUpdateReview(true);
  };

  useEffect(() => {
    if (mediaData.is_rated && mediaData.is_rated > 0) {
      setRatingValue(mediaData.is_rated > ratingValue ? mediaData.is_rated : ratingValue);
      if (props?.onChange && props?.onChange) {
        props?.onChange(ratingValue);
      }
    } else {
      setRatingValue(0);
    }
  }, [mediaData]);

  useEffect(() => {
    if (global.isOpenRateModal) {
      handleModalOpen();
    } else {
      handleRatingClose();
    }
  }, [global.isOpenRateModal]);

  useEffect(() => {
    if (global.isOpenReviewModal && global.isOpenReviewModal !== "" && !global.isOpenRateModal) {
      setActiveStep(3);
      handleClickOpen();
    }
  }, [global.isOpenReviewModal]);

  const handleClickOpen = () => {
    setReviewedPopup(true);
  };
  const handleClose = () => {
    setActiveStep(0);
    setReviewedPopup(false);
    dispatch(updateToggleReview(""));
    setRefreshFlag((prevRefreshFlag) => !prevRefreshFlag);
  };
  const handleRatingOpen = () => {
    setRatingPopup(true);
  };
  const handleRatingClose = () => {
    setRatingPopup(false);

    dispatch(updateToggleRate(false));
  };

  const handleModalOpen = (mediaData) => {
    if (global?.is_public) {
      dispatch(toggleLoginModal({ isOpen: true, content: HELPER_TEXT.NUDGE_TEXT.RATE }));
    } else {
      handleRatingOpen();
    }
  };

  // const handleSubmitRatingOnly = () => {
  //   submitRating();
  //   handleRatingClose();
  // };

  const handleContinueToreview = () => {
    if (ratingValue > 0) {
      if (props?.onChange && props?.onChange) {
        props?.onChange(ratingValue);
      }
      dispatch(updateToggleRate(false));
      submitRating();
      handleRatingClose();
      if (!mediaData.is_review_added) {
        handleClickOpen();
      }
    } else {
      helpers.toastWarning("please rate the title");
    }
  };

  const submitRating = () => {
    helpers.trackMoengageEvent('FR3_Searches', {
      title: mediaData.title,
      rate: MOENGAGE_ATTRIBUTE.YES,
      seen: MOENGAGE_ATTRIBUTE.YES,
    });
    if (ratingValue !== mediaData.is_rated) {
      const payload = {
        media:
          mediaData.media && mediaData.media.length > 25
            ? mediaData.media
            : mediaData.uuid && mediaData.uuid.length > 25
              ? mediaData.uuid
              : "",
        ratings: String(ratingValue),
      };
      setIsRatedIds(mediaData.media || mediaData.uuid);
      dispatch(rateAndLikeMedia({ body: payload }));
    }
  };

  // console.log('amanb',data)

  return (
    <>
      {type === 'btn' ?
        <button
          onClick={() => handleModalOpen(mediaData)}
          className={`btn pointer rounded-btn pr-20 pl-20 small ${(isRatedIds.includes(mediaData.media || mediaData.uuid) || (data.is_rated && data.is_rated) > 0) ? 'bg-muted text-light' : 'bg-light text-selected'}`}>
          {(isRatedIds.includes(mediaData.media || mediaData.uuid) || (data.is_rated && data.is_rated) > 0) ? 'edit rating' : 'rate'}
        </button>
        :
        <Grid
          container
          onClick={() => handleModalOpen(mediaData)}
          justifyContent={"center"}
        >
          {type === 'emoji' ?
            <>
              {data?.is_rated || ratingValue ?
                <>
                  <Box
                    key={refreshFlag}
                    alt={data?.is_rated}
                    component="img"
                    sx={{
                      height: "100%",
                      width: "100%",
                      maxHeight: height ? height : 48,
                      maxWidth: width ? width : 48,
                      verticalAlign: 'bottom'
                    }}
                    src={
                      ratingEmoji.filter(
                        (item) => item.valeu === parseInt(data.is_rated) / 2
                      ) &&
                        ratingEmoji.filter(
                          (item) => item.valeu === parseInt(data.is_rated) / 2
                        )[0]
                        ? ratingEmoji.filter(
                          (item) => item.valeu === parseInt(data.is_rated) / 2
                        )[0]?.emoji
                        : ""
                    }
                  />
                </>
                :
                <>
                  <Box
                    alt={data?.user_rating}
                    component="img"
                    sx={{
                      height: "100%",
                      width: "100%",
                      maxHeight: height ? height : 48,
                      maxWidth: width ? width : 48,
                      verticalAlign: 'bottom'
                    }}
                    className="pointer"
                    src={GrayScaleEmoji}
                  />
                </>
              }
            </>
            :
            <Box
              className={`pointer icon-container ${props.className}`}
              sx={{
                maxHeight: height ? height : 48,
                maxWidth: width ? width : 48,
                width: "100%",
                height: "100%",
                padding: "8px",
              }}
            >
              <StarBorderRoundedIcon
                className={`fs-${size ? size : 40} ${(isRatedIds.includes(mediaData.media || mediaData.uuid) || (data.is_rated && data.is_rated) > 0) ? "text-selected" : ''}`}
              />
            </Box>
          }
        </Grid>
      }
      {!hideLabel && (
        <span
          onClick={() => handleModalOpen(mediaData)}
          className="d-block text-center pointer fs-14 fw-600"
        >
          Rate
        </span>
      )}

      {/* RATING POPUP */}
      <div>
        <Modal
          open={ratingPopup}
          onClose={handleRatingClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item>
              <p className="fw-700 fs-28 m-0">
                {mediaData.title ? mediaData.title : ""}
              </p>
              <Grid
                container
                justifyContent={"space-between"}
                className="mt-16"
              >
                <Grid item xs={12}>
                  <p className="fs-20 fw-600 m-0 ">
                    tell us what you think about this title
                    {/* <span className="text-selected">{mediaData.media_type}</span>{" "} */}
                  </p>

                  <Grid
                    item
                    className="text-center"
                    xs={12}
                    sx={{ marginTop: "10px" }}
                  >
                    <Box
                      alt={ratingValue}
                      component="img"
                      sx={{
                        height: "100%",
                        width: "100%",
                        maxHeight: { xs: 70, sm: 150 },
                        maxWidth: { xs: 70, sm: 150 },
                      }}
                      src={
                        ratingEmoji.filter(
                          (item) => item.valeu === parseInt(ratingValue) / 2
                        ) &&
                          ratingEmoji.filter(
                            (item) => item.valeu === parseInt(ratingValue) / 2
                          )[0]
                          ? ratingEmoji.filter(
                            (item) => item.valeu === parseInt(ratingValue) / 2
                          )[0]?.emoji
                          : "aman"
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item className="text-center" xs={12}>
                  <Grid container justifyContent={'center'}>
                    <Box
                      sx={{ width: { xs: "75%", sm: "65%" }, marginTop: "30px" }}
                      className="rating-slider-container"
                    >
                      <Slider
                        aria-label="rating"
                        defaultValue={ratingValue}
                        valueLabelDisplay="on"
                        step={0.1}
                        marks
                        min={0}
                        max={10}
                        onChange={(e) => setRatingValue(e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <p className="fw-700 fs-24 m-0">
                    {ratingValue <= 2 && ratingText[0]}
                    {ratingValue > 2 && ratingValue <= 4 && ratingText[1]}
                    {ratingValue > 4 && ratingValue <= 6 && ratingText[2]}
                    {ratingValue > 6 && ratingValue <= 8 && ratingText[3]}
                    {ratingValue > 8 && ratingText[4]}
                  </p>
                </Grid>
              </Grid>
              <Grid container justifyContent={"center"} className="mt-30">
                {/* <Button onClick={() => handleSubmitRatingOnly()} className='rounded-btn mw-240 bg-light text-selected fs-14 fw-500 lh-16 '>submit rating only</Button> */}
                <Button
                  onClick={() => handleContinueToreview()}
                  className="rounded-btn mw-240 bg-selected text-light fs-14 fw-500 lh-16"
                >
                  submit rating
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>

      {/* REVIEWED POPUP */}
      <div>
        <Modal
          open={reviewedPopup}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {!updateReview && mediaData.is_review_added ? (
              <>
                <Grid item>
                  <p className="fw-700 fs-28 m-0">Title already reviewed</p>
                  <p className="fw-400 fs-20 m-0 pt-15 text-dark-grey">
                    Do you want to update your rating for the title?
                  </p>
                </Grid>
                <Grid item className="mt-30">
                  <p className="fw-400 fs-20 m-0">
                    You have already submitted a rating and review for this
                    title, on 21 Jan 2022 | 10:30 AM
                  </p>
                </Grid>
                <div className="d-flex jc-sb mt-30">
                  <Button
                    onClick={() => handleClose()}
                    className="rounded-btn mw-240 mr-20 bg-light text-selected fs-14 fw-500 lh-16 "
                  >
                    cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setUpdateReview(true);
                      setActiveStep(3);
                    }}
                    className="rounded-btn mw-240 ml-20 bg-selected text-light fs-14 fw-500 lh-16"
                  >
                    update earlier review
                  </Button>
                </div>
              </>
            ) : (
              <>
                {activeStep < 1 ? (
                  mediaData.is_review_added ? (
                    <Grid item>
                      <p className="fw-700 fs-28 m-0">
                        <span className="text-selected">thank you for </span>
                        your review
                      </p>
                      <p className="fw-400 fs-20 m-0 text-dark-grey">
                        your review is submitted for admin approval
                      </p>
                      <Grid item className="text-center mt-30">
                        <p className="fw-700 fs-20 m-0">
                          meanwhile rate these titles to earn more points
                        </p>
                      </Grid>
                      <Grid item xs={12} className="mt-30">
                        {haveYouWatchedData &&
                          haveYouWatchedData.length > 0 && (
                            <Swiper
                              slidesPerView={2}
                              spaceBetween={20}
                              // slidesPerGroup={5}
                              speed={1000}
                              freeMode={true}
                              navigation={true}
                              breakpoints={breakpoints}
                              modules={[FreeMode, Navigation]}
                              className="mySwiper"
                            >
                              {haveYouWatchedData.map((y, j) => {
                                return (<SwiperSlide key={j}>
                                  <div style={{ padding: '20px 0px 20px 0px' }} key={j}>
                                    <MediumTile
                                      x={y}
                                      hideBookmark={true}
                                      hideFooter={true}
                                      isRateTitle={true}
                                      refPage={props?.refPage}
                                    />
                                    <div
                                      style={{ marginTop: "20px" }}
                                      className="text-center"
                                    >
                                      <Button
                                        onClick={() => handleRateTitle(y)}
                                        className="rounded-btn w-75 bg-light text-selected"
                                      >
                                        rate title
                                      </Button>
                                    </div>
                                  </div>
                                </SwiperSlide>
                                );
                              })}
                            </Swiper>
                          )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item>
                      <p className="fw-700 fs-28 m-0">
                        <span className="text-selected">let’s build</span> your review
                      </p>
                      <p className="fw-400 fs-20 m-0 text-dark-grey">
                        congratulations!!! you have earned <span className="text-selected"> 1 </span> frenzi point
                      </p>
                      <Grid item className="text-center">
                        <Box
                          component="img"
                          alt='icon'
                          sx={{
                            height: 150,
                            width: 169,
                            maxHeight: { xs: 100, sm: 150 },
                            maxWidth: { xs: 100, sm: 168 },
                            borderRadius: "8px",
                            // padding: "5px"
                          }}
                          src={CongratsIcon}
                        />
                        <p className="fw-700 fs-20 m-0">
                          review and earn upto <span className="text-selected">100</span> frenzi points
                        </p>
                      </Grid>

                      <div className="d-flex jc-sb mt-30">
                        <Button
                          onClick={() => handleClose()}
                          className="rounded-btn mw-240 mr-20 bg-light text-selected fs-14 fw-500 lh-16 "
                        >
                          no, i’ll do this later
                        </Button>
                        <Button
                          onClick={() => {
                            setActiveStep(3);
                            helpers.trackMoengageEvent('FR3_Post_Review', {
                              proceed_with_review_click: MOENGAGE_ATTRIBUTE.YES,
                            });
                          }}
                          className="rounded-btn mw-240 ml-20 bg-selected text-light fs-14 fw-500 lh-16"
                        >
                          yes, i want to review
                        </Button>
                      </div>
                    </Grid>
                  )
                ) : activeStep === 1 ? (
                  <>
                    <Grid container>
                      <Grid item>
                        <Grid item>
                          <p className="fw-700 fs-28 m-0">
                            title review ({activeStep}/3)
                          </p>
                          <p className="fw-400 fs-20 m-0 pt-5 text-dark-grey">
                            She hulk (Web Series)
                          </p>
                          <p className="fw-500 fs-16 m-0">
                            how did you like the movie?
                          </p>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                          className="mt-10"
                        >
                          {titleReview.map((x, i) => {
                            return (
                              <Grid item sm={3}>
                                <Grid
                                  item
                                  className="emoji-container text-center"
                                >
                                  <Box
                                    component="img"
                                    alt='icon'
                                    sx={{
                                      height: 36,
                                      width: 36,
                                      maxHeight: { xs: 20, sm: 36 },
                                      maxWidth: { xs: 20, sm: 36 },
                                      borderRadius: "8px",
                                      // padding: "5px"
                                    }}
                                    src={x.icon}
                                  />
                                  <p className="fw-400 fs-12 m-0">{x.label}</p>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className="d-flex jc-sb mt-10 pb-10">
                      <Button
                        onClick={() => handleClose()}
                        className="rounded-btn mw-240 mr-20 bg-light text-selected fs-14 fw-500 lh-16 "
                      >
                        cancel
                      </Button>
                      <Button
                        onClick={() => setActiveStep(activeStep + 1)}
                        className="rounded-btn mw-240 ml-20 bg-selected text-light fs-14 fw-500 lh-16"
                      >
                        continue review
                      </Button>
                    </div>
                  </>
                ) : activeStep === 2 ? (
                  <>
                    <Grid container>
                      <Grid item>
                        <Grid item>
                          <p className="fw-700 fs-28 m-0">
                            title review ({activeStep}/3)
                          </p>
                          <p className="fw-400 fs-20 m-0 pt-5 text-dark-grey">
                            She hulk (Web Series)
                          </p>
                          <p className="fw-500 fs-16 m-0">
                            how did you like the movie?
                          </p>
                        </Grid>
                        <Grid
                          container
                          // spacing={3}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                          className="mt-10"
                        >
                          {titleChracter.map((x, i) => {
                            return (
                              <Grid item sm={3} className="mt-10">
                                <Grid item className="text-center">
                                  <Box
                                    component="img"
                                    alt='icon'
                                    sx={{
                                      height: 150,
                                      width: 100,
                                      maxHeight: { xs: 100, sm: 150 },
                                      maxWidth: { xs: 70, sm: 100 },
                                      borderRadius: "8px",
                                      // padding: "5px"
                                    }}
                                    src={x.icon}
                                  />
                                  <p className="fw-400 fs-12 m-0">{x.label}</p>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className="d-flex jc-sb mt-10 pb-10">
                      <Button
                        onClick={() => handleClose()}
                        className="rounded-btn mw-240 mr-20 bg-light text-selected fs-14 fw-500 lh-16 "
                      >
                        cancel
                      </Button>
                      <Button
                        onClick={() => setActiveStep(activeStep + 1)}
                        className="rounded-btn mw-240 ml-20 bg-selected text-light fs-14 fw-500 lh-16"
                      >
                        continue review
                      </Button>
                    </div>
                  </>
                ) : (
                  activeStep === 3 && (
                    <>
                      <FinalReview handleClose={handleClose} data={mediaData} />
                    </>
                  )
                )}
              </>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
}
