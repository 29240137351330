import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid'
import React from 'react'
import MyAds from '../adsense/MyAds';
import SingleMediaTile from '../common/SingleMediaTile'

export default function Films(props) {
    const { data, isLoading, title } = props;

    return (
        <Grid container className='pt-30' justifyContent={'center'} >
            <Grid item xs={12}>
                <h2 className='m-0 fs-24 fw-700'>{title} - movies</h2>
                <Grid container className='mt-0' spacing={4} >
                    {data && data.length > 0 && data.map((x, i) => {
                        if (i === 6 || i === 24 || i === 48) {
                            return <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                                <Grid container justifyContent={'center'}>
                                    <MyAds type="banner_2" adv_type={'web_films_middle_ad'} />
                                </Grid>
                            </Grid>
                        }
                        return <Grid item className='cast-data-items' sm={6} lg={4} xs={12} key={i}>
                            <SingleMediaTile x={x} showNotifyButton={false} />
                        </Grid>
                    })}
                    {isLoading &&
                        <Grid item sm={6} md={4} xs={12} key={'skeleton'}>
                            <Skeleton
                                sx={{
                                    height: 210,
                                    width: 147,
                                    maxHeight: { xs: 180, sm: 210 },
                                    maxWidth: { xs: 110, sm: 147 },
                                    borderRadius: '8px',
                                    // backgroundColor: '#c4c4c4 !important'
                                }}
                                animation="wave"
                                variant="rectangular" />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}
