import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import helpers from '../../services/helpers';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { joinWatchlist } from '../../slices/watchlist';
import SuggestTitles from './includes/SuggestTitles';
import MediumTile from '../common/MediumTile';
import AddRemoveTitles from './includes/AddRemoveTitles';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import MyAds from '../adsense/MyAds';


export default function SectionMedia(props) {
    const { data, showAds } = props;
    const dispatch = useDispatch();

    const handleJoinWatchlist = (item) => {
        let id = item.watchlist_id ? item.watchlist_id : item?.uuid;
        if (id && id != "") {
            dispatch(joinWatchlist({ id: id, body: {} }));
        }
    }

    return (
        <Grid item className='new-container watchlist-first-items section-border-bottom pl-50 pb-10 pt-10'>
            <Grid container alignItems={'center'} justifyContent={'space-between'} style={{ marginTop: '10px' }}>
                <Grid item xs={10}>
                    <Grid container alignItems={'center'}>
                        <p className='fw-700 fs-28 m-0 lh-25 pr-12'>{data.watchlist ? data.watchlist : data?.category_name}</p>
                        {/* {data.watchlist_tag &&
                            <Box className='mt-10'>
                                <p className='popular-container m-0 fw-500 fs-12'>{data.watchlist_tag ? helpers.getWatchlistLabel(data.watchlist_tag) : ''}</p>
                            </Box>
                        } */}
                    </Grid>
                    {data.total_members &&
                        <p className='fw-500 fs-24 m-0'>{helpers.getMemberNumber(data.total_members)} joined</p>
                    }
                </Grid>
                <Grid item className='pt-10'>
                    <Link onClick={() => helpers.watchlistClick(data?.category_name, data.watchlist_id ? data.watchlist_id : data?.uuid, 'Watchlist')} to={`/watchlist-detail/${data.watchlist_id ? data.watchlist_id : data?.uuid}`}>
                        <ArrowForwardIcon />
                    </Link>
                </Grid>
            </Grid>
            <Grid item xs={12} className='pt-20'>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={2}
                    slidesPerGroup={5}
                    freeMode={true}
                    navigation={true}
                    speed={1000}
                    // breakpoints={MEDIUM_TILE_BREAKPOINTS}
                    modules={[FreeMode, Navigation]}
                    className="mySwiper"
                    
                >
                    {data.medias && data.medias.length > 0 && data.medias.map((x, i) => {
                        return <>
                            <SwiperSlide key={i} style={{maxWidth:'170px'}}>
                                <Grid sx={{ padding: '20px 0px 20px 0px', maxWidth: { xs: '100px', sm: "140px" } }} key={i}>
                                    <MediumTile refPage={props?.refPage} x={x} />
                                </Grid>
                            </SwiperSlide>
                            {showAds && (data.medias.length > 2 ? i === 2 : data.medias.length - 1 === i) &&
                                <SwiperSlide style={{ minWidth: '300px' }} key={`ads=${i}`}>
                                    <MyAds type="banner_3" adv_type={'web_watchlist_listing_ad'} />
                                </SwiperSlide>
                            }
                        </>
                    })}
                </Swiper>
                <Grid container sx={{ justifyContent: { xs: 'center', lg: 'flex-end' } }} className=''>
                    {data.role === 'owner' ?
                        <AddRemoveTitles data={data} className={'mw-320'} label={'add titles'} isWhite={true} />
                        :
                        data.is_member ?
                            <SuggestTitles className={'mw-320'} data={data} />
                            :
                            <Button onClick={() => handleJoinWatchlist(data)} className='rounded-btn bg-light text-selected fs-14 mw-320 fw-500' style={{ marginTop: '10px', marginBottom: '10px' }}>join this watchlist</Button>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}