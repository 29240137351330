import React, { useEffect, useRef, useState } from 'react'
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import { useDispatch, useSelector } from 'react-redux';
// import Mute from '../../assets/icons/Mute.svg';
// import UnMute from '../../assets/icons/UnMute.svg';
import { Box } from '@mui/system';
import { toggleClipsMuted } from '../../slices/global';
import helpers from '../../services/helpers';
import ReactHlsPlayer from 'react-hls-player';
import { clipPostActivity } from '../../slices/clips';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import { Link } from 'react-router-dom';

const Mute = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/Mute.svg";
const UnMute = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/UnMute.svg";

export default function ClipsPlayer(props) {

    const { thumbnail_url, onScroll } = props;

    const playerRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [signedThumbUrl, setSignedThumbUrl] = useState('');
    const [viewedId, setViewedId] = useState('');
    const [currentProgress, setCurrentProgress] = useState(0);
    const global = useSelector(state => state.global);
    const { isClipsMuted } = global;
    const dispatch = useDispatch();

    useEffect(() => {
        if (thumbnail_url && thumbnail_url != '') {
            getPresignedUrl(thumbnail_url);
        }
    }, [thumbnail_url])

    useEffect(() => {
        let myVideo = document.getElementById(`frenzi-clip-${props?.id}`);
        myVideo.addEventListener("timeupdate", function () {
            if (!isNaN(this.duration)) {
                var percent_complete = (this.currentTime / this.duration) * 100;
                setCurrentProgress(percent_complete);
                if (parseInt(this.currentTime) === 15) {
                    setViewedId(props.id);
                }
            }
        });
    }, [])

    useEffect(() => {
        if (viewedId == props.id) {
            const body = {
                "post_id": props.id
            }
            dispatch(clipPostActivity({ type: 'view', body }));
        }
    }, [viewedId])

    const getPresignedUrl = async (path) => {
        if (path && path != "") {
            const url = await helpers.getPresignedUrl(path);
            if (url) {
                setSignedThumbUrl(url);
            }
        }
    }

    const handleOnPlay = (fromProp = false) => {
        if (onScroll) {
            setIsPlaying(true);
            playerRef.current.play();
        } else {
            if (fromProp) {
                setIsPlaying(true);
                playerRef.current.play();
            } else {
                props?.setActiveClip && props?.setActiveClip(props?.id);
            }
        }
    }

    const handleOnPause = (fromProp = false) => {
        if (onScroll) {
            setIsPlaying(false);
            playerRef.current.pause()
        } else {
            if (fromProp) {
                setIsPlaying(false);
                playerRef.current.pause()
            } else {
                props?.setActiveClip && props?.setActiveClip('');
            }
        }
    }

    const handlePlayPause = (check) => {
        if (check) {
            handleOnPlay();
        } else {
            handleOnPause();
        }
    }

    const handleMuteUnmute = (val) => {
        dispatch(toggleClipsMuted(val));
    }

    useEffect(() => {
        setIsPlaying(props.isPlay);
        if (playerRef && playerRef.current) {
            setCurrentProgress(0);
            // const timeToStart = (7 * 60) + 12.6;
            // playerRef.current.seekTo(timeToStart, 'seconds');
            if (props.isPlay) {
                handleOnPlay(true);
            } else {
                handleOnPause(true);
            }
        }
    }, [props.isPlay])



    return (
        <>
            {props?.isMovie ?
                <div className='pointer mute-unmute-icon'
                    style={{
                        left: '10px',
                    }}
                >
                    <Link to={`/clips/${props?.id}`}>
                        <OpenInFullRoundedIcon className='fs-24 scale-1'/>
                    </Link>
                </div>
                : ''
            }
            <Box
                component={'img'}
                className="d-block mute-unmute-icon scale-1 pointer"
                alt=""
                src={isClipsMuted ? UnMute : Mute}
                onClick={() => handleMuteUnmute(!isClipsMuted)}
            />
            <span className='play-action-overlay' onClick={() => handlePlayPause(!isPlaying)}>
                <ReactHlsPlayer
                    id={`frenzi-clip-${props?.id}`}
                    playerRef={playerRef}
                    controls={false}
                    loop={true}
                    muted={isClipsMuted}
                    src={props?.src}
                    height={'100%'}
                    width={'100%'}
                    className="frenzi-clips-player"
                    playsInline={true}
                />
                {!isPlaying && currentProgress == 0 && signedThumbUrl ?
                    <div style={{
                        backgroundImage: `url(${signedThumbUrl})`
                    }}
                        className="clip-thumbnail"
                    >
                    </div>
                    : ''
                }
                {/* <ReactPlayer
                    id={`frenzi-clip-${props?.id}`}
                    ref={playerRef}
                    src={props?.src}
                    // url={`${props?.src};ord=${Math.random()*10000000000000000};`}
                    onReady={(player) => handleReady()}
                    controls={false}
                    loop={true}
                    playing={isPlaying}
                    onPlay={() => handleOnPlay()}
                    onPause={() => handleOnPause()}
                    onError={(error, data, hlsInstance, hlsGlobal) => {
                        console.log(error, data, hlsInstance, hlsGlobal);
                    }}
                    onProgress={(played) => {
                        setCurrentProgress(played.playedSeconds / played.loadedSeconds * 100);
                    }}
                    className="frenzi-clips-player"
                    height={'100%'}
                    width={'100%'}
                    muted={isClipsMuted}
                    // playsinline={true}
                    light={signedThumbUrl}
                /> */}
            </span>
            <div className='clip-progress-bar'>
                <div className='bar' style={{ width: `${currentProgress}%` }} ></div>
            </div>

            {
                isPlaying ?
                    <PauseCircleFilledRoundedIcon sx={{ width: '32px', height: '32px', opacity: 0 }} onClick={() => handleOnPause()} className='clips-action-icon' />
                    :
                    <PlayCircleRoundedIcon sx={{ width: '32px', height: '32px' }} onClick={() => handleOnPlay()} className='text-selected clips-action-icon' />
            }
        </>
    )
}
