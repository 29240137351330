import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import helpers from '../../services/helpers';
import { watchOnTv } from '../../slices/media';
import { Html5Qrcode } from "html5-qrcode";
import { Stack } from '@mui/system';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { Slider } from '@mui/material';

export default function QrCodeScanner(props) {
    const { myQrReader, deeplink_id } = props;
    const dispatch = useDispatch();
    const [isScannerRunning, setIsScannerRunning] = useState(false);
    const [zoom, setZoom] = useState(1);

    const handleChange = (event, newValue) => {
        setZoom(newValue);
    };

    const handleScan = (text) => {
        if (text && props.uuid && deeplink_id) {
            const body = {
                media: props.uuid,
                ott_deep_link: deeplink_id
            }
            dispatch(watchOnTv({ body: body, id: text })).then((resp) => {
                if (resp.payload.status) {
                    helpers.toastSuccess("Playing On TV");
                    props.onClose && props.onClose();
                } else {
                    helpers.toastError("Failed to Play On TV");
                    props.onClose && props.onClose();
                }
            })
        }
    }


    function onScanSuccess(decodedText, decodedResult) {
        handleScan(decodedText);
    }

    function onScanFailure(error) {
        // console.warn(`Code scan error = ${error}`);
    }

    const onPageLoad = () => {
        if (!isScannerRunning) {
            const html5QrCode = new Html5Qrcode("reader");
            setIsScannerRunning(true);
            props?.setMyQrReader(html5QrCode);

            html5QrCode.start(
                { facingMode: "environment" },
                // {facingMode: { exact: "environment"} },
                {
                    fps: 10,    // Optional, frame per seconds for qr code scanning
                    qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
                },
                (decodedText, decodedResult) => {
                    onScanSuccess(decodedText, decodedResult);
                },
                (errorMessage) => {
                    onScanFailure(errorMessage);
                })
                .catch((err) => {
                    onScanFailure(err);
                });
        }
    };

    const applyConstraints = async (state) => {
        if (state === 2) {
            const constraints = {
                "zoom" : true,
                "advanced": [{
                    "zoom": zoom
                }]
            };
            console.log(constraints)
            let log = await myQrReader.applyVideoConstraints({ ...constraints });
        }
    }

    useEffect(() => {
        if (myQrReader) {
            let state = myQrReader.getState();
            applyConstraints(state);
        }
    }, [zoom])

    return (
        <div className='qr-scanner' >
            <img onLoad={() => onPageLoad()} src={'https://picsum.photos/200'} alt='' style={{ display: 'none' }} />
            <h2 style={{ paddingRight: '20px' }} className="fw-700 fs-22 m-0">Scan the QR code on the frenzi TV App</h2>
            <h2 className="fs-16 text-muted m-0">Download the Frenzi app from the playstore</h2>
            <div id="reader" width="600px"></div>
            <div>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <RemoveRoundedIcon className='pointer'
                        onClick={(e) => {
                            handleChange(e, zoom > 1 ? zoom - 0.1 : 1)
                        }}
                    />
                    <Slider aria-label="Zoom" min={1} max={7} step={0.1} value={zoom} onChange={handleChange} />
                    <AddRoundedIcon className='pointer'
                        onClick={(e) => {
                            handleChange(e, zoom < 10 ? zoom + 0.1 : 10)
                        }}
                    />
                </Stack>
            </div>

            {/* <div className='relative'>
                <CameraswitchIcon onClick={() => handleSwicth()} className='camera-switch text-selected' />
            </div> */}
        </div>
    )
}

