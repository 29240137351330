import { Grid, CircularProgress, Paper, IconButton, InputBase, Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCinephilesUserDetails, getFrenziUsers } from '../../../slices/connect';
// import Replay10Icon from '@mui/icons-material/Replay10';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import InviteWith from '../../common/InviteWith';
import NewUserCard from '../../common/NewUserCard';
import MovieLovers from '../../myCinescore/MovieLovers';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../../services/helpers';
import FollowUnfollowPerson from '../../common/FollowUnfollowPerson';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { Link } from 'react-router-dom';

const MediaFlipRed = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/MediaFlipRed.svg";



export default function InviteFriends() {
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const connect = useSelector(state => state.connect);
    const { frenziUserPageData, cinephilesUserPageData, cinephilesUserData } = connect;
    const [page, setPage] = useState(1);
    const settings = useSelector((state) => state.settings);
    const { userDetails } = settings;

    const handleSearchTextChange = (e) => {
        setValue(e.target.value)

    }

    useEffect(() => {
        dispatch(getFrenziUsers({ query: value, page: 1, pagelimit: 50 }));
    }, [value])

    useEffect(() => {
        dispatch(getCinephilesUserDetails({ page: 1, pagelimit: 18 }));
    }, [])

    // console.log("hello aman", cinephilesUserData)
    console.log('aman aman', frenziUserPageData)

    return (
        <div>
            <h2>invite with</h2>
            <InviteWith />
            <h2 className='fw-700 fs-20 lh-20 mb-0'>find friends on <span className='text-selected'>frenzi</span></h2>
            <h2 className='fw-300 fs-14 lh-14 text-muted mt-5 mb-0'>you can select multiple contacts to follow</h2>
            <Grid className='relative pb-10'>
                <Paper
                    component="form"
                    style={{ opacity: 1 }}
                    className='w-100 d-flex ai-center br-25 mb-5 mt-30'

                >
                    <IconButton aria-label="search" className=' h-100 bg-selected' sx={{ borderBottomRightRadius: '0px', borderTopRightRadius: '0px', }}>
                        <SearchIcon className='ml-3 pt-2' color="light" />
                    </IconButton>
                    <InputBase
                        className='f-1 fs-14 pl-10 lh-18 ls-1 fw-500 text-light'
                        value={value}
                        type='text'
                        onChange={handleSearchTextChange}
                        placeholder='search user'
                    />

                    <IconButton aria-label="search" onClick={() => setValue('')} className='h-100 bg-muted' sx={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}>
                        <CloseIcon className='ml-3 pt-2' color="light" />
                    </IconButton>
                </Paper>
            </Grid>
            {frenziUserPageData && frenziUserPageData.results && frenziUserPageData.results.length > 0 ?
                <div className="mt-10 mh-350" style={{ overflowY: 'auto' }}>
                    <Grid container spacing={3}>
                        {frenziUserPageData.results.map((x, i) => {
                            return <Grid item xs={4} sm={3} md={3} className='text-center' sx={{ boxSizing: 'border-box', minWidth: { lg: '200px' } }} >
                                <Grid className='bg-light p-10' sx={{minHeight:{lg:'206px' , xs:'158px' , md:'177px'}}}>
                                    <Box
                                        component="img"
                                        className='d-inline-block'
                                        sx={{
                                            height: 80,
                                            width: 80,
                                            maxHeight: { xs: 40, sm: 60 },
                                            maxWidth: { xs: 40, sm: 60 },
                                            borderRadius: '50%',
                                            objectFit: "cover"

                                        }}
                                        alt="profilepic"
                                        src={x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage('profile')}
                                    />
                                    <p title={x?.username} className='p-0 m-0 fs-18 text-dark ellipsis'>{x.username ? x.username : ''}</p>
                                    <div className='row mb-15 mt-15' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <img alt={''} src={MediaFlipRed} className="mr-10" />
                                        <p className='p-0 m-0 fs-16 text-dark'>{x?.cinescore}</p>
                                    </div>


                                    {/* <div className='row' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <FollowUnfollowPerson iconBtn={true} data={x} />
                                        </div> */}

                                    {x.uuid !== userDetails.uuid &&
                                        <Grid item xs={12} className='mt-18 w-100'>
                                            {/* <Button className="rounded-btn w-100 mw-110 bg-secondary text-light fs-12 fw-700 mb-15">
                            watchlist
                        </Button> */}
                                            <div className='row' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <FollowUnfollowPerson iconBtn={true} data={x} />
                                            </div>
                                        </Grid>
                                    }

                                </Grid>
                            </Grid>
                            {/* <Grid item xs={4} sm={3} md={2} className='text-center'>
                                <Grid container className='jc-center'>
                                    <Box
                                        component="img"
                                        className='d-inline-block'
                                        sx={{
                                            height: 80,
                                            width: 80,
                                            maxHeight: { xs: 60, sm: 80 },
                                            maxWidth: { xs: 60, sm: 80 },
                                            borderRadius: '50%',
                                            objectFit: 'cover'
                                        }}
                                        alt="profilepic"
                                        src={x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage('person')}
                                    />
                                    <p title={x?.username} className='fs-10 ellipsis fw-600 text-center pt-5 pb-12 m-0'>{x.username ? x.username : ''}</p>
                                    <FollowUnfollowPerson data={x} />
                                </Grid>
                            </Grid> */}
                        })}
                    </Grid>
                </div>
                :
                <div className="mt-10 mh-350" style={{ overflowY: 'auto' }}>
                    <Grid container spacing={3}>
                        {cinephilesUserData && cinephilesUserData.length > 0 && cinephilesUserData.map((x, i) => {
                            return <Grid item xs={4} sm={3} md={3} className='text-center' sx={{ boxSizing: 'border-box', minWidth: { lg: '200px' } }} >
                                <Grid className='bg-light p-10'>
                                    <Link
                                        onClick={() =>
                                            helpers.trackMoengageEvent("FR3_Connect", {
                                                user_pic_click: MOENGAGE_ATTRIBUTE.YES,
                                            })
                                        }
                                        to={`/profile/${x.uuid}`} >

                                        <Box
                                            component="img"
                                            className='d-inline-block'
                                            sx={{
                                                height: 80,
                                                width: 80,
                                                maxHeight: { xs: 40, sm: 60 },
                                                maxWidth: { xs: 40, sm: 60 },
                                                borderRadius: '50%',
                                                objectFit: "cover"

                                            }}
                                            alt="profilepic"
                                            src={x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage('profile')}
                                        />
                                    </Link>
                                    <p title={x?.username} className='p-0 m-0 fs-18 text-dark ellipsis'>{x.username ? x.username : ''}</p>
                                    <div className='row mb-15 mt-15' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        {/* <img alt={''} src={MediaFlipRed} className="mr-10" />
                                            <p className='p-0 m-0 fs-16 text-dark'>{x?.cinescore}</p> */}
                                    </div>
                                    <div className='row' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <FollowUnfollowPerson iconBtn={true} data={x} />
                                    </div>
                                    {/* <p title={x?.cinescore} className='p-0 m-0 fs-18 text-dark ellipsis'>{x.cinescore ? x.cinescore : ''}</p>

                                    <FollowUnfollowPerson data={x} /> */}
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                    {cinephilesUserPageData?.pagination && cinephilesUserPageData?.pagination.is_next_page &&
                        <div className='text-center mt-20'>
                            {connect.isLoading ?
                                <CircularProgress size={20} color="success" />
                                :
                                <RefreshOutlinedIcon onClick={() => {
                                    if (cinephilesUserPageData?.pagination && cinephilesUserPageData?.pagination.is_next_page) {
                                        dispatch(getCinephilesUserDetails({ page: cinephilesUserPageData?.pagination.page + 1, pagelimit: 18 }));
                                    }
                                }} className='pointer' />
                            }
                        </div>
                    }
                </div>
            }
            {/* <MovieLovers /> */}
        </div>
    )
}
