import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SettingsDataService from "../services/settings.services";
import helpers from "../services/helpers";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    userDetails: {},
    profileUserDetails: {},
    frenziPointSummaryPageData: {},
    frenziPointSummaryData: [],
    orderDetailsPageData: {},
    orderDetailsData: [],
    mutedUsersPageData: {},
    mutedUsersData: [],
    reportedUsersPageData: {},
    reportedUsersData: [],
    blockedUsersPageData: {},
    blockedUsersData: [],
    deleteAccountOtpData: {},
    faqListPageData: {},
    faqListData: [],
    pollSectionPageData: {},
    pollSectionData: [],
    quizSectionPageData: {},
    quizSectionData: [],
    recommendedPageData: {},
    recommendedData: [],
    userReviewPageData: {},
    userReviewData: [],
    userWatchlistPageData: {},
    userWatchlistData: [],
    userCommonData: {},
    notificationsPageData: {},
    notificationsData: [],
    notificationRequestsPageData: {},
    notificationRequestsData: [],
    unreadNotifications: 0
};

export const getUserDetails = createAsyncThunk(
    "native_main/userdetails",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getUserDetails()
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const clearSearchHistory = createAsyncThunk(
    "native_main/clearallsearchhistory",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.clearSearchHistory()
            return response.data
        }
        catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getOtherUserDetails = createAsyncThunk(
    "native_main/otheruserdetails",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getOtherUserDetails(data?.id)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFrenziPointSummary = createAsyncThunk(
    "native_main/frenzipointssummary",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getFrenziPointSummary(data?.date_range, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getOrderDetails = createAsyncThunk(
    "native_main/subscriptionlistviainvoice",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getOrderDetails(data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getMutedUsers = createAsyncThunk(
    "native_main/usermute",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getMutedUsers(data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getReportedUsers = createAsyncThunk(
    "native_main/userreport",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getReportedUsers(data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getBlockedUsers = createAsyncThunk(
    "native_main/userblock",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getBlockedUsers(data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const deleteUserAccount = createAsyncThunk(
    "native_main/deleteuseraccount",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.deleteUserAccount(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const deleteUserAccountOtp = createAsyncThunk(
    "native_main/deleteuseraccountotp",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.deleteUserAccountOtp(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const submitFeedback = createAsyncThunk(
    "native_main/submitfeedback",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.submitFeedback(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const changeProfileType = createAsyncThunk(
    "native_main/changeprofiltype",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.changeProfileType(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const changeAllowPosts = createAsyncThunk(
    "native_main/allowposts",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.changeAllowPosts(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFaqsList = createAsyncThunk(
    "native_main/faqlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getFaqsList(data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPollSection = createAsyncThunk(
    "native_main/userpolllist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getPollSection(data?.section, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const getQuizSection = createAsyncThunk(
    "native_main/userquizlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getQuizSection(data?.section, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const updateUserDetails = createAsyncThunk(
    "native_main/editprofile",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.updateUserDetails(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const updateUserName = createAsyncThunk(
    "native_main/changeusername",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.updateUserName(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const updatePhoneEmail = createAsyncThunk(
    "native_main/verificationotpv3",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.updatePhoneEmail(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const verifyOtp = createAsyncThunk(
    "native_main/otpverificationv3",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.verifyOtp(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getUserRecommendedData = createAsyncThunk(
    "native_main/userrecommended",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getUserRecommendedData(data?.category, data?.user, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFrenziUserAllReviews = createAsyncThunk(
    "native_main/frenziallreviews",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getFrenziUserAllReviews(data?.comment_type, data?.user, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getMyAllReviews = createAsyncThunk(
    "native_main/myreviews",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getMyAllReviews(data?.comment_type, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getUserWatchlist = createAsyncThunk(
    "native_main/watchlistcreated",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getUserWatchlist(data?.privacy, data?.sort, data?.user, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getCommonUserData = createAsyncThunk(
    "native_main/profilecommons",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getCommonUserData(data?.uuid)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const blockUser = createAsyncThunk(
    "native_main/blockuserblock",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.blockUser(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const reportUser = createAsyncThunk(
    "native_main/reportuserreport",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.reportUser(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getNotifications = createAsyncThunk(
    "native_main/notificationcenter",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getNotifications('remaining', data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getNotificationsRequests = createAsyncThunk(
    "native_main/notificationcenterrequest",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getNotifications('requests', data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const readNotification = createAsyncThunk(
    "native_main/readnotification",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.readNotification(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const deleteNotification = createAsyncThunk(
    "native_main/deletenotification",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.deleteNotification(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const acceptRequest = createAsyncThunk(
    "native_main/acceptrequest",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.acceptRequest(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const declineRequest = createAsyncThunk(
    "native_main/declinerequest",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.declineRequest(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const deleteReveiw = createAsyncThunk(
    "native_main/deletereview",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.deleteReveiw(data?.id)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const updateReview = createAsyncThunk(
    "native_main/updatereview",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.updateReview(data?.id, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getUnreadNotifications = createAsyncThunk(
    "native_main/unreadnotifications",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingsDataService.getUnreadNotifications(data?.id, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const settingsSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        },
        ClearProfileData: (state) => {
            state.profileUserDetails = {};
        },
        removeDeletedReview: (state, action) => {
            let id = action.payload;
            state.userReviewData = state.userReviewData.filter(x => x.comment_id != id);
        }
    },
    extraReducers: {
        [getUserDetails.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getUserDetails.fulfilled]: (state, action) => {
            state.userDetails = getPayloadData(state, action);
            state.profileUserDetails = getPayloadData(state, action);
        },
        [getUserDetails.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getOtherUserDetails
        [getOtherUserDetails.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getOtherUserDetails.fulfilled]: (state, action) => {
            state.profileUserDetails = getPayloadData(state, action);
        },
        [getOtherUserDetails.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFrenziPointSummary
        [getFrenziPointSummary.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFrenziPointSummary.fulfilled]: (state, action) => {
            state.frenziPointSummaryPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.frenziPointSummaryData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.frenziPointSummaryData = state.frenziPointSummaryData.concat(getArrayData(state, action));
            }
        },
        [getFrenziPointSummary.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getOrderDetails
        [getOrderDetails.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getOrderDetails.fulfilled]: (state, action) => {
            state.orderDetailsPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.orderDetailsData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.orderDetailsData = helpers.removeDuplicate(state.orderDetailsData.concat(getArrayData(state, action)));
            }
        },
        [getOrderDetails.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getMutedUsers
        [getMutedUsers.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getMutedUsers.fulfilled]: (state, action) => {
            state.mutedUsersPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.mutedUsersData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.mutedUsersData = helpers.removeDuplicate(state.mutedUsersData.concat(getArrayData(state, action)));
            }
        },
        [getMutedUsers.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getReportedUsers
        [getReportedUsers.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getReportedUsers.fulfilled]: (state, action) => {
            state.reportedUsersPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.reportedUsersData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.reportedUsersData = helpers.removeDuplicate(state.reportedUsersData.concat(getArrayData(state, action)));
            }
        },
        [getReportedUsers.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getBlockedUsers
        [getBlockedUsers.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getBlockedUsers.fulfilled]: (state, action) => {
            state.blockedUsersPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.blockedUsersData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.blockedUsersData = helpers.removeDuplicate(state.blockedUsersData.concat(getArrayData(state, action)));
            }
        },
        [getBlockedUsers.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // deleteUserAccount
        [deleteUserAccount.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [deleteUserAccount.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.deleteAccountOtpData = action.payload.data
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [deleteUserAccount.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // deleteUserAccountOtp
        [deleteUserAccountOtp.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [deleteUserAccountOtp.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
                state.token = '';
                state.isAuthenticated = false;
                localStorage.removeItem('frenzi_user_access');
                window.location.href = "/login";
            }
        },
        [deleteUserAccountOtp.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // submitFeedback
        [submitFeedback.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [submitFeedback.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [submitFeedback.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // changeProfileType
        [changeProfileType.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [changeProfileType.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [changeProfileType.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // changeAllowPosts
        [changeAllowPosts.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [changeAllowPosts.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [changeAllowPosts.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFaqsList
        [getFaqsList.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFaqsList.fulfilled]: (state, action) => {
            state.faqListPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.faqListData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.faqListData = helpers.removeDuplicate(state.faqListData.concat(getArrayData(state, action)));
            }
        },
        [getFaqsList.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPollSection
        [getPollSection.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPollSection.fulfilled]: (state, action) => {
            state.pollSectionPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination) {
                if (action.payload.data.pagination.page == 1) {
                    state.pollSectionData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
                } else {
                    state.pollSectionData = helpers.removeDuplicate(state.pollSectionData.concat(getArrayData(state, action)));
                }
            } else {
                state.pollSectionData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            }
        },
        [getPollSection.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getQuizSection
        [getQuizSection.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getQuizSection.fulfilled]: (state, action) => {
            state.quizSectionPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination) {
                if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                    state.quizSectionData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
                } else {
                    state.quizSectionData = helpers.removeDuplicate(state.quizSectionData.concat(getArrayData(state, action)));
                }
            } else {
                state.quizSectionData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            }
        },
        [getQuizSection.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // updateUserDetails
        [updateUserDetails.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [updateUserDetails.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [updateUserDetails.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // updateUserName
        [updateUserName.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [updateUserName.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [updateUserName.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getUserRecommendedData
        [getUserRecommendedData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getUserRecommendedData.fulfilled]: (state, action) => {
            state.recommendedPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.recommendedData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.recommendedData = helpers.removeDuplicate(state.recommendedData.concat(getArrayData(state, action)));
            }
        },
        [getUserRecommendedData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFrenziUserAllReviews
        [getFrenziUserAllReviews.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFrenziUserAllReviews.fulfilled]: (state, action) => {
            state.userReviewPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.userReviewData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                if (action.payload.data.results.length > 0) {
                    state.userReviewData = helpers.removeDuplicate(state.userReviewData.concat(getArrayData(state, action)));
                } else {
                    state.userReviewData = [];
                }
            }
        },
        [getFrenziUserAllReviews.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getMyAllReviews
        [getMyAllReviews.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getMyAllReviews.fulfilled]: (state, action) => {
            state.userReviewPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.userReviewData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.userReviewData = helpers.removeDuplicate(state.userReviewData.concat(getArrayData(state, action)));
            }
        },
        [getMyAllReviews.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getUserWatchlist
        [getUserWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getUserWatchlist.fulfilled]: (state, action) => {
            state.userWatchlistPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.userWatchlistData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.userWatchlistData = helpers.removeDuplicate(state.userWatchlistData.concat(getArrayData(state, action)));
            }
        },
        [getUserWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getCommonUserData
        [getCommonUserData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getCommonUserData.fulfilled]: (state, action) => {
            state.userCommonData = getPayloadData(state, action);
        },
        [getCommonUserData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // blockUser
        [blockUser.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [blockUser.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [blockUser.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // reportUser
        [reportUser.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [reportUser.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [reportUser.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getNotifications
        [getNotifications.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getNotifications.fulfilled]: (state, action) => {
            state.notificationsPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.notificationsData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.notificationsData = helpers.removeDuplicate(state.notificationsData.concat(getArrayData(state, action)));
            }
        },
        [getNotifications.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getNotificationsRequests
        [getNotificationsRequests.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getNotificationsRequests.fulfilled]: (state, action) => {
            state.notificationRequestsPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.notificationRequestsData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.notificationRequestsData = helpers.removeDuplicate(state.notificationRequestsData.concat(getArrayData(state, action)));
            }
        },
        [getNotificationsRequests.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // readNotification
        [readNotification.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [readNotification.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [readNotification.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getUnreadNotifications
        [getUnreadNotifications.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getUnreadNotifications.fulfilled]: (state, action) => {
            state.unreadNotifications = action.payload && action.payload.data ? action.payload.data : 0;
        },
        [getUnreadNotifications.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
    }
})

export const { clearMessages, ClearProfileData, removeDeletedReview } = settingsSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default settingsSlice.reducer;