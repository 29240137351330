import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

import { Link } from "react-router-dom";

import helpers from "../../services/helpers";
import BookmarkAction from "../watchlist/BookmarkAction";
import MediaTrailerBtn from "./MediaTrailerBtn";
import MediaSubdetails from "./MediaSubdetails";
import { isMobileDevice } from "../../services/Utils";


export default function CarouselTile(props) {
  const { x, isMediaSubDetails, withAd, customDetails, mediaSubDetails } = props;
  const [addedId, setAddeddId] = useState("");

  return (
    <div>
      <Grid className="relative w-100">
        {/* <Grid item xs={12}>
          <Paper
            sx={{
              backgroundImage: {
                xs: `url(${x.poster
                  ? helpers.getFullUrl(x?.poster, 500)
                  : x.poster
                    ? helpers.getFullUrlOriginal(x?.backdrop, 300)
                    : helpers.getDefaultImage("carousel")
                  })`,
                sm: `url(${x.backdrop
                  ? helpers.getFullUrlOriginal(x?.backdrop, 780)
                  : x.poster
                    ? helpers.getFullUrl(x?.poster, 780)
                    : helpers.getDefaultImage("carousel")
                  })`,
                lg: `url(${x.backdrop
                  ? helpers.getFullUrlOriginal(x?.backdrop, 1280)
                  : x.poster
                    ? helpers.getFullUrl(x?.poster, 780)
                    : helpers.getDefaultImage("carousel")
                  })`,
              },
              backgroundSize: 'auto',
              backgroundPosition: 'top',
              minWidth: '100%',
              height: '100vh',
              maxHeight: withAd ? { xs: '430px', sm: '400px', md: '400px', lg: '480px', xl: '65vh' } :
              { xs: '430px', sm: '400px', md: '460px', lg: '580px', xl: '75vh' }
            }}
            className="relative movie-card"
          >
            <Grid
              className="h-100"
              sx={{
                background: (isMediaSubDetails || customDetails)
                  ? "linear-gradient(180deg, rgba(0, 0, 0, 0) 26.51%, rgba(0, 0, 0, 0.9) 100%)"
                  : "",
              }}
            >
              <Link
                style={{ display: "block", height: "inherit", width: "100%" }}
                to={helpers.getMediaLink(x)}
                onClick={() => helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', props?.refPage)}
              ></Link>
              {x.language && x.media_type &&
                <Box className="">
                  <Grid className="p-15 mt-20" container flexDirection={"column"}>
                    <Grid item xs={12} className="pt-10 pr-30">
                      <Grid
                        container
                        alignItems={"center"}
                        style={{ minWidth: 90 }}
                        flexWrap={"nowrap"}
                        className="bottom-right w-100"
                      >
                        <p
                          style={{ textTransform: "uppercase" }}
                          className="fs-28 fw-700 lh-28 m-0"
                        >
                          {" "}
                          {x.media_type ? x.media_type : ""}{" "}
                        </p>
                        <p className="m-0 pl-5 pr-5"> | </p>{" "}
                        <p
                          className="m-0 pr-10"
                          style={{ textTransform: "uppercase" }}
                        >
                          {" "}
                          {x.language ? x.language : ""}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              }
              {x.media_type &&
                (x.media_type === "movie" || x.media_type === "tv") && (
                  <BookmarkAction
                    size={42}
                    data={x}
                    addedId={addedId}
                    setAddedId={setAddeddId}
                  />
                )}

              {isMediaSubDetails && (
                <Grid
                  sx={{
                    padding: { xs: "20px", sm: "30px" },
                    position: "absolute",
                    bottom: 30,
                    zIndex: 111,
                  }}
                >
                  <Link
                    to={helpers.getMediaLink(x)}
                    onClick={() => helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', props?.refPage)}
                  >
                    <Typography className="text-left fs-28 fw-700 lh-28 m-0">
                      {x?.title}
                    </Typography>
                  </Link>
                  <p className="fs-20 fw-500 lh-28 m-0">
                    {x?.release_year ? x?.release_year : ''} <span></span>
                    <span> {x?.media_type.toUpperCase()} </span>
                    <span>|</span>
                    <span> {x.data && x.data[0]?.ott_name}</span>
                  </p>
                  <MediaSubdetails data={x} className="mw-450" />
                </Grid>
              )}
              {customDetails && (
                <Grid
                  sx={{
                    padding: { xs: "20px", sm: "30px" },
                    position: "absolute",
                    bottom: 0,
                    zIndex: 111,
                  }}
                >
                  {mediaSubDetails}
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid> */}
        <Grid item>
          <Box
            component={'img'}
            src={isMobileDevice() ?
              (x.poster && x.poster !== ""
                ? helpers.getFullUrlOriginal(x?.poster || x?.poster, 500)
                : helpers.getDefaultImage('poster')
              )
              :
              (x.backdrop
                ? helpers.getFullUrlOriginal(x?.backdrop, 1280)
                : x.poster
                  ? helpers.getFullUrl(x?.poster, 780)
                  : helpers.getDefaultImage("carousel")
              )
            }
            alt=""
            className='w-100 d-block relative movie-card'
            sx={{
              objectPosition: 'top',
              objectFit: 'cover',
              minWidth: '100%',
              height: '100vh',
              maxHeight: withAd ? { xs: '430px', sm: '400px', md: '400px', lg: '480px', xl: '65vh' } :
                { xs: '430px', sm: '400px', md: '460px', lg: '580px', xl: '75vh' }
            }}
          />
          <Grid
            className="h-100"
            sx={{
              background: (isMediaSubDetails || customDetails)
                ? "linear-gradient(180deg, rgba(0, 0, 0, 0) 26.51%, rgba(0, 0, 0, 0.9) 100%)"
                : "",
            }}
          >
            <Link
              style={{ display: "block", height: "inherit", width: "100%" }}
              to={helpers.getMediaLink(x)}
              onClick={() => helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', props?.refPage)}
            ></Link>
            {/* {x.media_type &&
              (x.media_type === "movie" || x.media_type === "tv") && (
                <BookmarkAction
                  size={42}
                  data={x}
                  addedId={addedId}
                  setAddedId={setAddeddId}
                />
              )} */}

            {isMediaSubDetails && (
              <Grid
                sx={{
                  padding: { xs: "10px", sm: "30px" },
                  position: "absolute",
                  bottom: 20,
                  zIndex: 111,
                  // boxShadow: 'inset 0 -50px 10px rgba(0, 0, 0, 0.6)'
                }}
              >
                <Link
                  to={helpers.getMediaLink(x)}
                  onClick={() => helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', props?.refPage)}
                >
                  <Typography className="text-left fs-24 fw-700 lh-28 m-0">
                    {x?.title}
                  </Typography>
                </Link>
                <p className="fs-16 fw-500 lh-28 m-0">
                  {x?.release_year ? x?.release_year : ''} <span></span>
                  <span> {x?.media_type} </span>
                  <span>|</span>
                  <span> {x.data && x.data[0]?.ott_name}</span>
                </p>
                <MediaSubdetails data={x} className="mw-450" />
              </Grid>
            )}
            {customDetails && (
              <Grid
                sx={{
                  padding: { xs: "20px", sm: "30px" },
                  position: "absolute",
                  bottom: 0,
                  zIndex: 111,
                }}
              >
                {mediaSubDetails}
              </Grid>
            )}
          </Grid>
        </Grid>
        {x.trailer && x.trailer.key && (
          <Grid className="trailer-button-box w-100">
            <MediaTrailerBtn mediaData={x} ref_page={props?.ref_page} controls={true} isPlay={true} data={x.trailer} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
