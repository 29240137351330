import React, { useEffect, useState } from 'react'
import { Grid, Box, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { acceptRejectAllPendingPrivateWatchlistRequest, acceptRejectAllPendingWatchlistRequest, getAllPendingPrivateWatchlistRequest, getAllPendingWatchlistRequest } from '../../slices/watchlist';
import helpers from '../../services/helpers';
import { Link, useNavigate } from 'react-router-dom';
import MyHelmet from '../common/MyHelmet';


export default function PendingRequests(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const watchlist = useSelector(state => state.watchlist);
    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(20);

    useEffect(() => {
        if (watchlist.successMesg && watchlist.successMesg != "") {
            dispatch(getAllPendingWatchlistRequest({ page: props.limited ? 1 : page, pagelimit: props.limited ? 3 : pageLimit }));
            dispatch(getAllPendingPrivateWatchlistRequest({ page: props.limited ? 1 : page, pagelimit: props.limited ? 3 : pageLimit }));
        }
    }, [watchlist])

    useEffect(() => {
        dispatch(getAllPendingWatchlistRequest({ page: props.limited ? 1 : page, pagelimit: props.limited ? 3 : pageLimit }));
        dispatch(getAllPendingPrivateWatchlistRequest({ page: props.limited ? 1 : page, pagelimit: props.limited ? 3 : pageLimit }));
    }, [page])

    const handleAcceptTitle = (data) => {
        const payload = {
            watchlist_id: data?.watchlist_id,
            action: "accept"
        }
        dispatch(acceptRejectAllPendingWatchlistRequest({ body: payload }));
    }

    const handleDeclineTitle = (data) => {
        const payload = {
            watchlist_id: data?.watchlist_id,
            action: "reject"
        }
        dispatch(acceptRejectAllPendingWatchlistRequest({ body: payload }));
    }

    const handleAcceptPrivateTitle = (data) => {
        const payload = {
            watchlist_id: data?.watchlist_id,
            owner_id: data?.owner_id,
            action: "accept"
        }
        dispatch(acceptRejectAllPendingPrivateWatchlistRequest({ body: payload }));
    }

    const handleDeclinePrivateTitle = (data) => {
        const payload = {
            watchlist_id: data?.watchlist_id,
            owner_id: data?.owner_id,
            action: "reject"
        }
        dispatch(acceptRejectAllPendingPrivateWatchlistRequest({ body: payload }));
    }

    useEffect(() => {
        const handleScroll = () => {
            if (!props.limited && watchlist.pendingJoiningRequestPageData && watchlist.pendingJoiningRequestPageData.pagination && watchlist.pendingJoiningRequestPageData.pagination.is_next_page && !watchlist.isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, watchlist]);


    return (
        <>
            {!props.imported ?
                <MyHelmet
                    title="Pending Requests"
                    description={"Get ready for an epic movie experience as we add your most anticipated titles to our watchlist"}
                />
                :
                ''
            }
            <Grid container sx={{ paddingTop: '20px', paddingBottom: '20px' }} className='app-container'>
                <Grid item sm={12}>
                    {watchlist.pendingJoiningRequestData && watchlist.pendingJoiningRequestData.length > 0 && watchlist.pendingJoiningRequestData.map((x, i) => {
                        if (props.limited && i == 3) {
                            return true;
                        }
                        return <Grid key={i} className="pending-joining-items" sx={{ paddingTop: '20px' }}>
                            <Grid container justifyContent={'space-between'} className='w-100 h-100'>
                                <Grid item xs={9}>
                                    <Grid container flexWrap={'nowrap'}>
                                        <Link to={`${x?.user_id ? '/profile/' + x?.user_id : '#'}`}>
                                            <Box component="img"
                                                sx={{
                                                    height: '48px',
                                                    width: '48px',
                                                    maxWidth: { xs: '48px' },
                                                    maxHeight: { xs: '48px' },
                                                    clipPath: 'circle()',
                                                }}
                                                src={x?.owner_profile_image ? helpers.getFullUrl(x?.owner_profile_image) : helpers.getDefaultImage('profile')}
                                                alt='profile' />
                                        </Link>
                                        <Link to={x?.watchlist_id ? `/watchlist-detail/${x?.watchlist_id}` : '#'}>
                                            <Grid item sx={{ paddingLeft: '10px' }}>
                                                <Typography title={x.owner_username ? x.owner_username : ''} className='fs-20 fw-700 text-left ellipsis'>
                                                    {x?.owner_username}
                                                </Typography>
                                                <p className='fw-300 m-0 fs-12 text-left ellipsis text-dark-grey'>wants to join  ”{x?.watchlist}”</p>
                                            </Grid>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid item className='ml-16 mw-240' >
                                    <Grid container className='mt-2' justifyContent={'start'} alignItems={'center'}>
                                        <Grid item onClick={() => handleAcceptTitle(x)} className='rounded-circle mr-10 pointer mt-10'>
                                            <CheckIcon className='text-selected pointer' />
                                        </Grid>
                                        <Grid item onClick={() => handleDeclineTitle(x)} className='rounded-circle pointer mt-10'>
                                            <ClearIcon className='text-selected pointer' />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    })}

                    {watchlist.pendingPrivateJoiningRequestData && watchlist.pendingPrivateJoiningRequestData.length > 0 && watchlist.pendingPrivateJoiningRequestData.map((x, i) => {
                        if (props.limited && i == 3) {
                            return true;
                        }
                        return <Grid key={i} className="pending-joining-items" sx={{ paddingTop: '20px' }}>
                            <Grid container justifyContent={'space-between'} className='w-100 h-100'>
                                <Grid item xs={9}>
                                    <Grid container flexWrap={'nowrap'}>
                                        <Link to={`${x?.user_id ? '/profile/' + x?.user_id : '#'}`}>
                                            <Box component="img"
                                                sx={{
                                                    height: '48px',
                                                    width: '48px',
                                                    maxWidth: { xs: '48px' },
                                                    maxHeight: { xs: '48px' },
                                                    clipPath: 'circle()',
                                                }}
                                                src={x?.owner_profile_image ? helpers.getFullUrl(x?.owner_profile_image) : helpers.getDefaultImage('profile')}
                                                alt='profile' />
                                        </Link>
                                        <Link to={x?.watchlist_id ? `/watchlist-detail/${x?.watchlist_id}` : '#'}>
                                            <Grid item sx={{ paddingLeft: '10px' }}>
                                                <Typography title={x.owner_username ? x.owner_username : ''} className='fs-20 fw-700 text-left ellipsis'>
                                                    {x?.owner_username}
                                                </Typography>
                                                <p className='fw-300 m-0 fs-12 text-left ellipsis text-dark-grey'>wants to join  ”{x?.watchlist}”</p>
                                            </Grid>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid item className='ml-16 mw-240' >
                                    <Grid container className='mt-2' justifyContent={'start'} alignItems={'center'}>
                                        <Grid item onClick={() => handleAcceptPrivateTitle(x)} className='rounded-circle mr-10 pointer mt-10'>
                                            <CheckIcon className='text-selected pointer' />
                                        </Grid>
                                        <Grid item onClick={() => handleDeclinePrivateTitle(x)} className='rounded-circle pointer mt-10'>
                                            <ClearIcon className='text-selected pointer' />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    })}
                </Grid >
            </Grid >

        </>
    )
}

