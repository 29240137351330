import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { acceptRequest, clearMessages, declineRequest, deleteNotification, getNotifications, getNotificationsRequests, getUnreadNotifications, readNotification } from '../../slices/settings';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import { acceptRejectAllPendingPrivateWatchlistRequest, acceptRejectJoinWatchlistRequest, clearMessages as clearMessagesWatchlist } from "../../slices/watchlist";

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import MyAds from '../adsense/MyAds';
import { isMobileDevice } from '../../services/Utils';
import SingleMediaTile from '../common/SingleMediaTile';
import { getComingSoon } from '../../slices/trending';
import PendingSuggestions from '../watchlist/includes/PendingSuggestions';
import { acceptRejectTitleRequest } from '../../slices/watchlist';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import PendingRequests from '../watchlist/PendingRequests';
// import ZeroStateForNotification from '../../assets/ZeroStateForNotification.svg'
// import ZeroStateForPendingNotification from '../../assets/ZeroStateForPendingNotification.svg'
// import NotificationZeroStateMob from '../../assets/NotificationZeroStateMob.svg'


const ZeroStateForNotification = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/ZeroStateForNotification.svg";
const ZeroStateForPendingNotification = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/ZeroStateForPendingNotification.svg";
const NotificationZeroStateMob = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/NotificationZeroStateMob.svg";


const defaultSearchMediaFilter = {
    service: [],
    genre: [],
    language: [],
    content_type: [],
    exclude_already_watched: '',
    sort: '',
    apply_filter: false
}


export default function Notifications(props) {
    const { drawerOpen } = props;
    const dispatch = useDispatch();
    const [notificationId, setNotificationId] = useState('');
    const [page, setPage] = useState(1);
    const [requestPage, setRequestPage] = useState(1);
    const pageLimit = 20;
    const [type, setType] = useState('updates');
    const trending = useSelector(state => state.trending);
    const { comingSoonData } = trending;
    const [defaultSearchFilter, setDefaultSearchFilter] = useState(defaultSearchMediaFilter);
    const [searchParams] = useSearchParams();
    const is_free = searchParams.get('is_free') && searchParams.get('is_free') == 'true' ? true : false;
    const navigate = useNavigate();


    const settings = useSelector(state => state.settings);
    const watchlist = useSelector(state => state.watchlist);
    const { notificationsPageData, notificationsData, notificationRequestsPageData,
        notificationRequestsData, isLoading } = settings;
    const _global = useSelector(state => state.global);



    const trackEvent = (type) => {
        if (type === 'updates') {
            helpers.trackMoengageEvent('FR3_Notification', { update_tab: MOENGAGE_ATTRIBUTE.YES });
        } else {
            helpers.trackMoengageEvent('FR3_Notification', { request_tab: MOENGAGE_ATTRIBUTE.YES });
        }
    }

    useEffect(() => {
        if (settings.errMesg && settings.errMesg != "") {
            helpers.toastError(settings.errMesg);
            dispatch(clearMessages());
        }
        if (settings.successMesg && settings.successMesg != "") {
            helpers.toastSuccess(settings.successMesg);
            dispatch(clearMessages());
        }
        if (watchlist.errMesg && watchlist.errMesg != "") {
            helpers.toastError(watchlist.errMesg);
            dispatch(clearMessagesWatchlist());
        }
        if (watchlist.successMesg && watchlist.successMesg != "") {
            helpers.toastSuccess(watchlist.successMesg);
            dispatch(clearMessagesWatchlist());
        }
    }, [settings, watchlist])

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, [page])

    const getData = async (filter = defaultSearchFilter) => {
        var body = {
            page: page,
            pagelimit: 20,
            body: filter.apply_filter ? filter : {},
            is_free: is_free,
            is_public: _global.is_public
        }
        dispatch(getComingSoon(body));
    }

    useEffect(() => {
        if (!_global.is_public) {
            dispatch(getNotifications({ page: page, pagelimit: pageLimit }));
        }
    }, [page, drawerOpen])

    useEffect(() => {
        if (!_global.is_public) {
            dispatch(getNotificationsRequests({ page: requestPage, pagelimit: pageLimit }));
        }
    }, [requestPage, drawerOpen])

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        let element = window;

        const handleScroll = () => {
            if (type === 'updates') {
                if (notificationsPageData && notificationsPageData.pagination && notificationsPageData.pagination.is_next_page && !isLoading && element) {
                    let elements = document.getElementsByClassName('notification-items');
                    if (elements && elements.length > 0) {
                        let element = elements[elements.length - 1];
                        if (helpers.isInViewport(element)) {
                            setPage(page + 1);
                        }
                    }
                }
            } else {
                if (notificationRequestsPageData && notificationRequestsPageData.pagination && notificationRequestsPageData.pagination.is_next_page && !isLoading && element) {
                    let elements = document.getElementsByClassName('notification-requests-items');
                    if (elements && elements.length > 0) {
                        let element = elements[elements.length - 1];
                        if (helpers.isInViewport(element)) {
                            setRequestPage(requestPage + 1);
                        }
                    }
                }
            }
        }

        element.addEventListener("scroll", handleScroll, { passive: true });
        return () => element.removeEventListener("scroll", handleScroll);
    }, [page, requestPage, settings]);



    const handleReadNotification = (id, is_read = false, title = '') => {
        if (is_read) {
            return true;
        }
        if (title !== '') {
            helpers.trackMoengageEvent('FR3_Notification', { notfication_click: MOENGAGE_ATTRIBUTE.YES, title: title });
        }

        const body = {
            notification_id: id
        }
        dispatch(readNotification({ body })).then(() => {
            if (type === 'updates') {
                if (id === 'all') {
                    dispatch(getNotifications({ page: 1, pagelimit: pageLimit }));
                } else {
                    dispatch(getNotifications({ page: page, pagelimit: pageLimit }));
                }
            } else {
                if (id === 'all') {
                    dispatch(getNotificationsRequests({ page: 1, pagelimit: pageLimit }));
                } else {
                    dispatch(getNotificationsRequests({ page: requestPage, pagelimit: pageLimit }));
                }
            }
            dispatch(getUnreadNotifications());
        })
    }

    const handleOnrequestClick = (data, title = '') => {
        helpers.trackMoengageEvent('FR3_Notification', { notfication_click: MOENGAGE_ATTRIBUTE.YES, title: title });

        if (data?.notification_type === 'follow_request') {
            setNotificationId(data?.notification_id === notificationId ? '' : data?.notification_id);
        }
        if (!data?.is_read) {
            handleReadNotification(data?.notification_id, data?.is_read);
        }
    }

    const handleDeleteNotification = (id) => {
        const body = {
            notification_id: id
        }
        dispatch(deleteNotification({ body })).then(() => {
            if (type === 'updates') {
                dispatch(getNotifications({ page: page, pagelimit: pageLimit }));
            } else {
                dispatch(getNotificationsRequests({ page: requestPage, pagelimit: pageLimit }));
            }
        });
    }

    const handleRequests = (type, id, nid) => {
        const body = {
            target_user: id
        }
        if (type === 'accept') {
            dispatch(acceptRequest({ body })).then((resp) => {
                handleDeleteNotification(nid);
                if (resp.payload.message) {
                    if (resp.payload.status) {
                        helpers.toastSuccess(resp.payload.message);
                    } else {
                        helpers.toastError(resp.payload.message);
                    }
                }
            });
        } else {
            dispatch(declineRequest({ body })).then((resp) => {
                handleDeleteNotification(nid);
                if (resp.payload.message) {
                    if (resp.payload.status) {
                        helpers.toastSuccess(resp.payload.message);
                    } else {
                        helpers.toastError(resp.payload.message);
                    }
                }
            });
        }
        setNotificationId('');
    }

    const handleAcceptTitle = (data, action = 'accept') => {
        const { notification_type } = data;
        // suggest_title  watchlist_request  watchlist_invite
        if (notification_type === 'suggest_title') {
            const payload = {
                watchlist_id: data.watchlist_id,
                media_id: data?.media_id || data?.media,
                action: action,
                user_id: data.user_id
            }
            dispatch(acceptRejectTitleRequest({ body: payload }));
        } else if (notification_type === 'watchlist_request') {
            const payload = {
                watchlist_id: data.watchlist_id,
                action: action,
                owner_id: data?.user_id
            }
            // dispatch(acceptRejectJoinWatchlistRequest({ body: payload }));
            dispatch(acceptRejectAllPendingPrivateWatchlistRequest({ body: payload }));
        } else if (notification_type === 'watchlist_invite') {
            const payload = {
                watchlist_id: data.watchlist_id,
                action: action,
            }
            dispatch(acceptRejectJoinWatchlistRequest({ body: payload }));
        }
        if (data?.notification_id) {
            handleReadNotification(data?.notification_id);
        }
    }

    const handleLinkCLick = (x) => {
        if (x?.notification_type === 'follow') {
            console.log('do nothing');
        } else {
            helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', 'Notifications');
        }
    }

    const handleGetLink = (x) => {
        let type = x?.notification_type;

        if (type === 'follow' || type === 'follow_request_accept' || type === 'referral_bonus' || type === 'bonus' || type === 'birthday') {
            if (x?.user_id && x?.user_id != '') {
                return '/profile/' + x?.user_id;
            }
        } else if (type === 'follow_request') {
            console.log('open follow modal');
        } else if (type === 'report_error') {
            return '/points';
        } else if (type === 'watchlist' || type === 'watchlist_media' || type === 'suggest_accept' || type === 'watchlist_request_accept' || type === 'watchlist_join') {
            return '/watchlist-detail/' + x?.watchlist_id;
        } else if (type === 'suggest_title' || type === 'watchlist_request' || type === 'watchlist_invite') {
            return '/watchlists';
        } else if (type === 'discover_post_like') {
            return '/clips/' + x?.post_id;
        } else {
            if (x?.media) {
                return helpers.getMediaLink(x);
            } else {
                return '#';
            }
        }
    }

    const handleGetImgLink = (x) => {
        if (x?.notification_type === 'notify_me') {
            return helpers.getMediaLink(x);
        } else {
            return x?.user_id ? `/profile/${x?.user_id}` : '/profile';
        }
    }

    const getSrc = (x) => {
        if (x?.notification_type === 'notify_me') {
            return x?.poster ? helpers.getFullUrl(x?.poster, 300) : helpers.getDefaultImage('poster');
        } else {
            return x?.profile_image ? helpers.getFullUrl(x?.profile_image) : helpers.getDefaultImage('profile');
        }
    }
    // console.log(notificationsData)
    return (

        <>
            {/* <Grid container justifyContent={'center'}>
                <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"3422829246"} adv_type={"web_movie_detail_top_ad"} />
            </Grid> */}
            <Grid className='new-container'>
                <Grid container id="notifications-box">
                    <Grid item xs={12} className=''>
                        <Grid container justifyContent={'center'} className='bg-primary' sx={{ position: 'sticky', top: 0, zIndex: 9 }}>
                            <Grid item xs={12} sx={{ paddingY: '10px' }} className='w-100'>
                                <Grid container alignItems={'center'} className='w-100'>
                                    <KeyboardArrowLeftRoundedIcon onClick={() => navigate(-1)} className='pointer fs-30 pt-8' />
                                    <Typography className='fs-28 fw-700'>notification</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container className='left-container' justifyContent={'center'}>
                                    <Grid item sx={{ paddingLeft: '20px', paddingY: '10px' }}>
                                        <Button
                                            sx={{
                                                maxHeight: { xs: '24px', sm: '32px' },
                                                width: 'auto',
                                                marginRight: '10px',
                                                marginBottom: '10px'
                                            }}
                                            onClick={() => { setType('updates'); scrollToTop(); trackEvent('updates'); }}
                                            className={`rounded-border-btn ${type === 'updates' ? 'active' : ''} fs-14 text-light`}>notification</Button>
                                    </Grid>
                                    <Grid item sx={{ paddingLeft: '20px', paddingY: '10px' }}>
                                        <Button
                                            sx={{
                                                maxHeight: { xs: '24px', sm: '32px' },
                                                width: 'auto',
                                                marginRight: '10px',
                                                marginBottom: '10px'
                                            }}
                                            onClick={() => { setType('requests'); scrollToTop(); trackEvent('requests'); }}
                                            className={`rounded-border-btn ${type === 'requests' ? 'active' : ''} fs-14 text-light`}>pending</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* <Grid item sx={{ paddingRight: '20px', paddingY: '10px' }} className='fs-14 fw-400 pointer'>
                                <Grid onClick={() => handleReadNotification('all')} container alignItems={'center'}>
                                    read all
                                    <RemoveRedEyeIcon sx={{ marginLeft: '10px' }} />
                                </Grid>
                            </Grid> */}

                        </Grid>
                    </Grid>
                    <Grid className='left-container'>
                        <Grid container sx={{ marginBottom: { xs: '20px' } }}>
                            <Grid item xs={12} sm={10} >
                                <TextField
                                    variant="standard"
                                    placeholder='search for titles' className='w-100' InputProps={{
                                        disableUnderline: 'true',
                                        sx: { borderRadius: '30px', border: '1px solid grey', maxHeight: { sm: '40px', xs: '30px' }, paddingLeft: { sm: '10px', xs: '10px' } }
                                    }} />
                            </Grid>
                        </Grid>

                        {/* update = notifications , requests = pendings */}

                        <Grid container className=''>
                            {type === 'updates' ?
                                <>
                                    <Grid item xs={12} className='mb-10 mt-40'>
                                        <Typography onClick={() => handleReadNotification('all')} className='fw-700 fs-20 pointer lh-16'>mark all as read</Typography>
                                    </Grid>
                                    {notificationsData && notificationsData.length > 0 ? (notificationsData.map((x, i) => {
                                        return (<Grid key={i} onClick={() => handleReadNotification(x?.notification_id, x?.is_read, helpers.getNotificationTitle(x))} xs={12} lg={6} sx={{ paddingY: '20px' }} className='w-100 notification-items pointer'>
                                            <Grid container justifyContent={''} className='w-100 h-100'>
                                                <Grid item xs={12}>
                                                    <Grid container flexWrap={'nowrap'}>
                                                        <Grid item xs={2} sm={1} md={1} lg={1} className='mr-10'>
                                                            <Link to={handleGetImgLink(x)}>
                                                                <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: '48px',
                                                                        width: '48px',
                                                                        maxWidth: { xs: '48px' },
                                                                        maxHeight: { xs: '48px' },
                                                                        clipPath: 'circle()',
                                                                    }}
                                                                    src={getSrc(x)}
                                                                    alt='profile' />
                                                            </Link>
                                                        </Grid>
                                                        <Grid item xs={9} lg={8} sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                                            <Link
                                                                onClick={() => handleLinkCLick(x)}
                                                                to={handleGetLink(x)}
                                                            >
                                                                <Typography className='fs-16 fw-600'>
                                                                    {helpers.getNotificationTitle(x)}
                                                                </Typography>
                                                            </Link>
                                                            <Link
                                                                onClick={() => handleLinkCLick(x)}
                                                                to={handleGetLink(x)}
                                                            >
                                                                <Typography className='fs-14 fw-300'>
                                                                    {helpers.getNotificationType(x)}
                                                                </Typography>
                                                            </Link>
                                                        </Grid>
                                                        <Grid item xs={3} lg={2} className='text-right'>
                                                            {!x.is_read && helpers.isTodaysData(x?.created_at) &&
                                                                <span className='my-badge fs-10'>NEW</span>}
                                                            <Typography className='fs-14 fw-400'>
                                                                {x?.created_at ? helpers.getAgo(x?.created_at) : ''}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        )
                                    })) : (
                                        <Grid className="mt-50">
                                            <Grid container justifyContent={'center'} >
                                                <Grid item className="relative">
                                                    <Grid item>
                                                        <Box component={'img'} src={isMobileDevice() ? NotificationZeroStateMob : ZeroStateForNotification} alt={''} sx={{ maxWidth: { xs: '300px', sm: '900px' } }} />
                                                        {/* <img src={ZeroStateForNotification} alt={''} /> */}
                                                    </Grid>
                                                    {/* <Grid item className="absolute" sx={{ top: "20px", right: '20px' }}>
                                                        <Typography className="fw-700 fs-20 lh-20 mb-10">be the first to <span className="text-selected">review</span></Typography>
                                                        <Typography className="fw-500 fs-14 lh-24">Create your own review and get points</Typography>
                                                        <ReveiwMovie data={media.mediaData} isBtn={true} />`
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                                :
                                <>
                                    <Grid container justifyContent={'space-between'} className='mb-30 mt-10'>
                                        <Grid item xs={12} sm={6} textAlign={"center"} className=''>
                                            <PendingSuggestions />
                                        </Grid>
                                        <Grid className='h-100' item xs={12} sm={6} textAlign={"center"}>
                                            {notificationRequestsData && notificationRequestsData.length > 0 ?
                                                <Grid container>
                                                    <Typography className='fw-700 fs-20 lh-16'>Requests</Typography>
                                                    <PendingRequests />
                                                    {notificationRequestsData.map((x, i) => {
                                                        if (x?.notification_type != 'follow_request') {
                                                            return true;
                                                        }
                                                        return (<Grid className='w-100 notification-requests-items pointer pb-20' key={i} lg={12}>
                                                            <Grid container justifyContent={'space-between'} className='w-100 h-100'>
                                                                <Grid item xs={9}>
                                                                    <Grid container flexWrap={'nowrap'}>
                                                                        <Grid item className=''>
                                                                            <Link to={`${x?.user_id ? '/profile/' + x?.user_id : '#'}`}>
                                                                                <Box component="img"
                                                                                    sx={{
                                                                                        height: '48px',
                                                                                        width: '48px',
                                                                                        maxWidth: { xs: '48px' },
                                                                                        maxHeight: { xs: '48px' },
                                                                                        clipPath: 'circle()',
                                                                                    }}
                                                                                    src={x?.profile_image ? helpers.getFullUrl(x?.profile_image) : helpers.getDefaultImage('profile')}
                                                                                    alt='profile' />
                                                                            </Link>
                                                                        </Grid>
                                                                        <Link to={x?.watchlist_id ? `/watchlist-detail/${x?.watchlist_id}` : '#'}>
                                                                            <Grid item sx={{ paddingLeft: '10px' }}>
                                                                                <Typography className='fs-16 text-left fw-600'>
                                                                                    {helpers.getNotificationTitle(x)}
                                                                                </Typography>
                                                                                <Typography className='fs-12 text-left fw-300'>
                                                                                    {helpers.getNotificationType(x)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Link>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container className='mt-2' justifyContent={'start'} alignItems={'center'}>
                                                                        {/* <Grid item>
                                                                            <Button className='rounded-btn mw-130 bg-secondary text-light fs-12 fw-700 mt-10' endIcon={<PlayArrowIcon />}>
                                                                                watch trailer
                                                                            </Button>
                                                                        </Grid> */}
                                                                        <Grid item onClick={() => handleRequests('accept', x?.user_id, x?.notification_id)} className='rounded-circle mr-10 pointer mt-10'>
                                                                            <CheckIcon className='text-selected' />
                                                                        </Grid>
                                                                        <Grid item onClick={() => handleRequests('decline', x?.user_id, x?.notification_id)} className='rounded-circle pointer mt-10'>
                                                                            <ClearIcon className='text-selected' />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                {/* <Grid item xs={3} className='text-right'>
                                                                    {!x.is_read && helpers.isTodaysData(x?.created_at) &&
                                                                        <span className='my-badge fs-10'>NEW</span>}
                                                                    <Typography sx={{ marginTop: '5px' }} className='fs-12 fw-400'>
                                                                        {x?.created_at ? helpers.getAgo(x?.created_at) : ''}
                                                                    </Typography>
                                                                </Grid> */}
                                                            </Grid>
                                                        </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                                : (
                                                    <Grid className="mt-50">
                                                        <Grid container className='new-container' justifyContent={'center'} >
                                                            <Grid item className="relative">
                                                                <Grid item>
                                                                    {/* <img src={isMobileDevice() ? ZeroStateMob : ZeroStateimg} alt={''} /> */}
                                                                    <Box component={'img'} src={ZeroStateForPendingNotification} sx={{ maxWidth: { xs: '300px', sm: '400px' } }} alt={''} />
                                                                </Grid>
                                                                {/* <Grid item className="absolute" sx={{ top: "20px", right: '20px' }}>
                                                                        <Typography className="fw-700 fs-20 lh-20 mb-10">be the first to <span className="text-selected">review</span></Typography>
                                                                        <Typography className="fw-500 fs-14 lh-24">Create your own review and get points</Typography>
                                                                        <ReveiwMovie data={media.mediaData} isBtn={true} />`
                                                                    </Grid> */}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Grid>

                                    <Grid item style={{ maxWidth: '100%' }} flex={1}>
                                        {comingSoonData && comingSoonData.length > 0 &&
                                            <Grid container spacing={2}>
                                                {comingSoonData.map((x, i) => {
                                                    return <>
                                                        {(i !== 0 && i % 2 === 0 && i % 3 === 0) &&
                                                            <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                                                                <Grid container justifyContent={'center'}>
                                                                    <MyAds type="banner_2" adv_type={'web_media_listing_ad'} />
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        <Grid item className='coming-soon-items' key={i} xs={12} sm={6} >
                                                            <SingleMediaTile refPage={'Coming Soon'} x={x} showNotifyButton={true} />
                                                        </Grid>
                                                    </>
                                                })}
                                            </Grid>
                                        }
                                    </Grid>
                                </>
                            }


                            <Grid container sx={{ marginBottom: '20px' }} justifyContent={'center'}>
                                {isLoading && <CircularProgress color='success' />}
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isMobileDevice() ?
                        <Grid className="right-container">
                            <Grid className="right-container-ad">
                                <MyAds type="banner_1" adv_type={'web_movie_detail_right_ad'} />
                            </Grid>
                        </Grid>
                        : ''}
                </Grid>
            </Grid>
        </>
    )
}
