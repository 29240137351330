import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WatchlistDataService from "../services/watchlist.services";
import helpers from "../services/helpers";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    watchlistLandingData: {},
    pendingSuggestionsPageData: {},
    pendingSuggestionsData: [],
    unwatchedMediaPageData: {},
    unwatchedMediaData: [],
    watchlistSectionPageData: {},
    watchlistSectionData: {},
    firtsWatchlistPageData: {},
    firtsWatchlistData: [],
    userWatchListPageData: {},
    userWatchListData: [],
    watchlistDetail: {},
    watchlistTitlePageData: {},
    watchlistTitleData: [],
    watchlistMemberPageData: {},
    watchlistMemberData: [],
    suggestedTitlePageData: {},
    suggestedTitleData: [],
    watchlistPageData: {},
    watchlistData: [],
    sharelistPageData: {},
    sharelistData: [],
    watchlistIJoinedPageData: {},
    watchlistIJoinedData: [],
    frenziSharelistPageData: {},
    frenziSharelistData: [],
    friendsSharelistPageData: {},
    friendsSharelistData: [],
    popularCreatorSharelistPageData: {},
    popularCreatorSharelistData: [],
    watchlistToAddPageData: {},
    watchlistToAddData: [],
    sharelistToAddPageData: {},
    sharelistToAddData: [],
    pendingJoiningRequestPageData: {},
    pendingJoiningRequestData: [],
    pendingPrivateJoiningRequestPageData: {},
    pendingPrivateJoiningRequestData: [],
    searchWatchlistPageData: {},
    searchWatchlistData: []
};

export const getWatchlistLandingDetail = createAsyncThunk(
    "native_main/watchlistlanding",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getWatchlistLandingDetail(data.section)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getAllPendingSuggestions = createAsyncThunk(
    "native_main/pendingsuggestions",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getAllPendingSuggestions(data?.id, data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getUnwatchedMedias = createAsyncThunk(
    "native_main/unwatchedmedias",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getUnwatchedMedias(data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getWatchlistSection = createAsyncThunk(
    "native_main/watchlistsections",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getWatchlistSection(data.section, data.page, data.pagelimit)
            response.data.data.section = data.section;
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFirstWatchlistData = createAsyncThunk(
    "native_main/firstwatchlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getFirstWatchlistData(data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const addWatchlist = createAsyncThunk(
    "native_main/frenziwatchlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.addWatchlist(data.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const updateWatchlist = createAsyncThunk(
    "native_main/watchlistdetail",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.updateWatchlist(data.uuid, data.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getUserWatchlist = createAsyncThunk(
    "native_main/userwatchlistdetail",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getUserWatchlist(data.uuid, data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const addRemoveMediaFromWatchlist = createAsyncThunk(
    "native_main/userwatchlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.addRemoveMediaFromWatchlist(data.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const joinWatchlist = createAsyncThunk(
    "native_main/joinwatchlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.joinWatchlist(data.id, data.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const acceptRejectTitleRequest = createAsyncThunk(
    "native_main/watchlistmediarequest",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.acceptRejectTitleRequest(data.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const acceptRejectContributorRequest = createAsyncThunk(
    "native_main/watchlistrequests",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.acceptRejectContributorRequest(data.id, data.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getWatchlistDetail = createAsyncThunk(
    "native_main/getwatchlistdetail",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getWatchlistDetail(data.id)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getWatchlistTitleDetail = createAsyncThunk(
    "native_main/watchlisttitle",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getWatchlistTitleDetail(data.id, data.page, data.pagelimit, data.sort, data.body, data?.is_public)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const deleteLeaveWatchlist = createAsyncThunk(
    "native_main/deletewatchlistdetail",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.deleteLeaveWatchlist(data.uuid)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getWatchlistMembers = createAsyncThunk(
    "native_main/watchlistmember",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getWatchlistMembers(data.id, data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const addMemberToWatchlist = createAsyncThunk(
    "native_main/addwatchlistmember",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.addMemberToWatchlist(data.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const removeMemberToWatchlist = createAsyncThunk(
    "native_main/watchlistmemberdetail",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.removeMemberToWatchlist(data.uuid, data.id)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getSuggestedTitles = createAsyncThunk(
    "native_main/getwatchlistmediarequest",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getSuggestedTitles(data?.id, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const addSuggestTitles = createAsyncThunk(
    "native_main/suggesttitle",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.addSuggestTitles(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const addRemoveWatchlistMedia = createAsyncThunk(
    "native_main/watchlistmedia",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.addRemoveWatchlistMedia(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const getWatchlistData = createAsyncThunk(
    "native_main/frenziwatchlist?section=private",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getWatchlistSharelistData(data?.type ? data?.type : 'private', data?.page, data?.pagelimit, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getSharelistData = createAsyncThunk(
    "native_main/frenziwatchlist?section=public",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getWatchlistSharelistData(data?.type ? data?.type : 'public', data?.page, data?.pagelimit, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getWatchlistIJoined = createAsyncThunk(
    "native_main/getfrenziwatchlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getWatchlistIJoined(data?.page, data?.pagelimit, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFrenziSharelist = createAsyncThunk(
    "native_main/frenzisharelist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getFrenziSharelist(data?.page, data?.pagelimit, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFreindsSharelist = createAsyncThunk(
    "native_main/friendswatchlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getFreindsSharelist(data?.page, data?.pagelimit, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPopularCreatorSharelist = createAsyncThunk(
    "native_main/friendswatchlistpopular",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getPopularCreatorSharelist(data?.section, data?.page, data?.pagelimit, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getWatchlistToAdd = createAsyncThunk(
    "native_main/addtowatchlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getWatchlistToAdd(data?.uuid, data?.type || 'add', data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getSharelistToAdd = createAsyncThunk(
    "native_main/addtowatchlistsharelist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getWatchlistToAdd(data?.uuid, data?.type || 'add', data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const addToWatchlist = createAsyncThunk(
    "native_main/addtowatchlistadd",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.addToWatchlist(data?.uuid, data?.section, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getAllPendingWatchlistRequest = createAsyncThunk(
    "native_main/watchlistjoinrequest",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getAllPendingWatchlistRequest(data?.id, data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getAllPendingPrivateWatchlistRequest = createAsyncThunk(
    "native_main/privatewatchlistrequest",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.getAllPendingPrivateWatchlistRequest(data?.id, data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const acceptRejectAllPendingPrivateWatchlistRequest = createAsyncThunk(
    "native_main/acceptprivatewatchlistrequest",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.acceptRejectAllPendingPrivateWatchlistRequest(data?.id, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const acceptRejectAllPendingWatchlistRequest = createAsyncThunk(
    "native_main/acceptwatchlistrequest",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.acceptRejectAllPendingWatchlistRequest(data?.id, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const searchWatchlist = createAsyncThunk(
    "native_main/watchlistsearch",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.searchWatchlist(data?.query, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const acceptRejectJoinWatchlistRequest = createAsyncThunk(
    "native_main/acceptrejectwatchlistjoinrequest",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WatchlistDataService.acceptRejectJoinWatchlistRequest(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const watchlistSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        },
        clearWatchlistTitleData: (state) => {
            state.watchlistTitlePageData = {};
            state.watchlistTitleData = [];
        },
        resetSearchData: (state) => {
            state.searchWatchlistPageData = {};
            state.searchWatchlistData = [];
        }
    },
    extraReducers: {
        [getWatchlistLandingDetail.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getWatchlistLandingDetail.fulfilled]: (state, action) => {
            state.watchlistLandingData = getPayloadData(state, action);
        },
        [getWatchlistLandingDetail.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getAllPendingSuggestions
        [getAllPendingSuggestions.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getAllPendingSuggestions.fulfilled]: (state, action) => {
            state.pendingSuggestionsPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.pendingSuggestionsData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.pendingSuggestionsData = helpers.removeDuplicate(state.pendingSuggestionsData.concat(getArrayData(state, action)));
            }
        },
        [getAllPendingSuggestions.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getUnwatchedMedias
        [getUnwatchedMedias.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getUnwatchedMedias.fulfilled]: (state, action) => {
            state.unwatchedMediaPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.unwatchedMediaData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.unwatchedMediaData = helpers.removeDuplicate(state.unwatchedMediaData.concat(getArrayData(state, action)));
            }
        },
        [getUnwatchedMedias.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getWatchlistSection
        [getWatchlistSection.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getWatchlistSection.fulfilled]: (state, action) => {
            state.watchlistSectionPageData = getPayloadDataSection(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                if (action.payload && action.payload.data && action.payload.data.section) {
                    var tempData = { ...state.watchlistSectionData, [action.payload.data.section]: getPayloadData(state, action).results ? getPayloadData(state, action).results : [] };
                    state.watchlistSectionData = tempData;
                } else {
                    state.watchlistSectionData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
                }
            } else {
                state.watchlistSectionData = getArrayDataSection(state, action);
            }
        },
        [getWatchlistSection.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFirstWatchlistData
        [getFirstWatchlistData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFirstWatchlistData.fulfilled]: (state, action) => {
            state.firtsWatchlistPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.firtsWatchlistData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.firtsWatchlistData = helpers.removeDuplicate(state.firtsWatchlistData.concat(getArrayData(state, action)));
            }
        },
        [getFirstWatchlistData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // addWatchlist
        [addWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [addWatchlist.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [addWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // updateWatchlist
        [updateWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [updateWatchlist.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [updateWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getUserWatchlist
        [getUserWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getUserWatchlist.fulfilled]: (state, action) => {
            state.userWatchListPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.userWatchListData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.userWatchListData = helpers.removeDuplicate(state.userWatchListData.concat(getArrayData(state, action)));
            }
        },
        [getUserWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // addRemoveMediaFromWatchlist
        [addRemoveMediaFromWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [addRemoveMediaFromWatchlist.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [addRemoveMediaFromWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // joinWatchlist
        [joinWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [joinWatchlist.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [joinWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // acceptRejectTitleRequest
        [acceptRejectTitleRequest.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [acceptRejectTitleRequest.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [acceptRejectTitleRequest.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // acceptRejectContributorRequest
        [acceptRejectContributorRequest.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [acceptRejectContributorRequest.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [acceptRejectContributorRequest.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getWatchlistDetail
        [getWatchlistDetail.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getWatchlistDetail.fulfilled]: (state, action) => {
            state.watchlistDetail = getPayloadData(state, action);
        },
        [getWatchlistDetail.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getWatchlistTitleDetail
        [getWatchlistTitleDetail.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getWatchlistTitleDetail.fulfilled]: (state, action) => {
            state.watchlistTitlePageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.watchlistTitleData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.watchlistTitleData = helpers.removeDuplicate(state.watchlistTitleData.concat(getArrayData(state, action)));
            }
        },
        [getWatchlistTitleDetail.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // deleteLeaveWatchlist
        [deleteLeaveWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [deleteLeaveWatchlist.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [deleteLeaveWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getWatchlistMembers
        [getWatchlistMembers.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getWatchlistMembers.fulfilled]: (state, action) => {
            state.watchlistMemberPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.watchlistMemberData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.watchlistMemberData = helpers.removeDuplicate(state.watchlistMemberData.concat(getArrayData(state, action)));
            }
        },
        [getWatchlistMembers.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // addMemberToWatchlist
        [addMemberToWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [addMemberToWatchlist.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [addMemberToWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // removeMemberToWatchlist
        [removeMemberToWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [removeMemberToWatchlist.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [removeMemberToWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getSuggestedTitles
        [getSuggestedTitles.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getSuggestedTitles.fulfilled]: (state, action) => {
            state.suggestedTitlePageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.suggestedTitleData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.suggestedTitleData = helpers.removeDuplicate(state.suggestedTitleData.concat(getArrayData(state, action)));
            }
        },
        [getSuggestedTitles.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // addSuggestTitles
        [addSuggestTitles.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [addSuggestTitles.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [addSuggestTitles.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // addRemoveWatchlistMedia
        [addRemoveWatchlistMedia.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [addRemoveWatchlistMedia.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [addRemoveWatchlistMedia.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },


        // getWatchlistData
        [getWatchlistData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getWatchlistData.fulfilled]: (state, action) => {
            state.watchlistPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.watchlistData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.watchlistData = helpers.removeDuplicate(state.watchlistData.concat(getArrayData(state, action)));
            }
        },
        [getWatchlistData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getSharelistData
        [getSharelistData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getSharelistData.fulfilled]: (state, action) => {
            state.sharelistPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.sharelistData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.sharelistData = helpers.removeDuplicate(state.sharelistData.concat(getArrayData(state, action)));
            }
        },
        [getSharelistData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getWatchlistIJoined
        [getWatchlistIJoined.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getWatchlistIJoined.fulfilled]: (state, action) => {
            state.watchlistIJoinedPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.watchlistIJoinedData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.watchlistIJoinedData = helpers.removeDuplicate(state.watchlistIJoinedData.concat(getArrayData(state, action)));
            }
        },
        [getWatchlistIJoined.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFrenziSharelist
        [getFrenziSharelist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFrenziSharelist.fulfilled]: (state, action) => {
            state.frenziSharelistPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.frenziSharelistData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.frenziSharelistData = helpers.removeDuplicate(state.frenziSharelistData.concat(getArrayData(state, action)));
            }
        },
        [getFrenziSharelist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFreindsSharelist
        [getFreindsSharelist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFreindsSharelist.fulfilled]: (state, action) => {
            state.friendsSharelistPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.friendsSharelistData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.friendsSharelistData = helpers.removeDuplicate(state.friendsSharelistData.concat(getArrayData(state, action)));
            }
        },
        [getFreindsSharelist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPopularCreatorSharelist
        [getPopularCreatorSharelist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPopularCreatorSharelist.fulfilled]: (state, action) => {
            state.popularCreatorSharelistPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.popularCreatorSharelistData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.popularCreatorSharelistData = helpers.removeDuplicate(state.popularCreatorSharelistData.concat(getArrayData(state, action)));
            }
        },
        [getPopularCreatorSharelist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getWatchlistToAdd
        [getWatchlistToAdd.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getWatchlistToAdd.fulfilled]: (state, action) => {
            state.watchlistToAddPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.watchlistToAddData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.watchlistToAddData = helpers.removeDuplicate(state.watchlistToAddData.concat(getArrayData(state, action)));
            }
        },
        [getWatchlistToAdd.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getSharelistToAdd
        [getSharelistToAdd.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getSharelistToAdd.fulfilled]: (state, action) => {
            state.sharelistToAddPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.sharelistToAddData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.sharelistToAddData = helpers.removeDuplicate(state.sharelistToAddData.concat(getArrayData(state, action)));
            }
        },
        [getSharelistToAdd.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // addToWatchlist
        [addToWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [addToWatchlist.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [addToWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getAllPendingWatchlistRequest
        [getAllPendingWatchlistRequest.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getAllPendingWatchlistRequest.fulfilled]: (state, action) => {
            state.pendingJoiningRequestPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.pendingJoiningRequestData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.pendingJoiningRequestData = helpers.removeDuplicate(state.pendingJoiningRequestData.concat(getArrayData(state, action)));
            }
        },
        [getAllPendingWatchlistRequest.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getAllPendingPrivateWatchlistRequest
        [getAllPendingPrivateWatchlistRequest.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getAllPendingPrivateWatchlistRequest.fulfilled]: (state, action) => {
            state.pendingPrivateJoiningRequestPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.pendingPrivateJoiningRequestData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.pendingPrivateJoiningRequestData = helpers.removeDuplicate(state.pendingPrivateJoiningRequestData.concat(getArrayData(state, action)));
            }
        },
        [getAllPendingPrivateWatchlistRequest.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // acceptRejectAllPendingPrivateWatchlistRequest
        [acceptRejectAllPendingPrivateWatchlistRequest.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [acceptRejectAllPendingPrivateWatchlistRequest.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [acceptRejectAllPendingPrivateWatchlistRequest.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // acceptRejectAllPendingWatchlistRequest
        [acceptRejectAllPendingWatchlistRequest.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [acceptRejectAllPendingWatchlistRequest.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [acceptRejectAllPendingWatchlistRequest.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // searchWatchlist
        [searchWatchlist.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [searchWatchlist.fulfilled]: (state, action) => {
            state.searchWatchlistPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.searchWatchlistData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.searchWatchlistData = helpers.removeDuplicate(state.searchWatchlistData.concat(getArrayData(state, action)));
            }
        },
        [searchWatchlist.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // acceptRejectJoinWatchlistRequest
        [acceptRejectJoinWatchlistRequest.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [acceptRejectJoinWatchlistRequest.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [acceptRejectJoinWatchlistRequest.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
    }
})

export const { clearMessages, clearWatchlistTitleData, resetSearchData } = watchlistSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const getPayloadDataSection = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ?
        action.payload.data.section ?
            { ...state.watchlistSectionPageData, [action.payload.data.section]: action.payload.data }
            : action.payload.data
        : {};
}

const getArrayDataSection = (state, action) => {
    var res = action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ?
        action.payload.data.section ?
            { ...state.watchlistSectionData, [action.payload.data.section]: state.watchlistSectionData[action.payload.data.section] ? helpers.removeDuplicate(state.watchlistSectionData[action.payload.data.section].concat(action.payload.data.results)) : action.payload.data.results }
            : action.payload.data.results
        : [];
    state.isLoading = false;
    return res;
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default watchlistSlice.reducer;