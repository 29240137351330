import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination } from 'swiper';
import ClipsPlayer from '../snipz/ClipsPlayer';
import LikeAction from '../snipz/LikeAction';
import { Avatar, Button, Grid, Modal } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { deleteCLipPost } from '../../slices/clips';
import helpers from '../../services/helpers';

export const CLIPS_TILE_BREAKPOINTS = {
    280: {
        slidesPerView: 2,
        spaceBetween: 15,
    },
    360: {
        slidesPerView: 2,
        spaceBetween: 15,
    },

    460: {
        slidesPerView: 2,
        spaceBetween: 15,
    },

    690: {
        slidesPerView: 3,
        spaceBetween: 20,
        slidesPerGroup: 2
    },

    760: {
        slidesPerView: 4,
        spaceBetween: 20,
        slidesPerGroup: 3
    },
    860: {
        slidesPerView: 4,
        spaceBetween: 20,
        slidesPerGroup: 3
    },
    990: {
        slidesPerView: 5,
        spaceBetween: 20,
        slidesPerGroup: 4
    },
    1120: {
        slidesPerView: 5,
        spaceBetween: 20,
        slidesPerGroup: 4
    },
    1260: {
        slidesPerView: 6,
        spaceBetween: 30,
        slidesPerGroup: 5
    },
    1400: {
        slidesPerView: 6,
        spaceBetween: 30,
        slidesPerGroup: 5
    },

    1680: {
        slidesPerView: 7,
        spaceBetween: 30,
        slidesPerGroup: 6
    },
    1860: {
        slidesPerView: 8,
        spaceBetween: 30,
        slidesPerGroup: 7
    },

    1990: {
        slidesPerView: 10,
        spaceBetween: 30,
        slidesPerGroup: 9
    },
    2240: {
        slidesPerView: 12,
        spaceBetween: 30,
        slidesPerGroup: 11
    },

    2560: {
        slidesPerView: 14,
        spaceBetween: 30,
        slidesPerGroup: 13
    }
}

export const CLIPS_TILE_BREAKPOINTS_WITH_AD = {
    280: {
        slidesPerView: 1.5,
        spaceBetween: 15,
    },
    360: {
        slidesPerView: 2,
        spaceBetween: 15,
    },

    460: {
        slidesPerView: 2,
        spaceBetween: 15,
    },

    690: {
        slidesPerView: 3,
        spaceBetween: 20,
        slidesPerGroup: 2
    },

    760: {
        slidesPerView: 3,
        spaceBetween: 20,
        slidesPerGroup: 2
    },
    860: {
        slidesPerView: 2,
        spaceBetween: 20,
        slidesPerGroup: 1
    },
    990: {
        slidesPerView: 2,
        spaceBetween: 20,
        slidesPerGroup: 1
    },
    1120: {
        slidesPerView: 2.25,
        spaceBetween: 20,
        slidesPerGroup: 2
    },
    1260: {
        slidesPerView: 2.5,
        spaceBetween: 30,
        slidesPerGroup: 2
    },
    1400: {
        slidesPerView: 2.75,
        spaceBetween: 30,
        slidesPerGroup: 2
    },

    1680: {
        slidesPerView: 3.25,
        spaceBetween: 30,
        slidesPerGroup: 3
    },
    1860: {
        slidesPerView: 3.5,
        spaceBetween: 30,
        slidesPerGroup: 3
    },

    1990: {
        slidesPerView: 4,
        spaceBetween: 30,
        slidesPerGroup: 3
    },
    2240: {
        slidesPerView: 5,
        spaceBetween: 30,
        slidesPerGroup: 4
    },

    2560: {
        slidesPerView: 13,
        spaceBetween: 30,
        slidesPerGroup: 13
    }
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#16213E',
    boxShadow: 24,
    borderRadius: 2,
    p: 5
};


export default function ClipsSlider(props) {
    const { clipsData, heading, isOwner, isMovie } = props;
    const [deletePopup, setDeletePopup] = useState(false);
    const [activeClip, setActiveClip] = useState('');
    const [deleteData, setDeleteData] = useState({});
    const [deletedPostId, setDeletedPostId] = useState([]);
    const dispatch = useDispatch();

    const deleteClip = (data) => {
        dispatch(deleteCLipPost({ id: data?.post_id })).then(() => {
            setDeletedPostId([...deletedPostId, data?.post_id]);
            setDeleteData({});
            setDeletePopup(false);
        });
    }

    return (
        <div className='w-100 mb-30'>
            <h2 style={{ marginTop: '20px', marginBottom: '20px' }} className='fw-700 fs-28 mr-6 '>
                {heading ? heading : 'clips'}
            </h2>
            {clipsData && clipsData.length > 0 ?
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    slidesPerView={"auto"}
                    spaceBetween={30}
                    speed={1000}
                    navigation={true}
                    modules={[Pagination, Navigation, FreeMode]}
                    className="mySwiper"
                    id='newUserCard'
                    freeMode={true}
                    onSlideChange={(e) => {
                        if (e.isEnd) {
                            props?.getClipData();
                        }
                    }}
                >
                    {clipsData.map((clip, j) => {
                        if (!deletedPostId.includes(clip?.post_id)) {
                            return <SwiperSlide style={{ alignSelf: 'center' }} key={j}>
                                <Grid >
                                    <Grid
                                        sx={{
                                            width: { xs: '180', md: '360px' },
                                            height: { xs: '300', md: '600px' },
                                        }}
                                        className='relative'
                                    >
                                        {isOwner &&
                                            <DeleteRoundedIcon onClick={() => deleteClip(clip)} className='pointer scale-1 delete-icon' />
                                        }
                                        <ClipsPlayer
                                            id={clip?.post_id}
                                            isPlay={activeClip === clip?.post_id}
                                            src={clip?.post_url}
                                            isMovie={isMovie}
                                            thumbnail_url={clip?.thumbnail_url}
                                            setActiveClip={(val) => setActiveClip(val)}
                                        />
                                        <Grid container alignItems={'center'} sx={{ position: 'absolute', bottom: '10px', left: '10px' }} className="like-action-box">
                                            <LikeAction is_liked={clip.is_liked} data={clip} like_count={clip.total_likes} showCount={true} type="clip_like" />
                                        </Grid>
                                    </Grid>
                                    <div>
                                        {isMovie ?
                                            <Grid className='mt-0' container alignItems={'center'} spacing={2} flexWrap={'nowrap'}>
                                                <Grid item>
                                                    {clip?.profile_image &&
                                                        <Avatar key={j} sx={{ width: 36, height: 36, maxWidth: { xs: 24, sm: 36 }, maxHeight: { xs: 24, sm: 36 } }} alt="Remy Sharp" src={clip.profile_image ? helpers.getFullUrl(clip?.profile_image) : helpers.getDefaultImage("halfsmallprofile")} className='ott-avatar' />
                                                    }
                                                </Grid>
                                                <Grid sx={{ width: 'calc(100% - 36px)' }} item>
                                                    <p className='m-0 fs-16 ellipsis fw-600'>{clip?.username || clip?.title}</p>
                                                </Grid>
                                            </Grid>
                                            :
                                            <p className='m-0 fs-16 ellipsis fw-600'>{clip?.title || clip?.watchlist}</p>
                                        }
                                    </div>
                                </Grid>
                            </SwiperSlide>
                        }
                    })}
                </Swiper>
                :
                <p className='fs-14 text-muted text-center'>No Data</p>
            }
            <Modal
                open={deletePopup}
                onClose={() => setDeletePopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2 className='fw-700 fs-32 m-0 pb-20'>Delete Clip??</h2>
                    <Grid container justifyContent={'space-between'} alignItems={'center'} >
                        <Button onClick={() => deleteClip(deleteData)} className='rounded-btn w-100 mw-240 bg-selected text-light mt-40'>delete</Button>
                        <Button onClick={() => setDeletePopup(false)} className='rounded-btn w-100 mw-240 bg-light text-selected mt-10'>cancel</Button>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}
