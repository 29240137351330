import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import MoviePoster from '../../assets/HandpicIcon.svg'
// import RoundedProfile from "../../assets/profile.svg"
// import helpers from '../../services/helpers'
import ShareListData from './ShareListData'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom'
import Loader from '../common/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { getFriendsWatchlist } from '../../slices/connect'
import MyHelmet from '../common/MyHelmet'


export default function ShareListsDetailPage(props) {
    const { heading } = props;
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const connect = useSelector(state => state.connect);
    const { friendsWatchListData, isLoading, friendsWatchListPageData } = connect

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        dispatch(getFriendsWatchlist({ page: page, pagelimit: 20 }));
    }, [page])

    useEffect(() => {
        const handleScroll = () => {
            if (friendsWatchListPageData && friendsWatchListPageData && friendsWatchListPageData.pagination && friendsWatchListPageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, connect]);

    return (
        <>
        <MyHelmet
            title = {'Share Your Favorites: Create and Share Personalized Watchlists on OTT Platforms!'}
            description = {"Create personalized watchlists of your favorite movies, TV shows, and documentaries, and easily share them with friends, family, or fellow enthusiasts."}
        />
            {isLoading && <Loader />}
            <Grid className="new-container pt-10 pb-20">
                <Grid container alignItems={'center'} className="mt-10">
                    <ArrowForwardIcon onClick={() => navigate(-1)} style={{ transform: 'rotate(180deg)', marginRight: 10, marginTop: 7 }} className='pointer' />
                    <Grid item>
                        <h2 className="fw-700 fs-28 m-0">{heading && heading !== "" ? heading : <>my friends <span className='text-selected'>sharelists</span>{friendsWatchListPageData.pagination && friendsWatchListPageData.pagination?.total_entries ? ` (${friendsWatchListPageData.pagination?.total_entries})` : ''}</>}</h2>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={'pb-20'}>
                    {friendsWatchListData && friendsWatchListData.length > 0 &&
                        <Grid container>
                            {friendsWatchListData.map((x, i) => {
                                return <Grid item xs={12} sm={6} md={4} key={i}>
                                    <ShareListData x={x} />
                                </Grid>
                            })}
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}
