import { Grid, Box } from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
// import MediaOverView from "../common/MediaOverView";
import { Link } from "react-router-dom";
// import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import TextReviewTile from "./includes/TextReviewTile";

export default function TextReviews(props) {
  const { data, isDetailPage, category, isLoading, type } = props;
  // const navigate = useNavigate();

  const getLink = (id) => {
    if (id && id != "") {
      return `/reviews/${category}?id=${props?.media_id}`;
    } else {
      return `/reviews/${category}`;
    }
  };

  return (
    <>
      {type === "grid" ? (
        <Grid container>
          {data &&
            data.length > 0 &&
            data.map((x, i) => {
              if (!isDetailPage && i > 5) {
                return true;
              }
              return (
                <Grid
                  item
                  className="reviews-items"
                  xs={12}
                  sm={6}
                  sx={{ marginBottom: { xs: "20px", sm: "30px" } }}
                >
                  <TextReviewTile x={x} isme={props.isme} i={0} type={"grid"} />
                </Grid>
              );
            })}
        </Grid>
      ) : (
        <Grid
          container
          className="app-section section-border-bottom"
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid
                item
                xs={12}
                className={`pb-50 ${!isDetailPage ? "pt-20" : "pt-0"}`}
              >
                {/* {isDetailPage &&
              <Grid container alignItems={'center'} justifyContent={'flex-end'}>
                <p className='fw-700 fs-15 m-0 mr-6 '>
                  sort
                </p>
                <SwapVertIcon />
              </Grid>
            } */}
                <Grid
                  container
                  className="mb-10"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <p className="fs-28 fw-700  text-light">text reviews</p>
                  </Grid>
                  {!isDetailPage && (
                    <Link
                      onClick={() =>
                        helpers.trackMoengageEvent("FR3_View_Review", {
                          review_type_click: MOENGAGE_ATTRIBUTE.YES,
                          review_type: "text",
                        })
                      }
                      to={getLink(props?.media_id)}
                    >
                      <ArrowForwardIcon />
                    </Link>
                  )}
                </Grid>

                <Grid container spacing={3}>
                  {data &&
                    data.length > 0 &&
                    data.map((x, i) => {
                      if (!isDetailPage && i > 5) {
                        return true;
                      }
                      return (
                        <Grid
                          item
                          className="reviews-items"
                          xs={12}
                          sm={4}
                          sx={{ marginBottom: { xs: "20px", sm: "30px" } }}
                        >
                          <TextReviewTile x={x} isme={props.isme} />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
