import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Popover,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import SentimentSatisfiedAltOutlinedIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CountrySelection from "./CountrySelection";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoginModal } from "../../slices/global";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import { logOut } from "../../slices/auth";
import { ToastContainer } from "react-toastify";
import { getUserDetails } from "../../slices/settings";
import SearchBar from "../partner/components/SearchBar";

const Frame =
  process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/img/Frame.png";
const NavLogo =
  process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/frenziLogoNew.svg"; //frenziLogo1

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#16213E",
  boxShadow: 24,
  borderRadius: 2,
  width: "100%",
  maxWidth: { xs: "90%", sm: "50%", md: "45%", lg: "35%" },
  p: { xs: 2, sm: 5 },
};

export default function PartnerHeader() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [logoutPopup, setLogoutPopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSelector((state) => state.settings);
  const [input, setInput] = useState("");
  const [searchParams] = useSearchParams();

  const { userDetails } = settings;

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    const string = searchParams.get('q');
    setInput(string || '')
    // getData(string, searchData?.log_queue || null, page);
    // setIsError(false)
  }, [searchParams]);

  useEffect(() => {
    if (userDetails && userDetails.uuid) {
      if (!userDetails.is_staff) {
        alert("You don't currently have permission to access partner page, contact to Admin")
        navigate('/')
      }
    }
  }, [userDetails])

  useEffect(() => {
    if (!global.is_public) {
      dispatch(getUserDetails());
    }
  }, [global.is_public]);

  const getProfileUrl = (uuid) => {
    let url = "/profile";
    let currentUser = localStorage.getItem("frenzi_user_access");
    var currentUserData =
      currentUser && currentUser !== "" && JSON.parse(currentUser);
    if (currentUserData && currentUserData.uuid === uuid) {
      return url;
    } else {
      return url + "/" + uuid;
    }
  };

  const handleOpenUserMenu = (event) => {
    if (!global.is_public) {
      setAnchorElUser(event.currentTarget);
    } else {
      dispatch(toggleLoginModal({ isOpen: true }));
    }
  };

  const handleSeacrh = () => { }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      helpers.setSearchHistoryData(input)
      navigate(`/partner/results?q=${input}`);
    }
  }

  return (
    <div style={{ position: 'sticky', top: 0, backgroundColor: '#1a1a2e', zIndex: 99 }}>
      <ToastContainer className="my-frenzi-toast" />

      <Grid
        container
        className="pr-20 pl-20 pt-10 pb-10"
        justifyContent={"space-between"}
        style={{ alignItems: "center" }}
      >
        <Grid item>
          <Box
            component="img"
            alt="logo"
            sx={{
              width: "100%",
              height: "auto",
              maxWidth: { sm: 36, md: 50, lg: 44 },
              marginBottom: "10px",
            }}
            src={NavLogo}
          />
        </Grid>
        {/* {window.location.pathname.includes('/partner/results') ?
          <Grid item flex={1} className="pl-20 pr-20">
            <Grid
              container
              justifyContent={"center"}
              sx={{
                marginTop: { xs: "10px" },
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <SearchBar
                onChange={(val) => handleSeacrh(val)}
                onKeyUp={(e) => handleKeyUp(e)}
                setInput={setInput}
                input={input}
              />
            </Grid>
          </Grid>
          : ""} */}
        <Grid item>
          <Grid
            container
            justifyContent={"center"}
            style={{ alignItems: "center" }}
          >
            <Grid item className="mr-20">
              <CountrySelection isPartner={true} />
            </Grid>

            <Grid item className="text-center">
              <Box>
                <Tooltip title={userDetails?.username}>
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{
                      backgroundImage: `url(${Frame})`,
                      width: "60px",
                      height: "52px",
                      paddingTop: "7px",
                      paddingRight: "15px",
                      paddingBottom: "15px",
                      paddingLeft: "14px",
                      borderRadius: "0px !important",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 100%",
                    }}
                  >
                    <Avatar
                      src={
                        userDetails.profile_image
                          ? helpers.getFullUrl(userDetails.profile_image)
                          : helpers.getFullUrl("joeschmoe")
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Popover
                  sx={{ mt: "40px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  className="profile-nav-menu"
                >
                  <Link
                    to={getProfileUrl(userDetails?.uuid)}
                    onClick={() => {
                      handleCloseUserMenu();
                      helpers.trackMoengageEvent("FR3_ProSettings", {
                        profile_setting_click: MOENGAGE_ATTRIBUTE.YES,
                      });
                      helpers.trackMoengageEvent("FR3_My_Profile", {
                        view_profile_btn: MOENGAGE_ATTRIBUTE.YES,
                      });
                    }}
                  >
                    <MenuItem className="profile-menu-item">
                      <ListItemIcon>
                        <SentimentSatisfiedAltOutlinedIcon fontSize="medium" />
                      </ListItemIcon>
                      <p
                        style={{ marginTop: "-7px" }}
                        className="mb-0 ml-0 mr-0 fw-600 list-style"
                      >
                        profile
                      </p>
                    </MenuItem>
                  </Link>
                  <Link
                    to="/settings"
                    onClick={() => {
                      handleCloseUserMenu();
                      helpers.trackMoengageEvent("FR3_AccSettings", {
                        account_settings_click: MOENGAGE_ATTRIBUTE.YES,
                      });
                    }}
                  >
                    <MenuItem className="profile-menu-item">
                      <ListItemIcon>
                        <SettingsOutlinedIcon fontSize="medium" />
                      </ListItemIcon>
                      <p
                        style={{ marginTop: "-7px" }}
                        className="mb-0 ml-0 mr-0 fw-600 fs-16 list-style"
                      >
                        settings
                      </p>
                    </MenuItem>
                  </Link>
                  <MenuItem
                    className="profile-menu-item"
                    onClick={() => {
                      setLogoutPopup(true);
                      helpers.trackMoengageEvent("FR3_Logout", {
                        logout_click: MOENGAGE_ATTRIBUTE.YES,
                      });
                    }}
                  >
                    <ListItemIcon>
                      <LogoutOutlinedIcon
                        fontSize="medium"
                        sx={{ marginLeft: "2px" }}
                      />
                    </ListItemIcon>
                    <p
                      style={{ marginTop: "-7px" }}
                      className=" mb-0 ml-0 mr-0 fw-600 fs-16 list-style"
                    >
                      logout
                    </p>
                  </MenuItem>
                </Popover>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {logoutPopup && (
        <div>
          <Modal
            open={logoutPopup}
            onClose={() => setLogoutPopup(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <p className="fw-700 fs-26 m-0">Do you wish to logout?</p>
              <p className="fw-400 fs-20 m-0 text-dark-grey">
                are you sure you want to logout?
              </p>
              <Grid item className="text-center">
                <Button
                  onClick={() => {
                    dispatch(logOut());
                    helpers.trackMoengageEvent("FR3_Logout", {
                      continue_click: MOENGAGE_ATTRIBUTE.YES,
                    });
                  }}
                  className="rounded-btn w-100 mw-240 bg-selected text-light mt-30 "
                >
                  continue
                </Button>
                <br />
                <Button
                  onClick={() => {
                    setLogoutPopup(false);
                    helpers.trackMoengageEvent("FR3_Logout", {
                      no_click: MOENGAGE_ATTRIBUTE.YES,
                    });
                  }}
                  className="rounded-btn w-100 mw-240 bg-light text-selected mt-10"
                >
                  cancel
                </Button>
              </Grid>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}
