import React, { useState } from "react";

import {
  Grid,
  Button,
  Box,
  TextareaAutosize,
  Slider
} from "@mui/material";
// import FeedbackLogo from "../../../assets/icons/feedbackLogo.svg";
import { useDispatch } from "react-redux";
import { submitFeedback } from "../../../slices/settings";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../../services/helpers";

const FeedbackLogo = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/feedbackLogo.svg";

const FinalEmoji0 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00000-min.png";
const FinalEmoji1 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00001-min.png";
const FinalEmoji2 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00002-min.png";
const FinalEmoji3 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00003-min.png";
const FinalEmoji4 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00004-min.png";
const FinalEmoji5 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00005-min.png";
const FinalEmoji6 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00006-min.png";
const FinalEmoji7 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00007-min.png";
const FinalEmoji8 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00008-min.png";
const FinalEmoji9 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00009-min.png";
const FinalEmoji10 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00010-min.png";
const FinalEmoji11 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00011-min.png";
const FinalEmoji12 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00012-min.png";
const FinalEmoji13 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00013-min.png";
const FinalEmoji14 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00014-min.png";
const FinalEmoji15 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00015-min.png";
const FinalEmoji16 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00016-min.png";
const FinalEmoji17 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00017-min.png";
const FinalEmoji18 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00018-min.png";
const FinalEmoji19 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00019-min.png";
const FinalEmoji20 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00020-min.png";
const FinalEmoji21 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00021-min.png";
const FinalEmoji22 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00022-min.png";
const FinalEmoji23 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00023-min.png";
const FinalEmoji24 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00024-min.png";
const FinalEmoji25 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00025-min.png";
const FinalEmoji26 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00026-min.png";
const FinalEmoji27 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00027-min.png";
const FinalEmoji28 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00028-min.png";
const FinalEmoji29 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00029-min.png";
const FinalEmoji30 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00030-min.png";
const FinalEmoji31 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00031-min.png";
const FinalEmoji32 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00032-min.png";
const FinalEmoji33 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00033-min.png";
const FinalEmoji34 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00034-min.png";
const FinalEmoji35 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00035-min.png";
const FinalEmoji36 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00036-min.png";
const FinalEmoji37 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00037-min.png";
const FinalEmoji38 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00038-min.png";
const FinalEmoji39 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00039-min.png";
const FinalEmoji40 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00040-min.png";
const FinalEmoji41 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00041-min.png";
const FinalEmoji42 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00042-min.png";
const FinalEmoji43 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00043-min.png";
const FinalEmoji44 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00044-min.png";
const FinalEmoji45 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00045-min.png";
const FinalEmoji46 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00046-min.png";
const FinalEmoji47 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00047-min.png";
const FinalEmoji48 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00048-min.png";
const FinalEmoji49 = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ratingEmoji/LikertScale_v02_00049-min.png";

const ratingText = ["Fried", "Frown", "Fair", "Fab", "Frenzied"];

const ratingEmoji = [
  {
    id: 0,
    title: "Fried",
    valeu: 0,
    emoji: FinalEmoji0,
  },

  {
    id: 1,
    title: "Fried",
    valeu: 0.1,
    emoji: FinalEmoji1,
  },

  {
    id: 2,
    title: "Fried",
    valeu: 0.2,
    emoji: FinalEmoji2,
  },
  {
    id: 3,
    title: "Fried",
    valeu: 0.3,
    emoji: FinalEmoji3,
  },
  {
    id: 4,
    title: "Fried",
    valeu: 0.4,
    emoji: FinalEmoji4,
  },
  {
    id: 5,
    title: "Fried",
    valeu: 0.5,
    emoji: FinalEmoji5,
  },
  {
    id: 6,
    title: "Fried",
    valeu: 0.6,
    emoji: FinalEmoji6,
  },
  {
    id: 7,
    title: "Fried",
    valeu: 0.7,
    emoji: FinalEmoji7,
  },
  {
    id: 8,
    title: "Fried",
    valeu: 0.8,
    emoji: FinalEmoji8,
  },
  {
    id: 9,
    title: "Fried",
    valeu: 0.9,
    emoji: FinalEmoji9,
  },
  {
    id: 10,
    title: "Fried",
    valeu: 1,
    emoji: FinalEmoji10,
  },
  {
    id: 11,
    title: "Fried",
    valeu: 1.1,
    emoji: FinalEmoji11,
  },
  {
    id: 12,
    title: "Fried",
    valeu: 1.2,
    emoji: FinalEmoji12,
  },
  {
    id: 13,
    title: "Fried",
    valeu: 1.3,
    emoji: FinalEmoji13,
  },
  {
    id: 14,
    title: "Fried",
    valeu: 1.4,
    emoji: FinalEmoji14,
  },
  {
    id: 15,
    title: "Fried",
    valeu: 1.5,
    emoji: FinalEmoji15,
  },
  {
    id: 16,
    title: "Fried",
    valeu: 1.6,
    emoji: FinalEmoji16,
  },

  {
    id: 17,
    title: "Fried",
    valeu: 1.7,
    emoji: FinalEmoji17,
  },

  {
    id: 18,
    title: "Fried",
    valeu: 1.8,
    emoji: FinalEmoji18,
  },

  {
    id: 19,
    title: "Fried",
    valeu: 1.9,
    emoji: FinalEmoji19,
  },
  {
    id: 20,
    title: "Fried",
    valeu: 2,
    emoji: FinalEmoji20,
  },
  {
    id: 21,
    title: "Fried",
    valeu: 2.1,
    emoji: FinalEmoji21,
  },
  {
    id: 22,
    title: "Fried",
    valeu: 2.2,
    emoji: FinalEmoji22,
  },
  {
    id: 23,
    title: "Fried",
    valeu: 2.3,
    emoji: FinalEmoji23,
  },
  {
    id: 24,
    title: "Fried",
    valeu: 2.4,
    emoji: FinalEmoji24,
  },
  {
    id: 25,
    title: "Fried",
    valeu: 2.5,
    emoji: FinalEmoji25,
  },
  {
    id: 26,
    title: "Fried",
    valeu: 2.6,
    emoji: FinalEmoji26,
  },
  {
    id: 27,
    title: "Fried",
    valeu: 2.7,
    emoji: FinalEmoji27,
  },
  {
    id: 28,
    title: "Fried",
    valeu: 2.8,
    emoji: FinalEmoji28,
  },
  {
    id: 29,
    title: "Fried",
    valeu: 2.9,
    emoji: FinalEmoji29,
  },
  {
    id: 30,
    title: "Fried",
    valeu: 3,
    emoji: FinalEmoji30,
  },
  {
    id: 31,
    title: "Fried",
    valeu: 3.1,
    emoji: FinalEmoji31,
  },
  {
    id: 32,
    title: "Fried",
    valeu: 3.2,
    emoji: FinalEmoji32,
  },
  {
    id: 33,
    title: "Fried",
    valeu: 3.3,
    emoji: FinalEmoji33,
  },
  {
    id: 34,
    title: "Fried",
    valeu: 3.4,
    emoji: FinalEmoji34,
  },
  {
    id: 35,
    title: "Fried",
    valeu: 3.5,
    emoji: FinalEmoji35,
  },
  {
    id: 36,
    title: "Fried",
    valeu: 3.6,
    emoji: FinalEmoji36,
  },
  {
    id: 37,
    title: "Fried",
    valeu: 3.7,
    emoji: FinalEmoji37,
  },
  {
    id: 38,
    title: "Fried",
    valeu: 3.8,
    emoji: FinalEmoji38,
  },
  {
    id: 39,
    title: "Fried",
    valeu: 3.9,
    emoji: FinalEmoji39,
  },
  {
    id: 40,
    title: "Fried",
    valeu: 4,
    emoji: FinalEmoji40,
  },
  {
    id: 41,
    title: "Fried",
    valeu: 4.1,
    emoji: FinalEmoji41,
  },
  {
    id: 42,
    title: "Fried",
    valeu: 4.2,
    emoji: FinalEmoji42,
  },
  {
    id: 43,
    title: "Fried",
    valeu: 4.3,
    emoji: FinalEmoji43,
  },
  {
    id: 44,
    title: "Fried",
    valeu: 4.4,
    emoji: FinalEmoji44,
  },
  {
    id: 45,
    title: "Fried",
    valeu: 4.5,
    emoji: FinalEmoji45,
  },
  {
    id: 46,
    title: "Fried",
    valeu: 4.6,
    emoji: FinalEmoji46,
  },
  {
    id: 47,
    title: "Fried",
    valeu: 4.7,
    emoji: FinalEmoji47,
  },
  {
    id: 48,
    title: "Fried",
    valeu: 4.8,
    emoji: FinalEmoji48,
  },
  {
    id: 49,
    title: "Fried",
    valeu: 4.9,
    emoji: FinalEmoji49,
  },
  {
    id: 50,
    title: "Fried",
    valeu: 5,
    emoji: FinalEmoji49,
  },
];
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 650,
//   bgcolor: "#16213E",
//   boxShadow: 24,
//   p: 3,
//   borderRadius: 2,
//   maxHeight: "90vh",
//   overflowY: "auto",
//   zIndex: 999,
// };

// const titleChracter = [
//   {
//     id: 1,
//     label: "Drama ",
//     icon: HandpicIcon,
//   },
//   {
//     id: 2,
//     label: "Sports",
//     icon: HandpicIcon,
//   },
//   {
//     id: 3,
//     label: "Comedy",
//     icon: HandpicIcon,
//   },
//   {
//     id: 4,
//     label: "Action",
//     icon: HandpicIcon,
//   },
//   {
//     id: 5,
//     label: "Crime",
//     icon: HandpicIcon,
//   },
//   {
//     id: 6,
//     label: "Marathi",
//     icon: HandpicIcon,
//   },
//   {
//     id: 7,
//     label: "Documentary",
//     icon: HandpicIcon,
//   },
//   {
//     id: 8,
//     label: "Gujrati",
//     icon: HandpicIcon,
//   },
// ];

// const titleReview = [
//   {
//     id: 1,
//     label: "Thrilled ",
//     icon: ThrilledIcon,
//   },
//   {
//     id: 2,
//     label: "Amused          ",
//     icon: AmusedIcon,
//   },
//   {
//     id: 3,
//     label: "Shocked      ",
//     icon: ShockedIcon,
//   },
//   {
//     id: 4,
//     label: "Frustrated ",
//     icon: FrustrtedIcon,
//   },
//   {
//     id: 5,
//     label: "Sad",
//     icon: SadIcon,
//   },
//   {
//     id: 6,
//     label: "Touched",
//     icon: TouchedIcon,
//   },
//   {
//     id: 7,
//     label: "Tense ",
//     icon: TenseIcon,
//   },
//   {
//     id: 8,
//     label: "Scared",
//     icon: ScaredIcon,
//   },
//   {
//     id: 9,
//     label: "Bored",
//     icon: BoredIcon,
//   },
//   {
//     id: 10,
//     label: "Understood",
//     icon: UnderstoodIcon,
//   },
//   {
//     id: 11,
//     label: "Confused",
//     icon: ConfusedIcon,
//   },
//   {
//     id: 12,
//     label: "Reflective",
//     icon: ReflectiveIocn,
//   },
// ];

export default function HelpSupport() {
  const [ratingValue, setRatingValue] = useState(0);
  const [feedback, setFeedback] = useState("");
  const dispatch = useDispatch();

  const handleSubmitForm = () => {
    const payload = {
      feedback_rating: parseInt(ratingValue),
      additional_comments: feedback,
    };

    helpers.trackMoengageEvent('FR3_Help', {
      feed_back: MOENGAGE_ATTRIBUTE.YES,
      feedback_rate: parseInt(ratingValue),
      comment: feedback,
      feedback_form_submit: MOENGAGE_ATTRIBUTE.YES
    });

    if (feedback != "") {
      dispatch(submitFeedback({ body: payload }));
      setFeedback('');
      setRatingValue(0);
    } else {
      helpers.toastError("comments can't be blank");
    }
  };

  return (
    <Grid item>
      <Grid
        container
        className="bg-grey br-10 pl-10 pt-10 mh-105 "
        justifyContent={"space-between"}
      >
        <Grid item>
          <p className="fw-700 fs-16 m-0">Help us to improve your experience</p>
          <p className="fw-500 fs-12 m-0 pt-24 text-dark-grey">
            your feedback matter to us
          </p>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Grid container justifyContent={"flex-end"}>
            <Box
              component="img"
              sx={{
                height: 100,
                width: 100,
                maxHeight: { xs: 60, sm: 100 },
                maxWidth: { xs: 60, sm: 100 },
                padding: 0,
                // borderRadius: '50%'
              }}
              alt="feedback"
              src={FeedbackLogo}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} className="mt-5">
        <Grid item className="text-center" xs={12}>
          <Box
            component="img"
            sx={{
              height: "100%",
              width: "100%",
              maxHeight: { xs: 70, sm: 150 },
              maxWidth: { xs: 70, sm: 150 },
            }}
            alt="feedback"
            src={
              ratingEmoji.filter((item) => item.valeu == ratingValue) &&
                ratingEmoji.filter((item) => item.valeu == ratingValue)[0]
                ? ratingEmoji.filter((item) => item.valeu == ratingValue)[0]
                  ?.emoji
                : ""
            }
          />
        </Grid>
        <Grid item className="w-100 text-center mt-10" xs={8}>
          <Box className="mt-20 rating-slider-container">
            <Slider
              aria-label="Temperature"
              defaultValue={ratingValue}
              valueLabelDisplay="auto"
              step={0.1}
              marks
              min={0}
              max={5}
              onChange={(e) => setRatingValue(e.target.value)}
            />
          </Box>
          <p className="fw-700 fs-24 m-0 ">
            {ratingValue <= 1 && ratingText[0]}
            {ratingValue > 1 && ratingValue <= 2 && ratingText[1]}
            {ratingValue > 2 && ratingValue <= 3 && ratingText[2]}
            {ratingValue > 3 && ratingValue <= 4 && ratingText[3]}
            {ratingValue > 4 && ratingText[4]}
          </p>
        </Grid>
      </Grid>
      <Grid item className="mt-10">
        <TextareaAutosize
          aria-label="minimum height"
          minRows={4}
          maxRows={10}
          style={{
            width: "100%",
            maxWidth: "calc(100% - 20px)",
            maxHeight: "100%",
            height: "100%",
            borderRadius: "8px",
            paddingBottom: "20px",
            padding: 10,
            background: "transparent",
            color: "white",
          }}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="enter comments"
        />
      </Grid>
      <Button
        onClick={() => handleSubmitForm()}
        className="rounded-btn w-100 bg-selected text-light mt-10"
      >
        Submit
      </Button>
    </Grid>
  );
}
