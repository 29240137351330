import React, { useEffect, useState } from 'react'
import { Box, Grid, Button, Checkbox } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBackRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { getWatchlistMembers, removeMemberToWatchlist } from '../../slices/watchlist';
import helpers from '../../services/helpers';
import FollowUnfollowPerson from '../common/FollowUnfollowPerson';
import { clearMessages } from '../../slices/cast';
import { Link } from 'react-router-dom';


export default function WatchlistMember(props) {
    const [page, setPage] = useState(1);
    const [selectedMember, setSelectedMember] = useState([]);
    const { wathlist_id } = props;
    const cast = useSelector(state => state.cast);

    const dispatch = useDispatch();
    const watchlist = useSelector(state => state.watchlist);

    useEffect(() => {
        if (cast.errMesg && cast.errMesg != "") {
            helpers.toastError(cast.errMesg);
            dispatch(clearMessages());
        }
        if (cast.successMesg && cast.successMesg != "") {
            helpers.toastSuccess(cast.successMesg);
            handleReloadData();
            dispatch(clearMessages());
        }
    }, [cast])

    useEffect(() => {
        if (wathlist_id && wathlist_id != "") {
            dispatch(getWatchlistMembers({ id: wathlist_id, page: page, pagelimit: 12 }))
        }
    }, [page, wathlist_id])

    const handleReloadData = () => {
        if (page == 1) {
            dispatch(getWatchlistMembers({ id: wathlist_id, page: page, pagelimit: 12 }));
        } else {
            setPage(1);
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            if (watchlist.watchlistMemberPageData && watchlist.watchlistMemberPageData.pagination && watchlist.watchlistMemberPageData.pagination.is_next_page && !watchlist.isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, watchlist]);

    const handleDeleteMember = (data, id, itemId) => {
        if (data.uuid && wathlist_id == id) {
            dispatch(removeMemberToWatchlist({ uuid: data.uuid, id: id })).then((data) => {
                if (data.payload.message == 'member removed') {
                    document.getElementById(itemId).remove();
                }
            });
        }
    }

    const handleMultipleDelete = () => {

    }

    const handleSelectMember = (event, data) => {
        const tempArr = selectedMember;
        if (event.target.checked) {
            tempArr.push(data.uuid);
        } else {
            if (tempArr.includes(data.uuid)) {
                var index = tempArr.indexOf(data.uuid);
                tempArr.splice(index, 1);
            }
        }
        setSelectedMember([...tempArr]);
    }

    const handleSelectAll = () => {
        setSelectedMember([]);
        var temp = [];
        watchlist.watchlistMemberData && watchlist.watchlistMemberData.length > 0 &&
            watchlist.watchlistMemberData.map(item => {
                if (item.role != "owner") {
                    temp.push(item.uuid);
                }
            })
        setSelectedMember([...temp]);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} justifyContent={'space-between'} className='app-container mt-10'>
                    <Grid item xs={12} sm={4} className={'text-left'} >
                        <ArrowBack className='pointer' onClick={() => props.goBack()} />
                    </Grid>
                    <Grid item xs={12} sm={4} className={'text-center'} >
                        {props.role == "owner" && selectedMember.length > 0 &&
                            <Button onClick={() => handleMultipleDelete()} className='rounded-btn mw-260 bg-light text-selected fs-14 fw-500'>
                                delete selected members
                            </Button>
                        }
                    </Grid>
                    {/* {props.role == "owner" &&
                        <Grid item xs={12} sm={4} className={'text-right'} >
                            {selectedMember.length > 0 ?
                                <Button onClick={() => setSelectedMember([])} className='rounded-btn mw-120 bg-selected text-light fs-14 fw-500'>
                                    unselect all
                                </Button>
                                :
                                <Button onClick={() => handleSelectAll()} className='rounded-btn mw-120 bg-selected text-light fs-14 fw-500'>
                                    select all
                                </Button>
                            }
                        </Grid>
                    } */}
                </Grid>
                {watchlist.watchlistMemberData && watchlist.watchlistMemberData.length > 0 &&
                    <Grid item className='app-container mb-50'>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            {watchlist.watchlistMemberData.map((x, i) => {
                                return <Grid item id={`watchlist-member-${x?.uuid}`} className='watchlist-detail-members' xs={12} sm={4} key={i} >
                                    <Grid container alignItems={'center'} className='mt-10 mb-10'>
                                        <Grid item>
                                            <Link to={`/profile/${x?.uuid}`}>
                                                <Box
                                                    component="img"
                                                    className='d-block scale-1'
                                                    sx={{
                                                        height: 80,
                                                        width: 80,
                                                        maxHeight: { xs: 60, sm: 80 },
                                                        maxWidth: { xs: 60, sm: 80 },
                                                        borderRadius: '50%',
                                                    }}
                                                    alt="profilepic"
                                                    src={x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage("user")}
                                                />
                                            </Link>
                                        </Grid>
                                        <Grid item className='ml-10'>
                                            <p className='fw-500 fs-16 m-0'>{x.username ? x.username : ''}</p>
                                            <p className='fw-300 fs-12 m-0 text-dark-grey'>{x.unique_name ? x.unique_name : ''}</p>
                                            <Grid container className='w-100 mt-10' justifyContent={'space-between'} alignItems={'center'}>
                                                {props.role != "owner" &&
                                                    <Grid item className='pr-0'>
                                                        <FollowUnfollowPerson data={x} />
                                                    </Grid>
                                                }
                                                {props.role == "owner" && x.role != "owner" &&
                                                    <Grid item>
                                                        <Grid container >
                                                            <Grid item className='rounded-circle' >
                                                                <DeleteIcon onClick={() => handleDeleteMember(x, wathlist_id, `watchlist-member-${x?.uuid}`)} className='text-selected pointer' />
                                                            </Grid>
                                                            {/* <Grid item className='rounded-circle ml-15'>
                                                                <Checkbox
                                                                    className='text-selected'
                                                                    checked={selectedMember.includes(x.uuid)}
                                                                    onChange={(e) => handleSelectMember(e, x)}
                                                                />
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            })}
                        </Box>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}
