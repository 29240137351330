import React from 'react'
import { Helmet } from 'react-helmet-async'

const defaultKeywords = 'Frenzi, what to watch, what to watch on ott, ott simplified, best movies to watch on Ott';

export default function MyHelmet(props) {

    const { title, description, isBlog, schemaMarkup, og_title, og_type, og_image, og_url, og_description, og_site_name, keywords } = props

    return (
        <Helmet>
            <title>{title || 'Frenzi | simplifying "what to watch on OTT...!"'}</title>

            <meta name="description" content={description || "Discover What to Watch on OTT, Find the Perfect Shows and Movies, Stream on Your Preferred Platforms, and Get Notified when New Titles drop on OTT"} />

            {keywords && <meta name="keywords" content={keywords || defaultKeywords}></meta>}

            <meta property="og:title" content={og_title ? og_title : title ? title : 'Frenzi | simplifying "what to watch on OTT...!'} />

            <meta property="og:type" content={og_type || 'website'} />

            <meta property="og:image" content={og_image || window.location.host + '/frenzi-favicon-logo.jpg'} />

            <meta property="og:url" content={og_url || window.location.href} />

            <meta property="og:description" content={og_description ? og_description : description ? description : 'Frenzi simplifies content discovery across 60+ OTT platforms and guides you to platforms where you can legally watch them. Simplifying. Content Discovery.'} />

            <meta property="og:site_name" content={og_site_name || 'Frenzi'} />

            <link rel="canonical" href={window.location.href} />

            {isBlog ? schemaMarkup
                :
                <script type="application/ld+json">{schemaMarkup || ''}</script>
            }
        </Helmet>
    )
}
