import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { acceptRejectTitleRequest, getAllPendingSuggestions } from '../../../slices/watchlist';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import helpers from '../../../services/helpers';
import { Link } from 'react-router-dom';
// import ZeroStateForPendingNotification from '../../../assets/ZeroStateForPendingNotification.svg'

const ZeroStateForPendingNotification = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/ZeroStateForPendingNotification.svg";

export default function PendingSuggestions(props) {
    const { wid } = props;
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const watchlist = useSelector(state => state.watchlist);
    const { pendingSuggestionsData } = watchlist;

    useEffect(() => {
        getData();
    }, [wid, page])

    useEffect(() => {
        if (watchlist.successMesg && watchlist.successMesg != "") {
            if (page == 1) {
                getData();
            } else {
                setPage(1);
            }
        }
    }, [watchlist])

    const getData = () => {
        dispatch(getAllPendingSuggestions({ id: wid, page: page, pagelimit: 7 }));
    }

    const handleAcceptTitlePrivate = (data, action = 'accept') => {
        const payload = {
            watchlist_id: data.watchlist_id,
            media_id: data.media,
            action: action,
            user_id: data.user
        }
        dispatch(acceptRejectTitleRequest({ body: payload }));
    }

    // const isInViewport = (el) => {
    //     const rect = el.getBoundingClientRect();
    //     return (
    //         rect.top >= 0 &&
    //         rect.left >= 0 &&
    //         rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    //         rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    //     );
    // }

    return (
        <div className='h-100'>

            {pendingSuggestionsData && pendingSuggestionsData.length > 0 ?
                <Grid container className='h-100'>
                    <Typography className='fw-700 fs-20 lh-16 pt-10'>Suggestions</Typography>
                    <Grid item sm={12} className='h-100'>
                        <Grid
                            container
                            sx={{
                                paddingTop: '20px',
                                paddingRight: '20px',
                            }}
                            spacing={1}
                        >
                            {pendingSuggestionsData.map((x, i) => {
                                return <Grid item xs={12} sm={12} key={i} className="title-list-item">
                                    <Grid container className='mt-10' flexWrap={'nowrap'}>
                                        <Grid item className='relative text-center'>
                                            <Link onClick={() => helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', 'Pending Suggestions')} to={helpers.getMediaLink(x)}>
                                                <Box
                                                    component="img"
                                                    className='d-block'
                                                    sx={{
                                                        height: '48px',
                                                        width: '48px',
                                                        maxWidth: { xs: '48px' },
                                                        maxHeight: { xs: '48px' },
                                                        clipPath: 'circle()',
                                                    }}
                                                    alt="poster"
                                                    src={x.poster ? helpers.getFullUrl(x.poster) : helpers.getDefaultImage("tile")}
                                                />
                                            </Link>
                                        </Grid>
                                        <Grid item xs={9} sx={{ paddingLeft: '10px' }} >
                                            <Typography title={x.title ? x.title : ''} className='text-left fs-20 fw-700 ellipsis'>
                                                {x?.title}
                                            </Typography>
                                            <p className='text-left fw-300 fs-12 text-dark-grey m-0'>Recommended by {x.user_name ? x.user_name : x.unique_name} for ”{x.watchlist ? x.watchlist : ''}”</p>
                                        </Grid>
                                        <Grid item>
                                            <Grid container className='mt-2' justifyContent={'start'} alignItems={'center'} flexWrap='nowrap'>
                                                <Grid item onClick={() => handleAcceptTitlePrivate(x, 'accept')} className='rounded-circle mr-10 pointer mt-10'>
                                                    <CheckIcon className='text-selected' />
                                                </Grid>
                                                <Grid item onClick={() => handleAcceptTitlePrivate(x, 'reject')} className='rounded-circle pointer mt-10'>
                                                    <ClearIcon className='text-selected' />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                : (
                    <Grid className="mt-50">
                        <Grid container className='new-container' justifyContent={'center'} >
                            <Grid item className="relative">
                                <Grid item>
                                    <Box component={'img'} src={ZeroStateForPendingNotification} sx={{ maxWidth: { xs: '300px', sm: '400px' } }} alt={''} />
                                </Grid>
                                {/* <Grid item className="absolute" sx={{ top: "20px", right: '20px' }}>
                                    <Typography className="fw-700 fs-20 lh-20 mb-10">be the first to <span className="text-selected">review</span></Typography>
                                    <Typography className="fw-500 fs-14 lh-24">Create your own review and get points</Typography>
                                    <ReveiwMovie data={media.mediaData} isBtn={true} />`
                                 </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </div>
    )
}
