import Grid from '@mui/material/Grid'
import React from 'react'
import ShareListData from './ShareListData'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";

const breakpoints = {
    319: {
        slidesPerView: 1,
        spaceBetween: 15,
    },
    425: {
        slidesPerView: 1.5,
        spaceBetween: 15,
    },
    640: {
        slidesPerView: 2,
        spaceBetween: 15,
    },
    768: {
        slidesPerView: 2.5,
        spaceBetween: 20,
        slidesPerGroup: 1
    },
    1024: {
        slidesPerView: 2.7,
        spaceBetween: 20,
        slidesPerGroup: 1
    },
    1440: {
        slidesPerView: 3,
        spaceBetween: 20,
        slidesPerGroup: 2
    },
    1880: {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 2
    },
    2560: {
        slidesPerView: 4.2,
        spaceBetween: 30,
        slidesPerGroup: 2
    },
}

export default function FriendShareList(props) {
    const { data, heading, pageData } = props;

    return (
        <div>
            <Grid container className="mt-10 new-container" justifyContent='space-between'>
                <Grid item>
                    <h2 className="fw-700 fs-28 m-0">{heading && heading !== "" ? heading : <><span className='text-selected'>watchlist</span> my friends have{pageData.pagination && pageData.pagination?.total_entries ? ` (${pageData.pagination?.total_entries})` : ''}</>}</h2>
                    {/* <p className='fw-500 fs-24 m-0 text-dark-grey'>{heading && heading != "" ? heading : 'friends list to the world'}</p> */}
                </Grid>
                <Link to="/friends-sharelists">
                    <ArrowForwardIcon className='pointer' />
                </Link>
            </Grid>
            <Grid container className='new-container'>
                <Grid item xs={12} className={'pb-20'}>
                    {data && data.length > 0 &&
                        <Swiper
                            // slidesPerView={1}
                            // spaceBetween={20}
                            // slidesPerGroup={5}
                            freeMode={true}
                            navigation={true}
                            speed={1000}
                            breakpoints={breakpoints}
                            modules={[FreeMode, Navigation]}
                            className="mySwiper"
                        >
                            {data.map((x, i) => {
                                return <SwiperSlide key={i}>
                                    <Grid key={i} sx={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                        <ShareListData x={x} />
                                    </Grid>
                                </SwiperSlide>
                            })}
                        </Swiper>
                    }
                </Grid>
            </Grid>
        </div>
    )
}
