import { Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import MyHelmet from "../common/MyHelmet";
import SearchBar from "./components/SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSearchData, handleFilters } from "../../slices/frenziPartner";
import AdvancedSearch from "./components/AdvancedSearch";

import "./_partner-search.scss";
import helpers from "../../services/helpers";
import { getGenreList, getLanguageList, getPlatformList } from "../../slices/auth";

function PartnerSearch() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const { isLoading, searchData, advFilters } = useSelector((state) => state.frenziPartner);
  const _global = useSelector((state) => state.global);


  useEffect(() => {
    dispatch(getLanguageList());
    dispatch(getPlatformList({ page: 1, pagelimit: 100, is_public: _global?.is_public }));
    dispatch(getGenreList());
  }, [])

  let platforms =
    auth.allPreferences &&
      auth.allPreferences.platforms &&
      auth.allPreferences.platforms.length > 5
      ? auth.allPreferences.platforms
      : auth.platformList?.results
        ? auth.platformList?.results
        : [];

  const handleAdvancedSearch = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleRecommendedSearch = () => {
    navigate("/");
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      helpers.setSearchHistoryData(input)
      navigate(`/partner/results?q=${input}`);
    }
  }

  const handleSeacrh = () => {}

  // const handleSeacrh = useCallback((val) => {
  //   const allFilters = []
  //   for (const [key, value] of Object.entries(advFilters)) {
  //     if ((Array.isArray(value) && value.length > 0) || (value && value != '')) {
  //       allFilters.push({ [key]: value })
  //     }
  //   }

  //   var body = {
  //     query: val,
  //     // custom_filters_es: {
  //     //   any: [
  //     //     {
  //     //       all: allFilters
  //     //     }
  //     //   ]
  //     // },
  //   }

  //   dispatch(getSearchData(body));
  // }, []);

  const handleApply = (e) => {
    handleClose();
    navigate(`/partner/results?q=${input}`)
  };

  return (
    <>
      <MyHelmet
        title={"Partner search page"}
      // description={
      //   "Unveil the Hottest OTT Trends, Stay Updated on the Latest Streaming Buzz and Discover What's Trending in the OTT Worlds"
      // }
      />
      <Grid style={{ minHeight: 'calc(100vh - 100px)' }} container alignItems={'center'} className="new-container h-100 pb-10">
        <div className="partner-search-box-container">
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Grid container>
                <Typography className="fw-700 fs-90 text-selected">
                  Frenzi
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <div className="search-container">
            <Grid
              container
              justifyContent={"center"}
              sx={{
                marginTop: { xs: "10px" },
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <SearchBar
                suggestions={searchData}
                isLoading={isLoading}
                onChange={(val) => handleSeacrh(val)}
                onKeyUp={(e) => handleKeyUp(e)}
                setInput={setInput}
                input={input}
              />
            </Grid>
            <Grid
              justifyContent={"center"}
              container
              style={{ alignItems: "center" }}
            >
              <div>
                <p
                  onClick={() => handleAdvancedSearch()}
                  className="hover-link fs-24"
                >
                  advanced search
                </p>
              </div>
              <span className="text-selected ml-5 mr-5">|</span>
              <div>
                <p
                  onClick={() => handleRecommendedSearch()}
                  className="hover-link fs-24"
                >
                  recommendations
                </p>
              </div>
            </Grid>
            <Grid
              container
              style={{ flexWrap: "wrap" }}
              justifyContent={"center"}
            >
              <div className="trending-list">
                <Grid container justifyContent={"center"}>
                  <p className="fs-20 text-center fw-200 w-100 mb-5 mr-5">
                    Trending on :
                    {platforms &&
                      platforms.length > 0 &&
                      platforms.map((x, i) => {
                        if (i <= 4) {
                          return (
                            <Link
                              target="_blank"
                              to={`/platforms/${x?.ott_name}`}
                            >

                              {" "}
                              <span className="text-selected">
                                {x?.ott_name}
                              </span>
                              {i < 4 ? "," : ""}
                            </Link>
                          );
                        }
                      })}
                  </p>
                </Grid>
              </div>
            </Grid>
            {/* <Grid container justifyContent={"center"}>
              <div className="horizontal-line" />
            </Grid> */}
          </div>

          {/* Advanced search modal */}

          <AdvancedSearch
            open={open}
            searchData={searchData}
            isLoading={isLoading}
            input={input}
            handleClose={handleClose}
            handleSeacrh={handleSeacrh}
            handleKeyUp={handleKeyUp}
            setInput={setInput}
            handleApply={handleApply}
          />

          {/* <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h5"
                component="h2"
                className="mb-20 text-selected"
              >
                advanced search
              </Typography>
              <div>
                <Grid
                  container
                  justifyContent={"center"}
                  sx={{
                    marginTop: { xs: "10px" },
                    marginBottom: "20px",
                    width: "100%",
                  }}
                >
                  <SearchBar
                    suggestions={searchData}
                    isLoading={isLoading}
                    onChange={(val) => handleSeacrh(val)}
                    onKeyUp={(e) => handleKeyUp(e)}
                    setInput={setInput}
                    input={input}
                  />
                </Grid>
                <Grid container gap={2} justifyContent={"space-between"}>
                  <Grid flex={1} item>
                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        genre :
                      </Grid>
                      <Grid item>
                        <div>
                          {genreList && genreList.length ? (
                            <FormControl size="small" sx={{ m: 1, width: 200 }}>
                              <InputLabel id="demo-multiple-checkbox-label">
                                genre
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={searchMediaFilter?.genre}
                                onChange={handleChange}
                                input={<OutlinedInput label="genres" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {genreList.map((x, i) => (
                                  <MenuItem
                                    key={i}
                                    value={x.genre.toLowerCase()}
                                  >
                                    <Checkbox
                                      checked={searchMediaFilter.genre.includes(
                                        x.genre.toLowerCase()
                                      )}
                                    />
                                    <ListItemText primary={x.genre} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        platforms :
                      </Grid>
                      <Grid item>
                        <div>
                          {platforms && platforms.length ? (
                            <FormControl size="small" sx={{ m: 1, width: 200 }}>
                              <InputLabel id="demo-multiple-checkbox-label">
                                platforms
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={searchMediaFilter?.service}
                                onChange={handleChangePlatform}
                                input={<OutlinedInput label="platforms" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {platforms.map((x, i) => (
                                  <MenuItem key={i} value={x?.code}>
                                    <Checkbox
                                      checked={searchMediaFilter.service.includes(
                                        x?.code
                                      )}
                                    />
                                    <ListItemText
                                      primary={x?.ott_name || "N/A"}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        language :
                      </Grid>
                      <Grid item>
                        <div>
                          {languageList && languageList.length ? (
                            <FormControl size="small" sx={{ m: 1, width: 200 }}>
                              <InputLabel id="demo-multiple-checkbox-label">
                                language
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={searchMediaFilter?.language}
                                onChange={handleChangeLanguage}
                                input={<OutlinedInput label="language" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {languageList.map((x, i) => (
                                  <MenuItem key={i} value={x?.code}>
                                    <Checkbox
                                      checked={searchMediaFilter.language.includes(
                                        x?.code
                                      )}
                                    />
                                    <ListItemText
                                      primary={x?.language || "N/A"}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        content type :
                      </Grid>
                      <Grid item>
                        <Grid container justifyContent={"space-between"}>
                          <Grid item>
                            <Grid container justifyContent={"space-between"}>
                              <Grid item>
                                <Typography className="fs-15 fw-500">
                                  movies
                                </Typography>
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  className="m-0"
                                  control={
                                    <Checkbox
                                      className="pt-0 text-selected"
                                      checked={searchMediaFilter.content_type.includes(
                                        "movie"
                                      )}
                                      onChange={(e) =>
                                        handleSelectBoxChange(
                                          "content_type",
                                          "movie",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container justifyContent={"space-between"}>
                              <Grid item>
                                <Typography className="fs-15 fw-500">
                                  webseries
                                </Typography>
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  className="m-0 text-selected"
                                  control={
                                    <Checkbox
                                      className="pt-0"
                                      checked={searchMediaFilter.content_type.includes(
                                        "tv"
                                      )}
                                      onChange={(e) =>
                                        handleSelectBoxChange(
                                          "content_type",
                                          "tv",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item flex={1}>
                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        release year :
                      </Grid>
                      <Grid item>
                        <Grid container justifyContent={"space-between"}>
                          <Grid item>
                            <FormControl size="small" sx={{ m: 1, width: 100 }}>
                              <InputLabel id="demo-select-small-label">
                                from
                              </InputLabel>
                              <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                label="from"
                                value={searchMediaFilter?.from_year}
                                onChange={handleChangeFromYear}
                              >
                                {YEARS.map((x, i) => (
                                  <MenuItem key={i} value={x}>
                                    <ListItemText primary={x} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl size="small" sx={{ m: 1, width: 100 }}>
                              <InputLabel id="demo-select-small-label">
                                to
                              </InputLabel>
                              <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                label="to"
                                value={searchMediaFilter?.to_year}
                                onChange={handleChangeToYear}
                              >
                                {YEARS.map((x, i) => (
                                  <MenuItem key={i} value={x}>
                                    <ListItemText primary={x} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        certificate :
                      </Grid>
                      <Grid item>
                        <div></div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        rating :
                      </Grid>
                      <Grid item>
                        <FormControl size="small" sx={{ m: 1, width: 100 }}>
                          <InputLabel id="demo-select-small-label">
                            rating
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            label="rating"
                            value={searchMediaFilter?.rating}
                            onChange={handleChangeRating}
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x, i) => (
                              <MenuItem key={i} value={x}>
                                <ListItemText primary={x} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        sort by :
                      </Grid>
                      <Grid item>
                        <div>
                          <FormControl
                            sx={{ m: 1, minWidth: 200 }}
                            size="small"
                          >
                            <InputLabel id="demo-select-small-label">
                              sort by
                            </InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={searchMediaFilter.sort}
                              label="sort by"
                              onChange={handleChangeSort}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={"r"}>rating</MenuItem>
                              <MenuItem value={"l"}>popularity</MenuItem>
                              <MenuItem value={"d"}>release date</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid container gap={2} justifyContent={"space-between"}>
                  <Grid item flex={1} >
                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        cast :
                      </Grid>
                      <Grid item flex={1}>
                        <Autocomplete
                          disablePortal
                          id="autocomplete"
                          sx={{ width: '100%' }}
                          size="small"
                          renderInput={(params) => (
                            <TextField {...params} label="cast" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item flex={1}>
                  </Grid>
                </Grid>
              </div>
              <Grid container justifyContent={"center"}>
                <Button
                  style={{ marginBottom: "10px" }}
                  onClick={handleApply}
                  className="text-light bg-selected rounded-btn mt-10 mw-100"
                >
                  apply
                </Button>
              </Grid>
            </Box>
          </Modal> */}
        </div>
      </Grid>
    </>
  );
}

export default PartnerSearch;
