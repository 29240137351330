import { Button } from '@mui/material'
import React from 'react'
import './common.scss'

export default function CustomButton(props) {

  let fontSize = props.fontSize ? props.fontSize : 'fs-14'

  return (
    <Button sx={{
      // maxWidth: { xs: '46px', sm: '64px'}, 
      // maxWidth:'100px',
      minWidth: '40px',
      maxHeight: { xs: '24px', sm: '32px' }, 
      width: '100%',
      fontSize:{md:'12px' , lg:'13px'}
    }} onClick={props.onClick} className={`custom-button fw-500 pl-20 pointer ${props?.className} pr-20 ${props.selected ? 'selected-btn' : ''}`}>
      {props.label}
    </Button>
  )
}
