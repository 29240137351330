import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { getBlogDetail, getCommentLists } from '../../slices/blog';
import parse from 'html-react-parser';
import { Box } from '@mui/system';
import helpers from '../../services/helpers';
import Grid from '@mui/material/Grid';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import MyAds from '../adsense/MyAds';
import MyHelmet from '../common/MyHelmet';
import { isMobileDevice } from '../../services/Utils';
import {
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
} from "react-share";
import { useDispatch } from 'react-redux';
import FAQ from './FAQ';
import './WpBlog.scss'
import CommentReply from './CommentReply';


export default function WPblogDetail() {

    const params = useParams();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (params.unique_title && params.unique_title != '') {
            window.scrollTo(0, 0);
            dispatch(getBlogDetail({ unique_title: params.unique_title })).then((resp) => {
                if (resp.payload && resp.payload.data) {
                    setData(resp.payload.data);
                    setIsLoading(false);
                    dispatch(getCommentLists({ id: resp.payload.data.uuid }));
                }
            });

        } else if (params.id) {
            window.scrollTo(0, 0);
            setIsLoading(true);
            let section = searchParams.get('section') || '';
            let category = searchParams.get('category') || '';
            let index = searchParams.get('i') || '';

            dispatch(getBlogDetail({ id: params.id, section, category, index })).then((resp) => {
                if (resp.payload && resp.payload.data) {
                    setData(resp.payload.data);
                    console.log(resp.payload.data)
                    setIsLoading(false);
                }
            });

            dispatch(getCommentLists({ id: params.id }))
        }
    }, [params, searchParams, dispatch])

    const getFormattedDate = (value) => {
        if (value) {
            let date = [value.split("-")[2], value.split("-")[1], value.split("-")[0]].join("-");
            let newDate = new Date(date);
            let d = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
            let m = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
            let y = newDate.getFullYear();
            let res = y + "-" + m + "-" + d;
            return res;
        }
    }

    const getLink = (data, section, currentPage, currentIndex) => {
        if (data?.unique_title && data?.unique_title != "") {
            let link1 = `/blog/${data?.unique_title}`;
            return link1;
        } else {
            let index = currentPage + currentIndex;
            let titleUrl = data?.url || data?.title;
            let link = `/blog/${titleUrl.replace(/ /g, '-')}/${data?.uuid}?section=${section}&category=&i=${index}`;
            return link;
        }
    }

    return (
        <>
            <MyHelmet
                title={data?.title}
                og_title={data?.meta_title}
                description={data?.meta_description || ''}
                schemaMarkup={data?.schema || ''}
            />
            <Grid item className='new-container new-blog-layout pr-0'>
                <Grid container>
                    {/* {!isMobileDevice() ?
                        <Grid item textAlign='center' style={{ minWidth: 320 }}>
                            <MyAds type="banner_3" adv_type={"web_blog_detail_page_ad"} />
                        </Grid>
                        : ''
                    } */}
                    <Grid item sx={{ textAlign: 'center' }} className="pl-25 pr-25" flex={1}>
                        <Box
                            component={'img'}
                            sx={{
                                width: '100%',
                                // height: '100%',
                                maxHeight: '63vh',
                                objectFit: 'cover'
                            }}
                            src={data?.banner ? helpers.getFullUrl(data?.banner) : helpers.getDefaultImage('banner')}
                            alt='banner'
                        />
                        <h1 className='fs-32 fw-400 blog-title m-0 text-left'>{data?.title} <span></span></h1>
                        <Grid container columnGap={1} alignItems='center'>
                            <Grid item>
                                <AccessTimeFilledRoundedIcon className='text-selected pt-5' />
                            </Grid>
                            <Grid item>
                                <p className='text-muted fs-14'>{new Date(getFormattedDate(data?.date_published)).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}</p>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ paddingTop: '20px', textAlign: 'center' }} >
                            <MyAds type="banner_2" adv_type={"web_blog_detail_page_ad"} />
                        </Grid>
                    </Grid>

                    {/* {!isMobileDevice() ?
                        <Grid item textAlign='center' style={{ minWidth: 320 }}>
                            <MyAds type="banner_3" adv_type={"web_blog_detail_page_ad"} />
                        </Grid>
                        : ''
                    } */}
                </Grid>

                <Grid container className='imagewidth mt-30'>
                    {!isMobileDevice() ?
                        <Grid item textAlign='center' style={{ minWidth: 320 }} >
                            <MyAds type="banner_1" adv_type={"web_blog_detail_page_ad"} />
                        </Grid>
                        : ' '
                    }
                    <Grid item className='imagewidth' flex={1}>
                        {parse(data?.description || '')}
                        <br />
                        <FAQ data={data?.faQs || []} />
                        <br />
                        {parse(data?.blog_content || '')}
                        <br />
                        <CommentReply blog={params.id} />
                    </Grid>
                    {!isMobileDevice() ?
                        <Grid item textAlign='center' style={{ minWidth: 320 }} >
                            <MyAds type="banner_1" adv_type={"web_blog_detail_page_ad"} />
                        </Grid>
                        : ' '
                    }
                </Grid>

                <Grid item sx={{ paddingTop: '20px', textAlign: 'center' }} >
                    <MyAds type="banner_2" mobtype={'mobile_1'} adv_type={"web_blog_detail_page_ad"} />
                </Grid>

                <div class="sticky-container">
                    <ul class="sticky">
                        <li>
                            <FacebookShareButton style={{ textAlign: 'center' }} url={window.location.href} >
                                <FacebookIcon />
                                <p className='fs-16 lh-18'>Share on<br />Facebook</p>
                            </FacebookShareButton>
                        </li>
                        <li>
                            <TwitterShareButton style={{ textAlign: 'center' }} url={window.location.href} >
                                <TwitterIcon />
                                <p className='fs-16 lh-18'>Share on<br />Twitter</p>
                            </TwitterShareButton>
                        </li>
                        <li>
                            <WhatsappShareButton style={{ textAlign: 'center' }} url={window.location.href} >
                                <WhatsappIcon />
                                <p className='fs-16 lh-18'>Share on<br />Whatsapp</p>
                            </WhatsappShareButton>
                        </li>
                    </ul>
                </div>
                {data?.related_blogs && data?.related_blogs.length > 0 ?
                    <Grid item xs={12} className="app-container pl-25 pr-25">
                        <h2 style={{ fontWeight: 600, fontSize: '36px', color: 'black' }}>Related Posts</h2>
                        <Grid container columnSpacing={2}>
                            {data?.related_blogs.map((x, i) => {
                                return <Grid item xs={12} sm={6} md={4} >
                                    <Grid container flexDirection={'column'} justifyContent={'space-between'} sx={{ height: '100%', overflow: "clip" }} >
                                        <Grid item className='w-100'>
                                            <Link to={getLink(x, 'popular', 1, i)}>
                                                <Box component={'img'}
                                                    className='d-block pointer scale-1'
                                                    sx={{
                                                        width: '100%',
                                                        // height: '150px',
                                                        ":hover": { opacity: 0.3 },
                                                        // objectFit: 'cover'
                                                    }}
                                                    src={x?.banner ? helpers.getFullUrl(x?.banner) : helpers.getDefaultImage('banner')}
                                                    alt='banner'
                                                />
                                            </Link>
                                            <Grid container columnGap={1} alignItems='center'>
                                                <Grid item>
                                                    <AccessTimeFilledRoundedIcon className='text-selected pt-5' />
                                                </Grid>
                                                <Grid item>
                                                    <p className='text-muted fs-14'>{new Date(getFormattedDate(x?.date_published)).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}</p>
                                                </Grid>
                                            </Grid>
                                            <h2 className='fs-18 fw-700  m-0 text-dark'>{x?.title}</h2>
                                        </Grid>

                                        <Grid item className='w-100'>
                                            <p style={{ height: '100px', overflow: 'clip', color: 'black' }} className=' m-0'>
                                                {x?.description ? parse(x?.description) : ''}
                                                {/* {x?.description.replace(/<[^>]+>/g,'')} */}
                                            </p>

                                            {/* <Button onClick={() => navigate(getLink(x, 'popular', currentPage.POPULAR_BLOG, i))} className='rounded-btn mw-77 mh-22 bg-light text-selected fs-10 fw-500 lh-10 mb-20'>read more </Button> */}
                                        </Grid>

                                    </Grid>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                    : ''
                }
            </Grid>
        </>
    )

}
