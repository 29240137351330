export const HELPER_TEXT = {
    NUDGE_TEXT: {
        HANDPICKED: <span>help us choose the best</span>,
        NOTIFY_ME: <span>Get notified when it's available on OTT!</span>,
        REPORT_ERROR: <span>found an error? Report it!</span>,
        REVIEW: <span>want to write something about </span>,
        FOLLOW_ACTOR: <span> like </span>,
        WATCHLIST: <span>become a part of frenzi watchlist</span>,
        WATCH: <span>what do you want to watch? let us help you decide!</span>,
        CREATE_WATCHLIST: <span>start building your personalized watchlist now!</span>,
        CREATE_SHARELIST: <span>start building your sharelist now!</span>,
        TITLES_WATCHED: <span>increase your cinescore and earn frenzi points</span>,
        JOIN_POLL: <span>got an opinion? mark your choice</span>,
        START_QUIZ: <span>know the correct answer ?</span>,
        ADD_BIRTHDAY: <span>Discover famous people who share your birthday</span>,
        FREE_TITLES: <span>select titles you like</span>,
        ADD_PLATFORM: <span>For Personalized recommendation on your subscribed platforms, join Frenzi</span>,
        LOGIN: <span>For personalised recommendations on your subscribed platform, join Frenzi.</span>,
        ADD_LANGUAGE: <span>For Personalized recommendation in languages you enjoy your content, join Frenzi</span>,
        ADD_GENRE: <span>For Personalized recommendation in genres you enjoy your content, join Frenzi</span>,
        ADD_FRIENDS: <span>join us to make new friends and build lasting connections</span>,
        LIKE: <span>want to like this title ? </span>,
        RATE: <span>want to rate this title ?</span>,
        SEEN: <span>already watched this ?</span>,

        //prelogin nudgetext
        WATCHLIST: <span>create your first watchlist</span>,
        HANDPICK: <span>tailored content of your choice</span>,
        SHARELIST: <span>create interesting sharelist with friends</span>
    }
}

export const HELPER_ACCORDION_TEXT = {
    FREE: {
        TOP_10_H:``,
        TOP_10: ``,
        COMING_SOON: ``,
        PLATFORM_H:`Platform Pioneers: Top Free Trends`,
        PLATFORM: `<p>Stay ahead of the curve with ‘trending free by platform’ at Frenzi. Discover the latest and most popular free movies and TV series on different streaming platforms.</p>

        <p>Our expert curation spotlights the trending and freely accessible content, categorized by platform. </p>
        
        <p>Don't miss out on what's hot and free on your preferred platforms. Join us at Frenzi to explore and stay updated with the trending and free content by platform.</p>`,
        LANGUAGE_H:`Language Leaders: Hot Free Picks`,
        LANGUAGE: `<p>Explore the latest trends in free entertainment across multiple languages with ‘trending free by language’ at Frenzi. Stay informed and discover what's captivating audiences in your preferred language.</p>

        <p>Our curated selection showcases the most popular free movies and TV series, sorted by language. </p>
        
        <p>Don't miss out on the hottest trends in your preferred language. Join us at Frenzi to discover and stay up-to-date with the most trending and free content by language. </p>`,
        GENRE_H:`Genre Giants: Hot Free Trends`,
        GENRE: `<p>Explore the latest trends in free entertainment by genre with ‘trending free by genre’ at Frenzi. Stay informed and discover what's captivating audiences in your favorite genres without any cost.</p>

        <p>Our curated selection showcases the most popular free movies and TV series by genre. </p>
        
        <p>Don't miss out on the hottest trends in your preferred genres, all available for free. Join us at Frenzi to explore and stay up-to-date with the trending and free content by genre. </p>`,
        FRESH_H: `Fresh Free Picks: Latest Media`,
        FRESH: `<p>Explore the latest and free content on OTT platforms with ‘fresh & free on OTT" at Frenzi. Stay updated and enjoy a selection of new and free movies and TV series.</p>

        <p>Our carefully curated collection has some of the freshest and freely accessible entertainment options across platforms. Whether you're a fan of budget-friendly binge-watching, exploring new releases, or simply looking for high-quality content without any extra cost, you'll find it here.</p>
        
        <p>Join us at Frenzi to discover and enjoy entertainment that's both fresh and easy on your wallet. </p>`,
        POPULAR_H:`Frenzi Favorites`,
        POPULAR: `<p>Discover the best of both worlds with ‘popular and free on Frenzi.’ Explore the most loved and free content that's capturing the hearts of our community.</p>

        <p>Our curated selection features top-rated movies and TV series that come at no cost. </p>
        
        <p>Don't miss out on what's popular among Frenzi users, all available for free. Join us to explore and stay up-to-date with the content that our community adores.</p>`,
        POPULAR_FR: ``,
        HIDDEN: ``,
    },
    PRIVATE: {
        TOP_10_H:`Top 10 Trending Media Picks`,
        TOP_10: `<p>Discover the hottest content on Frenzi this week with our 'top 10 on OTT' rankings. Stay updated with the trending movies and TV series that are making noises in the world of online streaming.</p>

        <p>Our carefully curated list showcases the must-watch titles that are capturing audience attention right now. Whether you're into thrilling blockbusters, captivating dramas, or binge-worthy series, our "Top 10 on OTT" has you covered.</p>
    
        <p>Don't miss out on what everyone's talking about. Join us on Frenzi to explore this week's top streaming picks, handpicked just for you.</p>`,
        COMING_SOON_H:`On the Horizon: Future Releases`,
        COMING_SOON: `<p>Get ready for the next wave of entertainment in the world of streaming with 'coming soon on OTT' at Frenzi. Turn on your notifications and stay ahead of the curve about the latest movies and TV series set to hit your favorite streaming platforms.</p>

        <p>Our sneak peek into upcoming releases will pique your curiosity.</p> 
    
        <p>Stay tuned to Frenzi for a preview of what's the next big thing in streaming!</p>`,
        PLATFORM_H:`Platform Pioneers: Top Trends`,
        PLATFORM: `<p>Discover the hottest content on your favorite streaming platforms with 'trending by platform' at Frenzi. Stay updated with the latest buzz and see what's capturing the hearts of viewers on each streaming service.</p>

        <p>Our curated section highlights the top trending movies and TV series, from specific platforms like Netflix, Amazon Prime Video, Hotstar, Voot, and more.</p> 
    
        <p>Don't miss out on what's trending on your preferred streaming platform. Join us at Frenzi to explore and stay in the loop with the most popular content by platform.</p> `,
        LANGUAGE_H:`Language Leaders: Hot Picks`,
        LANGUAGE: `<p>Explore the latest trends in entertainment across different languages with ‘trending by language’ at Frenzi. Stay in the know and discover what's gaining audience attention in your preferred language.</p>

        <p>Our curated selection showcases the most popular movies and TV series, trending and popular by language. </p>
    
        <p>Don't miss out on the hottest trends in your preferred language. Join us at Frenzi to explore and stay updated with the most trending content by language. It's your ticket to the world of entertainment, tailored to your linguistic preferences!</p>`,
        GENRE_H:`Genre Giants: Hot Trends`,
        GENRE: `<p>Dive into the latest entertainment trends with ‘trending by genre’ at Frenzi. Stay informed and explore what's captivating audiences in your favorite genres.</p>
        <p>Our carefully curated selection showcases the most popular movies and TV series, sorted by genre. </p>
    
        <p>Don't miss out on the hottest trends in your preferred genres. Join us at Frenzi to discover and stay up-to-date with the most trending content by genre. It's your gateway to the world of entertainment, customized to your genre preferences!</p>`,
        FRESH_H:`Fresh Picks: Latest Media`,
        FRESH: `<p>Discover the latest and freshly added movies and TV series on Frenzi! Never miss out on new releases or episodes of your favourite movies and series. Frenzi allows you to tailor your streaming experience to your preferences.</p>

        <p>Choose your preferred streaming platforms, genres, or languages with our user-friendly filters. Our Watchbar remembers your settings, making sure you're up to date with your choice of content.</p>`,
        POPULAR_H:`Frenzi Favorites`,
        POPULAR: `<p>Explore the most sought-after content on Frenzi with ‘popular on Frenzi’. Stay in the loop and discover what's capturing the attention of our community.</p>

        <p>Our curated selection highlights the top-rated movies and TV series that are currently making waves. </p>
    
        <p>Don't miss out on what's popular among Frenzi users. Join us to explore and stay up-to-date with the most loved and watched content on our platform. It's your gateway to the best of entertainment, as loved by our community!</p>`,
        POPULAR_FR_H:`Friendship Favorites`,
        POPULAR_FR: `<p>Discover what's trending among your friends with ‘popular among friends’ on Frenzi. Stay connected and explore the content that's capturing the interest of your social circle.</p>
    
        <p>Our curated selection showcases the movies and TV series that are highly rated and recommended by your peers. </p>
    
        <p>Don't miss out on what's popular in your social network. Join us at Frenzi to explore and stay in sync with the content that your friends are enjoying.</p>`,
        HIDDEN_H:`Gem Finds: Underrated Media`,
        HIDDEN: `<p>Unearth cinematic treasures with ‘hidden gems’ on Frenzi. Dive into a world of undiscovered, must-see movies and TV series that deserve the spotlight.</p>

        <p>Our handpicked selection unveils the hidden gems that often fly under the radar.</p> 
    
        <p>Don't miss the chance to watch these hidden treasures. Join us at Frenzi to explore and enjoy entertainment that's often overlooked but well worth your time.</p>`,
    }
}
