import { Button, Grid } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoginModal } from '../slices/global';

export default function CrouselReviewNudge(props) {

  const { heading, sub_heading, btn, image, contentText } = props.data;
  const global = useSelector(state => state.global);
  const dispatch = useDispatch();

  const handleCLick = () => {
    if (global.is_public) {
      dispatch(toggleLoginModal({ isOpen: 'true', content: contentText || null }));
    }
  }

  return (
    <Grid container className="bg-gradient br-8 p-20" sx={{ minHeight: '270px' }} justifyContent={'space-between'}>
      <Grid item>
        <Grid item>{heading || ''}</Grid>
        <Grid item>{sub_heading || ''}</Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className='h-100' justifyContent={{ xs: 'center', sm: 'space-between' }}>
          <Grid item sx={{ order: { xs: '1', sm: '-1' } }} xs={12} sm={'auto'}>
            <Grid container flexDirection={{ sm: 'column' }} className='h-100' justifyContent={{ xs: 'center', sm: 'flex-end' }}>
              <Grid item className='w-100' textAlign={{ xs: 'center', sm: 'left' }} >
                {btn ?
                  <Button onClick={() => handleCLick(contentText)} className="rounded-btn bg-light text-selected mw-160 pr-30 pl-30 w-100">{btn}</Button>
                  :
                  ""
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ order: { xs: '-1', sm: '1' } }} xs={12} sm={'auto'}>
            <Grid container flexDirection={{ sm: 'column' }} className='h-100' justifyContent={{ xs: 'center' }}>
              <Grid item sx={{ width: { sm: '100%' } }}> {image || ''} </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  )
}
