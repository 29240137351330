import { Grid, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SingleMediaTile from '../../common/SingleMediaTile';
import Loader from '../../common/Loader';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import SideFilter from '../../search/SideFilter';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Box } from '@mui/system';
import { getPopularTodayMovie, getPopularTodaySearched, getPopularTodayTv } from '../../../slices/trending';
import MyHelmet from '../../common/MyHelmet';
import MyAds from '../../adsense/MyAds';

const defaultFilter = {
    service: [],
    genre: [],
    language: [],
    content_type: [],
    exclude_already_watched: '',
    sort: '',
    apply_filter: false
}


export default function PrefergenreDetail() {
    const [tempFilter, setTempFilter] = useState(defaultFilter);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const is_free = searchParams.get('is_free') && searchParams.get('is_free') === 'true' ? true : false;

    const trending = useSelector(state => state.trending);
    const _global = useSelector(state => state.global);
    const { popularTodayTvPageData, popularTodayTvData, popularTodayMoviePageData, popularTodayMovieData, popularTodaySearchPageData, popularTodaySearchData, isLoading } = trending;

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    const handleClickListItem2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleSort = (val) => {
        setSort(val);
        if (page === 1) {
            getData(val, tempFilter);
        } else {
            setPage(1);
        }
        handleClose2();
    }

    useEffect(() => {
        getData(sort, tempFilter);
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        getData(sort, tempFilter);
    }, [page])

    const getData = (sort, filter = tempFilter) => {
        var body = {
            page: page,
            pagelimit: 20,
            // id: mid,
            sort: sort && sort !== '' ? sort : params.type === 'search' ? 's' : '',
            body: filter,
            is_free: is_free,
            is_public: _global.is_public
        }

        if (params && params.type && params.type !== '') {
            if (params.type === 'movie') {
                dispatch(getPopularTodayMovie({ ...body, media_type: 'movie' }));
            }
            if (params.type === 'tv') {
                dispatch(getPopularTodayTv({ ...body, media_type: 'tv' }));
            }
            if (params.type === 'search') {
                dispatch(getPopularTodaySearched({ ...body, media_type: 'all' }));
            }
        }
    }

    useEffect(() => {
        const currentPageData = params.type === 'movie' ? popularTodayMoviePageData : params.type === 'tv' ? popularTodayTvPageData : params.type === 'search' ? popularTodaySearchPageData : null;
        const handleScroll = () => {
            if (currentPageData && currentPageData.pagination && currentPageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, trending]);


    const handleApplyFilter = (filter) => {
        if (filter) {
            setTempFilter({ ...filter });
            if (params && params.type && params.type !== '') {
                if (page === 1) {
                    getData(sort);
                } else {
                    setPage(1);
                }
            }
            window.scrollTo(0, 0);
        }
    }

    return (
        <>
            <MyHelmet
                title={"popular list on frenzi "}
                description={'Discover trending content recommended by Frenzi. Explore our "Popular list on Frienzi" section for top recommendations from like minded moviebuffs'}
            />
            {isLoading && <Loader />}
            <Grid className='new-container pb-50'>
                <Grid container alignItems={'center'} className="pt-20" justifyContent='space-between'>
                    <Grid item>
                        <Grid container alignItems={'center'}>
                            <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                            <p className='fs-28 fw-700 lh-28 m-0'><span className='text-selected'>popular</span> today</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className='pt-10 pb-20' alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item xs={10}>
                        <Grid container alignItems={'center'}>
                            <Grid item className=''>
                                <Grid container onClick={handleClickListItem2} alignItems={'center'} className="pointer">
                                    <h2 className='fw-700 fs-15 m-0 mr-6 '>
                                        sort
                                    </h2>
                                    <SwapVertIcon />
                                </Grid>
                                <Popover
                                    id={'menu2'}
                                    open={open2}
                                    anchorEl={anchorEl2}
                                    onClose={handleClose2}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Box className='pb-20 pl-20 pr-20'>
                                        <Grid onClick={() => handleSort('d')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                            <h2 className={`fw-500 fs-16 m-0 ${sort === 'd' && 'text-selected'}`} >release date</h2>
                                        </Grid>
                                        <Grid onClick={() => handleSort('l')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                            <h2 className={`fw-500 fs-16 m-0 ${sort === 'l' && 'text-selected'}`} >popular</h2>
                                        </Grid>
                                        <Grid onClick={() => handleSort('r')} container className='mt-20 pointer' sm={12} justifyContent={'start'}>
                                            <h2 className={`fw-500 fs-16 m-0 ${sort === 'r' && 'text-selected'}`} >rating</h2>
                                        </Grid>
                                    </Box>
                                </Popover>
                            </Grid>
                        </Grid>
                    </Grid>
                    <SideFilter
                        onSearchApply={(e) => handleApplyFilter(e)}
                        hideClearBtn={true}
                    />
                </Grid>

                <Grid container flexWrap={'nowrap'}>
                    <Grid item style={{ maxWidth: '100%' }} flex={1}>
                        <Grid item xs={12} style={{ marginBottom: '10px' }} justifyContent={'center'}>
                            <Grid container justifyContent={'center'}>
                                <MyAds type="banner_2" adv_type={'web_popular_top_ad'} hideInMobile={true} />
                            </Grid>
                        </Grid>

                        {params.type === 'movie' && popularTodayMovieData && popularTodayMovieData.length > 0 &&
                            <Grid container spacing={2}>
                                {popularTodayMovieData.map((x, i) => {
                                    return <>
                                        {(i !== 0 && i % 2 === 0 && i % 3 === 0) &&
                                            <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                                                <Grid container justifyContent={'center'}>
                                                    <MyAds type="banner_2" adv_type={'web_media_listing_ad'} />
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid item className='popular-today-items' key={i} xs={12} sm={6} >
                                            <SingleMediaTile refPage={'Popular on Frenzi'} x={x} />
                                        </Grid>
                                    </>
                                })}
                            </Grid>
                        }
                        {params.type === 'tv' && popularTodayTvData && popularTodayTvData.length > 0 &&
                            <Grid container spacing={2}>
                                {popularTodayTvData.map((x, i) => {
                                    return <>
                                        {(i !== 0 && i % 2 === 0 && i % 3 === 0) &&
                                            <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                                                <Grid container justifyContent={'center'}>
                                                    <MyAds type="banner_2" adv_type={'web_media_listing_ad'} />
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid item className='popular-today-items' key={i} xs={12} sm={6} >
                                            <SingleMediaTile refPage={'Popular on Frenzi'} x={x} />
                                        </Grid>
                                    </>
                                })}
                            </Grid>
                        }
                        {params.type === 'search' && popularTodaySearchData && popularTodaySearchData.length > 0 &&
                            <Grid container spacing={2}>
                                {popularTodaySearchData.map((x, i) => {
                                    return <>
                                        {(i !== 0 && i % 2 === 0 && i % 3 === 0) &&
                                            <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                                                <Grid container justifyContent={'center'}>
                                                    <MyAds type="banner_2" adv_type={'web_media_listing_ad'} />
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid item className='popular-today-items' key={i} xs={12} sm={6} >
                                            <SingleMediaTile refPage={'Popular on Frenzi'} x={x} />
                                        </Grid>
                                    </>
                                })}
                            </Grid>
                        }
                    </Grid>
                    <Grid item sx={{ display: { xs: 'none', lg: 'block' } }} className="sticky-ads">
                        <MyAds type={'banner_1'} adv_type={'web_popular_right_ad'} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

