import { Typography } from "@mui/material";
import React, { useState } from "react";

export default function MediaOverView(props) {
  const { text, max, fullHeight, noShort } = props;
  const [isShow, setIsShow] = useState(false);
  const maxLength = max && max !== "" ? max : 100;

  return (
    <Typography className={`fs-16 ${fullHeight ? '' : 'mh-70'} fw-500 ${props.className}`} sx={{ overflowY: 'auto' , textAlign:'left'}}>
      {text && text.length > 0 && !noShort && !isShow ? (
        <>
          {text && text.length > maxLength ? (
            <>
              {text.substring(0, maxLength)}
              <span
                onClick={() => setIsShow(true)}
                className="pointer text-selected"
              >
                {" "}
                ...more
              </span>
            </>
          ) : (
            <>{text}</>
          )}
        </>
      ) : (
        <>
          {text}
          {props.isLess && (
            <span
              onClick={() => setIsShow(false)}
              className="pointer text-selected"
            >
              {" "}
              less
            </span>
          )}
        </>
      )}
    </Typography>
  );
}
