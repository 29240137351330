import { Button, Modal } from "@mui/material";
import React, { useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box } from "@mui/system";
import ReactPlayer from "react-player";
import helpers from "../../services/helpers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "transparent",
  boxShadow: 24,
  borderRadius: 2,
  zIndex: 999,
};

export default function MediaTrailerBtn(props) {
  const { data, controls, isPlay, color, mediaData, from, sx } = props;
  const [videoPopUp, setVideoPopUp] = useState(false);

  return (
    <>
      {from === 'media' ?
        <Button
          sx={{ color: color ? color : "#000" ,textTransform: 'none', width:'100%'}}
          onClick={() => setVideoPopUp(true)}
          className="trailer-button bg-light br-30 fs-16 lh-16 fw-600 mb-15"
          endIcon={<PlayArrowIcon className="text-dark" style={{ fontSize: '30px' }} />}
        >
          trailer
        </Button>
        :
        <Button
          sx={{ color: color ? color : "#000", textTransform: 'none', ...sx , width:'100%'}}
          onClick={() => setVideoPopUp(true)}
          className="trailer-button fs-16 lh-16 fw-600"
        >
          trailer
          <PlayArrowIcon style={{ fontSize: '20px' }} />
        </Button>
      }
      <div>
        <Modal
          open={videoPopUp}
          onClose={() => setVideoPopUp(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className='text-center'>
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${data?.key}`}
              style={{ width: '100%', height: 'auto', maxWidth: '95vw' }}
              controls={controls}
              playing={isPlay}
              onPlay={() => {
                let myData = mediaData;
                let trackData = {
                  title: myData?.title,
                  type: myData?.media_type,
                  ref_page: props?.ref_page,
                  ref_url: document?.referrer,
                }
                helpers.trackMoengageEvent('FR3_Trailer', trackData)
              }}
            />
          </Box>
        </Modal>
      </div>
    </>
  );
}
