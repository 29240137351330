import axiosInstance from "../http-common";

const getUserDetails = () => {
    return axiosInstance.get(`native_main/userdetails`);
};

const getOtherUserDetails = (id) => {

    return axiosInstance.get(`native_main/profiledashboard${id ? '?user=' + id : ''}`);
};

const getFrenziPointSummary = (date_range = "last_30_days", page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/frenzipointssummary?page=${page}&pagelimit=${pagelimit}&date_range=${date_range}`);
};

const getOrderDetails = (page = 1, pagelimit = 10) => {
    return axiosInstance.get(`subscription/subscriptionlistviainvoice?page=${page}&pagelimit=${pagelimit}`);
};

const getMutedUsers = (page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/usermute?page=${page}&pagelimit=${pagelimit}`);
};

const getReportedUsers = (page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/userreport?page=${page}&pagelimit=${pagelimit}`);
};

const getBlockedUsers = (page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/userblock?page=${page}&pagelimit=${pagelimit}`);
};

const deleteUserAccount = (body) => {
    return axiosInstance.post(`native_main/deleteuseraccount`, body);
};

const deleteUserAccountOtp = (body) => {
    return axiosInstance.post(`native_main/deleteuseraccountotp`, body);
};

const submitFeedback = (body) => {
    return axiosInstance.post(`native_main/submitfeedback`, body);
};

const changeProfileType = (body) => {
    return axiosInstance.put(`native_main/changeprofiltype`, body);
};

const changeAllowPosts = (body) => {
    return axiosInstance.put(`native_main/allowposts`, body);
};

const getFaqsList = (page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/faqlist?page=${page}&pagelimit=${pagelimit}`);
};

const getPollSection = (section = "", page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/userpolllist?page=${page}&pagelimit=${pagelimit}&section=${section}`);
};

const getQuizSection = (section = "", page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/userquizlist?page=${page}&pagelimit=${pagelimit}&section=${section}`);
};

const updateUserDetails = (body) => {
    return axiosInstance.put(`native_main/editprofile`, body);
};

const updateUserName = (body) => {
    return axiosInstance.post(`native_main/changeusername`, body);
};

const updatePhoneEmail = (body) => {
    return axiosInstance.post(`native_main/verificationotpv3`, body);
};

const verifyOtp = (body) => {
    return axiosInstance.post(`native_main/otpverificationv3`, body);
};


const clearSearchHistory = () => {
    return axiosInstance.get(`native_main/clearallsearchhistory`);
};

const getUserRecommendedData = (category = '', user = "", page = 1, pagelimit = 20) => {
    let url = `native_main/userrecommended?page=${page}&pagelimit=${pagelimit}`;
    if (user && user != "") {
        url += `&user=${user}`;
    }
    if (category && category != "") {
        url += `&category=${category}`;
    }
    return axiosInstance.get(url);
};


const getFrenziUserAllReviews = (comment_type = '', user = "", page = 1, pagelimit = 20) => {
    let url = `native_main/frenziallreviews?page=${page}&pagelimit=${pagelimit}&category=single`;
    if (user && user != "") {
        url += `&target_user=${user}`;
    }
    if (comment_type && comment_type != "") {
        url += `&comment_type=${comment_type}`;
    }
    return axiosInstance.get(url);
};

const getMyAllReviews = (comment_type = '', page = 1, pagelimit = 20) => {
    let url = `native_main/myreviews?page=${page}&pagelimit=${pagelimit}`;
    if (comment_type && comment_type != "") {
        url += `&comment_type=${comment_type}`;
    }
    return axiosInstance.get(url);
};

const getUserWatchlist = (privacy = '', sort = '', user = "", page = 1, pagelimit = 20) => {
    let url = `native_main/watchlistcreated?page=${page}&pagelimit=${pagelimit}`;
    if (user && user != "") {
        url += `&user=${user}`;
    }
    if (privacy && privacy != "") {
        url += `&privacy=${privacy}`;
    }
    if (sort && sort != "") {
        url += `&sort=${sort}`;
    }
    return axiosInstance.get(url);
};

const getCommonUserData = (uuid) => {
    return axiosInstance.get(`native_main/profilecommons?user=${uuid}`);
};

const blockUser = (body) => {
    return axiosInstance.post(`native_main/userblock`, body);
};

const reportUser = (body) => {
    return axiosInstance.post(`native_main/userreport`, body);
};

const getNotifications = (section = 'remaining', page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/notificationcenter?section=${section}&page=${page}&pagelimit=${pagelimit}`);
};

const readNotification = (body) => {
    return axiosInstance.post(`native_main/readnotification`, body);
};


const deleteNotification = (body) => {
    return axiosInstance.post(`native_main/deletenotification`, body);
};

const acceptRequest = (body) => {
    return axiosInstance.post(`native_main/acceptrequest`, body);
};

const declineRequest = (body) => {
    return axiosInstance.post(`native_main/declinerequest`, body);
};

const deleteReveiw = (id) => {
    return axiosInstance.delete(`native_main/reviewdetail/${id}`);
};

const updateReview = (id, body) => {
    return axiosInstance.put(`native_main/reviewdetail/${id}`, body);
};

const getUnreadNotifications = (id, body) => {
    return axiosInstance.get(`native_main/unreadnotifications`);
};

const SettingsDataService = {
    getUserDetails,
    getOtherUserDetails,
    getFrenziPointSummary,
    getOrderDetails,
    getMutedUsers,
    getReportedUsers,
    getBlockedUsers,
    deleteUserAccount,
    deleteUserAccountOtp,
    submitFeedback,
    changeProfileType,
    changeAllowPosts,
    getFaqsList,
    getPollSection,
    getQuizSection,
    updateUserDetails,
    updateUserName,
    getUserRecommendedData,
    getMyAllReviews,
    getFrenziUserAllReviews,
    getUserWatchlist,
    getCommonUserData,
    blockUser,
    reportUser,
    getNotifications,
    readNotification,
    deleteNotification,
    acceptRequest,
    declineRequest,
    deleteReveiw,
    updateReview,
    clearSearchHistory,
    updatePhoneEmail,
    verifyOtp,
    getUnreadNotifications

};

export default SettingsDataService;