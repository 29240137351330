import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import helpers from '../../services/helpers';
import { toggleActiveVideo } from '../../slices/global';

function MediaPlayer(props) {
    const [currentPath, setCurrentPath] = useState('');
    const [playing, setPlay] = useState(false);
    const dispatch = useDispatch();
    const global = useSelector(state => state.global);
    const currentVidRef = useRef();

    useEffect(() => {
        if (props.src && props.src !== "") {
            getPresignedUrl(props.src)
        }
    }, [props.src])

    const getPresignedUrl = async (path) => {
        if (path && path !== "") {
            const url = await helpers.getPresignedUrl(path);
            if (url) {
                setCurrentPath(url);
            }
        }
    }

    useEffect(() => {
        if (props.type === "video" && currentVidRef?.current) {
            if (global.activeVideo === props?.id) {
                setPlay(true);
                currentVidRef.current.play();
            } else {
                currentVidRef.current.pause();
                setPlay(false);
            }
        }
    }, [global.activeVideo])

    const handlePlayPause = () => {
        var isPlay = global.activeVideo !== props?.id ? false : true;
        dispatch(toggleActiveVideo({ value: isPlay ? '' : props?.id }));
    };

    useEffect(() => {
        console.log("currentPath-changed");
    }, [currentPath])

    return (
        <div id={props?.id}>
            {
                props.type === "text" &&
                <h6>
                    {props.src}
                </h6>
            }
            {/* {(props.type == "video") && (currentPath != "") &&
                <ReactPlayer
                    id={props?.id + "-player"} width={'100%'}
                    style={{ maxWidth: 220, maxHeight: props.type == "audio" ? 50 : 140 }}
                    controls={true}
                    url={currentPath}
                    playing={playing}
                    onPlay={() => { if (!playing) { handlePlayPause(); } }}
                    onPause={() => { if (playing) { handlePlayPause(); } }}
                />
            } */}
            {(props.type === "video") && (currentPath !== "") &&
                <video
                    src={currentPath}
                    preload="auto"
                    ref={currentVidRef}
                    controls="true"
                    style={{ width: '100%', maxHeight: '133px', height: '133px' }}
                    onPlay={() => { if (!playing) { handlePlayPause(); } }}
                    onPause={() => { if (playing) { handlePlayPause(); } }}
                >
                </video>
            }
        </div>
    )
}

export default MediaPlayer
