import { Box, Button, Grid, Popover } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  clearMessages,
  getFeaturedReview,
  getFrenziAllReviews,
} from "../../slices/media";
import Loader from "../common/Loader";
import FeaturedReview from "./FeaturedReview";
import TextReviews from "./TextReviews";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import helpers from "../../services/helpers";
import MyHelmet from "../common/MyHelmet";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';


const category = 'all';
const activeSection = "text";

export default function ReviewInnerPage() {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("newest");
  // const [activeSection, setActiveSection] = useState("all");
  const params = useParams();
  const dispatch = useDispatch();
  const media = useSelector((state) => state.media);
  const [searchParams] = useSearchParams();
  var r_id = searchParams.get("id");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (type) => {
    setPage(1);
    setSort(type);
    handleClose();
  };

  useEffect(() => {
    if (media.errMesg && media.errMesg != "") {
      helpers.toastError(media.errMesg);
      dispatch(clearMessages());
    }
    if (media.successMesg && media.successMesg != "") {
      helpers.toastSuccess(media.successMesg);
      dispatch(clearMessages());
    }
  }, [media]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getFeaturedReview({
        media: r_id && r_id != "" ? r_id : "",
        comment_type: activeSection,
        unique_title: params?.unique_title
      })
    );
  }, []);

  useEffect(() => {
    if (category && category != "") {
      dispatch(
        getFrenziAllReviews({
          media: r_id && r_id != "" ? r_id : "",
          category: category,
          comment_type: activeSection,
          page: page,
          pagelimit: 10,
          sort: sort,
          unique_title: params?.unique_title
        })
      );
    }
  }, [page, params, sort, activeSection]);

  useEffect(() => {
    const handleScroll = () => {
      if (media.frenziAllReviewPageData && media.frenziAllReviewPageData['text'] && media.frenziAllReviewPageData['text'].pagination && media.frenziAllReviewPageData['text'].pagination.is_next_page && !media.isLoading) {
        if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
          setPage(page + 1);
          console.log('loading....');
        }
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page, media]);

  console.log("hhh-", media.frenziAllReviewData)

  return (
    <div>
      <MyHelmet
        title={`${category} reviews`}
        description={`Uncover the Power of Reviews: Make Informed Choices with ${category} Reviews on Our App!`}
      />
      {media.isLoading && <Loader />}
      {/* <CommonMenu
        menuList={menuList}
        tabValue={activeSection}
        setTabValeu={(val) => setActiveSection(val)}
        isBack={true}
      /> */}
      <Grid item className="new-container pl-80 pt-80 pb-20">
        <Button className="p-0">
          <ArrowBackRoundedIcon onClick={() => navigate(-1)} />
        </Button>
      </Grid>
      <Grid
        container
        alignItems={"center"}
        className="new-container pt-10 pb-20"
        justifyContent={"flex-start"}
      >
        <Grid item className="pointer" onClick={handleClickListItem}>

          <Grid container alignItems={"center"} flexWrap={"nowrap"}>
            <p className="fw-700 fs-15 m-0 mr-6 ">sort</p>
            <SwapVertIcon />
          </Grid>
        </Grid>
        <Popover
          id={"menbu"}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box className="pl-20 pr-20">
            <Grid
              onClick={() => handleSortChange("newest")}
              container
              className="pt-10 pb-0 pointer"
              sm={12}
              justifyContent={"start"}
            >
              <p
                className={`fw-500 fs-16 m-0 ${sort == "newest" && "text-selected"
                  }`}
              >
                Newest
              </p>
            </Grid>
            <Grid
              onClick={() => handleSortChange("popular")}
              container
              className="pt-10 pb-10 pointer"
              sm={12}
              justifyContent={"start"}
            >
              <p
                className={`fw-500 fs-16 m-0 ${sort == "popular" && "text-selected"
                  }`}
              >
                Popular
              </p>
            </Grid>
          </Box>
        </Popover>
      </Grid>
      <div className="new-container">
        {media.featuredReviewData && media.featuredReviewData.length > 0 && (
          <FeaturedReview data={media.featuredReviewData} />
        )}
        {activeSection == "text" &&
          media.frenziAllReviewData &&
          media.frenziAllReviewData["text"] &&
          media.frenziAllReviewData["text"].length > 0 && (
            <TextReviews
              media_id={r_id ? r_id : ""}
              category={category}
              data={media.frenziAllReviewData["text"]}
              isDetailPage={true}
              isLoading={media.isLoading}
            />
          )
        }
      </div>
    </div>
  );
}
