import { Box, Grid, Typography, duration } from '@mui/material'
import React, { useEffect } from 'react'
import Stories from 'react-insta-stories';
import { useDispatch } from 'react-redux';
import { webStoriesReelList } from '../../slices/blog';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

const NavLogo = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/frenziLogo1.svg";



export default function WebReelData() {

    const dispatch = useDispatch();
    const [reelUniqueTitle, setReelUniqueTitle] = useState([])

    useEffect(() => {
        dispatch(webStoriesReelList())
    }, [])

    const params = useParams();

    useEffect(() => {
        var mid = params?.unique_title || null;
        if (params?.unique_title !== '') {
            var body = {
                unique_title: params?.unique_title,
            };
            dispatch(webStoriesReelList(body)).then((resp) => {
                if (resp.payload?.data) {
                    // console.log("aman", resp.payload?.data.data)
                    setReelUniqueTitle(resp.payload?.data.data.results)
                }
            });
        }
    }, [params]);

    console.log("amanreel", reelUniqueTitle)

    const getStories = (data) => {
        if (data && data.length > 0) {
            return data.map((x, i) => {
                return {
                    url: x.s3_image_url,
                    header: {
                        heading: x.title,
                        subheading: x.description,
                        profileImage: NavLogo,
                    },
                    
                    
                    // content:()=>{
                    //     return(
                    //         <div style={{position:'absolute'}}>
                    //             <h1>aman</h1>
                    //         </div>
                    //     )
                    // },
                }
            })
        } else {
            const stories = [
                'https://www.kasandbox.org/programming-images/avatars/spunky-sam.png',
                ' https://www.kasandbox.org/programming-images/avatars/spunky-sam-green.png',
                ' https://www.kasandbox.org/programming-images/avatars/purple-pi.png',
                'https://www.kasandbox.org/programming-images/avatars/purple-pi-teal.png',
            ];
            return stories
        }
    }


    return (
        <>
            <Grid container justifyContent='center'>
                <Grid item className='myStories'>
                    <>
                        <Stories stories={getStories(reelUniqueTitle)} keyboardNavigation={true} loop={true} defaultInterval={10000} />
                        {/* <h1>aman</h1> */}
                        {/* <Stories stories={storiesData} loop={true} /> */}
                    </>
                </Grid>
            </Grid>
        </>
    )
}
// const storiesData = [
//     {
//         id: 1,
//         url: 'https://www.kasandbox.org/programming-images/avatars/spunky-sam.png',
//         header: {
//             heading: 'Mohit Karekar',
//             subheading: 'Posted 32m ago',
//             profileImage: 'https://picsum.photos/1080/1920'
//         }
//     },
//     { id: 2, title: 'Node.js Security Update', url: ' https://www.kasandbox.org/programming-images/avatars/spunky-sam-green.png' },
//     { id: 3, title: 'New Features in JavaScript', url: ' https://www.kasandbox.org/programming-images/avatars/purple-pi.png' },
// ];
