import { Button, Drawer, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { getAllPreferences, getGenreList, getLanguageList, getPlatformList } from '../../slices/auth';
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';

const currentPage = 1;
const defaultFilter = {
    service: [],
    genre: [],
    language: [],
    content_type: [],
    exclude_already_watched: '',
    sort: '',
    apply_filter: false
}

export default function SideFilter(props) {
    const { iconClassName, tempFilter, hideClearBtn, isPartner } = props;
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [searchMediaFilter, setSearchMediaFilter] = useState(defaultFilter);

    const dispatch = useDispatch();
    const [accordianToggle, setAccordianToggle] = useState(
        {
            content: true,
            platform: false,
            lang: false,
            genre: false,
        }
    )

    const auth = useSelector(state => state.auth);
    const _global = useSelector(state => state.global);

    useEffect(() => {
        if (tempFilter && tempFilter.service && tempFilter.genre) {
            setSearchMediaFilter({ ...tempFilter })
        }
    }, [tempFilter])

    useEffect(() => {
        if (_global.is_public) {
            dispatch(getAllPreferences({ is_public: _global.is_public }));
        } else {
            dispatch(getLanguageList());
            dispatch(getPlatformList({ page: currentPage, pagelimit: 100 }));
            dispatch(getGenreList());
        }
    }, [])

    function toggle(type) {
        const tempArr = ['content', 'platform', 'lang', 'genre'];
        const index = tempArr.indexOf(type);
        tempArr.splice(index, 1);
        const temp = {
            [tempArr[0]]: false,
            [tempArr[1]]: false,
            [type]: !accordianToggle[type]
        }
        setAccordianToggle({ ...temp });
    }

    useEffect(() => {
        if (searchMediaFilter) {
            let count = 0;
            if (searchMediaFilter.content_type.length > 0) {
                count += 1;
            }
            if (searchMediaFilter.genre.length > 0) {
                count += 1;
            }
            if (searchMediaFilter.language.length > 0) {
                count += 1;
            }
            if (searchMediaFilter.service.length > 0) {
                count += 1;
            }
            setFilterCount(count);
        }
    }, [searchMediaFilter])

    const handleSelectBoxChange = (name, val, checked) => {
        if (checked) {
            let tempArr = searchMediaFilter[name];
            setSearchMediaFilter({ ...searchMediaFilter, [name]: [...tempArr, val] })
        } else {
            let tempArr = searchMediaFilter[name];
            let index = tempArr.indexOf(val);
            let newArr = [...tempArr];
            newArr.splice(index, 1);
            setSearchMediaFilter({ ...searchMediaFilter, [name]: newArr })
        }
    }

    const handleClearAllSearches = () => {
        setFilterOpen(!filterOpen);
        setSearchMediaFilter({ ...defaultFilter });
        props.onSearchApply(defaultFilter);
    }

    const handleChangeSeenType = (val) => {
        setSearchMediaFilter({ ...searchMediaFilter, ['exclude_already_watched']: val ? 'yes' : 'no' })
    }

    const handleSearchApply = () => {
        setFilterOpen(!filterOpen);
        props.onSearchApply({ ...searchMediaFilter, ['apply_filter']: true });
    }

    return (
      <>
        <Grid item className="relative pointer">
          {isPartner ? (
            <TuneRoundedIcon
              onClick={() => {
                setFilterOpen(true);
                helpers.trackMoengageEvent("FR3_Find", {
                  filter_click: MOENGAGE_ATTRIBUTE.YES,
                  ref_page: document.referrer,
                });
              }}
            />
          ) : (
            <TuneRoundedIcon
              sx={{
                width: { xs: "30px", sm: "40px" },
                height: { xs: "17px", sx: "30px" },
              }}
              onClick={() => {
                setFilterOpen(true);
                helpers.trackMoengageEvent("FR3_Find", {
                  filter_click: MOENGAGE_ATTRIBUTE.YES,
                  ref_page: document.referrer,
                });
              }}
              className={`${iconClassName || ""} pointer ${
                filterCount && filterCount > 0 && "text-selected"
              }`}
            />
          )}
          {filterCount && filterCount > 0 ? (
            <span className="filter-count-show bg-selected pointer">
              {filterCount}
            </span>
          ) : (
            ""
          )}
        </Grid>
        <Drawer
          anchor={props.anchor ? props.anchor : "right"}
          open={filterOpen}
          onClose={() => setFilterOpen(!filterOpen)}
        >
          <Grid
            container
            className="h-100 search-filter-drawer-content"
            justifyContent={"space-between"}
            flexDirection="column"
            flexWrap={"nowrap"}
            sx={{ minWidth: { xs: 250, sm: 320, md: 350 } }}
          >
            <Grid item>
              <Accordion expanded={accordianToggle.content}>
                <AccordionSummary
                  onClick={() => toggle("content")}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="fs-16 fw-600">
                    Content Type{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ maxHeight: 300, overflowY: "auto" }}>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item>
                      <Typography className="fs-15 fw-500">Movies</Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        className="m-0"
                        control={
                          <Checkbox
                            className="pt-0 text-selected"
                            checked={searchMediaFilter.content_type.includes(
                              "movie"
                            )}
                            onChange={(e) =>
                              handleSelectBoxChange(
                                "content_type",
                                "movie",
                                e.target.checked
                              )
                            }
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item>
                      <Typography className="fs-15 fw-500">
                        Webseries
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        className="m-0 text-selected"
                        control={
                          <Checkbox
                            className="pt-0"
                            checked={searchMediaFilter.content_type.includes(
                              "tv"
                            )}
                            onChange={(e) =>
                              handleSelectBoxChange(
                                "content_type",
                                "tv",
                                e.target.checked
                              )
                            }
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={accordianToggle.platform}>
                <AccordionSummary
                  onClick={() => toggle("platform")}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="fs-16 fw-600">Platform</Typography>
                </AccordionSummary>
                {auth.platformList &&
                  auth.platformList.results &&
                  auth.platformList.results.length > 0 && (
                    <AccordionDetails
                      style={{ maxHeight: 300, overflowY: "auto" }}
                    >
                      {auth.platformList.results.map((x, i) => {
                        return (
                          <Grid
                            key={i}
                            container
                            justifyContent={"space-between"}
                          >
                            <Grid item>
                              <Typography className="fs-15 fw-500">
                                {x.ott_name ? x.ott_name : ""}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Checkbox
                                className="text-selected"
                                checked={searchMediaFilter.service.includes(
                                  x.code
                                )}
                                onChange={(e) =>
                                  handleSelectBoxChange(
                                    "service",
                                    x.code,
                                    e.target.checked
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </AccordionDetails>
                  )}
              </Accordion>

              <Accordion expanded={accordianToggle.lang}>
                <AccordionSummary
                  onClick={() => toggle("lang")}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="fs-16 fw-600">Languages</Typography>
                </AccordionSummary>

                {auth.languageList &&
                  auth.languageList.languages &&
                  auth.languageList.languages.length > 0 && (
                    <AccordionDetails
                      style={{ maxHeight: 300, overflowY: "auto" }}
                    >
                      {auth.languageList.languages.map((x, i) => {
                        return (
                          <Grid
                            key={i}
                            container
                            justifyContent={"space-between"}
                          >
                            <Grid item>
                              <Typography className="fs-15 fw-500">
                                {x.language ? x.language : ""}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Checkbox
                                className="text-selected"
                                checked={searchMediaFilter.language.includes(
                                  x.code
                                )}
                                onChange={(e) =>
                                  handleSelectBoxChange(
                                    "language",
                                    x.code,
                                    e.target.checked
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </AccordionDetails>
                  )}
              </Accordion>

              <Accordion expanded={accordianToggle.genre}>
                <AccordionSummary
                  onClick={() => toggle("genre")}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="fs-16 fw-600">Genres</Typography>
                </AccordionSummary>

                {auth.genreList &&
                  auth.genreList.genres &&
                  auth.genreList.genres.length > 0 && (
                    <AccordionDetails
                      style={{ maxHeight: 300, overflowY: "auto" }}
                    >
                      {auth.genreList.genres.map((x, i) => {
                        return (
                          <Grid
                            key={i}
                            container
                            justifyContent={"space-between"}
                          >
                            <Grid item>
                              <Typography className="fs-15 fw-500">
                                {x.genre ? x.genre : "N/A"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Checkbox
                                className="text-selected"
                                checked={searchMediaFilter.genre.includes(
                                  x.genre.toLowerCase()
                                )}
                                onChange={(e) =>
                                  handleSelectBoxChange(
                                    "genre",
                                    x.genre.toLowerCase(),
                                    e.target.checked
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </AccordionDetails>
                  )}
              </Accordion>

              <div
                className="mt-10 mb-20"
                style={{ paddingRight: 16, paddingLeft: 16 }}
              >
                {!_global.is_public && (
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <Typography className="fs-14 mr-20 fw-500">
                        Hide Already Seen?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Checkbox
                        checked={
                          searchMediaFilter.exclude_already_watched == "yes"
                            ? true
                            : false
                        }
                        className="text-selected"
                        onChange={(e) => handleChangeSeenType(e.target.checked)}
                      />
                    </Grid>
                  </Grid>
                )}
                {!hideClearBtn && (
                  <Grid container justifyContent={"center"}>
                    <Button
                      onClick={() => handleClearAllSearches()}
                      className="rounded-btn w-100 bg-selected text-light"
                    >
                      clear all filters
                    </Button>
                  </Grid>
                )}
              </div>
            </Grid>

            <Grid
              item
              sx={{ paddingBottom: { xs: "50px", sm: "10px" } }}
              className="pr-20 pl-20"
            >
              <Button
                onClick={() => handleSearchApply()}
                className="rounded-btn w-100 bg-selected text-light"
              >
                apply
              </Button>
              <Button
                onClick={() => setFilterOpen(!filterOpen)}
                className="rounded-btn mt-20 w-100 bg-light text-selected"
              >
                cancel
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </>
    );
}
