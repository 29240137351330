import { Grid, Popover } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SingleMediaTile from "../../common/SingleMediaTile";
import {
  getPlatformMediaMovie,
  getPlatformMediaSearched,
  getPlatformMediaTv,
} from "../../../slices/trending";
import Loader from "../../common/Loader";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SideFilter from "../../search/SideFilter";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Box } from "@mui/system";
import MyHelmet from "../../common/MyHelmet";
import MyAds from "../../adsense/MyAds";
import MediaDetailLoader from "../../common/MediaDetailLoader";
import { isMobileDevice } from "../../../services/Utils";

const defaultFilter = {
  service: [],
  genre: [],
  language: [],
  content_type: [],
  exclude_already_watched: "",
  sort: "",
  apply_filter: false,
};

export default function TrendingAllData() {
  const [tempFilter, setTempFilter] = useState(defaultFilter);
  const [selectedPlatformName, setSelectedPlatformName] = useState("");

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const _global = useSelector((state) => state.global);

  const trending = useSelector((state) => state.trending);
  const {
    platformMovieData,
    platformMoviePageData,
    platformTvData,
    platformTvPageData,
    platformMediaSearchedData,
    platformMediaSearchedPageData,
    isLoading,
  } = trending;
  const [searchParams] = useSearchParams();
  const params = useParams();

  const platformListShow =
    auth.platformList?.results &&
      auth.platformList.results.length > 0 &&
      !_global.is_public
      ? auth.platformList.results
      : auth.allPreferences?.platforms &&
        auth.allPreferences.platforms.length > 0
        ? auth.allPreferences.platforms
        : [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const is_free_check = searchParams.get("is_free");
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClickListItem2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleSort = (val) => {
    setSort(val);
    if (page === 1) {
      getData(val, tempFilter);
    } else {
      setPage(1);
    }
    handleClose2();
  };

  useEffect(() => {
    var lang = searchParams.get("lang");
    var genre = searchParams.get("genre");
    var platform = searchParams.get("platform");

    let temp = tempFilter;

    if (lang && lang != '') {
      temp = {
        ...temp,
        language: [lang]
      }
      setTempFilter({ ...temp });
    }

    if (genre && genre != '') {
      temp = {
        ...temp,
        genre: [genre]
      }
      setTempFilter({ ...temp });
    }

    if (platform && platform != '') {
      temp = {
        ...temp,
        service: [platform]
      }
      setTempFilter({ ...temp });
    }

    getData(sort, temp);
    window.scrollTo(0, 0);
  }, [params, auth.platformList]);

  useEffect(() => {
    var platform = params.platform.replace(/-/g, " ");
    if (platform == 'Prime Video') {
      platform = 'Amazon Prime Video';
    }
    let pname = platformListShow.filter(x => x.ott_name === platform)[0] || null;
    if (pname && pname != "") {
      if (
        auth.platformList &&
        auth.platformList.results &&
        auth.platformList.results.length > 0
      ) {
        auth.platformList.results.map((p, k) => {
          if (pname == p.ott_name) {
            setSelectedPlatformName(p?.ott_name);
            // setSelectedPlatformName(p?.ott_name.toLowerCase());
            return true;
          }
        });
      }
    }
  }, [params, auth.platformList]);

  const getData = (sort, filter = tempFilter) => {
    var is_free = searchParams.get("is_free");

    var platform = params.platform.replace(/-/g, " ");
    if (platform == 'Prime Video') {
      platform = 'Amazon Prime Video';
    }
    let pname = platformListShow.filter(x => x.ott_name === platform)[0] || null;
    if (!pname) {
      return;
    }

    var body = {
      page: page,
      pagelimit: 20,
      id: pname?.uuid || '',
      sort: sort,
      body: filter,
      is_public: _global.is_public,
      is_free: !is_free || is_free == "false" ? false : true,
    };

    if (params && params.type && params.type !== "") {
      if (params.type === "trending-movies") {
        dispatch(getPlatformMediaMovie({ ...body, media_type: "movie" }));
      }
      if (params.type === "trending-series") {
        dispatch(getPlatformMediaTv({ ...body, media_type: "tv" }));
      }
      if (params.type === "most-searched" || (params.type !== "trending-movies" && params.type !== "trending-series")) {
        dispatch(
          getPlatformMediaSearched({ ...body, media_type: "all" })
        );
      }
    }
  };

  const handleApplyFilter = (filter) => {
    if (filter) {
      setTempFilter({ ...filter });
      if (params && params.type && params.type != "") {
        if (page == 1) {
          getData(sort, filter);
        } else {
          setPage(1);
        }
      }
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (page > 1) {
      getData(sort, tempFilter);
    }
  }, [page]);

  useEffect(() => {
    const currentPageData =
      params.type == "trending-movies"
        ? platformMoviePageData
        : params.type == "trending-series"
          ? platformTvPageData
          : params.type == "most-searched"
            ? platformMediaSearchedPageData
            : platformMediaSearchedPageData;
    const handleScroll = () => {
      if (
        currentPageData &&
        currentPageData &&
        currentPageData.pagination &&
        currentPageData.pagination.is_next_page &&
        !isLoading
      ) {
        if (
          window.innerHeight + window.scrollY + 500 >=
          document.body.offsetHeight
        ) {
          setPage(page + 1);
        }
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page, trending]);

  const selectedPlatformOttName = params?.platform;

  const getPtype = (p) => {
    let res = '';
    res = p.type === "trending-series" ? 'Web Series' : 'Movies';
    return res;
  };

  const pType = useMemo(() => getPtype(params), [params]);


  return (
    <>
      <MyHelmet
        title={`Trending ${selectedPlatformOttName} ${pType} this Week`}
        description={`Stay updated with the trending ${pType} on ${selectedPlatformOttName} this week. Discover your next favorite movie with MyFrenzi.`}
        og_title={`This Week's Top Trending ${pType} on ${selectedPlatformOttName} - MyFrenzi`}
        keywords={`${selectedPlatformOttName}, trending, ${pType}, weekly, MyFrenzi`}
      />

      {isLoading && <Loader />}
      <Grid className="new-container">
        <Grid
          container
          alignItems={"center"}
          className="pt-20"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <ArrowBackRoundedIcon
                onClick={() => navigate(-1)}
                className="pointer mr-10 mt-7"
              />
              {!is_free_check || is_free_check == "false" ? (
                <p className="fs-28 fw-700 lh-28 m-0">
                  what's <span className="text-selected">trending</span>
                </p>
              ) : (
                <p className="fs-28 fw-700 lh-28 m-0">
                  <span className="text-selected">free</span> across platform
                </p>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className="pt-10 pb-20"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item xs={10}>
            <Grid container alignItems={"center"}>
              <Grid item className="">
                <Grid
                  container
                  onClick={handleClickListItem2}
                  alignItems={"center"}
                  className="pointer mt-20"
                >
                  <p className="fw-700 fs-15 m-0 mr-6 ">sort</p>
                  <SwapVertIcon />
                </Grid>
                <Popover
                  id={"menu2"}
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={handleClose2}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box className="pb-20 pl-20 pr-20">
                    <Grid
                      onClick={() => handleSort("d")}
                      container
                      className="mt-20 pointer"
                      sm={12}
                      justifyContent={"start"}
                    >
                      <p className={`fw-500 fs-16 m-0 ${sort === 'd' && 'text-selected'}`}>release date</p>
                    </Grid>
                    <Grid
                      onClick={() => handleSort("l")}
                      container
                      className="mt-20 pointer"
                      sm={12}
                      justifyContent={"start"}
                    >
                      <p className={`fw-500 fs-16 m-0 ${sort === 'l' && 'text-selected'}`}>popular</p>
                    </Grid>
                    <Grid
                      onClick={() => handleSort("r")}
                      container
                      className="mt-20 pointer"
                      sm={12}
                      justifyContent={"start"}
                    >
                      <p className={`fw-500 fs-16 m-0 ${sort === 'r' && 'text-selected'}`}>rating</p>
                    </Grid>
                  </Box>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          <SideFilter
            onSearchApply={(e) => handleApplyFilter(e)}
            hideClearBtn={true}
          />
        </Grid>
      </Grid>

      <Grid container className="new-container pb-20">
        <Grid flex={1} className="left-container">
          <Grid item style={{ maxWidth: '100%' }} flex={1}>
            <Grid item xs={12} style={{ marginBottom: '10px' }} justifyContent={'center'}>
              <Grid container justifyContent={'center'}>
                <MyAds type="banner_2" adv_type={'web_top_ten_middle_ad'} hideInMobile={true} />
              </Grid>
            </Grid>

            {(params.type === "trending-movies") &&
              platformMovieData &&
              platformMovieData.length > 0 && (
                <Grid container spacing={2}>
                  {platformMovieData.map((x, i) => {
                    return (<>
                      {(i !== 0 && i % 2 === 0 && i % 3 === 0) &&
                        <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                          <Grid container justifyContent={'center'}>
                            <MyAds type="banner_2" adv_type={'web_media_listing_ad'} />
                          </Grid>
                        </Grid>
                      }
                      <Grid item key={i} xs={12} md={6}>
                        <SingleMediaTile
                          refPage={"Trending"}
                          x={x}
                          platform={selectedPlatformName}
                        />
                      </Grid>
                    </>
                    );
                  })}
                </Grid>
              )}
            {params.type === "trending-series" && platformTvData && platformTvData.length > 0 && (
              <Grid container spacing={2}>
                {platformTvData.map((x, i) => {
                  return (<>
                    {(i !== 0 && i % 2 === 0 && i % 3 === 0) &&
                      <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                        <Grid container justifyContent={'center'}>
                          <MyAds type="banner_2" adv_type={'web_media_listing_ad'} />
                        </Grid>
                      </Grid>
                    }
                    <Grid item key={i} xs={12} md={6}>
                      <SingleMediaTile
                        refPage={"Trending"}
                        x={x}
                        platform={selectedPlatformName}
                      />
                    </Grid>
                  </>
                  );
                })}
              </Grid>
            )}
            {(params.type === "most-searched" || (params.type !== "trending-series" && params.type !== "trending-movies")) &&
              platformMediaSearchedData &&
              platformMediaSearchedData.length > 0 && (
                <Grid container spacing={2}>
                  {platformMediaSearchedData.map((x, i) => {
                    return (<>
                      {(i !== 0 && i % 2 === 0 && i % 3 === 0) &&
                        <Grid item key={`ads-${i}`} xs={12} style={{ marginTop: '10px' }} >
                          <Grid container justifyContent={'center'}>
                            <MyAds type="banner_2" adv_type={'web_media_listing_ad'} />
                          </Grid>
                        </Grid>
                      }
                      <Grid item key={i} xs={12} md={6}>
                        <SingleMediaTile
                          refPage={"Trending"}
                          x={x}
                          platform={selectedPlatformName}
                        />
                      </Grid>
                    </>
                    );
                  })}
                </Grid>
              )}
            {isLoading ?
              <MediaDetailLoader />
              : ''
            }
          </Grid>
        </Grid>
        {!isMobileDevice() ?
          <Grid className="right-container">
            <Grid className="right-container-ad">
              <MyAds type="banner_1" adv_type={'web_trending_top_ad'} />
            </Grid>
          </Grid>
          : ''}
      </Grid>
    </>
  );
}
