import React, { useEffect } from 'react'
import { Grid, Box, Button } from '@mui/material';
import { addRemovePersonFan, getFavPerson } from '../../slices/cast';
import { useDispatch, useSelector } from 'react-redux';
import helpers from '../../services/helpers';
import { Link } from 'react-router-dom';
import { toggleLoginModal } from '../../slices/global';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";

const breakpoints = {
    319: {
        slidesPerView: 3,
        spaceBetween: 15,
    },
    425: {
        slidesPerView: 4,
        spaceBetween: 15,
    },
    580: {
        slidesPerView: 5,
        spaceBetween: 15,
    },
    768: {
        slidesPerView: 6,
        spaceBetween: 20,
        slidesPerGroup: 5
    },
    1024: {
        slidesPerView: 8,
        spaceBetween: 20,
        slidesPerGroup: 5
    },
    1440: {
        slidesPerView: 9,
        spaceBetween: 20,
        slidesPerGroup: 8
    },
    1620: {
        slidesPerView: 11,
        spaceBetween: 20,
        slidesPerGroup: 8
    },
    1880: {
        slidesPerView: 11,
        spaceBetween: 30,
        slidesPerGroup: 9
    },
    2230: {
        slidesPerView: 14,
        spaceBetween: 30,
        slidesPerGroup: 12
    },
}

const page = 1;

export default function FollowedDirector(props) {


    const dispatch = useDispatch();
    const cast = useSelector(state => state.cast);

    useEffect(() => {
        if (cast.successMesg && cast.successMesg !== "") {
            var body = {
                page: page,
                pagelimit: 20
            }
            if (!props.hideToast) {
                dispatch(getFavPerson(body));
            }
        }
    }, [cast, dispatch])

    useEffect(() => {
        var body = {
            page: page,
            pagelimit: 20
        }
        dispatch(getFavPerson(body));
    }, [ page])

    const handleFollowUnfollow = (id) => {
        if (props.is_public) {
            dispatch(toggleLoginModal({ isOpen: true }));
        } else {
            dispatch(addRemovePersonFan({ body: { person: id } }));
        }
    }

    return (
        <>
            {cast.mostFavPersonData && cast.mostFavPersonData.length > 0 &&
                <Grid container className='app-container app-section section-border-bottom ' sx={{ paddingTop: { xs: '20px', sm: '30px' }, paddingBottom: { xs: '20px', sm: '30px' } }}>
                    <Grid item xs={12}>
                        <Grid item >
                            <h2 className='fw-700 fs-28 lh-28 m-0'>
                                most followed <span className='text-selected'>celebrities</span>
                            </h2>
                            {/* <h2 className='fw-500 fs-24 lh-24  mt-0 pt-5 mb-10'>
                                follow the newsmakers
                            </h2> */}
                        </Grid>
                        <Grid item xs={12}>
                            <Swiper
                                slidesPerView={2}
                                spaceBetween={30}
                                speed={1000}
                                // slidesPerGroup={5}
                                freeMode={true}
                                navigation={true}
                                breakpoints={breakpoints}
                                modules={[FreeMode, Navigation]}
                                className="mySwiper"
                            >
                                {cast.mostFavPersonData.map((x, i) => {
                                    return <SwiperSlide key={i}>
                                        <div style={{ padding: '20px 0px 20px 0px' }} key={i}>
                                            <Grid item className='text-center'>
                                                <Link to={`/person/${x?.unique_title || x?.name.replace(/ /g, "-")}${x?.unique_title ? '' : '/' + x?.person}`}>
                                                    <Box
                                                        component="img"
                                                        className='d-block scale-1'
                                                        // sx={{
                                                        //     height: 150,
                                                        //     width: '100%',
                                                        //     maxHeight: { xs: 150, sm: 150 },
                                                        //     borderRadius: '8px'
                                                        // }}
                                                        sx={{
                                                            width: '100%',
                                                            maxHeight: '210px',
                                                            borderRadius: '7px',
                                                            objectFit: 'cover',
                                                        }}
                                                        src={x.profile_image ? helpers.getFullUrl(x.profile_image, 185) : helpers.getDefaultImage('personmedia')}
                                                        alt={x?.name}
                                                    />
                                                </Link>
                                                <p style={{ wordBreak: 'break-all', paddingBottom: '8px' }} className='fw-500 fs-14 m-0 text-center ellipsis'>
                                                    {x.name || ''}
                                                </p>
                                                {x.is_fan ?
                                                    <Button onClick={() => handleFollowUnfollow(x?.person)} className='rounded-btn pr-20 pl-20 small fs-14 bg-selected text-light'>following</Button>
                                                    :
                                                    <Button onClick={() => handleFollowUnfollow(x?.person)} className='rounded-btn pr-20 pl-20 small fs-14 bg-light text-selected'>follow</Button>
                                                }
                                            </Grid>
                                        </div>
                                    </SwiperSlide>
                                })}
                            </Swiper>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>

    )
}
