import axios from "axios";
import axiosInstance from "../http-common";

const getSearchData = (body = {}) => {
    // let url = `native_main/aisearch?page=${page}&pagelimit=${pagelimit}&query=${query}`;
    let url = `native_main/aisearch`;
    // let url = `https://tidy-dassie-adapting.ngrok-free.app/ai_search`;
    if(body?.page > 1){
        url += `?page=${body?.page}`
    }
    return axiosInstance.post(url, body);
    // return axios.post(url, body);
};

const getCastData = (q, limit = 40, page = 1) => {
    let url = `native_main/personsearch?query=${q}&pagelimit=${limit}&page=${page}`;
    return axiosInstance.get(url);
};

const FrenziPartnerDataService = {
    getSearchData,
    getCastData
};

export default FrenziPartnerDataService;